import React from "react";
import { FilterType } from "../../../../../../components/interfaces/interfaces";
import ApplyFilter from "./Buttons/ApplyFilter/ApplyFilter";
import CancelFilter from "./Buttons/CancelFilter/CancelFilter";
import "./FilterOption.scss";

interface Props {
  payload: FilterType;
  setPayload: React.Dispatch<React.SetStateAction<FilterType>>;
  isInterSecting: boolean;
}

const FilterOption: React.FC<Props> = ({
  setPayload,
  payload,
  isInterSecting,
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPayload((prev) => ({ ...prev, option: e.target.value }));
  };
  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPayload((prev) => ({ ...prev, value: e.target.value }));
  };
  const handleCancel = () => {
    setPayload({ name: "", option: "", value: "" });
  };

  return (
    <div
      className={`filteroption ${
        !isInterSecting ? "halfpassed" : "nothalfpassed"
      }`}
    >
      <div className="filteroption_container">
        <div className="filteroption_radio_section">
          <div className="filteroption_row">
            <input
              type="radio"
              value="is"
              onChange={(event) => handleChange(event)}
              checked={payload.option === "is"}
            />
            <label className={`${payload.option === "is" ? "active" : ""}`}>
              is
            </label>
          </div>
          {payload.option === "is" && (
            <input
              type="text"
              required
              value={payload.value}
              onChange={(e) => handleValueChange(e)}
              className="filteroption_value_field"
            />
          )}
        </div>
        <div className="filteroption_radio_section">
          <div className="filteroption_row">
            <input
              type="radio"
              value="is not"
              onChange={(event) => handleChange(event)}
              checked={payload.option === "is not"}
            />
            <label className={`${payload.option === "is not" ? "active" : ""}`}>
              is not
            </label>
          </div>
          {payload.option === "is not" && (
            <input
              type="text"
              required
              value={payload.value}
              onChange={(e) => handleValueChange(e)}
              className="filteroption_value_field"
            />
          )}
        </div>
        <div className="filteroption_radio_section">
          <div className="filteroption_row">
            <input
              type="radio"
              value="contains"
              onChange={(event) => handleChange(event)}
              checked={payload.option === "contains"}
            />
            <label
              className={`${payload.option === "contains" ? "active" : ""}`}
            >
              contains
            </label>
          </div>
          {payload.option === "contains" && (
            <input
              type="text"
              required
              value={payload.value}
              onChange={(e) => handleValueChange(e)}
              className="filteroption_value_field"
            />
          )}
        </div>
      </div>
      <div className="filteroption_radio_buttons">
        <CancelFilter onClick={handleCancel} type="reset">
          Cancel
        </CancelFilter>
        <ApplyFilter type="submit">Apply filter</ApplyFilter>
      </div>
    </div>
  );
};

export default FilterOption;
