import { StyleSheet } from "@react-pdf/renderer";
export const styles = StyleSheet.create({
  header: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    backgroundColor: "transparent",
    paddingLeft: "15px",
    paddingRight: "20px",
    paddingBottom: "15px",
    position: "relative",
  },
  image: {
    width: "70px",
    height: "70px",
    objectFit: "contain",
  },
});
