import { View, Text, Font } from "@react-pdf/renderer";
import moment from "moment";
import React from "react";
import { Invoicestyles } from "../InvoiceStyles/InvoiceStyle";
import arial from "../../../../assets/fonts/Arial_medium.ttf";
import arialText from "../../../../assets/fonts/arialW.ttf";
import arialBold from "../../../../assets/fonts/Arial_bold.TTF";

Font.register({
  family: "Roboto",
  fonts: [
    {
      src: arial,
    },
    {
      src: arialText,
      fontWeight: 300,
    },
    { src: arialBold, fontWeight: 700 },
  ],
});

interface Props {
  invoiceDate: string;
  paymentTerms: number;
}

const InvoiceDate: React.FC<Props> = ({ invoiceDate, paymentTerms }) => {
  const nextMonth = moment(invoiceDate).add(1, "M");
  return (
    <View style={Invoicestyles.invoiceDate}>
      <Text
        style={{
          fontSize: 8.5,
          fontFamily: "Roboto",
          fontWeight: 300,
          lineHeight: 1.2,
        }}
      >
        Dátum dodania:{" "}
      </Text>
      <Text
        style={{
          fontSize: 9,
          fontWeight: 700,
          fontFamily: "Roboto",
          lineHeight: 1.2,
        }}
      >
        {moment(invoiceDate).endOf("M").format("DD.MM.YYYY")}
        {"     "}
      </Text>
      <Text
        style={{
          fontSize: 8.5,
          fontFamily: "Roboto",
          fontWeight: 300,
          lineHeight: 1.2,
        }}
      >
        Dátum vystavenia:{" "}
      </Text>
      <Text
        style={{
          fontSize: 9,
          fontWeight: 700,
          fontFamily: "Roboto",
          lineHeight: 1.2,
        }}
      >
        {moment(nextMonth).startOf("month").format("DD.MM.YYYY")}
        {"     "}
      </Text>
      <Text
        style={{
          fontSize: 8.5,
          fontFamily: "Roboto",
          fontWeight: 300,
          lineHeight: 1.2,
        }}
      >
        Dátum splatnosti:{" "}
      </Text>
      <Text
        style={{
          fontSize: 9,
          fontWeight: 700,
          fontFamily: "Roboto",
          lineHeight: 1.2,
        }}
      >
        {moment(invoiceDate)
          .add(1, "month")
          .add(paymentTerms, "day")
          .format("DD.MM.YYYY")}
      </Text>
    </View>
  );
};

export default InvoiceDate;
