import React from "react";
import "./UserInfoModalHeader.scss";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { ISection } from "../../../../../components/interfaces/interfaces";

interface Props {
  headerText: string;
  displayUserCredentials: ISection;
  setdisplayUserCredentials: React.Dispatch<React.SetStateAction<ISection>>;
}

const UserInfoModalHeader: React.FC<Props> = ({
  headerText,
  displayUserCredentials,
  setdisplayUserCredentials,
}) => {
  return (
    <span
      className="candidate_info_header"
      onClick={() => {
        setdisplayUserCredentials({
          ...displayUserCredentials,
          display: !displayUserCredentials.display,
          angle: displayUserCredentials.angle + 180,
        });
      }}
    >
      <h5 className="setting_header">{headerText}</h5>
      <KeyboardArrowDownIcon
        style={{
          transform: `rotate(${displayUserCredentials.angle}deg)`,
          transition: "all 550ms ease-in-out",
          cursor: "pointer",
          color: "#008db9",
        }}
      />
    </span>
  );
};

export default UserInfoModalHeader;
