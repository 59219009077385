import React, { useContext } from "react";
import "./InvoiceList.scss";
import DeleteIcon from "@mui/icons-material/Delete";
import UseAuth from "../../../../hooks/UseAuth";
import { invoiceContext } from "../../Invoices";
import { invoiceContexFromDescription } from "../../InvoiceDetails/InvoiceDetails";

type ListType = {
  id: number;
  itemName: string;
  Qty: string;
  price: number;
  total: string;
};

interface Props {
  list: ListType[];
  rate: string;
  setList: React.Dispatch<React.SetStateAction<ListType[]>>;
  byod: boolean;
}

const InvoiceList: React.FC<Props> = ({ list, setList, rate, byod }) => {
  const invoiceData = useContext(invoiceContext);
  const invoiceDescriptionData = useContext(invoiceContexFromDescription);
  const helpRate = invoiceData
    ? String(invoiceData)
    : String(invoiceDescriptionData);
  const finalRate = helpRate.includes(",")
    ? helpRate.replace(",", ".")
    : helpRate;
  const itemRate = byod ? String(Number(Number(finalRate) + 2.5)) : finalRate;

  const { auth } = UseAuth();
  const addItem = () => {
    const maxId = list.length
      ? Math.max(...list.map((listElement) => listElement.id))
      : 0;
    const listFields = {
      id: maxId + 1,
      itemName: "",
      Qty: "",
      price: Number(itemRate),
      total: "",
    };
    setList([...list, listFields]);
  };

  const updateValue = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>,
    id: number
  ) => {
    const name = e.currentTarget.name;
    const value = e.currentTarget.value;
    console.log(String(Number(Number(Number(value) / 8) * Number(itemRate))));
    setList((current: ListType[]) =>
      current.map((listElement: ListType) => {
        if (Number(listElement.id) === id) {
          return {
            ...listElement,
            [name]: value.toString(),
            total:
              name === "Qty"
                ? String(Number(Number(Number(value) / 8) * Number(itemRate)))
                : listElement.total,
          };
        }
        return listElement;
      })
    );
  };

  const deleteFromList = (id: number) => {
    setList((current: ListType[]) =>
      current.filter((listElement: ListType) => {
        return Number(listElement.id) !== id;
      })
    );
  };

  return (
    <div className="invoiceList">
      {list &&
        list.map((listItem: ListType) => (
          <div className="invoiceList_element" key={listItem.id}>
            <div className="invoiceList_element_field">
              <label htmlFor="itemName">Item Name</label>
              <input
                required
                name="itemName"
                value={listItem.itemName}
                onChange={(e) => updateValue(e, listItem.id)}
                type="text"
              />
            </div>
            <div className="invoiceList_element_field">
              <label htmlFor="Qty">Hours</label>
              <input
                required
                name="Qty"
                value={listItem.Qty}
                style={{ width: 70 }}
                onChange={(e) => {
                  updateValue(e, listItem.id);
                  // setList((current: ListType[]) =>
                  //   current.map((element: ListType) => {
                  //     if (Number(element.id) === listItem.id) {
                  //       return {
                  //         ...element,
                  //         Qty: e.target.value.toString(),
                  //         total: String(
                  //           Number(
                  //             Number(Number(e.target.value) / 8) *
                  //               Number(itemRate)
                  //           )
                  //         ),
                  //       };
                  //     }
                  //     return element;
                  //   })
                  // );
                }}
                type="text"
              />
            </div>
            <div className="invoiceList_element_field">
              <label htmlFor="price">Price</label>
              <input
                name="price"
                value={Number(itemRate)}
                style={{ width: 80 }}
                disabled
                type={"number"}
              />
            </div>
            <div className="invoiceList_element_field">
              <label htmlFor="total">Total</label>
              <input
                name="total"
                value={Number(
                  (Number(listItem.Qty) / 8) * Number(itemRate)
                ).toFixed(2)}
                style={{ width: 95, border: "none", fontWeight: "bold" }}
                disabled
                type={"number"}
              />
            </div>
            <DeleteIcon
              sx={{
                marginTop: "1.3rem",
                cursor: "pointer",
                ":hover": { color: "red" },
              }}
              onClick={() => deleteFromList(listItem.id)}
            />
          </div>
        ))}
      <button className="add_invoice" onClick={addItem} type="button">
        + Add New Item
      </button>
    </div>
  );
};

export default InvoiceList;
