import React, { useState } from "react";
import { Document, Page, Text, PDFViewer, Font } from "@react-pdf/renderer";

import { useParams } from "react-router-dom";
import { styles } from "./PdfComponents/PDFStylings";
import ShowPDFHeader from "./PdfComponents/ShowPDFHeader";
import ShowPDFSummary from "./PdfComponents/ShowPDFSummary";
import ShowPDFSkills from "./PdfComponents/ShowPDFSkills";
import ShowPDFExperiences from "./PdfComponents/ShowPDFExperiences";
import ShowPDFProjects from "./PdfComponents/ShowPDFProjects";
import ShowPDFEducation from "./PdfComponents/ShowPDFEducation";
import ShowPDFCertification from "./PdfComponents/ShowPDFCertification";

import arial from "../../../assets/fonts/Arial_bold.TTF";
import { useQuery } from "react-query";
import { getCandidate } from "../../../api/candidateApi";
import { PDF_type } from "../../../components/interfaces/interfaces";
import ShowPDFLanguages from "./PdfComponents/ShowPDFLanguages";
import { UserAuth } from "../../../components/Context/StateProvider";
import { CircularProgress } from "@mui/material";

interface Props {
  PDFtype: string;
}

const ShowPDF2: React.FC<Props> = ({ PDFtype }) => {
  const [{ PDFSettings, selectedEmail, selectedNumber }] = UserAuth();
  const { id } = useParams();
  const [user, setUser] = useState<any>([]);
  const { isFetching } = useQuery(
    ["candidates", Number(id)],
    () => getCandidate(Number(id)),
    {
      refetchOnWindowFocus: false,
      retry: 0,
      onSuccess: (data) => {
        setUser(data);
      },
    }
  );
  Font.register({
    family: "Roboto",
    fonts: [
      {
        src: arial,
      },
    ],
  });
  Font.registerHyphenationCallback((word) => [word]);

  function noNumber() {
      if(PDFSettings.anonymizeNumber && selectedNumber === ""){
          return true;
      }
      return false;
  }
  function noEmail() {
      if(PDFSettings.anonymizeEmail && selectedEmail === "") {
          return true;
      }
      return false;
  }

  function noRegion() {
      if(PDFSettings.anonymizeRegion && user.region === ""){
          return true;
      }
      return false;
  }
  function noSummary(){
      if(PDFSettings.anonymizeSummary || user.summary ===""){
          return true;
      }
      return false;
  }

  function hideSummary() {
      if(noNumber() && noRegion() && noEmail() && noSummary()){
          return true;
      }
      return false;
  }
  console.log(hideSummary());

  if (isFetching) {
    return (
      <div>
        <CircularProgress />
      </div>
    );
  } else {
    return (
      <PDFViewer style={styles.viewer}>
        <Document>
          <Page style={styles.body} size="A4">
            <ShowPDFHeader />
            <Text
              style={{
                color: "#1F3864",
                fontSize: "18px",
                textTransform: "uppercase",
                fontFamily: "Roboto",
                fontWeight: 700,
              }}
            >
              {PDFSettings.anonymizeName
                ? user.firstName[0] + ". " + user.lastName[0] + "."
                : PDFtype === PDF_type.normal
                ? user.firstName + " " + user.lastName
                : user.firstName[0] + ". " + user.lastName[0] + "."}
            </Text>
              {!hideSummary() && (
                  <ShowPDFSummary
                      place={user.place}
                      email={user.email}
                      number={user.phone}
                      summary={user.summary}
                      region={user.region}
                      anonymizeNumber={PDFSettings.anonymizeNumber}
                      anonymizeRegion={PDFSettings.anonymizeRegion}
                      anonymizeEmail={PDFSettings.anonymizeEmail}
                      anonymizeSummary={PDFSettings.anonymizeSummary}
                      selectedEmail={selectedEmail}
                      selectedNumber={selectedNumber}
                      PDFType={PDFtype}
                  />
              )}
            {user.skills !== "" ? (
              <ShowPDFSkills skills={user?.skills} />
            ) : null}

            {user.experiences.length > 0 && (
              <ShowPDFExperiences
                experiences={user?.experiences}
                anonymizeExperienceName={PDFSettings.anonymizeExperienceName}
                pdfType={PDFtype}
              />
            )}
            {user.projects.length > 0 && (
              <ShowPDFProjects projects={user.projects} />
            )}
            {user.education.length > 0 && (
              <ShowPDFEducation education={user.education!} />
            )}
            {user.certifications !== "" ? (
              <ShowPDFCertification certifications={user.certifications!} />
            ) : null}
            {JSON.parse(user.languages) !== null &&
            JSON.parse(user.languages)[0].language !== "" ? (
              <ShowPDFLanguages languages={user.languages} />
            ) : null}
          </Page>
        </Document>
      </PDFViewer>
    );
  }
};

export default ShowPDF2;
