import React from "react";
import "./UserActiveLayer.scss";

interface Props {
  menu: string;
}

const UserActiveLayer: React.FC<Props> = ({ menu }) => {
  return (
    <div
      className={`${
        menu === "Dashboard"
          ? "userBackground"
          : menu === "Invoices"
          ? "userBackground2"
          : menu === "Settings"
          ? "userBackground3"
          : ""
      }`}
    />
  );
};

export default UserActiveLayer;
