import React from "react";
import "./SettingsNavigation.scss";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import SettingsIcon from "@mui/icons-material/Settings";
import UseAuth from "../../../hooks/UseAuth";
import { ROLES } from "../../../components/Roles/Roles";

interface Props {
  navigation: string;
  setNavigation: React.Dispatch<React.SetStateAction<string>>;
}

const SettingsNavigation: React.FC<Props> = ({ navigation, setNavigation }) => {
  const { auth } = UseAuth();
  return (
    <div className="settings_navigation">
      {auth.userRoles === ROLES.ADMIN ? (
        <>
          <button
            className={navigation === "users" ? "active" : ""}
            onClick={() => setNavigation("users")}
          >
            <PeopleAltIcon /> <span>Users</span>
          </button>
          <button
            className={navigation === "addUser" ? "active" : ""}
            onClick={() => setNavigation("addUser")}
          >
            <GroupAddIcon /> <span>Add user</span>
          </button>
          <button
            className={navigation === "general" ? "active" : ""}
            onClick={() => setNavigation("general")}
          >
            <SettingsIcon /> <span>General</span>
          </button>
        </>
      ) : (
        <>
          <button
            className={navigation === "changeUserSettings" ? "active" : ""}
            onClick={() => setNavigation("changeUserSettings")}
          >
            <ManageAccountsIcon /> <span>Account</span>
          </button>
        </>
      )}
    </div>
  );
};

export default SettingsNavigation;
