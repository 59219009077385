import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import "./GDPRBody.scss";
import { TiUserDeleteOutline } from "react-icons/ti";
import { useNavigate } from "react-router-dom";
import TableButtonPrimary from "../../../components/Buttons/TableButtons/PrimaryButton/TableButtonPrimary";
import TableButtonSecondary from "../../../components/Buttons/TableButtons/SecondaryButton/TableButtonSecondary";
import { UserAuth } from "../../../components/Context/StateProvider";
import { actTypes } from "../../../components/Context/Reducer";
import Loading from "../../../components/Loading/Loading";

interface Props {
  data: {
    id: number;
    firstName: string;
    lastName: string;
    gdpr: number;
    gdpr_valid_date: string | null;
    expiration: number | null;
  }[];
  setConfirmDelete: React.Dispatch<React.SetStateAction<boolean>>;
  setDeleteID: React.Dispatch<React.SetStateAction<any>>;
}

const GDPRBody: React.FC<Props> = ({ data, setConfirmDelete, setDeleteID }) => {
  const [{ expirationCount }, dispatch] = UserAuth();
  const [gdprData, setGdprData] = useState<any[]>([]);

  const navigate = useNavigate();

  const showCV = (id: number) => {
    navigate(`/cv/${id}`);
  };

  const deleteCV = (id: number) => {
    setConfirmDelete(true);
    setDeleteID(id);
  };

  useEffect(() => {
    const now = dayjs(new Date()).format("YYYY-MM-DD");
    if (data.length > 0) {
      let count = 0;
      dispatch({
        type: actTypes.EXPIRATION_COUNT,
        expirationCount: count,
      });
      const filteredData = data.filter((dataElement) => {
        if (
          dataElement.gdpr === 1 &&
          (dayjs(dataElement.gdpr_valid_date).diff(now, "month") <= 2 ||
            isNaN(dayjs(dataElement.gdpr_valid_date).diff(now, "month")))
        ) {
          dataElement.expiration = dayjs(dataElement.gdpr_valid_date).diff(
            now,
            "month"
          );
          dispatch({
            type: actTypes.EXPIRATION_COUNT,
            expirationCount: ++count,
          });
          return dataElement;
        }
      });
      filteredData.sort((a: any, b: any) => a.expiration - b.expiration);
      setGdprData(filteredData);
    }
  }, [data, dispatch]);

  if (!gdprData) {
    return <Loading />;
  }

  return (
    <tbody>
      {gdprData &&
        gdprData.map((userData) => (
          <tr
            key={userData.id}
            className={`${
              userData.expiration === 0 ||
              userData.expiration < 0 ||
              isNaN(userData.expiration)
                ? "critical"
                : userData.expiration === 1
                ? "normal"
                : "good"
            }`}
          >
            <td>
              <p style={{ fontWeight: 500, color: "black" }}>
                {userData.firstName} {userData.lastName}
              </p>
            </td>
            <td>
              <p>{userData.gdpr === 1 ? "Valid" : "Invalid"}</p>
            </td>
            <td>
              <p>
                {userData.expiration === 0
                  ? "Less than a month"
                  : userData.expiration === 1
                  ? "1 month"
                  : userData.expiration < 0
                  ? "Expired"
                  : isNaN(userData.expiration)
                  ? "GDRP expiration date is missing"
                  : `${userData.expiration} months`}
              </p>
            </td>
            <td>
              <TableButtonPrimary onClick={() => showCV(userData.id)}>
                SHOW CV
              </TableButtonPrimary>
              <TableButtonSecondary onClick={() => deleteCV(userData.id)}>
                <TiUserDeleteOutline className="delete_svg" />
              </TableButtonSecondary>
            </td>
          </tr>
        ))}
    </tbody>
  );
};

export default GDPRBody;
