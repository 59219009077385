import React from "react";
import "./Notification.scss";
import { MdGppBad } from "react-icons/md";
import { VscPass } from "react-icons/vsc";

const Notification = (props: { message: string; error: boolean }) => {
  return (
    <div className={`notification ${props.error ? "successfull" : "error"}`}>
      {props.error ? <VscPass fontSize={27} /> : <MdGppBad fontSize={27} />}
      {props.message}
    </div>
  );
};

export default Notification;
