import {
  Paragraph,
  Table,
  TableRow,
  TableCell,
  TextRun,
  BorderStyle,
  WidthType,
} from "docx";
export const languageComponent = (language: string) => {
  return new Table({
    columnWidths: [10000],
    borders: {
      top: {
        style: BorderStyle.SINGLE,
        size: 0,
        color: "e6e6e6",
      },
      left: {
        style: BorderStyle.SINGLE,
        size: 0,
        color: "e6e6e6",
      },
      bottom: {
        style: BorderStyle.SINGLE,
        size: 0,
        color: "e6e6e6",
      },
      right: {
        style: BorderStyle.SINGLE,
        size: 0,
        color: "e6e6e6",
      },
    },
    rows: [
      new TableRow({
        children: [
          new TableCell({
            width: {
              size: 10000,
              type: WidthType.DXA,
            },
            borders: {
              top: {
                style: BorderStyle.SINGLE,
                size: 0,
                color: "e6e6e6",
              },
              left: {
                style: BorderStyle.SINGLE,
                size: 0,
                color: "e6e6e6",
              },
              bottom: {
                style: BorderStyle.SINGLE,
                size: 0,
                color: "e6e6e6",
              },
              right: {
                style: BorderStyle.SINGLE,
                size: 0,
                color: "e6e6e6",
              },
            },
            children: [
              new Paragraph({
                spacing: {
                  before: 35,
                  after: 35,
                },
                indent: {
                  left: 60,
                  right: 60,
                },
                children: [
                  new TextRun({
                    text: language,
                    font: "Arial",
                    size: 24,
                  }),
                ],
              }),
            ],
          }),
        ],
      }),
    ],
  });
};
