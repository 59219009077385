import Box from "@mui/material/Box/Box";
import Modal from "@mui/material/Modal/Modal";
import TextField from "@mui/material/TextField/TextField";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import React, { useState } from "react";
import CancelButton from "../../../../components/Buttons/Cancel/CancelButton";
import SubmitButton from "../../../../components/Buttons/Submit/SubmitButton";
import Notification from "../../../../components/Notification/Notification";
import "./AddEventModal.scss";
import { useEffect } from "react";

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddEventModal: React.FC<Props> = ({ open, setOpen }) => {
  const [title, setTitle] = useState<string>("");
  const [firstDate, setFirstDate] = useState<Dayjs | null>(null);
  const [secondDate, setSecondDate] = useState<Dayjs | null>(null);
  const [accessToken, setAccessToken] = useState("");

  //Notification
  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");
  const [displayNotification, setDisplayNotification] = useState(false);
  const timeZone = "Europe/Prague";
  const calendarID = "aberesik%40gmail.com";

  const renderNotification = (Message: string, Error: boolean) => {
    setError(Error);
    setMessage(Message);
    setDisplayNotification(true);
    const timeId = setTimeout(() => {
      // After 3 seconds set the show value to false
      setDisplayNotification(false);
    }, 4000);

    return () => {
      clearTimeout(timeId);
    };
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleCreateEvent = async () => {
    let token = accessToken;
    const payload = {
      summary: title,
      location: "",
      start: {
        dateTime: dayjs(firstDate).format("YYYY-MM-DDTHH:mm:00"),
        timeZone: timeZone,
      },
      end: {
        dateTime: dayjs(secondDate).format("YYYY-MM-DDTHH:mm:00"),
        timeZone: timeZone,
      },
      attendees: [],
      reminders: {
        useDefault: false,
        overrides: [{ method: "email", minutes: 24 * 60 }],
      },
    };
    if (token === "") {
      await gapi.auth.authorize(
        {
          client_id:
            "725912353387-d73mbc9levd7tcftnnfe9cehkilpftgn.apps.googleusercontent.com",
          scope:
            "https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.events",
          response_type: " id_token permission",
        },
        function (response: any) {
          token = response.access_token;
          setAccessToken(response.access_token);
        }
      );
    }
    function initiate() {
      gapi.client
        .request({
          path: `https://www.googleapis.com/calendar/v3/calendars/${calendarID}/events`,
          method: "POST",
          body: payload,
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then(
          (response) => {
            renderNotification("Event has been added to calendar", true);
            return [true, response];
          },
          function (err) {
            resendCalendarAction(payload);
            return [false, err];
          }
        );
    }
    gapi.load("client:auth2", initiate);
  };
  const resendCalendarAction = async (payload: any) => {
    let token = "";
    await gapi.auth.authorize(
      {
        client_id:
          "725912353387-d73mbc9levd7tcftnnfe9cehkilpftgn.apps.googleusercontent.com",
        scope:
          "https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.events",
        response_type: " id_token permission",
      },
      function (response: any) {
        token = response.access_token;
        setAccessToken(response.access_token);
      }
    );
    gapi.client
      .request({
        path: `https://www.googleapis.com/calendar/v3/calendars/${calendarID}/events`,
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then(
        (response) => {
          return [true, response];
        },
        function (err) {
          return [false, err];
        }
      );
  };
  useEffect(() => {
    if (firstDate === null || secondDate === null || title === "") {
      setError(false);
      setMessage("Please fill out all fields!");
      setDisplayNotification(true);
    } else {
      setMessage("");
      setDisplayNotification(false);
    }
  }, [firstDate, secondDate]);
  return (
    <Modal open={open} onClose={handleClose}>
      <Box className="addEvent_modal">
        {displayNotification ? (
          <Notification message={message} error={error} />
        ) : null}
        <div className="addEvent_modal_container">
          <p style={{ fontSize: 20 }}>Add event to calendar</p>
          <div className="addEvent_modal_container_fields">
            <div className="user_input">
              <label htmlFor="title">Title</label>
              <input
                type={"text"}
                style={{ width: "50%" }}
                name="userName"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                required
              />
            </div>
            <div className="user_input_time">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  label="Start"
                  renderInput={(params) => <TextField {...params} />}
                  value={firstDate}
                  onChange={(newValue) => {
                    setFirstDate(newValue);
                  }}
                  className="date_picker"
                />
                <DateTimePicker
                  label="End"
                  renderInput={(params) => <TextField {...params} />}
                  value={secondDate}
                  onChange={(newValue) => {
                    setSecondDate(newValue);
                  }}
                  className="date_picker"
                />
              </LocalizationProvider>
            </div>
          </div>
          <div className="addEvent_modal_container_buttons">
            <SubmitButton
              onClick={handleCreateEvent}
              disabled={displayNotification}
            >
              Add
            </SubmitButton>
            <CancelButton onClick={handleClose}>Cancel</CancelButton>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default AddEventModal;
