import { View, Text, Font } from "@react-pdf/renderer";
import React from "react";
import { styles } from "./PDFStylings";

import arialText from "../../../../assets/fonts/arialW.ttf";
import arial from "../../../../assets/fonts/Arial_bold.TTF";

Font.register({
  family: "Roboto",
  fonts: [
    {
      src: arial,
    },
    {
      src: arialText,
      fontWeight: 300,
    },
  ],
});

interface Props {
  certifications: string;
}

const ShowPDFCertification: React.FC<Props> = ({ certifications }) => {
  return (
    <View style={styles.skills} wrap={false}>
      <View style={styles.skills_header}>
        <Text
          style={{
            color: "#1F3864",
            fontSize: "14px",
            width: "100%",
            backgroundColor: "#c9c9c9",
            textTransform: "uppercase",
            padding: "4px",
            fontWeight: "bold",
            fontFamily: "Roboto",
          }}
        >
          CERTIFICATIONS
        </Text>
        <View
          style={{
            backgroundColor: "transparent",
            paddingLeft: "4px",
            display: "flex",
            paddingTop: "4px",
            width: "100%",
          }}
        >
          <Text
            style={{
              fontSize: "12px",
              color: "black",
              width: "100%",
              lineHeight: "1.3px",
              fontFamily: "Roboto",
              fontWeight: 300,
            }}
          >
            {certifications}
          </Text>
        </View>
      </View>
    </View>
  );
};

export default ShowPDFCertification;
