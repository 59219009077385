import React, { useState } from "react";
import { HiOutlineFilter } from "react-icons/hi";
import { UserAuth } from "../../../components/Context/StateProvider";
import Columnfilter from "../Modals/ColumnFilter/Columnfilter";
import "./CVTableHead.scss";
import { useEffect } from "react";

export interface Props {
  CVHeading: string[];
}

const CVTableHead: React.FC<Props> = ({ CVHeading }) => {
  const [{ statusFilter }] = UserAuth();
  const [open, setOpen] = useState(false);
  const [number, setNumber] = useState(statusFilter.length);
  const [scrollPosition, setScrollPosition] = useState(0);


  useEffect(() => {
    if (statusFilter[1] === undefined) {
      setNumber(0);
    } else {
      setNumber(statusFilter.length - 1);
    }
  }, [statusFilter]);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.pageYOffset);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <thead className={`${scrollPosition > 220 ? "scrolled":""}`}>
        <tr>
          {CVHeading.map((head, index) => (
            <th key={index}>
              {head === "Status" ? (
                <div className="head_filter">
                  {head}
                  <HiOutlineFilter onClick={() => setOpen((prev) => !prev)} />
                  {open && <Columnfilter />}
                  {number > 0 ? (
                    <p>{number}</p>
                  ) : (
                    <p style={{ backgroundColor: "transparent" }}></p>
                  )}
                </div>
              ) : (
                head
              )}
            </th>
          ))}
        </tr>
      </thead>
    </>
  );
};

export default CVTableHead;
