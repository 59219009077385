import dayjs from "dayjs";
import React, { useEffect, useState } from "react";

interface IInvoiceDate {
  daysUntilInvoice: number;
  lengthOfMonth: number;
}

const useDaysUntilInvoice = () => {
  const [result, setResult] = useState<IInvoiceDate>({
    daysUntilInvoice: 0,
    lengthOfMonth: 0,
  });
  useEffect(() => {
    const currentDate = dayjs();
    const lastDayOfMonth = currentDate.endOf("month");
    setResult({
      daysUntilInvoice: currentDate.date(),
      lengthOfMonth: currentDate.daysInMonth(),
    });
  }, []);
  return result;
};

export default useDaysUntilInvoice;
