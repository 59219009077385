import React from "react";
import "./CustomerActiveLayer.scss";

interface Props {
  menu: string;
}

const CustomerActiveLayer: React.FC<Props> = ({ menu }) => {
  return (
    <div className={`${menu === "Employees" ? "customerBackground2" : ""}`} />
  );
};

export default CustomerActiveLayer;
