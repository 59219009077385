import React from "react";
import "./InvoiceDetailsHeader.scss";
import { ROLES } from "../../../../components/Roles/Roles";

interface Props {
  role: string;
  status: string;
  setopenNewInvoice: React.Dispatch<React.SetStateAction<boolean>>;
  setConfirmDelete: React.Dispatch<React.SetStateAction<boolean>>;
}

const InvoiceDetailsHeader: React.FC<Props> = ({
  role,
  status,
  setopenNewInvoice,
  setConfirmDelete,
}) => {
  return (
    <div className="invoiceDetails_header">
      <div className="invoiceDetails_header_info">
        <p>Status</p>
        <div
          className={`invoice_element_status ${
            status === "PAID"
              ? "paid"
              : status === "PENDING"
              ? "pending"
              : "declined"
          }`}
        >
          <div
            className={`invoice_element_status_dot ${
              status === "PAID"
                ? "paid_dot"
                : status === "PENDING"
                ? "pending_dot"
                : "declined_dot"
            }`}
          ></div>
          {status}
        </div>
      </div>
      <div className="invoiceDetails_buttons">
        <button
          className="pdfButton"
          onClick={() =>
            window.open(`/invoice/pdf`, "_blank", "noopener,noreferrer")
          }
        >
          Create PDF
        </button>
        <button className="editButton" onClick={() => setopenNewInvoice(true)}>
          Edit
        </button>
        {role === ROLES.ADMIN && (
          <>
            <button
              className="deleteButton"
              onClick={() => {
                setConfirmDelete(true);
              }}
            >
              Delete
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default InvoiceDetailsHeader;
