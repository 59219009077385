import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";

import "./App.scss";
import Navbar from "./components/Navbar/Navbar";
import Protected from "./components/Protected/Protected";
import { ROLES } from "./components/Roles/Roles";
import RouteAuth from "./components/RouteAuth/RouteAuth";
import UseAuth from "./hooks/UseAuth";
import NewCV from "./pages/CreateCV/NewCV";
import ShowPDF from "./pages/CreateCV/Pdf/ShowPDF";
import Dashboard from "./pages/Dashboard/Dashboard";
import Employees from "./pages/Employees/Employees";
import GDPRValidation from "./pages/GRPRValidation/GDPRValidation";
import Home from "./pages/Home/Home";
import InvoiceDetails from "./pages/Invoices/InvoiceDetails/InvoiceDetails";
import InvoicePDF from "./pages/Invoices/InvoicePDF/InvoicePDF";
import Invoices from "./pages/Invoices/Invoices";
import Login from "./pages/Login/Login";
import General from "./pages/Settings/General/General";
import Settings from "./pages/Settings/Settings";
import UserDashboard from "./pages/UserDashboard/UserDashboard";

function App() {
  const { auth } = UseAuth();
  const location = useLocation();
  const currentRoute = location.pathname;
  const excludedRoutes = ["/", "/invoice/pdf", "pdf"];
  const match = currentRoute.includes("pdf");

  return (
    <div className="App">
      {excludedRoutes.includes(currentRoute) || match ? null : <Navbar />}
      <Routes>
        <Route path="/" element={<Login />} />
        {/* CUSTOMER */}
        <Route
          element={<RouteAuth allowedRoles={[ROLES.CUSTOMER, ROLES.ADMIN]} />}
        >
          <Route
            path="/cv/:id/pdf"
            element={
              <Protected>
                <ShowPDF PDFtype="normal" />
              </Protected>
            }
          />
        </Route>
        {/* CUSTOMER, ADMIN, USER ROUTES */}
        <Route
          element={
            <RouteAuth
              allowedRoles={[ROLES.ADMIN, ROLES.CUSTOMER, ROLES.USER]}
            />
          }
        >
          <Route
            path="/dashboard"
            element={
              <Protected>
                {auth.userRoles === ROLES.ADMIN ? (
                  <Dashboard />
                ) : auth.userRoles === ROLES.USER ? (
                  <UserDashboard />
                ) : null}
              </Protected>
            }
          />
          <Route
            path="/employees"
            element={
              <Protected>
                <Employees />
              </Protected>
            }
          />
          <Route
            path="/cv/anonymized/:id"
            element={
              <Protected>
                <NewCV fullTemplate={false} pdf={false} />
              </Protected>
            }
          />
        </Route>
        {/* ADMIN, USER ROUTES */}
        <Route element={<RouteAuth allowedRoles={[ROLES.ADMIN, ROLES.USER]} />}>
          <Route
            path="/invoices"
            element={
              <Protected>
                <Invoices />
              </Protected>
            }
          />
          <Route
            path="/invoice/pdf"
            element={
              <Protected>
                <InvoicePDF />
              </Protected>
            }
          />
          <Route
            path="/invoice/details/:id"
            element={
              <Protected>
                <InvoiceDetails />
              </Protected>
            }
          />
          <Route
            path="/settings"
            element={
              <Protected>
                <Settings />
              </Protected>
            }
          />
        </Route>
        {/* ADMIN ONLY ROUTES */}
        <Route element={<RouteAuth allowedRoles={[ROLES.ADMIN]} />}>
          <Route
            path="/dashboard"
            element={
              <Protected>
                <Dashboard />
              </Protected>
            }
          />
          <Route
            path="/cv"
            element={
              <Protected>
                <Home />
              </Protected>
            }
          />
          <Route
            path="/cv/new"
            element={
              <Protected>
                <NewCV fullTemplate={true} pdf={false} />
              </Protected>
            }
          />
          <Route
            path="/cv/link"
            element={
              <Protected>
                <NewCV fullTemplate={false} pdf={false} />
              </Protected>
            }
          />
          <Route
            path="/cv/:id"
            element={
              <Protected>
                <NewCV fullTemplate={true} pdf={true} />
              </Protected>
            }
          />
          <Route
            path="/cv/:id/pdf"
            element={
              <Protected>
                <ShowPDF PDFtype="normal" />
              </Protected>
            }
          />
          <Route
            path="/gdpr"
            element={
              <Protected>
                <GDPRValidation />
              </Protected>
            }
          />
          <Route
            path="/general"
            element={
              <Protected>
                <General />
              </Protected>
            }
          />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
