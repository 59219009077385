import React, { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import CancelButton from "../../../components/Buttons/Cancel/CancelButton";
import SubmitButton from "../../../components/Buttons/Submit/SubmitButton";
import "./SettingsModal.scss";
import { getSettings, updateSettings } from "../../../api/settingsApi";
import { useEffect } from "react";

interface Props {
  setOpenSettings: React.Dispatch<React.SetStateAction<boolean>>;
}
type SettingsType = {
  id: number;
  whitespaceRemove: boolean;
  clientNumber: string;
  byod: string;
};

const SettingsModal: React.FC<Props> = ({ setOpenSettings }) => {
  const [settingsValues, setSettingsValues] = useState<SettingsType>({
    id: 0,
    clientNumber: "0",
    whitespaceRemove: false,
    byod: "0",
  });

  const queryClient = useQueryClient();
  const { data: settings, isFetched } = useQuery("settings", getSettings, {
    refetchOnWindowFocus: false,
    retry: 0,
  });
  const updateSettingsMutation = useMutation(updateSettings, {
    onSuccess: (data) => {
      // Invalidates cache and refetch
      queryClient.invalidateQueries("settings");
      // renderNotification("Settings updated", true);
    },
    onError: () => {
      // renderNotification("", false);
    },
  });

  const sendUpdateDSettings = () => {
    if (settingsValues.clientNumber === "") {
      settingsValues.clientNumber = "0";
    }
    console.log(settingsValues.clientNumber);
    const payload = {
      id: 0,
      whitespaceRemove: settingsValues.whitespaceRemove,
      clientNumber: Number(settingsValues.clientNumber),
      byod: settings.byod,
    };
    updateSettingsMutation.mutate(payload);
    console.log(payload);
  };

  useEffect(() => {
    if (isFetched) {
      setSettingsValues(settings[0]);
    }
  }, [isFetched, settings]);
  return (
    <>
      {isFetched && (
        <div className="settings_modal">
          <div className="settings_modal_container">
            <h2>Settings</h2>
            <div className="settings_modal_row">
              <p>Customers</p>
              <input
                type={"text"}
                value={settingsValues.clientNumber}
                onChange={(e) => {
                  setSettingsValues((prev) => ({
                    ...prev,
                    clientNumber: e.target.value,
                  }));
                }}
              />
            </div>
            <div className="settings_modal_row">
              <p>Remove multiple whitespaces on paste</p>
              <input
                type={"checkbox"}
                checked={settingsValues.whitespaceRemove}
                onChange={(e) =>
                  setSettingsValues((prev) => ({
                    ...prev,
                    whitespaceRemove: !prev.whitespaceRemove,
                  }))
                }
              />
            </div>
          </div>
          <div className="settings_modal_buttons">
            <CancelButton
              type="button"
              onClick={() => setOpenSettings((prev) => !prev)}
            >
              Cancel
            </CancelButton>
            <SubmitButton type="submit" onClick={sendUpdateDSettings}>
              Submit
            </SubmitButton>
          </div>
        </div>
      )}
    </>
  );
};

export default SettingsModal;
