import React from "react";
import "../NewInvoiceFrom/NewInvoiceFrom.scss";

interface Props {
  iban: string;
  swift: string;
  constantSymbol: string;
  variableSymbol: string;
  specificSymbol: string;

  setIban: React.Dispatch<React.SetStateAction<string>>;
  setSwift: React.Dispatch<React.SetStateAction<string>>;
  setConstantSymbol: React.Dispatch<React.SetStateAction<string>>;
  setVariableSymbol: React.Dispatch<React.SetStateAction<string>>;
  setSpecificSymbol: React.Dispatch<React.SetStateAction<string>>;
}

const PaymentInfo: React.FC<Props> = ({
  iban,
  swift,
  constantSymbol,
  variableSymbol,
  specificSymbol,
  setIban,
  setSwift,
  setVariableSymbol,
  setConstantSymbol,
  setSpecificSymbol,
}) => {
  return (
    <div className="invoice_add_from">
      <div className="invoice_add_from_element_container">
        <div className="invoice_add_from_element">
          <label htmlFor="iban">IBAN</label>
          <input
            type={"text"}
            required
            disabled
            style={{ width: "100%" }}
            name="iban"
            value={iban}
            onChange={(e) => setIban(e.target.value)}
          />
        </div>
      </div>
      <div className="invoice_add_from_element_container">
        <div className="invoice_add_from_element">
          <label htmlFor="swift">SWIFT</label>
          <input
            type={"text"}
            style={{ width: "100%" }}
            name="swift"
            value={swift}
            disabled
            onChange={(e) => setSwift(e.target.value)}
          />
        </div>
        <div className="invoice_add_from_element">
          <label htmlFor="variableSymbol">Variable Symbol</label>
          <input
            type={"text"}
            style={{ width: "100%" }}
            name="variableSymbol"
            value={variableSymbol}
            onChange={(e) => setVariableSymbol(e.target.value)}
          />
        </div>
      </div>
      <div className="invoice_add_from_element_container">
        <div className="invoice_add_from_element">
          <label htmlFor="constantSymbol">Constant Symbol</label>
          <input
            type={"text"}
            style={{ width: "100%" }}
            name="constantSymbol"
            value={constantSymbol}
            onChange={(e) => setConstantSymbol(e.target.value)}
          />
        </div>
        <div className="invoice_add_from_element">
          <label htmlFor="specificSymbol">Specific Symbol</label>
          <input
            type={"text"}
            style={{ width: "100%" }}
            name="specificSymbol"
            value={specificSymbol}
            onChange={(e) => setSpecificSymbol(e.target.value)}
          />
        </div>
      </div>
    </div>
  );
};

export default PaymentInfo;
