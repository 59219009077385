import axios from "axios";
import {
  ClientType,
  InvoiceType,
  UpdatePasswordType,
} from "../components/interfaces/interfaces";
import { refreshToken } from "./authApi";
import { baseURL } from './apiConfig';

const serverApi = axios.create({
  //localhost:8080
  baseURL: baseURL,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});

export const getClient = async (id: string) => {
  await refreshToken();
  const bearer_token = `${localStorage.getItem("token")}`;
  const { data } = await serverApi.get(`/api/client/id/?id=${id}`, {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${bearer_token}`,
    },
  });
  return data;
};

export const getClients = async () => {
  await refreshToken();
  const bearer_token = `${localStorage.getItem("token")}`;
  const { data } = await serverApi.get("/api/client", {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${bearer_token}`,
    },
  });
  return data;
};

export const getClientsSettings = async () => {
  await refreshToken();
  const bearer_token = `${localStorage.getItem("token")}`;
  const { data } = await serverApi.get("/api/client/clients/settings", {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${bearer_token}`,
    },
  });
  return data;
};

export const getClientSettings = async (id: number) => {
  await refreshToken();
  const bearer_token = `${localStorage.getItem("token")}`;
  const { data } = await serverApi.get(
    `/api/client/client/settings/id/?id=${id}`,
    {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${bearer_token}`,
      },
    }
  );
  return data;
};

export const updateClient = async (payload: ClientType) => {
  await refreshToken();
  const bearer_token = `${localStorage.getItem("token")}`;
  await serverApi.put(`/api/client`, payload, {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${bearer_token}`,
    },
  });
};

export const deleteUser = async (id: number) => {
  await refreshToken();
  const bearer_token = `${localStorage.getItem("token")}`;
  await serverApi.delete(`/api/client/id/?id=${id}`, {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${bearer_token}`,
    },
  });
};

export const updateClientPassword = async (payload: UpdatePasswordType) => {
  await refreshToken();
  const bearer_token = `${localStorage.getItem("token")}`;
  await serverApi.put(`/api/client/passsword`, payload, {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${bearer_token}`,
    },
  });
};

export const getInvoices = async () => {
  await refreshToken();
  const bearer_token = `${localStorage.getItem("token")}`;
  const { data } = await serverApi.get("/api/client/invoices", {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${bearer_token}`,
    },
  });
  return data;
};

export const getInvoice = async (id: number) => {
  await refreshToken();
  const bearer_token = `${localStorage.getItem("token")}`;
  const { data } = await serverApi.get(`/api/client/invoice/id/?id=${id}`, {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${bearer_token}`,
    },
  });
  return data;
};

export const updateInvoice = async (invoice: InvoiceType) => {
  await refreshToken();
  const bearer_token = `${localStorage.getItem("token")}`;
  const { data } = await serverApi.put(`/api/client/invoice`, invoice, {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${bearer_token}`,
    },
  });
  return data;
};

export const createInvoice = async (invoice: InvoiceType) => {
  await refreshToken();
  const bearer_token = `${localStorage.getItem("token")}`;
  const { data } = await serverApi.post(`/api/client/invoice`, invoice, {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${bearer_token}`,
    },
  });
  return data;
};

export const deleteInvoice = async (id: number) => {
  await refreshToken();
  const bearer_token = `${localStorage.getItem("token")}`;
  await serverApi.delete(`/api/client/invoice/id/?id=${id}`, {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${bearer_token}`,
    },
  });
};
