import React, { createContext, useEffect, useState } from "react";
import "./InvoiceDetails.scss";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useNavigate, useParams } from "react-router-dom";
import { animated, useTransition } from "react-spring";
import NewInvoice from "../AddInvoice/NewInvoice";
import UseAuth from "../../../hooks/UseAuth";
import InvoiceDetailsHeader from "./InvoiceDetailsHeader/InvoiceDetailsHeader";
import InvoiceDetailsBody from "./InvoiceDetailsBody/InvoiceDetailsBody";
import { useQuery } from "react-query";
import { getClient, getInvoice, getInvoices } from "../../../api/clientApi";
import DeleteModal from "./DeleteModal/DeleteModal";
import { ROLES } from "../../../components/Roles/Roles";
import InvoiceNotification from "../InvoiceNotification/InvoiceNotification";
import useCurrentAndNextRate from "../../../hooks/useCurrentAndNextRate";

export const invoiceContexFromDescription = createContext<number | undefined>(
  undefined
);

const InvoiceDetails = () => {
  const { id } = useParams();
  const { auth } = UseAuth();
  const role = auth.userRoles;
  const navigate = useNavigate();
  const [openNewInvoice, setopenNewInvoice] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [triggerRefetch, setTriggerRefetch] = useState(false);

  const [sameInvoice, setSameInvoice] = useState(false);
  const [allInvoiceNumbers, setAllInvoiceNumbers] = useState<String[]>([]);
  const [allVariableNumbers, setAllVariableNumbers] = useState<String[]>([]);

  const { data, isFetched, refetch } = useQuery(
    ["invoices", id],
    () => getInvoice(Number(id)!),
    {
      refetchOnWindowFocus: false,
      retry: 0,
      onSuccess: (data) => {
        data.list === "" ? (data.list = []) : (data.list = data.list);
        data.invoices = JSON.parse(data.list);
        data.clientId = String(data.clientId);
      },
    }
  );
  const rateInformation = useCurrentAndNextRate(data?.projectsList);
  const transition = useTransition(openNewInvoice, {
    from: { x: -500, opacity: 0 },
    enter: { x: 0, opacity: 1 },
    leave: { x: -500, opacity: 0 },
  });
  const { data: invoicesData } = useQuery(
    ["invoices", auth.userName],
    () =>
      auth.userRoles === ROLES.ADMIN ? getInvoices() : getClient(auth.userName),
    {
      refetchOnWindowFocus: false,
      retry: 0,
      onSuccess: (data) => {
        if (auth.userRoles === ROLES.ADMIN) {
          setAllInvoiceNumbers(
            data.map((invoice: any) => invoice.invoiceNumber)
          );
          setAllVariableNumbers(
            data.map((invoice: any) => invoice.variableSymbol)
          );
        } else {
          setAllInvoiceNumbers(
            data.invoices.map((invoice: any) => invoice.invoiceNumber)
          );
          setAllVariableNumbers(
            data.invoices.map((invoice: any) => invoice.variableSymbol)
          );
        }
      },
    }
  );

  useEffect(() => {
    if (triggerRefetch) {
      refetch();
      setTriggerRefetch(false);
    }
  }, [triggerRefetch]);

  useEffect(() => {
    localStorage.setItem("InvoicePDF", JSON.stringify(data));
  }, [isFetched]);

  return (
    <div className="invoiceDetails">
      {sameInvoice && <InvoiceNotification />}
      {isFetched && (
        <div className="invoiceDetails_container">
          <div className="invoice_backButton" onClick={() => navigate(-1)}>
            <ChevronLeftIcon className="backButtonIcon" />
            <p>Back</p>
          </div>
          <InvoiceDetailsHeader
            role={role}
            setopenNewInvoice={setopenNewInvoice}
            status={data.status}
            setConfirmDelete={setConfirmDelete}
          />
          <InvoiceDetailsBody invoice={data} multiSportCard={invoicesData?.multiSportCard}/>
        </div>
      )}
      {transition((style, item) =>
        item ? (
          <animated.div style={style} className="invoices_detail_mod">
            <invoiceContexFromDescription.Provider
              value={rateInformation.currentRate}
            >
              <NewInvoice
                setopenNewInvoice={setopenNewInvoice}
                invoice={data}
                setSameInvoice={setSameInvoice}
                sameInvoice={sameInvoice}
                allInvoices={allInvoiceNumbers}
                allVariableNumbers={allVariableNumbers}
                multiSportCard={invoicesData.multiSportCard}
              />
            </invoiceContexFromDescription.Provider>
          </animated.div>
        ) : null
      )}
      {confirmDelete && (
        <DeleteModal
          open={confirmDelete}
          setOpen={setConfirmDelete}
          invoiceNumber={data.invoiceNumber}
          id={data.id}
        />
      )}
    </div>
  );
};

export default InvoiceDetails;
