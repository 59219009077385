import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { createUser } from "../../../api/authApi";
import CancelButton from "../../../components/Buttons/Cancel/CancelButton";
import SubmitButton from "../../../components/Buttons/Submit/SubmitButton";
import {
  ClientProjects,
  InvoiceType,
  ISection,
} from "../../../components/interfaces/interfaces";
import "./AddUser.scss";
import Notification from "../../../components/Notification/Notification";
import CustomToggle from "../Users/UserInfoModal/CustomToggle/CustomToggle";
import UserInfoModalHeader from "../Users/UserInfoModal/UserInfoModalHeader/UserInfoModalHeader";
import Collapse from "@mui/material/Collapse";
import { ROLES } from "../../../components/Roles/Roles";
import AddExperience from "../../../components/Buttons/AddExperience/AddExperience";
import RemoveExperience from "../../../components/Buttons/RemoveExperience/RemoveExperience";
import useCurrentAndNextRate from "../../../hooks/useCurrentAndNextRate";
import useFindNextRateDate from "../../../hooks/useFindNextRateDate";
import dayjs from "dayjs";
import CustomTooltip from "../../../components/CustomTooltip/CustomTooltip";

const AddUser = () => {
  const [address, seTaddress] = useState("");
  const [businessId, setBusinessId] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [email, setEmail] = useState("");
  const [iban, setIban] = useState("");
  const [invoices, setInvoices] = useState<InvoiceType[]>([]);
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [rate, setRate] = useState("");
  const [role, setRole] = useState("USER");
  const [swift, setSwift] = useState("");
  const [taxId, setTaxId] = useState("");
  const [userName, setUserName] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [byod, setByod] = useState<boolean>(false);
  const [contractExpirationDate, setContractExpirationDate] = useState("");
  const [multiSportCard, setMultiSportCard] = useState<boolean>(false);
  const [taxPayer, settaxPayer] = useState<boolean>(false);
  const [accompanyingMultiSportCard, setAccompanyingMultiSportCard] =
    useState<boolean>(false);
  const [clientProjects, setClientProjects] = useState<ClientProjects[]>([
    { id: 1, rate: "", startingFrom: "" },
  ]);

  //Notification
  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");
  const [displayNotification, setDisplayNotification] = useState(false);

  //Sections
  const [displayUserCredentials, setdisplayUserCredentials] =
    useState<ISection>({ angle: 180, display: true });
  const [displayPersonalInformation, setDisplayPersonalInformation] =
    useState<ISection>({ angle: 180, display: true });
  const [displayPaymentInformation, setDisplayPaymentInformation] =
    useState<ISection>({ angle: 180, display: true });
  const [displayRateInformation, setDisplayRateInformation] =
    useState<ISection>({ angle: 180, display: true });
  const [displayRoleInformation, setDisplayRoleInformation] =
    useState<ISection>({ angle: 180, display: true });
  const [displayContractInformation, setDisplayContractInformation] =
    useState<ISection>({ angle: 180, display: true });
  const [displayMultisportSection, setDisplayMultisportSection] =
    useState<ISection>({ angle: 180, display: true });
  const [displayProjectRates, setDisplayProjectRates] = useState<ISection>({
    angle: 180,
    display: true,
  });

  const data = useCurrentAndNextRate(clientProjects);
  const nextRateDate = useFindNextRateDate(clientProjects, data.nextRate);

  const renderNotification = (Message: string, Error: boolean) => {
    setError(Error);
    setMessage(Message);
    setDisplayNotification(true);
    const timeId = setTimeout(() => {
      // After 3 seconds set the show value to false
      setDisplayNotification(false);
    }, 4000);

    return () => {
      clearTimeout(timeId);
    };
  };

  //Adding candidate
  const addUserMutation = useMutation(createUser, {
    onSuccess: () => {
      // Invalidates cache and refetch
      renderNotification("User added to system", true);
    },
    onError: () => {
      renderNotification("Something went wrong", false);
    },
  });
  const handleAddProjectRate = () => {
    const highestId = clientProjects.reduce((maxId, obj: any) => {
      return obj.id > maxId ? obj.id : maxId;
    }, 0);
    setClientProjects((oldArray) => [
      { id: highestId + 1, rate: "", startingFrom: "" },
      ...oldArray,
    ]);
  };

  const handleDelete = (id: number) => {
    setClientProjects((oldArray) => oldArray.filter((obj) => obj.id !== id));
  };

  const handleCreateUser = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const clientProjectsWithoutID = clientProjects.map(
      ({ id, ...rest }) => rest
    );
    const payload = {
      address,
      businessId,
      city,
      country,
      email,
      iban,
      invoices,
      name,
      password,
      phoneNumber,
      rate,
      role,
      swift,
      taxId,
      userName,
      zipCode,
      byod,
      contractExpirationDate,
      multiSportCard,
      accompanyingMultiSportCard,
      taxPayer,
      clientProjects: clientProjectsWithoutID,
    };
    addUserMutation.mutate(payload);
    clearData();
  };

  const clearData = () => {
    setByod(false);
    setRate("");
    seTaddress("");
    setBusinessId("");
    setCity("");
    setCountry("");
    setEmail("");
    setIban("");
    setName("");
    setPassword("");
    setPhoneNumber("");
    setRole("USER");
    setSwift("");
    setTaxId("");
    setUserName("");
    setZipCode("");
    setContractExpirationDate("");
    setMultiSportCard(false);
    settaxPayer(false);
    setAccompanyingMultiSportCard(false);
    setClientProjects([{ id: 1, rate: "", startingFrom: "" }]);
  };

  const handleClose = () => {
    clearData();
  };

  useEffect(() => {
    if (!multiSportCard) {
      setAccompanyingMultiSportCard(false);
    }
  }, [multiSportCard]);

  useEffect(() => {
    if (data.currentRate) {
      setRate(String(data.currentRate));
    }
  }, [data.currentRate]);
  console.log(data);

  return (
    <div className="add_user">
      {displayNotification ? (
        <Notification message={message} error={error} />
      ) : null}
      <p className="add_user_header">Create User</p>
      <form onSubmit={(e) => handleCreateUser(e)}>
        <UserInfoModalHeader
          headerText="User Credentials"
          displayUserCredentials={displayUserCredentials}
          setdisplayUserCredentials={setdisplayUserCredentials}
        />
        <Collapse in={displayUserCredentials.display} style={{ width: "100%" }}>
          <div className="user_input_row">
            <div className="user_input">
              <label htmlFor="userName">User Name</label>
              <input
                type={"text"}
                style={{ width: "80%" }}
                name="userName"
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
                required
              />
            </div>
            <div className="user_input">
              <label htmlFor="password">Password</label>
              <input
                type={"password"}
                style={{ width: "80%" }}
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
          </div>
        </Collapse>
        <UserInfoModalHeader
          headerText="User Role"
          displayUserCredentials={displayRoleInformation}
          setdisplayUserCredentials={setDisplayRoleInformation}
        />
        <Collapse in={displayRoleInformation.display} style={{ width: "100%" }}>
          <div className="user_input_row">
            <div className="user_input">
              <label htmlFor="role">Role</label>
              <select
                required
                name="role"
                value={role}
                onChange={(e) => {
                  setRole(e.target.value);
                }}
              >
                <option value="USER">User</option>
                <option value="CUSTOMER">Customer</option>
                <option value="ADMIN">Admin</option>
              </select>
            </div>
          </div>
        </Collapse>
        {role === "USER" && (
          <>
            <UserInfoModalHeader
              headerText="Personal Information"
              displayUserCredentials={displayPersonalInformation}
              setdisplayUserCredentials={setDisplayPersonalInformation}
            />
            <Collapse
              in={displayPersonalInformation.display}
              style={{ width: "100%" }}
            >
              <div className="user_input_row">
                <div className="user_input">
                  <label htmlFor="companyName">Company Name</label>
                  <input
                    type={"text"}
                    style={{ width: "90%" }}
                    name="companyName"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                </div>
              </div>
              <div className="user_input_row">
                <div className="user_input">
                  <label htmlFor="number">Phone Number</label>
                  <input
                    type={"text"}
                    style={{ width: "80%" }}
                    name="number"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    required
                  />
                </div>
                <div className="user_input">
                  <label htmlFor="email">Email</label>
                  <input
                    type={"text"}
                    style={{ width: "80%" }}
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
              </div>
              <div className="user_input_row">
                <div className="user_input">
                  <label htmlFor="businessId">Business ID</label>
                  <input
                    type={"text"}
                    style={{ width: "80%" }}
                    name="businessId"
                    value={businessId}
                    onChange={(e) => setBusinessId(e.target.value)}
                    required
                  />
                </div>
                <div className="user_input">
                  <label htmlFor="taxId">Tax ID</label>
                  <input
                    type={"text"}
                    style={{ width: "80%" }}
                    name="taxId"
                    value={taxId}
                    onChange={(e) => setTaxId(e.target.value)}
                  />
                </div>
              </div>
              <div className="user_input_row">
                <div className="user_input">
                  <label htmlFor="city">City</label>
                  <input
                    type={"text"}
                    style={{ width: "80%" }}
                    name="city"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    required
                  />
                </div>
                <div className="user_input">
                  <label htmlFor="address">Address</label>
                  <input
                    type={"text"}
                    style={{ width: "80%" }}
                    name="address"
                    value={address}
                    onChange={(e) => seTaddress(e.target.value)}
                    required
                  />
                </div>
              </div>
              <div className="user_input_row">
                <div className="user_input">
                  <label htmlFor="zipCode">Zip Code</label>
                  <input
                    type={"text"}
                    style={{ width: "80%" }}
                    name="zipCode"
                    value={zipCode}
                    onChange={(e) => setZipCode(e.target.value)}
                    required
                  />
                </div>
                <div className="user_input">
                  <label htmlFor="country">Country</label>
                  <input
                    type={"text"}
                    style={{ width: "80%" }}
                    name="country"
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                    required
                  />
                </div>
              </div>
              <div className="user_input_row">
                <div className="user_input">
                  <label htmlFor="rate">Tax Payer</label>
                  <CustomToggle checked={taxPayer} setChecked={settaxPayer} />
                </div>
              </div>
            </Collapse>
            <UserInfoModalHeader
              headerText="Payment Information"
              displayUserCredentials={displayPaymentInformation}
              setdisplayUserCredentials={setDisplayPaymentInformation}
            />
            <Collapse
              in={displayPaymentInformation.display}
              style={{ width: "100%" }}
            >
              <div className="user_input_row">
                <div className="user_input">
                  <label htmlFor="iban">Iban</label>
                  <input
                    type={"text"}
                    style={{ width: "80%" }}
                    name="iban"
                    value={iban}
                    onChange={(e) => setIban(e.target.value)}
                    required
                  />
                </div>
                <div className="user_input">
                  <label htmlFor="switft">Swift</label>
                  <input
                    type={"text"}
                    style={{ width: "80%" }}
                    value={swift}
                    onChange={(e) => setSwift(e.target.value)}
                    name="swift"
                  />
                </div>
              </div>
            </Collapse>
            <UserInfoModalHeader
              headerText="Multisport card"
              displayUserCredentials={displayMultisportSection}
              setdisplayUserCredentials={setDisplayMultisportSection}
            />
            <Collapse
              in={displayMultisportSection.display}
              style={{ width: "100%" }}
            >
              <div className="user_input_row">
                <div className="user_input">
                  <label htmlFor="rate">Multisport card</label>
                  <CustomToggle
                    checked={multiSportCard}
                    setChecked={setMultiSportCard}
                  />
                </div>
                <div className="user_input">
                  <label htmlFor="rate">Accompanying card</label>
                  <CustomToggle
                    checked={accompanyingMultiSportCard}
                    setChecked={setAccompanyingMultiSportCard}
                  />
                </div>
              </div>
            </Collapse>
            <UserInfoModalHeader
              headerText="Project Rates"
              displayUserCredentials={displayProjectRates}
              setdisplayUserCredentials={setDisplayProjectRates}
            />
            <Collapse
              in={displayProjectRates.display}
              style={{ width: "100%" }}
            >
              <div className="user_input_row">
                <div className="user_input_row_column">
                  <div className="user_input_row_column_header">
                    <label htmlFor="projectRate">Project Rate</label>
                    <AddExperience onClick={handleAddProjectRate} type="button">
                      Add
                    </AddExperience>
                  </div>
                  {clientProjects
                    .sort((a, b) =>
                      dayjs(b.startingFrom).diff(dayjs(a.startingFrom))
                    )
                    .map((project, index) => (
                      <div className="user_input" key={project.id}>
                        <div className="projectRate_element">
                          <input
                            type={"text"}
                            style={{ width: "20%" }}
                            name="rate"
                            value={project.rate}
                            onChange={(e) =>
                              setClientProjects(
                                clientProjects.map((projectElement) => {
                                  if (
                                    Number(projectElement.id) ===
                                    Number(project.id)
                                  ) {
                                    return {
                                      ...projectElement,
                                      rate: e.target.value.replace(",", "."),
                                    };
                                  }
                                  return projectElement;
                                })
                              )
                            }
                            required
                          />
                          <input
                            type={"date"}
                            style={{ width: "22%" }}
                            name="rate"
                            value={project.startingFrom}
                            onChange={(e) =>
                              setClientProjects(
                                clientProjects.map((projectElement) => {
                                  if (
                                    Number(projectElement.id) ===
                                    Number(project.id)
                                  ) {
                                    return {
                                      ...projectElement,
                                      startingFrom: e.target.value,
                                    };
                                  }
                                  return projectElement;
                                })
                              )
                            }
                            required
                          />
                          {index !== clientProjects.length - 1 && (
                            <RemoveExperience
                              type="button"
                              onClick={() => handleDelete(project.id!)}
                            >
                              DELETE
                            </RemoveExperience>
                          )}
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </Collapse>
            <UserInfoModalHeader
              headerText="Rate Information"
              displayUserCredentials={displayRateInformation}
              setdisplayUserCredentials={setDisplayRateInformation}
            />
            <Collapse
              in={displayRateInformation.display}
              style={{ width: "100%" }}
            >
              <div className="user_input_row">
                <div className="user_input">
                  <label htmlFor="rate">Current Rate</label>
                  {!isNaN(data.currentRate!) && (
                    <input
                      type={"text"}
                      style={{ width: "80%" }}
                      name="rate"
                      value={data.currentRate}
                      disabled
                      className="disabled_rate"
                    />
                  )}
                </div>
                {(data.nextRate || !isNaN(data.nextRate!)) && nextRateDate && (
                  <div className="user_input">
                    <label htmlFor="rate">
                      Next Rate from{" "}
                      <label style={{ fontWeight: "bolder", color: "#008db9" }}>
                        {dayjs(nextRateDate).format("DD.MM.YYYY")}
                      </label>
                    </label>

                    <input
                      type={"text"}
                      style={{ width: "80%" }}
                      name="rate"
                      value={data.nextRate}
                      disabled
                      className="disabled_next_rate"
                    />
                  </div>
                )}
                <div className="user_input">
                  <CustomTooltip title="Adding 2.5 to rate">
                    <div className="tooltip_inputs">
                      <label htmlFor="rate">Bring Your Own Device</label>
                      <CustomToggle checked={byod} setChecked={setByod} />
                    </div>
                  </CustomTooltip>
                </div>
              </div>
            </Collapse>
            <UserInfoModalHeader
              headerText="Contract Information"
              displayUserCredentials={displayContractInformation}
              setdisplayUserCredentials={setDisplayContractInformation}
            />
            <Collapse
              in={displayContractInformation.display}
              style={{ width: "100%" }}
            >
              <div className="user_input_row">
                <div className="user_input">
                  <label htmlFor="contractExpirationDate">
                    Contract Expiration Date
                  </label>
                  <input
                    type={"text"}
                    style={{ width: "40%" }}
                    name="contractExpirationDate"
                    value={contractExpirationDate}
                    onChange={(e) => setContractExpirationDate(e.target.value)}
                  />
                </div>
              </div>
            </Collapse>
          </>
        )}
        <div className="createCandidate_modal_container_buttons">
          <SubmitButton type="submit">Create User</SubmitButton>
          <CancelButton onClick={handleClose} type="button">
            Cancel
          </CancelButton>
        </div>
      </form>
    </div>
  );
};

export default AddUser;
