import Box from "@mui/material/Box/Box";
import Modal from "@mui/material/Modal/Modal";
import React, { useState } from "react";
import CancelButton from "../../../../components/Buttons/Cancel/CancelButton";
import SubmitButton from "../../../../components/Buttons/Submit/SubmitButton";
import "./ChangePasswordModal.scss";
import { useEffect } from "react";
import { useMutation } from "react-query";
import { updateClientPassword } from "../../../../api/clientApi";
import Notification from "../../../../components/Notification/Notification";

interface Props {
  open: boolean;
  id: number | undefined;
  name: string;

  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const ChangePasswordModal: React.FC<Props> = ({ open, id, setOpen, name }) => {
  const [password, setPassword] = useState("");
  const [repeatPassowrd, setRepeatPassowrd] = useState("");
  const [displayErrorMessage, setDisplayErrorMessage] = useState(false);

  //Notification
  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");
  const [displayNotification, setDisplayNotification] = useState(false);

  const renderNotification = (Message: string, Error: boolean) => {
    setError(Error);
    setMessage(Message);
    setDisplayNotification(true);
    const timeId = setTimeout(() => {
      // After 3 seconds set the show value to false
      setDisplayNotification(false);
    }, 4000);

    return () => {
      clearTimeout(timeId);
    };
  };

  //Updating password
  const updatePasswordMutation = useMutation(updateClientPassword, {
    onSuccess: () => {
      renderNotification("Password updated", true);
    },
    onError: () => {
      renderNotification("Something went wrong", false);
    },
  });

  //exit modal
  const handleClose = () => {
    setOpen(false);
  };

  //update password
  const handleChangePassword = () => {
    const payload = {
      id: String(id),
      password,
    };
    updatePasswordMutation.mutate(payload);
  };

  //check if password is empty or if they match
  useEffect(() => {
    if (
      password !== repeatPassowrd ||
      password === "" ||
      repeatPassowrd === ""
    ) {
      setDisplayErrorMessage(true);
    } else {
      setDisplayErrorMessage(false);
    }
  }, [password, repeatPassowrd]);

  return (
    <Modal open={open} onClose={handleClose}>
      <Box className="deleteCandidate_modal changePassword">
        {displayErrorMessage ? (
          <Notification message={message} error={error} />
        ) : null}
        <div className="deleteCandidate_modal_container changePassword_container">
          <h2>
            Change password for user{" "}
            <span style={{ fontWeight: "bold", color: "#008db9" }}>{name}</span>
          </h2>
          <div className="update_password_inputs">
            <form>
              <div className="update_password_inputs_section">
                <label htmlFor="password">Password</label>
                <input
                  required
                  value={password}
                  type="password"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <div className="update_password_inputs_section">
                <label htmlFor="repeatPassowrd">Repeat Password</label>
                <input
                  required
                  value={repeatPassowrd}
                  type="password"
                  onChange={(e) => setRepeatPassowrd(e.target.value)}
                />
              </div>
            </form>
          </div>
          <p
            style={{
              color: `${displayErrorMessage ? "red" : "transparent"}`,
              paddingBottom: "1.5rem",
            }}
          >
            {password === "" && repeatPassowrd === ""
              ? "Password is empty!"
              : "Passwords don't match!"}
          </p>
          <div className="deleteCandidate_modal_container_buttons">
            <SubmitButton
              onClick={handleChangePassword}
              disabled={displayErrorMessage}
              type="submit"
            >
              Update
            </SubmitButton>
            <CancelButton onClick={handleClose}>Cancel</CancelButton>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default ChangePasswordModal;
