import React, { forwardRef } from "react";
import "./EmployeeView.scss";
import EmployeeViewCard from "./EmployeeViewCard/EmployeeViewCard";
import TableRowsIcon from "@mui/icons-material/TableRows";
import { AiOutlineIdcard } from "react-icons/ai";

const EmployeeView = forwardRef<HTMLDivElement, {}>((props, ref) => {
  return (
    <div className="employee_view" ref={ref}>
      <p>Choose View</p>
      <div className="employee_view_container">
        <EmployeeViewCard title="Card" icon={<AiOutlineIdcard />} />
        <EmployeeViewCard title="Table" icon={<TableRowsIcon />} />
      </div>
    </div>
  );
});

export default EmployeeView;
