import React, { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import AdditionalInformation from "./AdditionalInformation/AdditionalInformation";
import BasicInformation from "./BasicInformation/BasicInformation";
import Education from "./Education/Education";
import Experience from "./Experience/Experience";
import GDPR from "./GDPR/GDPR";
import NewCVHeader from "./Header/NewCVHeader";
import "./NewCV.scss";
import Project from "./Project/Project";
import Submit from "./Submit/Submit";
import {
  getCandidate,
  createCandidate,
  updateCandidate,
} from "../../api/candidateApi";
import Notification from "../../components/Notification/Notification";
import {
  CandidateLanguages,
  EducationData,
  ExperienceData,
  ProjectData,
} from "../../components/interfaces/interfaces";
import { usePrompt } from "../../components/Prompt/UseCallbackPrompt";
import AnonymizePDFModal from "./Modals/AnonymizePDFModal/AnonymizePDFModal";
import {
  getAnonymizedCandidate,
  getAvailabilityDateAndPrice,
  updateAvailability,
} from "../../api/anonymizedApi";
import {
  CandidateEmails,
  CandidateNumbers,
} from "../../components/interfaces/interfaces";
import UseAuth from "../../hooks/UseAuth";
import { ROLES } from "../../components/Roles/Roles";
import Availability from "./Availability/Availability";
import { getSettings } from "../../api/settingsApi";
import { SettingsType } from "../../components/interfaces/interfaces";

type Props = {
  fullTemplate: boolean;
  pdf: boolean;
};

const NewCV: React.FC<Props> = ({ fullTemplate, pdf }) => {
  const navigate = useNavigate();
  const { auth } = UseAuth();
  const disabled = auth.userRoles === ROLES.ADMIN ? false : true;
  //Basic information
  const [firstname, setFirstname] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState<CandidateEmails[]>([{ email: "" }]);
  const [number, setNumber] = useState<CandidateNumbers[]>([{ phone: "" }]);
  const [place, setPlace] = useState("");
  const [position, setPosition] = useState("");
  const [summary, setSummary] = useState("");
  const [skills, setSkills] = useState("");
  const [status, setStatus] = useState("");
  const [certification, setCertification] = useState("");
  const [links, setLinks] = useState("");
  const [reason, setReason] = useState("");
  const [languages, setLanguages] = useState<CandidateLanguages[]>([
    { language: "" },
  ]);
  const [region, setRegion] = useState("");
  const [contractType, setContractType] = useState("");

  //Aditional information
  const [expectedSalary, setExpectedSalary] = useState("");
  const [startDate, setStartDate] = useState("");

  //Projects Experiences Education
  const [projects, setProjects] = useState<ProjectData[]>([]);
  const [experiences, setExperiences] = useState<ExperienceData[]>([]);
  const [education, setEducation] = useState<EducationData[]>([]);

  //Education
  const [gdpr, setGdpr] = useState(false);
  const [gdprValidation, setGdprValidation] = useState<string | null>("");
  const [documentLocation, setDocumentLocation] = useState("");

  //Availability
  const [availabilitySalary, setavailabilitySalary] = useState<string>("");
  const [availabilityStart, setAvailabilityStart] = useState<string>("");

  //DB Query
  const { id } = useParams();
  const [candidateId, setCandidateId] = useState<string | undefined>(id);
  const [enable, setEnable] = useState(false);

  //Notification
  const [message, setMessage] = useState("");
  const [error, setError] = useState(false);
  const [displayNotification, setDisplayNotification] = useState(false);

  //Change check
  const [changed, setChanged] = useState(false);
  const [backButton, setBackButton] = useState(false);

  //Update Availability when candidate is sent and send to accepted or hired
  const [updateAvailabilityEffect, setUpdateAvailabilityEffect] =
    useState(false);

  //editPDF
  const [editPDF, setEditPDF] = useState(false);
  const [CVType, setCVType] = useState("");

  const queryClient = useQueryClient();

  //Settings if remove multiple whitespaces should be active
  const { data: settings, isFetched } = useQuery("settings", getSettings, {
    refetchOnWindowFocus: false,
    retry: 0,
  });
  const [whiteSpaceSetting, setWhiteSpaceSetting] = useState<SettingsType>();

  //Adding candidate
  const addCandidateMutation = useMutation(createCandidate, {
    onSuccess: (data) => {
      // Invalidates cache and refetch
      queryClient.invalidateQueries("candidates");
      renderNotification("Candidate added to system", true);
      setCandidateId(String(data));
      setUpdateAvailabilityEffect(true);
    },
    onError: () => {
      renderNotification("Something went wrong", false);
    },
  });

  //Updating candidate
  const updateCandidateMutation = useMutation(updateCandidate, {
    onSuccess: () => {
      // Invalidates cache and refetch
      queryClient.invalidateQueries("candidates");
      renderNotification("Candidate updated", true);
    },
    onError: () => {
      renderNotification("Something went wrong", false);
    },
  });

  const updateAvailabilityMutation = useMutation(updateAvailability, {
    onSuccess: () => {
      queryClient.invalidateQueries("availability");
    },
    onError: () => {
      renderNotification("Candidate availability update failed", false);
    },
  });
  //Fetching candidate data
  // eslint-disable-next-line no-empty-pattern
  const { data } = useQuery(
    ["candidates", Number(candidateId)],
    () =>
      disabled
        ? getAnonymizedCandidate(Number(candidateId))
        : getCandidate(Number(candidateId)),
    {
      refetchOnWindowFocus: false,
      enabled: enable,
      retry: 0,
      onSuccess: (data) => {
        setFirstname(data.firstName);
        setLastName(data.lastName);
        setEmail(
          data.hasOwnProperty("email")
            ? JSON.parse(data.email)
            : [{ email: "" }]
        );
        setNumber(
          data.hasOwnProperty("phone")
            ? JSON.parse(data.phone)
            : [{ phone: "" }]
        );
        setPlace(data.place);
        setPosition(data.work_position);
        setStatus(data.status);
        setSummary(data.summary);
        setSkills(data.skills);
        setProjects(data.projects);
        setExperiences(data.experiences);
        setStartDate(data.exp_date);
        setExpectedSalary(data.exp_salary);
        setEducation(data?.education);
        setGdpr(data.gdpr);
        setLinks(data.links);
        setDocumentLocation(data.data_location);
        setCertification(data.certifications);
        setGdprValidation(data.gdpr_valid_date);
        setReason(data.reason);
        setLanguages(JSON.parse(data.languages));
        setRegion(data.region);
        setContractType(data.contractType);
      },
    }
  );

  const { data: hiredData } = useQuery(
    "hiredCandidate",
    () => getAvailabilityDateAndPrice(Number(candidateId)),
    {
      enabled: status === "Hired" || status === "Accepted" ? true : false,
      refetchOnWindowFocus: false,
      retry: 0,
      onSuccess: (data: any) => {
        setAvailabilityStart(data.date);
        setavailabilitySalary(data.salary);
      },
    }
  );

  useEffect(() => {
    if (
      candidateId !== undefined &&
      Boolean(sessionStorage.getItem("unsaved")) !== true
    ) {
      setEnable(true);
    }
  }, [candidateId]);

  useEffect(() => {
    if (isFetched) {
      setWhiteSpaceSetting(settings[0]);
    }
  }, [isFetched, settings]);

  //Loading data from session storage if unsaved changes are made
  useEffect(() => {
    const getDataFromSessionStorage = () => {
      setChanged(true);
      setFirstname(sessionStorage.getItem("firstname")!);
      setLastName(sessionStorage.getItem("lastName")!);
      setEmail(JSON.parse(sessionStorage.getItem("email")!));
      setNumber(JSON.parse(sessionStorage.getItem("phone")!));
      setPlace(sessionStorage.getItem("place")!);
      setPosition(sessionStorage.getItem("position")!);
      setStatus(sessionStorage.getItem("status")!);
      setSummary(sessionStorage.getItem("summary")!);
      setSkills(sessionStorage.getItem("skills")!);
      setProjects(JSON.parse(sessionStorage.getItem("projects")!));
      setExperiences(JSON.parse(sessionStorage.getItem("experiences")!));
      setStartDate(sessionStorage.getItem("startDate")!);
      setExpectedSalary(sessionStorage.getItem("expectedSalary")!);
      setEducation(JSON.parse(sessionStorage.getItem("education")!));
      setGdpr(JSON.parse(sessionStorage.getItem("gdpr")!));
      setLinks(sessionStorage.getItem("links")!);
      setDocumentLocation(sessionStorage.getItem("data_location")!);
      setCertification(sessionStorage.getItem("certification")!);
      setGdprValidation(sessionStorage.getItem("gdprValidation")!);
      setReason(sessionStorage.getItem("reason")!);
      setLanguages(JSON.parse(sessionStorage.getItem("languages")!));
      setRegion(sessionStorage.getItem("region")!);
      setAvailabilityStart(sessionStorage.getItem("availabilityStart") || "");
      setavailabilitySalary(sessionStorage.getItem("availabilitySalary") || "");
      setContractType(sessionStorage.getItem("contractType") || "");
    };
    if (sessionStorage.getItem("unsaved")) {
      getDataFromSessionStorage();
    }
  }, []);

  // //Page reload and leave event handler
  useEffect(() => {
    const setSessionStorage = () => {
      const gdprString = gdpr ? "true" : "false";
      sessionStorage.setItem("unsaved", String(true));
      sessionStorage.setItem("firstname", firstname);
      sessionStorage.setItem("lastName", lastName);
      sessionStorage.setItem("email", JSON.stringify(email));
      sessionStorage.setItem("phone", JSON.stringify(number));
      sessionStorage.setItem("place", place);
      sessionStorage.setItem("position", position);
      sessionStorage.setItem("expectedSalary", expectedSalary);
      sessionStorage.setItem("skills", skills);
      sessionStorage.setItem("summary", summary);
      sessionStorage.setItem("certification", certification);
      sessionStorage.setItem("links", links);
      sessionStorage.setItem("status", status);
      sessionStorage.setItem("startDate", startDate);
      sessionStorage.setItem("gdpr", gdprString);
      sessionStorage.setItem("experiences", JSON.stringify(experiences));
      sessionStorage.setItem("projects", JSON.stringify(projects));
      sessionStorage.setItem("data_location", documentLocation);
      sessionStorage.setItem("gdprValidation", String(gdprValidation));
      sessionStorage.setItem("education", JSON.stringify(education));
      sessionStorage.setItem("reason", reason);
      sessionStorage.setItem("languages", JSON.stringify(languages));
      sessionStorage.setItem("region", region);
      sessionStorage.setItem("availabilityStart", availabilityStart);
      sessionStorage.setItem("availabilitySalary", availabilitySalary);
      sessionStorage.setItem("contractType", contractType);
    };
    if (changed) {
      window.onpopstate = (event: PopStateEvent) => {
        if (!backButton) {
          if (changed) {
            let answer = window.confirm("Changes you made may not be saved.");
            if (answer) {
              setChanged(false);
              setBackButton(true);
              sessionStorage.clear();
            } else {
              setSessionStorage();
              // setChanged(false);
              window.history.forward();
            }
          }
        }
      };
    } else {
      window.onpopstate = () => {
        return null;
      };
    }
  }, [
    availabilitySalary,
    availabilityStart,
    backButton,
    certification,
    changed,
    contractType,
    documentLocation,
    education,
    email,
    expectedSalary,
    experiences,
    firstname,
    gdpr,
    gdprValidation,
    languages,
    lastName,
    links,
    number,
    place,
    position,
    projects,
    reason,
    region,
    skills,
    startDate,
    status,
    summary,
  ]);

  useEffect(() => {
    //Page reload handler
    const handler = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      event.returnValue = "";
      sessionStorage.clear();
    };

    // if the form is changed, then set the onbeforeunload
    if (changed) {
      window.addEventListener("beforeunload", handler);
      // clean it up, if the dirty state changes

      return () => {
        window.removeEventListener("beforeunload", handler);
      };
    }

    // since this is not dirty, don't do anything
    return () => {};
  }, [backButton, changed, navigate]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (candidateId === undefined) {
      const preparedExperiences: any = experiences.map(
        ({ id, ...rest }) => rest
      );
      const preparedProjects: any = projects.map(({ id, ...rest }) => rest);
      const preparedEducation: any = education.map(({ id, ...rest }) => rest);
      const payload = {
        firstName: firstname,
        lastName: lastName,
        email: JSON.stringify(email),
        phone: JSON.stringify(number),
        place: place,
        work_position: position,
        exp_salary: expectedSalary,
        skills: skills,
        summary: summary,
        certifications: certification,
        links: links,
        status: status,
        exp_date: startDate,
        gdpr: Number(gdpr),
        experiences: preparedExperiences,
        projects: preparedProjects,
        data_location: documentLocation,
        education: preparedEducation,
        gdpr_valid_date: gdprValidation,
        reason: reason,
        languages: JSON.stringify(languages),
        region: region,
        contractType: contractType,
      };
      addCandidateMutation.mutate(payload);
    } else {
      if (gdprValidation === "undefined") {
        setGdprValidation(null);
      }
      const payload = {
        id: candidateId,
        firstName: firstname,
        lastName: lastName,
        email: JSON.stringify(email),
        phone: JSON.stringify(number),
        place: place,
        work_position: position,
        exp_salary: expectedSalary,
        skills: skills,
        summary: summary,
        certifications: certification,
        links: links,
        status: status,
        exp_date: startDate,
        gdpr: Number(gdpr),
        experiences: experiences,
        projects: projects,
        data_location: documentLocation,
        education: education,
        gdpr_valid_date: gdprValidation,
        reason: reason,
        languages: JSON.stringify(languages),
        region: region,
        contractType: contractType,
      };
      updateCandidateMutation.mutate(payload);
    }
    setChanged(false);
    setUpdateAvailabilityEffect(true);
    sessionStorage.clear();
  };

  const renderNotification = (Message: string, Error: boolean) => {
    setError(Error);
    setMessage(Message);
    setDisplayNotification(true);
    const timeId = setTimeout(() => {
      // After 4 seconds set the show value to false
      setDisplayNotification(false);
    }, 4000);

    return () => {
      clearTimeout(timeId);
    };
  };

  useEffect(() => {
    if (updateAvailabilityEffect) {
      if (status === "Hired" || status === "Accepted") {
        const payload = {
          id: Number(candidateId),
          salary: availabilitySalary,
          availableDate: availabilityStart,
        };
        updateAvailabilityMutation.mutate(payload);
      }
      setUpdateAvailabilityEffect(false);
      setChanged(false);
      sessionStorage.clear();
    }
  }, [updateAvailabilityEffect]);

  //page redirect handler
  usePrompt("Changes you made may not be saved.", changed);

  return (
    <div className="newCV">
      <form className="newCV_container" onSubmit={(e) => handleSubmit(e)}>
        <NewCVHeader
          showPdf={pdf}
          setEditPDF={setEditPDF}
          disabled={disabled}
          setCVType={setCVType}
        />
        <BasicInformation
          firstname={firstname}
          setFirstname={setFirstname}
          lastName={lastName}
          setLastName={setLastName}
          email={email}
          setEmail={setEmail}
          number={number}
          setNumber={setNumber}
          place={place}
          setPlace={setPlace}
          position={position}
          setPosition={setPosition}
          summary={summary}
          reason={reason}
          setSummary={setSummary}
          skills={skills}
          setSkills={setSkills}
          status={status}
          setStatus={setStatus}
          certification={certification}
          setCertification={setCertification}
          links={links}
          setLinks={setLinks}
          fullTemplate={fullTemplate}
          setChanged={setChanged}
          setReason={setReason}
          languages={languages}
          setLanguages={setLanguages}
          region={region}
          setRegion={setRegion}
          disabled={disabled}
          settings={whiteSpaceSetting}
          contractType={contractType}
          setContractType={setContractType}
        />
        {(status === "Hired" || status === "Accepted") && (
          <Availability
            availabilitySalary={availabilitySalary}
            availabilityStart={availabilityStart}
            setAvailabilitySalary={setavailabilitySalary}
            setAvailabilityStart={setAvailabilityStart}
            setChanged={setChanged}
          />
        )}
        {!disabled && (
          <AdditionalInformation
            expectedSalary={expectedSalary}
            setexpectedSalary={setExpectedSalary}
            startDate={startDate}
            setstartDate={setStartDate}
            setChanged={setChanged}
            disabled={disabled}
          />
        )}
        <Education
          education={education}
          setEducation={setEducation}
          setChanged={setChanged}
          disabled={disabled}
          settings={whiteSpaceSetting}
        />
        <Experience
          experienceData={experiences}
          setExperiencesData={setExperiences}
          setChanged={setChanged}
          disabled={disabled}
          settings={whiteSpaceSetting}
        />
        <Project
          projectData={projects}
          setProjectData={setProjects}
          setChanged={setChanged}
          disabled={disabled}
          settings={whiteSpaceSetting}
        />
        <GDPR
          fullTemplate={fullTemplate}
          gdpr={gdpr}
          setGdpr={setGdpr}
          documentLocation={documentLocation}
          setDocumentLocation={setDocumentLocation}
          gdprValidation={gdprValidation}
          setGdprValidation={setGdprValidation}
          setChanged={setChanged}
          disabled={disabled}
        />
        {displayNotification ? (
          <Notification message={message} error={error} />
        ) : null}
        <Submit setChanged={setChanged} disabled={disabled} />
      </form>
      {editPDF && (
        <AnonymizePDFModal
          editPDF={editPDF}
          setEditPdf={setEditPDF}
          id={candidateId}
          allNumbers={number}
          allEmails={email}
          type={CVType}
          data={data}
        />
      )}
    </div>
  );
};

export default NewCV;
