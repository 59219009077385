import React, { useState } from "react";
import "./EmployeeHeader.scss";
import SearchIcon from "@mui/icons-material/Search";
import EmployeeSortFilter from "./Buttons/EmployeeSortFilter/EmployeeSortFilter";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import SortView from "./Views/SortView/SortView";
import FilterView from "./Views/FilterView/FilterView";
import { FilterType } from "../../../components/interfaces/interfaces";

interface Props {
  sort: string;
  setSort: React.Dispatch<React.SetStateAction<string>>;
  filter: FilterType[];
  setFilter: React.Dispatch<React.SetStateAction<FilterType[]>>;
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  innerRef: React.Ref<HTMLDivElement>;
  sortView: boolean;
  setSortView: React.Dispatch<React.SetStateAction<boolean>>;
}

const EmployeeHeader: React.FC<Props> = ({
  setSort,
  sort,
  filter,
  setFilter,
  search,
  setSearch,
  innerRef,
  setSortView,
  sortView,
}) => {
  const [filterView, setFilterView] = useState(false);
  return (
    <>
      <div className="employee_card_container_header">
        <SearchIcon className="search_icon" />
        <input
          className="employee_search_input"
          type="text"
          placeholder="Search by position, rate, availability..."
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <EmployeeSortFilter
          className={`${sort !== "" ? " clicked" : ""}`}
          onClick={() => {
            setSortView((previous) => !previous);
          }}
        >
          <ArrowDropDownIcon /> Sort
        </EmployeeSortFilter>
        <EmployeeSortFilter
          className={`${filter.length > 0 ? " clicked" : ""}`}
          onClick={() => {
            setFilterView((previous) => !previous);
            setSortView(false);
          }}
        >
          <ArrowDropDownIcon />
          Filters
        </EmployeeSortFilter>
        {sortView && <SortView setSort={setSort} sort={sort} ref={innerRef} />}
      </div>
      {filterView && <FilterView filter={filter} setFilter={setFilter} />}
    </>
  );
};

export default EmployeeHeader;
