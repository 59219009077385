import axios from "axios";
import { refreshToken } from "./authApi";
import { baseURL } from './apiConfig';

const serverApi = axios.create({
  //localhost:8080
  baseURL: baseURL,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});

export const generateQR = async (
  iban: string,
  amount: string,
  vs: string,
  ss: string,
  cs: string
) => {
  await refreshToken();
  const bearer_token = `${localStorage.getItem("token")}`;
  const { data } = await serverApi.get("/invoice/qr", {
    headers: {
      iban,
      amount,
      vs,
      cs,
      ss,
      "Content-type": "application/json",
      Authorization: `Bearer ${bearer_token}`,
    },
  });
  return data;
};
