import { View, Text, Font } from "@react-pdf/renderer";
import React from "react";
import { styles } from "./PDFStylings";

import arial from "../../../../assets/fonts/Arial_medium.ttf";
import arialText from "../../../../assets/fonts/arialW.ttf";
import { CandidateLanguages } from "../../../../components/interfaces/interfaces";

interface Props {
  languages: string;
}

Font.register({
  family: "Roboto",
  fonts: [
    {
      src: arial,
    },
    {
      src: arialText,
      fontWeight: 300,
    },
  ],
});

const ShowPDFLanguages: React.FC<Props> = ({ languages }) => {
  return (
    <View style={styles.languages} wrap={false}>
      {JSON.parse(languages).map(
        (language: CandidateLanguages, index: number) => (
          <View
            style={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              flexDirection: "column",
              width: "100%",
            }}
            key={index}
          >
            {index === 0 && (
              <View style={styles.languages_header}>
                <Text
                  style={{
                    color: "#1F3864",
                    fontWeight: "bold",
                    fontSize: "14px",
                    width: "100%",
                    backgroundColor: "#c9c9c9",
                    textTransform: "uppercase",
                    padding: "4px",
                    fontFamily: "Roboto",
                  }}
                >
                  Languages
                </Text>
              </View>
            )}
            <View
              style={{
                backgroundColor: "transparent",
                paddingLeft: "4px",
                display: "flex",
                paddingTop: "4px",
                width: "100%",
                borderRight: "1px solid #c9c9c9",
                borderLeft: "1px solid #c9c9c9",
                borderBottom: "1px solid #c9c9c9",
              }}
            >
              <Text
                style={{
                  fontSize: "12px",
                  color: "black",
                  width: "100%",
                  lineHeight: "1.3px",
                  textAlign: "justify",
                  fontFamily: "Roboto",
                  fontWeight: 300,
                }}
              >
                {language.language}
              </Text>
            </View>
          </View>
        )
      )}
    </View>
  );
};

export default ShowPDFLanguages;
