import { Fade, Tooltip } from "@mui/material";
import React from "react";
import "./ProgressBar.scss";

interface Props {
  daysPassed: number;
  daysLeft: number;
}

const ProgressBar: React.FC<Props> = ({ daysPassed, daysLeft }) => {
  const progress_bar_container = {
    height: "100%",
    width: "100%",
    backgroundColor: "#e0e0de",
    borderRadius: 50,
    cursor: "pointer",
  };
  const progress_bar_filter = {
    height: "100%",
    width: `${daysPassed}%`,
    backgroundColor: "#003d50",
    borderRadius: "inherit",
  };

  return (
    <Tooltip
      title={`${daysLeft} days left until payment`}
      TransitionComponent={Fade}
      placement="right-start"
      className="tooltip"
      PopperProps={{
        sx: {
          "& .MuiTooltip-tooltip": {
            border: "transparent",
            color: "#003d50",
            backgroundColor: "white",
            padding: "10px",
            borderRadius: "10px",
            fontSize: "12px",
            boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px;",
          },
        },
      }}
    >
      <div className="progressBar">
        <div style={progress_bar_container}>
          <div style={progress_bar_filter}></div>
        </div>
      </div>
    </Tooltip>
  );
};

export default ProgressBar;
