import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Theme, useTheme } from "@mui/material/styles";
import React, { useState } from "react";
import { actTypes } from "../../../../components/Context/Reducer";
import { UserAuth } from "../../../../components/Context/StateProvider";
import "./Columnfilter.scss";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const statuses = [
  "Accepted",
  "Hired",
  "Interview",
  "Rejected",
  "User Rejected",
];

function getStyles(name: string, personName: string[], theme: Theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const Columnfilter = () => {
  const theme = useTheme();
  const [{ statusFilter }, dispatch] = UserAuth();
  const [status, setStatus] = useState<string[]>(statusFilter);

  const handleChange = (event: SelectChangeEvent<typeof status>) => {
    const {
      target: { value },
    } = event;
    setStatus(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    localStorage.setItem("statusFilter", String(value));
    dispatch({
      type: actTypes.STATUS_FILTER,
      statusFilter: value,
    });
  };

  return (
    <div className="filter_container">
      <div>Select filtered values.</div>
      <FormControl sx={{ m: 1, width: 300 }}>
        <Select
          id="demo-multiple-name"
          multiple
          value={status}
          onChange={handleChange}
          MenuProps={MenuProps}
        >
          {statuses.map((statusElement) => (
            <MenuItem
              key={statusElement}
              value={statusElement}
              style={getStyles(statusElement, status, theme)}
            >
              {statusElement}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default Columnfilter;
