import React from "react";
import "./AddInvoiceButtons.scss";

interface Props {
  setopenNewInvoice: React.Dispatch<React.SetStateAction<boolean>>;
  setInvoiceError: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddInvoiceButtons: React.FC<Props> = ({
  setopenNewInvoice,
  setInvoiceError,
}) => {
  return (
    <div className="addInvoiceButtons">
      <button
        type="button"
        className="cancel_invoice"
        onClick={() => {
          setopenNewInvoice(false);
          setInvoiceError(false);
        }}
      >
        Discard
      </button>
      <button type="submit" className="submit_invoice">
        Save & Send
      </button>
    </div>
  );
};

export default AddInvoiceButtons;
