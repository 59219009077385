import React, { useState } from "react";
import "./Settings.scss";
import Box from "@mui/material/Box";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import SettingsIcon from "@mui/icons-material/Settings";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import LinkIcon from "@mui/icons-material/Link";
import { useNavigate } from "react-router-dom";

const actions = [
  { icon: <SettingsIcon />, name: "Settings" },
  { icon: <PersonAddIcon />, name: "Add Candidate" },
  { icon: <LinkIcon />, name: "Create link" },
];

interface Props {
  openSet: boolean;
  setOpenSet: React.Dispatch<React.SetStateAction<boolean>>;
}

const Settings: React.FC<Props> = ({ openSet, setOpenSet }) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const openSettings = (actionName: string) => {
    switch (actionName) {
      case "Settings":
        setOpen(false);
        setOpenSet((prev) => !prev);
        break;
      case "Add Candidate":
        navigate("/cv/new");
        break;
      case "Create link":
        navigate("/cv/link");
        break;
      default:
        break;
    }
  };
  return (
    <Box
      sx={{
        transform: "translateZ(0px)",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        gap: "10px",
        width: "60px",
        height: "60px",
      }}
      className="speed_dial_box"
    >
      <SpeedDial
        ariaLabel="Settings speed dial"
        sx={{
          position: "absolute",
          bottom: 0,
          right: 15,
        }}
        icon={<SpeedDialIcon />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
        direction="left"
        className="speed_dial"
        FabProps={{
          sx: {
            bgcolor: "#008db9",
            transition: "all 500 ease-in-out",
            "&:hover": { bgcolor: "#0dcaf0" },
          },
        }}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            onClick={() => openSettings(action.name)}
          />
        ))}
      </SpeedDial>
    </Box>
  );
};

export default Settings;
