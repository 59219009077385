import axios from "axios";
import { ClientType } from "../components/interfaces/interfaces";
import { baseURL } from './apiConfig';

const serverApi = axios.create({
  //localhost:8080
  baseURL: baseURL,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});
interface Iuser {
  name: string;
  password: string;
}
export const authUser = async ({ name, password }: Iuser) => {
  const { data } = await serverApi.get("/public/token", {
    headers: {
      username: name,
      password: password,
    },
  });
  return data;
};

export const createUser = async (payload: ClientType) => {
  const { data } = await serverApi.post("/public/signup", payload, {});
  return data;
};

export const refreshToken = async () => {
  const body = localStorage.getItem("token") || "";
  await serverApi
    .get("/public/refresh", {
      headers: {
        token: body,
      },
    })
    .then((data) => {
      data.data !== "" && localStorage.setItem("token", data.data);
    });
};

export default serverApi;
