import { View, Text, Font } from "@react-pdf/renderer";
import React from "react";
import { styles } from "./PDFStylings";
import arialText from "../../../../assets/fonts/arialW.ttf";
import arial from "../../../../assets/fonts/Arial_medium.ttf";
import { PDF_type } from "../../../../components/interfaces/interfaces";

Font.register({
  family: "Roboto",
  fonts: [
    {
      src: arial,
    },
    {
      src: arialText,
      fontWeight: 300,
    },
  ],
});

interface Props {
  experiences: {
    id: number;
    name: string;
    description: string;
    experienceStart: string;
    experienceEnd: string;
    position: string;
    sequence: number;
  }[];
  pdfType: string;
  anonymizeExperienceName: boolean;
}

const ShowPDFExperiences: React.FC<Props> = ({
  experiences,
  pdfType,
  anonymizeExperienceName,
}) => {
  return (
    <View style={styles.experiences}>
      {experiences
        .sort((a, b) => (a.sequence > b.sequence ? -1 : 1))
        .map((exp: any, index: number) => (
          <View
            key={exp.id}
            style={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              flexDirection: "column",
              width: "100%",
              borderRight: "1px solid #c9c9c9",
              borderLeft: "1px solid #c9c9c9",
              borderBottom: "1px solid #c9c9c9",
            }}
            wrap={false}
          >
            {index === 0 && (
              <View style={styles.experiences_header}>
                <Text
                  style={{
                    color: "#1F3864",
                    fontSize: "14px",
                    backgroundColor: "#c9c9c9",
                    textTransform: "uppercase",
                    padding: "4px",
                    fontWeight: "bold",
                    fontFamily: "Roboto",
                  }}
                >
                  EXPERIENCES
                </Text>
              </View>
            )}
            <View style={styles.experiences_name}>
              <Text
                style={{
                  color: "#2f5496",
                  fontSize: "13px",
                  padding: "4px",
                  fontWeight: "bold",
                  fontFamily: "Roboto",
                  width: "60%",
                }}
              >
                {anonymizeExperienceName
                  ? exp.position
                  : exp.name === "" || exp.name === null
                  ? exp.position
                  : pdfType === PDF_type.full
                  ? exp.position
                  : exp.name}
              </Text>
              <Text
                style={{
                  color: "#2f5496",
                  fontSize: "12px",
                  padding: "4px",
                  fontWeight: "bold",
                  fontFamily: "Roboto",
                }}
              >
                {exp.experienceStart === null && exp.experienceEnd === null
                  ? ""
                  : exp.experienceEnd === null
                  ? exp.experienceStart + " - "
                  : exp.experienceStart === null
                  ? " - " + exp.experienceEnd
                  : exp.experienceStart + " - " + exp.experienceEnd}
              </Text>
            </View>
            {exp.description !== "" ? (
              <View style={styles.experiences_description}>
                {!anonymizeExperienceName ? (
                  <Text
                    style={{
                      color: "black",
                      fontSize: "12px",
                      padding: "4px",
                      lineHeight: "1.3px",
                      textAlign: "justify",
                      fontWeight: "bold",
                      fontFamily: "Roboto",
                    }}
                  >
                    {exp.position}
                  </Text>
                ) : null}
                <Text
                  style={{
                    color: "black",
                    fontSize: "12px",
                    padding: "4px",
                    lineHeight: "1.3px",
                    textAlign: "justify",
                    fontFamily: "Roboto",
                    fontWeight: 300,
                  }}
                >
                  {exp.description}
                </Text>
              </View>
            ) : null}
          </View>
        ))}
    </View>
  );
};

export default ShowPDFExperiences;
