import Box from "@mui/material/Box/Box";
import Modal from "@mui/material/Modal/Modal";
import React, { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { deleteUser } from "../../../../api/clientApi";
import CancelButton from "../../../../components/Buttons/Cancel/CancelButton";
import SubmitButton from "../../../../components/Buttons/Submit/SubmitButton";
import "./DeleteUserModal.scss";
import Notification from "../../../../components/Notification/Notification";

interface Props {
  open: boolean;
  id: number | undefined;
  name: string;

  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const DeleteUserModal: React.FC<Props> = ({ open, id, name, setOpen }) => {
  const queryClient = useQueryClient();
  //Notification
  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");
  const [displayNotification, setDisplayNotification] = useState(false);

  const renderNotification = (Message: string, Error: boolean) => {
    setError(Error);
    setMessage(Message);
    setDisplayNotification(true);
    const timeId = setTimeout(() => {
      // After 3 seconds set the show value to false
      setDisplayNotification(false);
    }, 4000);

    return () => {
      clearTimeout(timeId);
    };
  };

  const deleteCandidateMutation = useMutation(deleteUser, {
    onSuccess: () => {
      // Invalidates cache and refetch
      queryClient.invalidateQueries("users");
      renderNotification("User deleted", true);
    },
    onError: () => {
      renderNotification("Something went wrong", false);
    },
  });

  const handleClose = () => {
    setOpen(false);
  };
  const handledeleteUser = () => {
    deleteCandidateMutation.mutate(Number(id!));
    setOpen(false);
  };
  return (
    <Modal open={open} onClose={handleClose}>
      <Box className="deleteCandidate_modal deleteUser">
        {displayNotification ? (
          <Notification message={message} error={error} />
        ) : null}
        <div className="deleteCandidate_modal_container deleteUser_container">
          <h2>Confirm the action</h2>
          <p>
            Do you really want to delete user{" "}
            <span style={{ fontWeight: "bold", color: "#008db9" }}>{name}</span>{" "}
            ?
          </p>
          <div className="deleteCandidate_modal_container_buttons">
            <SubmitButton onClick={handledeleteUser}>Delete</SubmitButton>
            <CancelButton onClick={handleClose}>Cancel</CancelButton>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default DeleteUserModal;
