import {
  Paragraph,
  Table,
  TableRow,
  TableCell,
  AlignmentType,
  TextRun,
  BorderStyle,
} from "docx";

export const skillsComponent = (arr: any) => {
  const skillsText = arr.skills
    .split("\n")
    .map((line: any) => new TextRun({ text: line, font: "Arial", size: 24 }));
  return new Table({
    columnWidths: [10000],
    borders: {
      top: {
        style: BorderStyle.SINGLE,
        size: 0,
        color: "e6e6e6",
      },
      left: {
        style: BorderStyle.SINGLE,
        size: 0,
        color: "e6e6e6",
      },
      bottom: {
        style: BorderStyle.SINGLE,
        size: 0,
        color: "e6e6e6",
      },
      right: {
        style: BorderStyle.SINGLE,
        size: 0,
        color: "e6e6e6",
      },
    },
    rows: [
      new TableRow({
        children: [
          new TableCell({
            borders: {
              top: {
                style: BorderStyle.SINGLE,
                size: 0,
                color: "e6e6e6",
              },
              left: {
                style: BorderStyle.SINGLE,
                size: 0,
                color: "e6e6e6",
              },
              bottom: {
                style: BorderStyle.SINGLE,
                size: 0,
                color: "e6e6e6",
              },
              right: {
                style: BorderStyle.SINGLE,
                size: 0,
                color: "e6e6e6",
              },
            },
            children: [
              new Paragraph({
                spacing: {
                  before: 35,
                  after: 35,
                },
                indent: {
                  left: 60,
                  right: 60,
                },
                alignment: AlignmentType.LEFT,
                children: skillsText,
              }),
            ],
          }),
        ],
      }),
    ],
  });
};
