import React, {useMemo} from "react";
import {Document, Page, PDFViewer} from "@react-pdf/renderer";
import {UserAuth} from "../../../components/Context/StateProvider";
import IdentificationData from "./IdentificationData/IdentificationData";
import {Invoicestyles} from "./InvoiceStyles/InvoiceStyle";
import InvoiceDate from "./InvoiceDate/InvoiceDate";
import BillingInfo from "./BillingInfo/BillingInfo";
import InvoiceText from "./InvoiceText/InvoiceText";
import InvoiceItems from "./InvoiceItems/InvoiceItems";
import InvoiceSummary from "./InvoiceSummary/InvoiceSummary";
import InvoicePDFHeader from "./InvoiceHeader/InvoicePDFHeader";
import {useQuery} from "react-query";
import {generateQR} from "../../../api/paymentQr";
import Tax from "./Tax/Tax";
import TaxInvoiceItems from "./InvoiceItems/TaxInvoiceItems/TaxInvoiceItems";

type Invoicelist = {
    id: number;
    itemName: string;
    Qty: string;
    price: number;
    total: string;
};

const InvoicePDF = () => {
    const invoicePDf = JSON.parse(localStorage.getItem("InvoicePDF")!);
    const [{invoice}] = UserAuth();
    const price = JSON.parse(invoice.list).reduce(
        (accumulator: number, obj: Invoicelist) => {
            return accumulator + Number(obj.total);
        },
        0
    );
    const totalPrice = price - (invoice.multiSportCard ? 20 : 0);
    const totalWithTax = useMemo(() => {
        const tax = Number((totalPrice / 100) * 20).toFixed(2);
        return Number(tax) + Number(totalPrice);
    }, [totalPrice]);
    const {data, isFetched} = useQuery(
        [
            "qr",
            invoice.iban,
            invoice.taxPayer
                ? String(totalWithTax.toFixed(2))
                : String(totalPrice.toFixed(2)),
            invoice.variableSymbol,
            invoice.specificSymbol,
            invoice.constantSymbol,
        ],
        () =>
            generateQR(
                invoice.iban,
                invoice.taxPayer
                    ? String(totalWithTax.toFixed(2))
                    : String(totalPrice.toFixed(2)),
                invoice.variableSymbol,
                invoice.specificSymbol,
                invoice.constantSymbol
            ),
        {
            refetchOnWindowFocus: false,
            retry: 0,
        }
    );
    console.log(invoice.multiSportCard)
    return (
        <PDFViewer style={Invoicestyles.viewer}>
            <Document>
                {isFetched && (
                    <Page style={Invoicestyles.body} size="A4">
                        <InvoicePDFHeader invoiceNumber={invoice.invoiceNumber}/>
                        <IdentificationData
                            name={invoice.name}
                            address={invoice.address}
                            city={invoice.city}
                            zipCode={invoice.zipCode}
                            country={invoice.country}
                            businessID={invoice.businessId}
                            taxID={invoice.taxId}
                            email={invoice.email}
                            number={invoice.phoneNumber}
                        />
                        <InvoiceDate
                            invoiceDate={invoice.invoiceDate}
                            paymentTerms={invoicePDf.paymentTerms}
                        />
                        <BillingInfo
                            totalPrice={
                                invoice.taxPayer ? String(totalWithTax) : String(totalPrice)
                            }
                            iban={invoice.iban}
                            swift={invoice.swift}
                            variableSymbol={invoice.variableSymbol}
                            specificSymbol={invoice.specificSymbol}
                            constantSymbol={invoice.constantSymbol}
                            qrCode={data.data}
                        />
                        <InvoiceText/>
                        {invoice.taxPayer ? (
                            <TaxInvoiceItems list={JSON.parse(invoice.list)} multiSportCard={invoice.multiSportCard}/>
                        ) : (
                            <InvoiceItems list={JSON.parse(invoice.list)} multiSportCard={invoice.multiSportCard}/>
                        )}
                        {invoice.taxPayer && <Tax totalPrice={String(totalPrice)}/>}
                        <InvoiceSummary
                            totalPrice={
                                invoice.taxPayer ? String(totalWithTax) : String(totalPrice)
                            }
                            taxPayer={invoice.taxPayer}
                        />
                    </Page>
                )}
            </Document>
        </PDFViewer>
    );
};

export default InvoicePDF;
