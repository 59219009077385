import React from "react";
import Kodiva from "../../../assets/images/kodiva_logo_white.png";
import waves from "../../../assets/images/wave.svg";
import "./UserDashboardKodivaLogo.scss";

const UserDashboardKodivaLogo = () => {
  return (
    <div
      className="user_dashboard_kodiva_widget"
      style={{ backgroundImage: `url(${waves})` }}
    >
      <div className="kodiva_widget_logo">
        <img src={Kodiva} alt="Kodiva" className="logo" />
      </div>
      <p>Welcome to</p>
      <h3>Kodiva s.r.o.</h3>
    </div>
  );
};

export default UserDashboardKodivaLogo;
