import React, { useRef } from "react";
import { MdDragHandle } from "react-icons/md";
import ReactTooltip from "react-tooltip";
import AddExperience from "../../../components/Buttons/AddExperience/AddExperience";
import RemoveExperience from "../../../components/Buttons/RemoveExperience/RemoveExperience";
import {
  ProjectData,
  SettingsType,
} from "../../../components/interfaces/interfaces";
import HandlePaste from "../../../hooks/HandlePaste";
import Description from "../Description/Description";
import Title from "../Title/Title";
import "./Project.scss";

export interface Props {
  projectData: {
    id: number;
    name: string;
    description: string;
    project_from: string;
    project_end: string;
    sequence: number;
  }[];
  disabled: boolean;
  settings: SettingsType | undefined;
  setProjectData: React.Dispatch<React.SetStateAction<any>>;
  setChanged: React.Dispatch<React.SetStateAction<boolean>>;
}

const Project: React.FC<Props> = ({
  projectData,
  disabled,
  settings,
  setProjectData,
  setChanged,
}) => {
  const dragItem = useRef(null);
  const dragOverItem = useRef(null);

  const dragStart = (position: any) => {
    dragItem.current = position;
  };

  const dragEnter = (position: any) => {
    dragOverItem.current = position;
  };

  const drop = () => {
    var copyListItems = [...projectData];
    let firstIndex = 0;
    let lastIndex = 0;
    copyListItems = copyListItems.map((obj, index) => {
      if (obj.sequence === dragItem.current) {
        firstIndex = index;
        return { ...obj, sequence: dragOverItem.current! };
      } else if (obj.sequence === dragOverItem.current!) {
        lastIndex = index;
        return { ...obj, sequence: dragItem.current! };
      }
      return obj;
    });
    const dragItemContent = copyListItems[firstIndex];
    copyListItems.splice(firstIndex, 1);
    copyListItems.splice(lastIndex, 0, dragItemContent!);
    dragItem.current = null;
    dragOverItem.current = null;
    setProjectData(copyListItems);
    setChanged(true);
  };

  const addProject = () => {
    const maxId = projectData.length
      ? Math.max(...projectData.map((project) => project.id))
      : 0;
    const maxPosition = projectData.length
      ? Math.max(...projectData.map((project) => project.sequence)) + 1
      : 0;
    const projectFields = {
      id: maxId + 1,
      name: "",
      description: "",
      project_from: "",
      project_end: "",
      sequence: maxPosition,
    };
    setProjectData([...projectData, projectFields]);
  };

  const updateValue = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>,
    id: number
  ) => {
    const name = e.currentTarget.name;
    const value = settings?.whitespaceRemove
      ? HandlePaste(e.currentTarget.value)
      : e.currentTarget.value;
    setProjectData((current: []) =>
      current.map((project: ProjectData) => {
        if (Number(project.id) === id) {
          return { ...project, [name]: value.toString() };
        }
        return project;
      })
    );
    setChanged(true);
  };
  const removeProject = (id: number) => {
    setProjectData((current: []) =>
      current.filter((project: ProjectData) => {
        return Number(project.id) !== id;
      })
    );
  };
  return (
    <div className="project">
      <div className="project_body">
        <div className="project_description">
          <Title title="Project" />
          <Description description="Most relevant and interesting projects with short description about project, technologies used, responsibilities and skills developed on the project." />
          {!disabled && (
            <div className="project_addButton">
              <AddExperience type="button" onClick={addProject}>
                + Project
              </AddExperience>
            </div>
          )}
        </div>
        <div className="project_input">
          {projectData &&
            projectData
              .sort((a, b) => (a.sequence > b.sequence ? -1 : 1))
              .map((project: ProjectData, index: number) => (
                <div
                  className="project_input_container"
                  key={project.id}
                  onDragStart={() => dragStart(project.sequence)}
                  onDragEnter={() => dragEnter(project.sequence)}
                  onDragEnd={drop}
                  draggable
                >
                  <div className="project_input">
                    <label htmlFor="name">Project name</label>
                    <input
                      type={"text"}
                      style={{ width: "33%" }}
                      name="name"
                      value={project.name}
                      onChange={(e) => updateValue(e, project.id)}
                      disabled={disabled}
                    />
                  </div>
                  <div className="project_input">
                    <label htmlFor="description">Description</label>
                    <textarea
                      name="description"
                      rows={5}
                      value={project.description}
                      onChange={(e) => updateValue(e, project.id)}
                      disabled={disabled}
                    />
                  </div>
                  <div className="project_input_row">
                    <div className="project_input">
                      <label htmlFor="project_from">From</label>
                      <input
                        name="project_from"
                        type={"text"}
                        style={{ width: "80%" }}
                        value={
                          project.project_from !== null
                            ? project.project_from
                            : ""
                        }
                        onChange={(e) => updateValue(e, project.id)}
                        disabled={disabled}
                      />
                    </div>
                    <div className="project_input">
                      <label htmlFor="project_end">To</label>
                      <input
                        name="project_end"
                        type={"text"}
                        style={{ width: "80%" }}
                        value={
                          project.project_end !== null
                            ? project.project_end
                            : ""
                        }
                        onChange={(e) => updateValue(e, project.id)}
                        disabled={disabled}
                      />
                    </div>
                  </div>
                  {!disabled && (
                    <div className="project_input">
                      <RemoveExperience
                        type="button"
                        onClick={() => removeProject(project.id)}
                      >
                        - Project
                      </RemoveExperience>
                    </div>
                  )}
                  <p className="sequence_number">{index + 1}</p>
                  {!disabled && (
                    <>
                      <div
                        id="drag_icon"
                        className="drag_icon"
                        data-tip
                        data-for={"tooltip"}
                      >
                        <MdDragHandle className="drag_handle" />
                      </div>
                      <ReactTooltip
                        id="tooltip"
                        effect="solid"
                        className="exp_tooltip"
                      >
                        Drag to another position
                      </ReactTooltip>
                    </>
                  )}
                </div>
              ))}
        </div>
      </div>
    </div>
  );
};

export default Project;
