export type ProjectData = {
  id: number;
  name: string;
  description: string;
  project_from: string;
  project_end: string;
  sequence: number;
};
export type EducationData = {
  id: number;
  school_name: string;
  field_of_study: string;
  study_from: string;
  study_end: string;
  faculty: string;
  description: string;
  sequence: number;
};
export type ExperienceData = {
  id: number;
  name: string;
  description: string;
  experienceEnd: string;
  experienceStart: string;
  position: string;
  sequence: number;
};
export type CandidateType = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  place: string;
  work_position: string;
  exp_salary: string;
  skills: string;
  summary: string;
  certifications: string;
  links: string;
  status: string;
  exp_date: string;
  gdpr: number;
  experiences: ExperienceData[];
  projects: ProjectData[];
  education: EducationData[];
  gdpr_valid_date: string | null;
  reason: string | null;
  languages: string;
  region: string;
  contractType: string;
};
export type AvailabilityType = {
  id: number;
  salary: string;
  availableDate: string;
};

export type SettingsType = {
  id: number;
  whitespaceRemove: boolean;
  clientNumber: number;
  byod: string;
};

export type PDFSettings = {
  anonymizeName: boolean;
  anonymizeRegion: boolean;
  anonymizeNumber: boolean;
  anonymizeEmail: boolean;
  anonymizeExperienceName: boolean;
  anonymizeSummary: boolean;
  selectedNumber: string;
  selectedEmail: string;
};

export type CandidateEmails = {
  email: string;
};

export type CandidateNumbers = {
  phone: string;
};

export type CandidateLanguages = {
  language: string;
};

export type MyToken = {
  sub: string;
  roles: string;
};

export type UserRoles = {
  userName: string;
  userRoles: string;
};

export enum PDF_type {
  normal = "normal",
  semi = "semi",
  full = "full",
}

export type InvoiceType = {
  id?: number;
  invoiceNumber: string;
  invoiceDate: string;
  status: string;
  variableSymbol: string;
  constantSymbol: string;
  specificSymbol: string;
  totalPrice: string;
  list: string;
  userId?: number;
  supplier: string;
  paymentTerms: number;
};

export type ClientType = {
  id?: Number;
  userName: string;
  email: string;
  password: string;
  rate: string;
  name: string;
  city: string;
  zipCode: string;
  address: string;
  country: string;
  businessId: string;
  taxId: string;
  phoneNumber: string;
  iban: string;
  swift: string;
  role: string;
  invoices: InvoiceType[];
  byod: boolean;
  contractExpirationDate: string;
  multiSportCard: boolean;
  accompanyingMultiSportCard: boolean;
  clientProjects: ClientProjects[];
  taxPayer: boolean;
};

export interface ClientProjects {
  id?: number;
  rate: string;
  startingFrom: string;
}

export type UpdatePasswordType = {
  id: string;
  password: string;
};

export type CalendarEventType = {
  id: number;
  event_id: string;
  end: Date;
  start: Date;
  title: string;
};

export type EventTypeSelected = {
  id: string;
  event_id: number;
  start: Date | string;
  end: Date | string;
  title: string;
  description?: string;
};
export type FilterType = {
  name: string;
  option: string;
  value: string;
};
export type ISection = {
  angle: number;
  display: boolean;
};
