import React, { useState } from "react";
import jwtDecode from "jwt-decode";
import { MyToken, UserRoles } from "../components/interfaces/interfaces";

const UseAuth = () => {
  const token = localStorage.getItem("token");
  const [role] = useState<UserRoles>({
    userName: "",
    userRoles: "",
  });
  let decodedToken;
  if (token !== null && token !== "") {
    decodedToken = jwtDecode<MyToken>(token);
    const auth = {
      userName: decodedToken.sub,
      userRoles: decodedToken.roles,
    };
    return { auth };
  }
  return { auth: role };
};

export default UseAuth;
