import React, { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const InvoiceNotification = () => {
  useEffect(() => {
    const notify = () => {
      toast.error("Invoice number or Variable symbol has been already used!", {
        position: "top-right",
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    };
    notify();
  }, []);

  return (
    <div style={{ position: "absolute" }}>
      <ToastContainer
        position="top-right"
        autoClose={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        theme="colored"
        style={{ width: "25%", top: "4rem" }}
      />
    </div>
  );
};

export default InvoiceNotification;
