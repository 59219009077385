import React, { useRef } from "react";
import "./Education.scss";
import Description from "../Description/Description";
import Title from "../Title/Title";
import RemoveExperience from "../../../components/Buttons/RemoveExperience/RemoveExperience";
import AddExperience from "../../../components/Buttons/AddExperience/AddExperience";
import {
  EducationData,
  SettingsType,
} from "../../../components/interfaces/interfaces";
import HandlePaste from "../../../hooks/HandlePaste";
import { MdDragHandle } from "react-icons/md";
import ReactTooltip from "react-tooltip";

export interface Props {
  education: {
    id: number;
    school_name: string;
    field_of_study: string;
    study_from: string;
    study_end: string;
    faculty: string;
    description: string;
    sequence: number;
  }[];
  disabled: boolean;
  settings: SettingsType | undefined;
  setEducation: React.Dispatch<React.SetStateAction<any>>;
  setChanged: React.Dispatch<React.SetStateAction<boolean>>;
}

const Education: React.FC<Props> = ({
  education,
  disabled,
  settings,
  setEducation,
  setChanged,
}) => {
  const dragItem = useRef(null);
  const dragOverItem = useRef(null);
  const dragStart = (position: any) => {
    dragItem.current = position;
  };
  const dragEnter = (position: any) => {
    dragOverItem.current = position;
  };

  const drop = () => {
    var copyListItems = [...education];
    let firstIndex = 0;
    let lastIndex = 0;
    copyListItems = copyListItems.map((obj, index) => {
      if (obj.sequence === dragItem.current) {
        firstIndex = index;
        return { ...obj, sequence: dragOverItem.current! };
      } else if (obj.sequence === dragOverItem.current!) {
        lastIndex = index;
        return { ...obj, sequence: dragItem.current! };
      }
      return obj;
    });
    const dragItemContent = copyListItems[firstIndex];
    copyListItems.splice(firstIndex, 1);
    copyListItems.splice(lastIndex, 0, dragItemContent!);
    dragItem.current = null;
    dragOverItem.current = null;
    setEducation(copyListItems);
    setChanged(true);
  };

  const updateValue = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>,
    id: number
  ) => {
    const name = e.currentTarget.name;
    const value = settings?.whitespaceRemove
      ? HandlePaste(e.currentTarget.value)
      : e.currentTarget.value;
    setEducation((current: []) =>
      current.map((project: EducationData) => {
        if (Number(project.id) === id) {
          return { ...project, [name]: value.toString() };
        }
        return project;
      })
    );
    setChanged(true);
  };
  const removeEducation = (id: number) => {
    setEducation((current: []) =>
      current.filter((project: EducationData) => {
        return Number(project.id) !== id;
      })
    );
    setChanged(true);
  };
  const addEducation = () => {
    const maxId = education.length
      ? Math.max(...education.map((school) => school.id))
      : 0;
    const maxPosition = education.length
      ? Math.max(...education.map((education) => education.sequence)) + 1
      : 0;
    console.log(maxPosition);
    const educationfields = {
      id: maxId + 1,
      school_name: "",
      field_of_study: "",
      study_from: "",
      study_end: "",
      faculty: "",
      description: "",
      sequence: maxPosition,
    };
    setEducation([...education, educationfields]);
    setChanged(true);
  };
  return (
    <div className="project">
      <div className="project_body">
        <div className="project_description">
          <Title title="Education" />
          <Description description="Information about finished studies." />
          {!disabled && (
            <div className="project_addButton">
              <AddExperience type="button" onClick={addEducation}>
                + School
              </AddExperience>
            </div>
          )}
        </div>
        <div className="project_input">
          {education &&
            education
              .sort((a, b) => (a.sequence > b.sequence ? -1 : 1))
              .map((educationElement: EducationData, index: number) => (
                <div
                  className="project_input_container"
                  key={educationElement.id}
                  onDragStart={() => dragStart(educationElement.sequence)}
                  onDragEnter={() => dragEnter(educationElement.sequence)}
                  onDragEnd={drop}
                  draggable={!disabled}
                >
                  <div className="project_input">
                    <label htmlFor="school_name">School name</label>
                    <input
                      type={"text"}
                      style={{ width: "50%" }}
                      name="school_name"
                      value={educationElement.school_name}
                      onChange={(e) => updateValue(e, educationElement.id)}
                      disabled={disabled}
                    />
                  </div>
                  <div className="project_input">
                    <label htmlFor="faculty">Faculty</label>
                    <input
                      type={"text"}
                      style={{ width: "50%" }}
                      name="faculty"
                      value={
                        educationElement.faculty !== null
                          ? educationElement.faculty
                          : ""
                      }
                      onChange={(e) => updateValue(e, educationElement.id)}
                      disabled={disabled}
                    />
                  </div>
                  <div className="project_input">
                    <label htmlFor="field_of_study">Field of study</label>
                    <input
                      type={"text"}
                      style={{ width: "33%" }}
                      name="field_of_study"
                      value={educationElement.field_of_study}
                      onChange={(e) => updateValue(e, educationElement.id)}
                      disabled={disabled}
                    />
                  </div>
                  <div className="project_input_row">
                    <div className="project_input">
                      <label htmlFor="study_from">From</label>
                      <input
                        type={"text"}
                        style={{ width: "80%" }}
                        name="study_from"
                        value={
                          educationElement.study_from !== null
                            ? educationElement.study_from
                            : ""
                        }
                        onChange={(e) => updateValue(e, educationElement.id)}
                        disabled={disabled}
                      />
                    </div>
                    <div className="project_input">
                      <label htmlFor="study_end">To</label>
                      <input
                        type={"text"}
                        style={{ width: "80%" }}
                        name="study_end"
                        value={
                          educationElement.study_end !== null
                            ? educationElement.study_end
                            : ""
                        }
                        onChange={(e) => updateValue(e, educationElement.id)}
                        disabled={disabled}
                      />
                    </div>
                  </div>
                  <div className="project_input">
                    <label htmlFor="description">Description</label>
                    <textarea
                      name="description"
                      rows={5}
                      value={
                        educationElement.description !== null
                          ? educationElement.description
                          : ""
                      }
                      onChange={(e) => updateValue(e, educationElement.id)}
                      disabled={disabled}
                    />
                  </div>
                  {!disabled && (
                    <div className="project_input">
                      <RemoveExperience
                        type="button"
                        onClick={() => removeEducation(educationElement.id)}
                      >
                        - School
                      </RemoveExperience>
                    </div>
                  )}
                  <p className="sequence_number">{index + 1}</p>
                  {!disabled && (
                    <>
                      <div
                        id="drag_icon"
                        className="drag_icon"
                        data-tip
                        data-for={"tooltip"}
                      >
                        <MdDragHandle className="drag_handle" />
                      </div>
                      <ReactTooltip
                        id="tooltip"
                        effect="solid"
                        className="exp_tooltip"
                      >
                        Drag to another position
                      </ReactTooltip>
                    </>
                  )}
                </div>
              ))}
        </div>
      </div>
    </div>
  );
};

export default Education;
