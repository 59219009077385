import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { CandidateType } from "../components/interfaces/interfaces";

interface ICandidates {
  candidates: CandidateType[];
}

const initialState: ICandidates = {
  candidates: [],
};

export const candidateSlice = createSlice({
  name: "candidates",
  initialState,
  reducers: {
    setCandidatesStore: (state, action) => {
      state.candidates = action.payload;
    },
  },
});

export const { setCandidatesStore } = candidateSlice.actions;
export default candidateSlice.reducer;
