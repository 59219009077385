import React from "react";
import Description from "../Description/Description";
import Title from "../Title/Title";
import "./Availability.scss";

interface Props {
  availabilitySalary: string;
  availabilityStart: string;
  setAvailabilitySalary: React.Dispatch<React.SetStateAction<string>>;
  setAvailabilityStart: React.Dispatch<React.SetStateAction<string>>;
  setChanged: React.Dispatch<React.SetStateAction<boolean>>;
}

const Availability: React.FC<Props> = ({
  availabilitySalary,
  availabilityStart,
  setAvailabilitySalary,
  setAvailabilityStart,
  setChanged,
}) => {
  return (
    <div className="available_information">
      <div className="available_information_body">
        <div className="available_information_body_description">
          <Title title="Hired candidate information" />
          <Description description="Additional information about salary and possible start at new project." />
        </div>
        <div className="available_information_body_input_container">
          <div className="available_information_input">
            <label htmlFor="expectedSalary">Expected Salary</label>
            <input
              type={"text"}
              style={{ width: "33%" }}
              name="expectedSalary"
              value={availabilitySalary !== null ? availabilitySalary: ""}
              onChange={(e) => {
                setAvailabilitySalary(e.currentTarget.value);
                setChanged(true);
              }}
            />
          </div>
          <div className="available_information_input">
            <label htmlFor="startDate">Possible start</label>
            <input
              type={"date"}
              style={{ width: "33%" }}
              name="available_date"
              value={availabilityStart !== null ? availabilityStart : ""}
              onChange={(e) => {
                setAvailabilityStart(e.currentTarget.value);
                setChanged(true);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Availability;
