import moment from "moment";
import React, {useCallback} from "react";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import "./AllInvoices.scss";
import { useNavigate } from "react-router-dom";

interface Props {
  invoices: {
    id: number;
    constantSymbol: string;
    invoiceDate: string;
    invoiceNumber: string;
    list: string;
    specificSymbol: string;
    status: string;
    totalPrice: string;
    variableSymbol: string;
    supplier: string;
  }[];
  name: string;
  multiSportCard:boolean;
}

const AllInvoices: React.FC<Props> = ({ invoices, name, multiSportCard }) => {
  const navigate = useNavigate();

  const totalPrice = useCallback((price:number)=>{
    return Number(price - (multiSportCard ? 20 : 0)).toFixed(2);
  },[multiSportCard])

  return (
    <>
      {invoices &&
        invoices.map((invoiceElement) => (
          <div
            className="invoice_element"
            key={invoiceElement.id}
            onClick={() => navigate(`/invoice/details/${invoiceElement.id}`)}
          >
            <h3 style={{ maxWidth: "120px", width: "120px" }}>
              <span style={{ color: "#008db9" }}># </span>
              {invoiceElement.invoiceNumber}
            </h3>
            <p className="invoice_element_date">
              <span>Due </span>
              {moment(invoiceElement.invoiceDate)
                .add(1, "M")
                .add(30, "day")
                .format("DD MMM YYYY")}
            </p>
            <p className="supplier">{invoiceElement.supplier}</p>
            <h3
              style={{
                width: "120px",
                maxWidth: "120px",
                wordBreak: "break-word",
              }}
            >
              <span style={{ color: "#008db9" }}>€ </span>
              {totalPrice(Number(invoiceElement.totalPrice))}
            </h3>
            <div
              className={`invoice_element_status ${
                invoiceElement.status === "PAID"
                  ? "paid"
                  : invoiceElement.status === "PENDING"
                  ? "pending"
                  : "declined"
              }`}
            >
              <div
                className={`invoice_element_status_dot ${
                  invoiceElement.status === "PAID"
                    ? "paid_dot"
                    : invoiceElement.status === "PENDING"
                    ? "pending_dot"
                    : "declined_dot"
                }`}
              ></div>
              {invoiceElement.status}
            </div>
            <NavigateNextIcon
              sx={{
                color: "#008db9",
                cursor: "pointer",
              }}
            />
          </div>
        ))}
    </>
  );
};

export default AllInvoices;
