import React from "react";
import "./ActiveLayer.scss";

interface Props {
  menu: string;
}

const ActiveLayer: React.FC<Props> = ({ menu }) => {
  return (
    <div
      className={`${
        menu === "Dashboard"
          ? "background"
          : menu === "Employees"
          ? "background2"
          : menu === "Candidates"
          ? "background3"
          : menu === "GDPR"
          ? "background4"
          : menu === "Invoices"
          ? "background5"
          : menu === "Settings"
          ? "background6"
          : ""
      }`}
    />
  );
};

export default ActiveLayer;
