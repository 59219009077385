import { StyleSheet } from "@react-pdf/renderer";

export const Invoicestyles = StyleSheet.create({
  identificationData: {
    width: "100%",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    flexDirection: "row",
  },
  indentificationData_suplier: {
    width: "50%",
    display: "flex",
    height: 250,
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column",
    borderRight: "1px solid #e6e6e6",
  },
  identificatioNdata_customer: {
    width: "50%",
    display: "flex",
    height: 250,
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column",
  },
  header_text: {
    width: "100%",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column",
    marginLeft: 50,
    height: 120,
    position: "relative",
  },
  customer_text: {
    width: "100%",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column",
    paddingLeft: 35,
    height: 120,
    position: "relative",
  },
  header: {
    fontSize: 9,
    color: "#008db9",
    fontWeight: "ultrabold",
    transform: "rotate(-90deg)",
    top: -210,
    left: 130,
    height: 500,
    width: 120,
    marginLeft: 80,
    position: "absolute",
    fontFamily: "Roboto",
  },
  billing_header: {
    fontSize: 9,
    color: "white",
    fontWeight: "ultrabold",
    transform: "rotate(-90deg)",
    top: -218,
    left: 130,
    height: 500,
    width: 120,
    marginLeft: 80,
    position: "absolute",
    fontFamily: "Roboto",
  },
  invoiceHeader: {
    fontSize: 9,
    color: "#008db9",
    fontWeight: "ultrabold",
    transform: "rotate(-90deg)",
    top: -240,
    left: 130,
    height: 500,
    width: 120,
    marginLeft: 80,
    position: "absolute",
    fontFamily: "Roboto",
  },
  summaryHeader: {
    fontSize: 9,
    color: "#008db9",
    fontWeight: "ultrabold",
    transform: "rotate(-90deg)",
    top: -280,
    left: 130,
    height: 500,
    width: 120,
    marginLeft: 80,
    position: "absolute",
    fontFamily: "Roboto",
  },
  invoiceDate: {
    width: "100%",
    paddingLeft: 50,
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "row",
    marginTop: 15,
  },
  invoiceBilling: {
    width: "80%",
    marginTop: 10,
    paddingLeft: 50,
    paddingBottom: 17,
    paddingRight: 15,
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    flexDirection: "row",
    position: "relative",
    backgroundColor: "#008db9",
  },
  invoiceBilling_column: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column",
  },
  invoiceBilling_lastColumn: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "space-between",
    flexDirection: "column",
  },
  invoiceText: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "row",
    marginTop: 10,
    paddingLeft: 50,
  },
  invoiceItems: {
    width: "100%",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column",
    paddingLeft: 50,
    marginTop: 10,
    position: "relative",
  },
  viewer: {
    width: "100%", //the pdf viewer will take up all of the width and height
    height: "100vh",
  },
  body: {
    paddingTop: 5,
    paddingBottom: 15,
  },
});
