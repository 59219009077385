import { Paragraph, TextRun, SpaceType } from "docx";
export const nameComponent = (data: any, anonymizeName: boolean) => {
  let name = "";
  anonymizeName
    ? (name = data.firstName[0] + ". " + data.lastName[0] + ".")
    : (name = data.firstName + " " + data.lastName);
  return new Paragraph({
    spacing: {
      before: 35,
      after: 35,
    },
    indent: {
      left: 60,
      right: 60,
    },
    children: [
      new TextRun({
        allCaps: true,
        space: SpaceType.PRESERVE,
        text: name,
        font: "Arial",
        size: 36,
        color: "1F3864",
        bold: true,
      }),
    ],
  });
};
