import React, { useEffect, useRef, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { getClient } from "../../api/clientApi";
import {
  ClientType,
  EventTypeSelected,
  SettingsType,
} from "../../components/interfaces/interfaces";
import UseAuth from "../../hooks/UseAuth";
import useGreetHook from "../../hooks/useGreetHook";
import "./UserDashboard.scss";
import UserDashboardWidget from "./UserDashboardWidget/UserDashboardWidget";
import WalletIcon from "@mui/icons-material/Wallet";
import CustomToggle from "../Settings/Users/UserInfoModal/CustomToggle/CustomToggle";
import Tooltip from "@mui/material/Tooltip/Tooltip";
import Fade from "@mui/material/Fade/Fade";
import ScheduleIcon from "@mui/icons-material/Schedule";
import useDaysUntilInvoice from "../../hooks/useDaysUntilInvoice";
import ProgressBar from "../../components/ProgressBar/ProgressBar";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import InvoiceChart from "./UserDashboardWidget/InvoiceChart/InvoiceChart";
import kodivaOffice from "../../assets/images/office.png";
import UserDashboardSmallWidget from "./UserDashboardSmallWidget/UserDashboardSmallWidget";
import UserDashboardCalendar from "./UserDashboardCalendar/UserDashboardCalendar";
import MonthCalendar from "../Dashboard/Modals/MonthCalendar/MonthCalendar";
import UserDashboardKodivaLogo from "./UserDashboardKodivaLogo/UserDashboardKodivaLogo";
import useCurrentAndNextRate from "../../hooks/useCurrentAndNextRate";
import UserDashboardScrollableWidget from "./UserDashboardScrollableWidget/UserDashboardScrollableWidget";
import useIsVisible from "../../hooks/useIsVisible";
import useFindNextRateDate from "../../hooks/useFindNextRateDate";
import dayjs from "dayjs";
import { getSettings } from "../../api/settingsApi";

const UserDashboard = () => {
  const firstPageRef = useRef<HTMLDivElement>(null);
  const ref = useRef<HTMLDivElement>(null);
  const isVisible = useIsVisible(ref);
  const [pendingInvoices, setPendingInvoices] = useState<number>(0);
  const [openMonthCalendar, setOpenMonthCalendar] = useState(false);
  const [eventsFromCalendar, setEventsFromCalendar] = useState<
    EventTypeSelected[]
  >([]);
  const greet = useGreetHook();
  const invoiceData = useDaysUntilInvoice();
  const queryclient = useQueryClient();
  const { auth } = UseAuth();
  const userData = useQuery<ClientType>(["user", auth.userName], () =>
    getClient(auth.userName)
  );
  const settingsData = useQuery<SettingsType[]>(["byod"], getSettings, {
    refetchOnWindowFocus: false,
    retry: 0,
  });
  useEffect(() => {
    if (userData.data) {
      setPendingInvoices(
        userData.data.invoices.reduce(
          (count, invoice) =>
            invoice.status === "PENDING" ? count + 1 : count,
          0
        )
      );
    }
  }, [userData]);
  const rateData = useCurrentAndNextRate(userData?.data?.clientProjects!);
  const nextRateDate = useFindNextRateDate(
    userData?.data?.clientProjects!,
    rateData.nextRate
  );
  const nextRateDateFormated = dayjs(nextRateDate).format("DD.MM.YYYY");
  return (
    <div className="user_dashboard">
      <div className="user_dashboard_content">
        <h5>
          {greet}
          {" " + userData.data?.name + " !"}
        </h5>
        <div className="user_dashboard_content_widgets">
          <UserDashboardKodivaLogo />
          <UserDashboardWidget>
            <a
              style={{ width: "100%", height: "100%" }}
              href="https://www.google.sk/maps/place/Doln%C3%A9+Rudiny+8363,+010+01+%C5%BDilina/@49.2195697,18.7256234,16.5z/data=!4m6!3m5!1s0x47145ea30a8f39e5:0x43f0ebd254689811!8m2!3d49.2196759!4d18.7294785!16s%2Fg%2F11sjsg75fh"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={kodivaOffice}
                alt="kodiva-office"
                className="kodiva_office"
              />
            </a>
          </UserDashboardWidget>
          <UserDashboardScrollableWidget>
            <div
              className={`page ${rateData.nextRate && "first"}`}
              ref={firstPageRef}
            >
              <WalletIcon className="widget_icon" />
              <div className="user_dashboard_content_widget">
                <p>{rateData?.currentRate} € / MD</p>
              </div>
              {rateData.nextRate && (
                <>
                  <span className="your_rate">
                    Your rate from{" "}
                    <p className="rate_value">{nextRateDateFormated}</p>
                  </span>
                  <span className="next_rate">{rateData.nextRate} € / MD</span>
                </>
              )}
            </div>
          </UserDashboardScrollableWidget>
          <UserDashboardWidget>
            <CalendarTodayIcon className="widget_icon" />
            <p>{userData.data?.contractExpirationDate}</p>
            <span>Your contract valid date</span>
          </UserDashboardWidget>
          {/* <UserDashboardWidget>
            <ScheduleIcon className="widget_icon" />
            <ProgressBar
              daysPassed={
                100 -
                (invoiceData.daysUntilInvoice / invoiceData.lengthOfMonth) * 100
              }
              daysLeft={
                invoiceData.lengthOfMonth - invoiceData.daysUntilInvoice
              }
            />
            <span>Your time until new invoice</span>
          </UserDashboardWidget> */}
          <UserDashboardWidget>
            <DirectionsRunIcon className="widget_icon" />
            {userData.data?.multiSportCard && (
              <ProgressBar
                daysPassed={
                  100 -
                  (invoiceData.daysUntilInvoice / invoiceData.lengthOfMonth) *
                    100
                }
                daysLeft={
                  invoiceData.lengthOfMonth - invoiceData.daysUntilInvoice
                }
              />
            )}
            <div className="user_dashboard_content_widget-multisport">
              <div className="multisport_names">
                <span>Main card</span>
                <span>Second card</span>
              </div>
              <CustomToggle checked={userData.data?.multiSportCard!} />
              <CustomToggle
                checked={userData.data?.accompanyingMultiSportCard!}
              />
            </div>
            <span style={{ marginTop: "-15px" }}>Multisport card</span>
          </UserDashboardWidget>
        </div>
        <div className="user_dashboard_content_widgets">
          <div className="user_dashboard_content_widgets-invoice-details">
            <UserDashboardSmallWidget>
              <h4>Pending invoices</h4>
              <div className="pending_invoice_number">{pendingInvoices}</div>
            </UserDashboardSmallWidget>
            <UserDashboardSmallWidget>
              <ScheduleIcon className="widget_icon" />
              <ProgressBar
                daysPassed={
                  100 -
                  (invoiceData.daysUntilInvoice / invoiceData.lengthOfMonth) *
                    100
                }
                daysLeft={
                  invoiceData.lengthOfMonth - invoiceData.daysUntilInvoice
                }
              />
              <span>Time until new invoice</span>
            </UserDashboardSmallWidget>
          </div>
          <InvoiceChart invoices={userData.data?.invoices} />
        </div>
      </div>
      <UserDashboardCalendar
        setEventsFromCalendar={setEventsFromCalendar}
        setOpenMonthCalendar={setOpenMonthCalendar}
      />
      {openMonthCalendar && (
        <MonthCalendar
          open={openMonthCalendar}
          setOpen={setOpenMonthCalendar}
          events={eventsFromCalendar}
        />
      )}
    </div>
  );
};

export default UserDashboard;
