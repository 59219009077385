import axios from "axios";
import { CandidateType } from "../components/interfaces/interfaces";
import { refreshToken } from "./authApi";
import { baseURL } from './apiConfig';

const serverApi = axios.create({
  baseURL: baseURL,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});
export const getCandidates = async () => {
  await refreshToken();
  const bearer_token = `${localStorage.getItem("token")}`;
  const { data } = await serverApi.get("/api/candidate", {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${bearer_token}`,
    },
  });
  return data;
};
export const getCandidate = async (id: number) => {
  await refreshToken();
  const bearer_token = `${localStorage.getItem("token")}`;
  const { data } = await serverApi.get(`api/candidate/id/?id=${id}`, {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${bearer_token}`,
    },
  });
  return data;
};

export const deleteCandidate = async (id: number) => {
  await refreshToken();
  const bearer_token = `${localStorage.getItem("token")}`;
  await serverApi.delete(`/api/candidate/id/?id=${id}`, {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${bearer_token}`,
    },
  });
};

export const createCandidate = async (payload: CandidateType) => {
  await refreshToken();
  const bearer_token = `${localStorage.getItem("token")}`;
  const data = await serverApi.post(`api/candidate/`, payload, {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${bearer_token}`,
    },
  });
  return data.data.id;
};

export const updateCandidate = async (payload: CandidateType) => {
  await refreshToken();
  const bearer_token = `${localStorage.getItem("token")}`;
  await serverApi.put(`api/candidate/`, payload, {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${bearer_token}`,
    },
  });
};

export const getInterviewCount = async () => {
  await refreshToken();
  const bearer_token = `${localStorage.getItem("token")}`;
  const { data } = await serverApi.get("/api/candidate/interview/count", {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${bearer_token}`,
    },
  });
  return data;
};

export const getApplicationCount = async () => {
  await refreshToken();
  const bearer_token = `${localStorage.getItem("token")}`;
  const { data } = await serverApi.get("/api/candidate/application/count", {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${bearer_token}`,
    },
  });
  return data;
};

/**
 * @description if any of the API gets 401 status code, user is
 * redirected to login page
 */
serverApi.interceptors.response.use(undefined, (err) => {
  const error = err;
  // if (error.response.status === 401) {
  //   localStorage.setItem("user", "false");
  //   localStorage.setItem("token", "");
  //   alert("Logged out due to inactivity.");
  //   window.location.href = "/";
  // }
});

export default serverApi;
