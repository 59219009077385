import React from "react";
interface Props {
  text: string;
}
const NewInvoiceHeader: React.FC<Props> = ({ text }) => {
  return (
    <p style={{ color: "#008db9", fontWeight: "600", letterSpacing: "0.05em" }}>
      {text}
    </p>
  );
};

export default NewInvoiceHeader;
