import Fade from "@mui/material/Fade/Fade";
import Tooltip, { TooltipProps } from "@mui/material/Tooltip/Tooltip";
import React from "react";

type CustomTooltipProps = {
  title: string;
} & Omit<TooltipProps, "title">;

const CustomTooltip = ({ children, title, ...props }: CustomTooltipProps) => {
  return (
    <Tooltip
      title={title}
      TransitionComponent={Fade}
      placement="right-start"
      className="tooltip"
      PopperProps={{
        sx: {
          "& .MuiTooltip-tooltip": {
            border: "transparent",
            color: "#003d50",
            backgroundColor: "white",
            padding: "10px",
            borderRadius: "10px",
            fontSize: "12px",
            boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px;",
          },
        },
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [20, -50],
            },
          },
        ],
      }}
      {...props}
    >
      {children}
    </Tooltip>
  );
};

export default CustomTooltip;
