export const InitialState = {
  user: false,
  page: 1,
  expirationCount: 0,
  statusFilter: localStorage.getItem("statusFilter")?.split(",") || [],
  PDFSettings:
    "PDFSettings" in localStorage
      ? JSON.parse(localStorage.getItem("PDFSettings")!)
      : {
          anonymizeName: false,
          anonymizeRegion: false,
          anonymizeNumber: false,
          anonymizeEmail: false,
          anonymizeExperienceName: false,
          anonymizeSummary: false,
        },
  selectedNumber: localStorage.getItem("selectedNumber") || "",
  selectedEmail: localStorage.getItem("selectedEmail") || "",
    invoice:
        localStorage.getItem("InvoicePDF") && localStorage.getItem("InvoicePDF") !== 'undefined'
            ? JSON.parse(localStorage.getItem("InvoicePDF")!)
            : null,
  menu: localStorage.getItem("navbar") || "Dashboard",
  employeeView: "Card",
    search: "",
};
