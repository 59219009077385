import { View, Text, Font } from "@react-pdf/renderer";
import React from "react";
import { styles } from "./PDFStylings";

import arialText from "../../../../assets/fonts/arialW.ttf";
import arial from "../../../../assets/fonts/Arial_medium.ttf";

Font.register({
  family: "Roboto",
  fonts: [
    {
      src: arial,
    },
    {
      src: arialText,
      fontWeight: 300,
    },
  ],
});

interface Props {
  projects: {
    id: number;
    name: string;
    description: string;
    project_from: string;
    project_end: string;
    sequence: number;
  }[];
}

const ShowPDFProjects: React.FC<Props> = ({ projects }) => {
  return (
    <View style={styles.experiences} wrap={true}>
      {projects
        .sort((a, b) => (a.sequence > b.sequence ? -1 : 1))
        .map((exp, index: number) => (
          <View
            key={exp.id}
            style={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              flexDirection: "column",
              width: "100%",
              // border: "1px solid #c9c9c9",
              borderLeft: "1px solid #c9c9c9",
              borderRight: "1px solid #c9c9c9",
              borderBottom: "1px solid #c9c9c9",
            }}
            wrap={false}
          >
            {index === 0 && (
              <View style={styles.experiences_header} wrap={true}>
                <Text
                  style={{
                    color: "#1F3864",
                    fontSize: "14px",
                    backgroundColor: "#c9c9c9",
                    textTransform: "uppercase",
                    padding: "4px",
                    fontFamily: "Roboto",
                    fontWeight: "bold",
                  }}
                >
                  PROJECTS
                </Text>
              </View>
            )}
            <View style={styles.experiences_name}>
              <Text
                style={{
                  color: "#2f5496",
                  fontSize: "13px",
                  padding: "4px",
                  fontFamily: "Roboto",
                  fontWeight: "bold",
                  width: "75%",
                }}
              >
                {exp.name}
              </Text>
              <Text
                style={{
                  color: "#2f5496",
                  fontSize: "12px",
                  padding: "4px",
                  fontFamily: "Roboto",
                  fontWeight: "bold",
                  width: "25%",
                  textAlign: "right",
                }}
              >
                {/* {exp.project_from === null && exp.project_end === null
                  ? ""
                  : exp.project_end === null
                  ? exp.project_from + " -"
                  : exp.project_from === null
                  ? "- " + exp.project_end
                  : exp.project_from + " - " + exp.project_end} */}
                {exp.project_from === "" && exp.project_end === ""
                  ? ""
                  : exp.project_from + " - " + exp.project_end}
              </Text>
            </View>
            <View style={styles.experiences_description}>
              <Text
                style={{
                  color: "black",
                  fontSize: "12px",
                  padding: "4px",
                  lineHeight: "1.3px",
                  textAlign: "justify",
                  fontFamily: "Roboto",
                  fontWeight: 300,
                }}
              >
                {exp.description}
              </Text>
            </View>
          </View>
        ))}
    </View>
  );
};

export default ShowPDFProjects;
