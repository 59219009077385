import {
  Box,
  Checkbox,
  FormControl,
  MenuItem,
  Modal,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import React, { useState } from "react";
import CancelButton from "../../../../components/Buttons/Cancel/CancelButton";
import SubmitButton from "../../../../components/Buttons/Submit/SubmitButton";
import { actTypes } from "../../../../components/Context/Reducer";
import { UserAuth } from "../../../../components/Context/StateProvider";
import "./AnonymizePDFModal.scss";
import {
  CandidateNumbers,
  CandidateEmails,
} from "../../../../components/interfaces/interfaces";
import { ShowDocx } from "../../Docx/ShowDOCX";
import { Packer } from "docx";
import saveAs from "file-saver";

interface Props {
  editPDF: boolean;
  id: string | undefined;
  allNumbers: CandidateNumbers[];
  allEmails: CandidateEmails[];
  type: string;
  data: any[];

  setEditPdf: React.Dispatch<React.SetStateAction<any>>;
}

const AnonymizePDFModal: React.FC<Props> = ({
  editPDF,
  setEditPdf,
  type,
  id,
  allNumbers,
  allEmails,
  data,
}) => {
  const [{ PDFSettings }, dispatch] = UserAuth();

  const [anonymizeName, setAnonymizeName] = useState<boolean>(
    Boolean(PDFSettings.anonymizeName)
  );
  const [anonymizeSummary, setAnonymizeSummary] = useState<boolean>(
    Boolean(PDFSettings.anonymizeSummary)
  );
  const [anonymizePlace, setanonymizePlace] = useState<boolean>(
    Boolean(PDFSettings.anonymizeRegion)
  );
  const [anonymizeNumber, setAnonymizeNumber] = useState<boolean>(
    Boolean(PDFSettings.anonymizeNumber)
  );
  const [selectedNumber, setSelectedNumber] = useState("");

  const [anonymizeExperienceName, setAnonymizeExperienceName] =
    useState<boolean>(PDFSettings.anonymizeExperienceName);

  const [anonymizeEmail, setAnonymizeEmail] = useState<boolean>(
    Boolean(PDFSettings.anonymizeEmail)
  );
  const [selectedEmail, setSelectedEmail] = useState("");

  const handleChange = (event: SelectChangeEvent) => {
    setSelectedNumber(event.target.value);
  };
  const handleSelectEmailChange = (event: SelectChangeEvent) => {
    console.log("email changed");
    setSelectedEmail(event.target.value);
  };

  const handleClose = () => {
    localStorage.removeItem("selectedNumber");
    localStorage.removeItem("selectedEmail");
    setEditPdf(false);
  };
  const handleSubmit = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    const payload = {
      anonymizeExperienceName: anonymizeExperienceName,
      anonymizeName: anonymizeName,
      anonymizeNumber: anonymizeNumber,
      anonymizeEmail: anonymizeEmail,
      anonymizeRegion: anonymizePlace,
      anonymizeSummary: anonymizeSummary,
    };
    dispatch({
      type: actTypes.SET_PDF_SETTINGS,
      PDFSettings: payload,
    });
    localStorage.setItem("PDFSettings", JSON.stringify(payload));

    dispatch({
      type: actTypes.SET_SELECTED_MAIL,
      selectedEmail: selectedEmail,
    });
    localStorage.setItem("selectedEmail", selectedEmail);

    dispatch({
      type: actTypes.SET_SELECTED_NUMBER,
      selectedNumber: selectedNumber,
    });
    localStorage.setItem("selectedNumber", selectedNumber);

    if (type === "PDF") {
      window.open(`/cv/${id}/pdf`, "_blank", "noopener,noreferrer");
    }
    if (type === "WORD") {
      const doc = ShowDocx(data, payload, selectedNumber, selectedEmail);
      Packer.toBlob(doc).then((blob) => {
        saveAs(blob, "example.docx");
        // alert("Document created successfully");
      });
    }
    setEditPdf(false);
  };
  return (
    <div>
      <Modal open={editPDF} onClose={handleClose}>
        <Box className="anonymize_modal">
          <div className="anonymize_modal_container">
            <h2>Edit {type}</h2>
            <form className="anonymize_modal_form">
              <div className="anonymize_modal_form_item">
                <p>Anonymize name</p>
                <Checkbox
                  checked={anonymizeName}
                  onChange={() => setAnonymizeName((prev) => !prev)}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </div>
              <div className="anonymize_modal_form_item">
                <p>Anonymize region</p>
                <Checkbox
                  checked={Boolean(anonymizePlace)}
                  onChange={() => setanonymizePlace((prev) => !prev)}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </div>
              <div className="anonymize_modal_form_dropdown">
                <div className="anonymize_modal_form_item">
                  <p>Anonymize number</p>
                  <Checkbox
                    checked={anonymizeNumber}
                    onChange={() => setAnonymizeNumber((prev) => !prev)}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </div>
                {anonymizeNumber && (
                  <div className="anonymize_modal_form_select">
                    <p>Select number</p>
                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                      <Select
                        id="number_select"
                        value={selectedNumber}
                        onChange={handleChange}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {allNumbers.map((number, index) => (
                          <MenuItem value={number.phone} key={index}>
                            {number.phone}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                )}
              </div>
              <div className="anonymize_modal_form_dropdown">
                <div className="anonymize_modal_form_item">
                  <p>Anonymize email</p>
                  <Checkbox
                    checked={anonymizeEmail}
                    onChange={() => setAnonymizeEmail((prev) => !prev)}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </div>
                {anonymizeEmail && (
                  <div className="anonymize_modal_form_select">
                    <p>Select email</p>
                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                      <Select
                        id="email_select"
                        value={selectedEmail}
                        onChange={handleSelectEmailChange}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {allEmails.map((email, index) => (
                          <MenuItem value={email.email} key={index}>
                            {email.email}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                )}
              </div>
              <div className="anonymize_modal_form_item">
                <p>Anonymize summary</p>
                <Checkbox
                  checked={anonymizeSummary}
                  onChange={() => setAnonymizeSummary((prev) => !prev)}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </div>
              <div className="anonymize_modal_form_item">
                <p>Anonymize experience name</p>
                <Checkbox
                  checked={anonymizeExperienceName}
                  onChange={() => setAnonymizeExperienceName((prev) => !prev)}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </div>
            </form>
            <div className="anonymize_modal_container_buttons">
              <SubmitButton onClick={handleSubmit}>Open {type}</SubmitButton>
              <CancelButton onClick={handleClose}>Cancel</CancelButton>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default AnonymizePDFModal;
