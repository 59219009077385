import React, { useEffect, useState } from "react";
import "./OpenScheduler.scss";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Scheduler } from "@aldabil/react-scheduler";
import moment from "moment";
import { EventActions, ProcessedEvent } from "@aldabil/react-scheduler/types";

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const OpenScheduler: React.FC<Props> = ({ open, setOpen }) => {
  const [events, setEvents] = useState<any[]>([]);
  const calendarID = "aberesik%40gmail.com";
  const apiKey = "AIzaSyAGsmn9HANE7Gf6bdJp8O5ZNkagAZyLTV8";
  const accessToken =
    "ya29.a0AX9GBdXbUiYGu4EgygjG_8TmVaH-i-3f5YSAHROht6Ue5pgDtkVfP_f-EATDhTbMsZGRFJlRitBKt5Y0HTtx5xkeVG0aYr5REVRpRBoTWn_4yzjPkDrga7EXm_bUiqeh8f5FlCq3vwZ8-KWKXUPsj44ky0u8aCgYKAdcSARESFQHUCsbCVLF8925UQ4T4dUVFZPK15Q0163";
  const timeZone = "Europe/Prague";
  const handleClose = () => {
    setOpen(false);
  };

  const getEvents = (calendarID: string, apiKey: string) => {
    async function initiate() {
      await gapi.client
        .init({
          apiKey: apiKey,
        })
        .then(function () {
          return gapi.client.request({
            path: `https://www.googleapis.com/calendar/v3/calendars/${calendarID}/events`,
          });
        })
        .then(
          (response) => {
            console.log("success", response.result.items);
            const events = response.result.items;
            let count = 0;
            let eventsReworked: any[] = [];
            events.map((event: any) => {
              eventsReworked.push({
                event_id: ++count,
                title: event.summary,
                start: new Date(
                  moment(event.start.dateTime).format("YYYY/MM/DD HH:mm")
                ),
                end: new Date(
                  moment(event.end.dateTime).format("YYYY/MM/DD HH:mm")
                ),
              });
            });
            console.log(eventsReworked);
            setEvents(eventsReworked);
          },
          function (err) {
            return [false, err];
          }
        );
    }
    gapi.load("client", initiate);
  };

  useEffect(() => {
    getEvents(calendarID, apiKey);
  }, []);

  const handleConfirm = async (
    event: ProcessedEvent,
    action: EventActions
  ): Promise<ProcessedEvent> => {
    console.log("handleConfirm =", action, event);
    console.log(moment(event.start).format("YYYY-MM-DDTHH:mm:00"));
    const startTime = moment(event.start).format("YYYY-MM-DDTHH:mm:00");
    const endTime = moment(event.end).format("YYYY-MM-DDTHH:mm:00");
    console.log("s", startTime);
    var text = {
      summary: "Hello World",
      location: "",
      start: {
        dateTime: startTime,
        timeZone: timeZone,
      },
      end: {
        dateTime: endTime,
        timeZone: timeZone,
      },
      attendees: [],
      reminders: {
        useDefault: false,
        overrides: [{ method: "email", minutes: 24 * 60 }],
      },
    };
    function initiate() {
      gapi.client
        .request({
          path: `https://www.googleapis.com/calendar/v3/calendars/${calendarID}/events`,
          method: "POST",
          body: text,
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then(
          (response) => {
            return [true, response];
          },
          function (err) {
            console.log(err);
            return [false, err];
          }
        );
    }
    gapi.load("client", initiate);
    if (action === "edit") {
      /** PUT event to remote DB */
    } else if (action === "create") {
      /**POST event to remote DB */
    }
    /**
     * Make sure to return 4 mandatory fields:
     * event_id: string|number
     * title: string
     * start: Date|string
     * end: Date|string
     * ....extra other fields depend on your custom fields/editor properties
     */
    // Simulate http request: return added/edited event
    return new Promise((res, rej) => {
      setTimeout(() => {
        res({
          ...event,
          event_id: event.event_id || Math.random(),
        });
      }, 3000);
    });
  };

  const handleDelete = async (deletedId: string): Promise<string> => {
    // Simulate http request: return the deleted id
    return new Promise((res, rej) => {
      setTimeout(() => {
        res(deletedId);
      }, 3000);
    });
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box className="scheduler_modal">
        <div className="scheduler_modal_container">
          <h2>Calendar</h2>
          <Scheduler
            events={events}
            view="day"
            height={500}
            day={null}
            week={null}
            onConfirm={handleConfirm}
            onDelete={handleDelete}
            hourFormat="24"
          />
        </div>
      </Box>
    </Modal>
  );
};

export default OpenScheduler;
