import React from "react";
import "./EmployeeCard.scss";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import PersonIcon from "@mui/icons-material/Person";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import dayjs from "dayjs";
import { UserAuth } from "../../../components/Context/StateProvider";
import { actTypes } from "../../../components/Context/Reducer";

interface Props {
  firstName: string;
  lastName: string;
  region: string;
  work_position: string;
  exp_date: string;
  expectedSalary: string;
  id: number;
}

const EmployeeCard: React.FC<Props> = ({
  firstName,
  lastName,
  region,
  work_position,
  exp_date,
  expectedSalary,
  id,
}) => {
  const [{ PDFSettings }, dispatch] = UserAuth();
  const date = exp_date === null || exp_date === "" ? "" : exp_date;
  const navigate = useNavigate();
  const now = dayjs(new Date()).format("YYYY-MM-DD");

  const handleClick = () => {
    const payload = {
      anonymizeExperienceName: true,
      anonymizeName: true,
      anonymizeNumber: true,
      anonymizeEmail: true,
      anonymizeRegion: true,
      anonymizeSummary: true,
    };
    dispatch({
      type: actTypes.SET_PDF_SETTINGS,
      PDFSettings: payload,
    });
    localStorage.setItem("PDFSettings", JSON.stringify(payload));

    dispatch({
      type: actTypes.SET_SELECTED_MAIL,
      selectedEmail: "",
    });
    localStorage.setItem("selectedEmail", "");

    dispatch({
      type: actTypes.SET_SELECTED_NUMBER,
      selectedNumber: "",
    });
    localStorage.setItem("selectedNumber", "");
    window.open(`/cv/${id}/pdf`, "_blank", "noopener,noreferrer");
  };
  return (
    <div className="empoloyee_card" onClick={handleClick}>
      <div className="employee_card_header">
        <div
          className={`employee_card_header_availability ${
            moment(exp_date).diff(moment(), "days") > 0
              ? "unavailable"
              : "available"
          }`}
        >
          {moment(exp_date).diff(moment(), "days") > 0
            ? "Unavailable"
            : "Available"}
        </div>
        <NavigateNextIcon className="employee_forward_icon" />
      </div>
      <div className="employee_card_img">
        <PersonIcon className="person_icon" />
      </div>
      <div className="employee_card_text">
        <p className="name">{firstName + lastName}</p>
        <p className="position">{work_position}</p>
      </div>
      <div className="employee_card_description">
        <div className="employee_card_description_row">
          <p>Place</p>
          <p className="description">{region}</p>
        </div>
        <div className="employee_card_description_row">
          <p>Rate</p>
          <p className="description">{expectedSalary}</p>
        </div>
        <div className="employee_card_description_row">
          <p>Availability</p>
          <p className="description">
            {/* {exp_date ? dayjs(exp_date).format("DD.MM.YYYY") : ""} */}
            {date !== ""
              ? dayjs(date).diff(now, "month") > 1 &&
                dayjs(date).diff(now, "month") < 4
                ? `In ${dayjs(date).diff(now, "month")} months`
                : dayjs(date).diff(now, "month") === 1
                ? `In ${dayjs(date).diff(now, "month")} month`
                : dayjs(date).diff(now, "month") < 1 &&
                  dayjs(date).diff(now, "month") > 0
                ? "Less than a month"
                : dayjs(date).diff(now, "month") <= 0
                ? "Available"
                : dayjs(date).format("MM.DD.YYYY")
              : ""}
          </p>
        </div>
      </div>
    </div>
  );
};

export default EmployeeCard;
