import { StyleSheet } from "@react-pdf/renderer";

export const styles = StyleSheet.create({
  header: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    backgroundColor: "transparent",
    paddingLeft: "5px",
  },
  headerInfo: {
    width: "100%",
    height: "45px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    marginLeft: "45px",
    flexDirection: "row",
    borderLeft: "1px solid black",
    paddingLeft: "5px",
  },
  body: {
    paddingTop: 23,
    paddingBottom: 53,
    paddingHorizontal: 65,
  },
  image: {
    width: "85px",
    height: "85px",
    objectFit: "contain",
  },
  viewer: {
    width: "100%", //the pdf viewer will take up all of the width and height
    height: "100vh",
  },
  details_summary: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "row",
    width: "100%",
    border: "1px solid #c9c9c9",
    marginTop: "10px",
  },
  skills: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column",
    width: "100%",
    border: "1px solid #c9c9c9",
    marginTop: "15px",
  },
  experiences: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column",
    width: "100%",
    marginTop: "15px",
  },
  experiences_header: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "row",
    width: "100%",
    backgroundColor: "#c9c9c9",
  },
  experiences_name: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    width: "100%",
    backgroundColor: "#e6e6e6",
  },
  experiences_description: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column",
    width: "100%",
    backgroundColor: "transparent",
  },
  details: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column",
  },
  detailsSummary: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column",
  },
  skills_header: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column",
    width: "100%",
  },
  languages: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column",
    width: "100%",
    marginTop: "15px",
  },
  languages_header: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "row",
    width: "100%",
    backgroundColor: "#c9c9c9",
  },
});
