import React from "react";
import { actTypes } from "../../../../components/Context/Reducer";
import { UserAuth } from "../../../../components/Context/StateProvider";
import "./EmployeeViewCard.scss";

interface Props {
  title: string;
  icon: JSX.Element;
}

const EmployeeViewCard: React.FC<Props> = ({ title, icon }) => {
  const [{ employeeView }, dispatch] = UserAuth();
  const handleClick = () => {
    dispatch({
      type: actTypes.SET_EMPLOYEE_VIEW,
      employeeView: title,
    });
  };
  return (
    <div
      className={`employee_view_card ${
        employeeView === title ? "active_card" : ""
      }`}
      onClick={handleClick}
    >
      <div className="employee_view_card_active">
        {employeeView === title ? <div className="active" /> : null}
      </div>
      <div className="employee_view_card_icon">{icon}</div>
      <p>{title}</p>
    </div>
  );
};

export default EmployeeViewCard;
