import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { CandidateType } from "../components/interfaces/interfaces";

interface ICandidates {
  allCandidates: CandidateType[];
}

const initialState: ICandidates = {
  allCandidates: [],
};

export const allCandidatesSlice = createSlice({
  name: "allCandidates",
  initialState: initialState,
  reducers: {
    setAllCandidates: (state, action) => {
      state.allCandidates = action.payload;
    },
  },
});

export const { setAllCandidates } = allCandidatesSlice.actions;
export default allCandidatesSlice.reducer;
