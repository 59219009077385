import React, { useEffect, useRef } from "react";
import { FilterType } from "../../../../../../components/interfaces/interfaces";
import "./Filter.scss";

interface Props {
  setPayload: React.Dispatch<React.SetStateAction<FilterType>>;
  setIsInterSecting: React.Dispatch<React.SetStateAction<boolean>>;
}

const Filter: React.FC<Props> = ({ setPayload, setIsInterSecting }) => {
  const elementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!elementRef.current) return;
    const observer = new IntersectionObserver((entries) => {
      const intersectionRect = entries[0].boundingClientRect;
      if (window.innerWidth < 500) {
        const isHalfPassedX =
          intersectionRect.x + intersectionRect.width / 2 <
          window.innerWidth / 2.5;
        setIsInterSecting(isHalfPassedX);
      } else {
        const isHalfPassedX =
          intersectionRect.x + intersectionRect.width / 2 <
          window.innerWidth / 1.5;
        setIsInterSecting(isHalfPassedX);
      }
    });
    observer.observe(elementRef.current!);
    return () => observer.disconnect();
  }, [elementRef, setIsInterSecting]);

  return (
    <div className="filter" ref={elementRef}>
      <button
        className="filter_select_option"
        type="button"
        onClick={() => {
          setPayload((prev) => ({ ...prev, name: "position" }));
        }}
      >
        Position
      </button>
    </div>
  );
};

export default Filter;
