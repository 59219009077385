import React, { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getSettings, updateSettings } from "../../../api/settingsApi";
import CancelButton from "../../../components/Buttons/Cancel/CancelButton";
import SubmitButton from "../../../components/Buttons/Submit/SubmitButton";
import "./General.scss";
interface IGeneralSettings {
  clientNumber: number;
  id: number;
  whitespaceRemove: boolean;
  byod: string;
}

const General = () => {
  const [generalSettings, setGeneralSettings] = useState<IGeneralSettings>({
    id: 0,
    byod: "0",
    clientNumber: 0,
    whitespaceRemove: false,
  });
  const queryClient = useQueryClient();

  const generalSettingsQuery = useQuery(["byod"], getSettings, {
    refetchOnWindowFocus: false,
    retry: 0,
    onSuccess: (data) => {
      queryClient.invalidateQueries("settings");
      console.log(data);
      setGeneralSettings(data[0]);
    },
  });

  const updateGeneralSettings = useMutation(updateSettings, {
    onSuccess: () => {
      // Invalidates cache and refetch
      queryClient.invalidateQueries("byod");
    },
  });
  const handleClose = () => {
    setGeneralSettings(generalSettingsQuery.data[0]);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    console.log(generalSettings);
    updateGeneralSettings.mutate(generalSettings);
  };

  return (
    <div className="general">
      <p className="general_settings_header">General Settings</p>
      {generalSettingsQuery.isFetched && (
        <form onSubmit={(e) => handleSubmit(e)}>
          <div className="user_input_row">
            <div className="user_input">
              <label htmlFor="byod">Bring your own device rate</label>
              <input
                type={"text"}
                style={{ width: "80%" }}
                name="byod"
                value={generalSettings?.byod}
                onChange={(e) =>
                  setGeneralSettings((prev) => ({
                    ...prev,
                    byod: e.target.value
                      .replace(/,/g, ".")
                      .replace(/[^0-9.]/g, ""),
                  }))
                }
                required
              />
            </div>
          </div>
          <div className="update_settings_buttons">
            <SubmitButton type="submit">Update settings</SubmitButton>
            <CancelButton onClick={handleClose} type="button">
              Cancel
            </CancelButton>
          </div>
        </form>
      )}
    </div>
  );
};

export default General;
