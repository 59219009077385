import React from "react";
import { useNavigate } from "react-router-dom";
import TableButtonPrimary from "../../../components/Buttons/TableButtons/PrimaryButton/TableButtonPrimary";
import TableButtonSecondary from "../../../components/Buttons/TableButtons/SecondaryButton/TableButtonSecondary";
import "./CVTableBody.scss";
import { TiUserDeleteOutline } from "react-icons/ti";

export interface Props {
  CVData: {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    place: string;
    status: string;
    work_position: string;
    exp_salary: string;
    exp_date: string;
    gdpr: number;
  }[];
  setCVData: React.Dispatch<React.SetStateAction<any>>;
  setConfirmDelete: React.Dispatch<React.SetStateAction<boolean>>;
  setDeleteID: React.Dispatch<React.SetStateAction<any>>;
}
const CVTableBody: React.FC<Props> = ({
  CVData,
  setCVData,
  setConfirmDelete,
  setDeleteID,
}) => {
  const navigate = useNavigate();
  const showCV = (id: number) => {
    navigate(`/cv/${id}`);
  };
  const deleteCV = (id: number) => {
    setConfirmDelete(true);
    setDeleteID(id);
    // CVDeleteMutation.mutate(id);
  };
  return (
    <tbody>
      {CVData &&
        CVData.map((userData) => (
          <tr
            key={userData.id}
            className={`${
              userData.status === "Rejected" ||
              userData.status === "User Rejected"
                ? "rejected"
                : userData.status === "Interview"
                ? "interview"
                : userData.status === "Hired" || userData.status === "Accepted"
                ? "hired"
                : ""
            }`}
          >
            <td>
              <p style={{ fontWeight: 500, color: "black" }}>
                {userData.firstName} {userData.lastName}
              </p>
            </td>
            <td>
              <p>{JSON.parse(userData?.email)[0]?.email}</p>
            </td>
            <td>
              <p style={{ width: "137px" }}>
                {JSON.parse(userData.phone)[0].phone.charCodeAt(4) !== 32
                  ? JSON.parse(userData.phone)[0].phone.replace(
                      /^(.{4})(.{3})(.{3})(.*)$/,
                      "$1 $2 $3 $4"
                    )
                  : JSON.parse(userData.phone)[0].phone}
              </p>
            </td>
            <td>
              <p>{userData.place}</p>
            </td>
            <td>
              <p>{userData.work_position}</p>
            </td>
            <td>
              <p>{userData.exp_salary}</p>
            </td>
            <td>
              <p style={{ width: "100px" }}>{userData.exp_date}</p>
            </td>
            <td>
              <p>{userData.status}</p>
            </td>
            <td>
              <p>{userData.gdpr === 1 ? "Valid" : "Invalid"}</p>
            </td>
            <td>
              <TableButtonPrimary onClick={() => showCV(userData.id)}>
                SHOW CV
              </TableButtonPrimary>
              <TableButtonSecondary onClick={() => deleteCV(userData.id)}>
                <TiUserDeleteOutline className="delete_svg" />
              </TableButtonSecondary>
            </td>
          </tr>
        ))}
    </tbody>
  );
};

export default CVTableBody;
