import React from "react";
import { Navigate } from "react-router-dom";

const Protected = ({ children }: any) => {
  if (localStorage.getItem("token") === "") {
    return <Navigate to="/" />;
  } else {
    return children;
  }
};

export default Protected;
