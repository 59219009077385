import { View, Image, Text } from "@react-pdf/renderer";
import React from "react";
import { styles } from "./PDFStylings";
import kodiva from "../../../../assets/images/KODIVA_pdf.png";

const ShowPDFHeader = () => {
  return (
    <View fixed style={styles.header}>
      <Image src={kodiva} style={styles.image} />
      <View style={styles.headerInfo}>
        <View style={{ marginRight: "10px" }}>
          <Text
            style={{
              color: "black",
              fontSize: 12,
              marginBottom: "1px",
              lineHeight: "1.15px",
            }}
          >
            Kodiva s. r. o.
          </Text>
          <Text
            style={{
              color: "black",
              fontSize: 12,
              marginBottom: "1px",
              lineHeight: "1.15px",
            }}
          >
            Karpatské námestie 7770/10A
          </Text>
          <Text
            style={{
              color: "black",
              fontSize: 12,
              marginBottom: "1px",
              lineHeight: "1.15px",
            }}
          >
            831 06 Bratislava
          </Text>
        </View>
        <View>
          <Text
            style={{
              color: "black",
              fontSize: 12,
              marginBottom: "1px",
              lineHeight: "1.15px",
            }}
          >
            Phone: +421 905 631 868
          </Text>
          <Text
            style={{
              color: "black",
              fontSize: 12,
              fontWeight: 300,
              marginBottom: "1px",
              lineHeight: "1.15px",
            }}
          >
            E-mail: sales@kodiva.sk
          </Text>
          <Text
            style={{
              color: "black",
              fontSize: 12,
              marginBottom: "1px",
              lineHeight: "1.15px",
            }}
          >
            Web: {"   "}https://kodiva.sk
          </Text>
        </View>
      </View>
    </View>
  );
};

export default ShowPDFHeader;
