import { ApexOptions } from "apexcharts";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { InvoiceType } from "../../../../components/interfaces/interfaces";
import "./InvoiceChart.scss";

interface Props {
  invoices: InvoiceType[] | undefined;
}
interface IApexChartData {
  string: number;
}

const InvoiceChart: React.FC<Props> = ({ invoices }) => {
  const [currentYear, setCurrentYear] = useState("");
  const [groupedData, setGroupedData] = useState<number[]>([]);
  const [series, setSeries] = useState<{ name: string; data: number[] }[]>([]);

  const options: ApexOptions = {
    chart: {
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    stroke: {
      width: 5,
      curve: "smooth",
    },
    colors: ["#008db9", "#008db9", "#008db9"],
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        gradientToColors: ["#008db9", "#0dcaf0"],
        shadeIntensity: 1,
        type: "horizontal",
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100, 100, 100],
      },
    },
    yaxis: {
      tickAmount: 3,
      labels: {
        formatter: function (value: any) {
          return Math.round(value).toString();
        },
      },
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
    tooltip: {
      //   shared: true,
      intersect: false,
      y: {
        formatter: function (val: number) {
          return "€" + val.toFixed(2);
        },
      },
    },
  };

  useEffect(() => {
    if (invoices) {
      setCurrentYear(new Date().getFullYear().toString());
      const filteredInvoicesByYear = invoices?.filter((invoice) =>
        invoice.invoiceDate.includes(currentYear)
      );
      const arrayData: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      filteredInvoicesByYear.map((invoice) => {
        const month: number = Number(invoice.invoiceDate.slice(5));
        const total: number = parseFloat(invoice.totalPrice);
        arrayData[month - 1] += total;
      });
      setGroupedData(arrayData);
    }
  }, [invoices]);

  useEffect(() => {
    if (groupedData) {
      setSeries([
        {
          name: "Invoice",
          data: groupedData,
        },
      ]);
    }
  }, [groupedData]);
  return (
    <div className="chart_widget">
      <div className="chart_widget_header">
        <h5>Invoices</h5>
        <p>Year {currentYear}</p>
      </div>
      <ReactApexChart
        options={options}
        series={series}
        type="line"
        height="100%"
        width="100%"
        style={{ width: "100%", height: "90%" }}
      />
    </div>
  );
};

export default InvoiceChart;
