import React, {useEffect, useState} from "react";
import "./Searchbar.scss";
import { AiOutlineSearch } from "react-icons/ai";
import {UserAuth} from "../Context/StateProvider";
import {actTypes} from "../Context/Reducer";

export interface Props {
  allData: {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    place: string;
    status: string;
    work_position: string;
    exp_salary: string;
    exp_date: string;
    gdpr_valid_date: string;
  }[];
  currentData: any[];
  setTableData: React.Dispatch<React.SetStateAction<any>>;
  setSearchQuery: React.Dispatch<React.SetStateAction<any>>;
  filteredData:any[];
  setEmpty:React.Dispatch<React.SetStateAction<boolean>>;
  filter:boolean;
}

const Searchbar: React.FC<Props> = ({
  setTableData,
  allData,
  currentData,
  setSearchQuery,
    setEmpty,
    filteredData,
    filter
}) => {
  const [{search}, dispatch] = UserAuth();
  const [query, setQuery] = useState(search);

  function handleSetData(searchQuery:string) {
    const data = filter ? filteredData : allData;
    setSearchQuery(searchQuery);
    dispatch({
      type:actTypes.SET_SEARCH_BAR,
      search:searchQuery
    })
    setQuery(searchQuery);
    if (searchQuery !== "") {
      setEmpty(false);
      setTableData(
          data.filter((item) => {
            if (searchQuery === "") {
              return item;
            } else {
              return (
                  item.email
                      .toLowerCase()
                      .includes(searchQuery.toLowerCase().toString()) ||
                  item.firstName
                      .toLowerCase()
                      .includes(searchQuery.toLowerCase().toString()) ||
                  (item.firstName + " " + item.lastName)
                      .toLowerCase()
                      .includes(searchQuery.toLowerCase().toString()) ||
                  (item.lastName || "")
                      .toLowerCase()
                      .includes(searchQuery.toLowerCase().toString()) ||
                  (item.phone || "")
                      .toLowerCase()
                      .includes(searchQuery.toLowerCase().toString()) ||
                  (item.place || "")
                      .toLowerCase()
                      .includes(searchQuery.toLowerCase().toString()) ||
                  (item.work_position || "")
                      .toLowerCase()
                      .includes(searchQuery.toLowerCase().toString()) ||
                  (item.status || "")
                      .toLowerCase()
                      .includes(searchQuery.toLowerCase().toString()) ||
                  (item.exp_salary || "")
                      .toLowerCase()
                      .includes(searchQuery.toLowerCase().toString()) ||
                  (item.exp_date || "")
                      .toLowerCase()
                      .includes(searchQuery.toLowerCase().toString())
              );
            }
          })
      );
    } else {
      setTableData(currentData);
      setEmpty(true);
    }
  }

  const handleSearch = (e: React.FormEvent<HTMLInputElement>) => {
    handleSetData(e.currentTarget.value)
  };
  return (
    <div className="searchbar_container">
      <input
        type="text"
        placeholder="Search here"
        className="searchbar"
        value={query}
        onChange={(e) => handleSearch(e)}
      />
      <AiOutlineSearch />
    </div>
  );
};

export default Searchbar;
