import React from 'react';
import {Text, View} from "@react-pdf/renderer";

type Props = {
    multiSportCard: boolean;
}

function MultiSportCard({multiSportCard}: Props) {
    return (
        <>
            {multiSportCard &&
                <View
                    style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        flexDirection: "row",
                    }}
                >
                    <View
                        style={{
                            width: 400,
                            display: "flex",
                            alignItems: "flex-start",
                            justifyContent: "flex-start",
                            borderBottom: "2px solid #e6e6e6",
                            paddingTop: 2,
                        }}
                    >
                        <Text
                            style={{
                                fontSize: 8.5,
                                fontFamily: "Roboto",
                                fontWeight: 300,
                                lineHeight: 1.6,
                            }}
                        >
                            MultiSport Card
                        </Text>
                    </View>
                    <View
                        style={{
                            width: 40,
                            display: "flex",
                            alignItems: "flex-start",
                            justifyContent: "flex-start",
                            borderBottom: "2px solid #e6e6e6",
                            paddingTop: 2,
                        }}
                    >
                        <Text
                            style={{
                                fontSize: 8.5,
                                fontFamily: "Roboto",
                                fontWeight: 300,
                                lineHeight: 1.6,
                            }}
                        >
                            1
                        </Text>
                    </View>
                    <View
                        style={{
                            width: 40,
                            display: "flex",
                            alignItems: "flex-start",
                            justifyContent: "flex-start",
                            borderBottom: "2px solid #e6e6e6",
                            paddingTop: 2,
                        }}
                    >
                        <Text
                            style={{
                                fontSize: 8.5,
                                fontFamily: "Roboto",
                                fontWeight: 300,
                                lineHeight: 1.6,
                            }}
                        >
                            j.
                        </Text>
                    </View>
                    <View
                        style={{
                            width: 100,
                            display: "flex",
                            alignItems: "flex-end",
                            justifyContent: "flex-start",
                            borderBottom: "2px solid #e6e6e6",
                            paddingTop: 2,
                        }}
                    >
                        <Text
                            style={{
                                fontSize: 8.5,
                                fontWeight: 300,
                                fontFamily: "Roboto",
                                lineHeight: 1.6,
                            }}
                        >
                            20
                        </Text>
                    </View>
                    <View
                        style={{
                            width: 100,
                            display: "flex",
                            alignItems: "flex-end",
                            justifyContent: "flex-start",
                            borderBottom: "2px solid #e6e6e6",
                            backgroundColor: "#e6e6e6",
                            marginRight: 20,
                            paddingTop: 2,
                        }}
                    >
                        <Text
                            style={{
                                fontSize: 8.5,
                                fontWeight: 700,
                                fontFamily: "Roboto",
                                lineHeight: 1.6,
                                paddingRight: 5,
                            }}
                        >
                            -20
                        </Text>
                    </View>
                </View>}
        </>
    );
}

export default MultiSportCard;