import {
  Header,
  Paragraph,
  ImageRun,
  Table,
  TableRow,
  TableCell,
  WidthType,
  AlignmentType,
  TextRun,
  BorderStyle,
  TableLayoutType,
} from "docx";
import { imageStringImage } from "../kodivaImage";
export const headerComponent = () => {
  return new Header({
    children: [
      new Paragraph({
        children: [
          new ImageRun({
            data: Uint8Array.from(atob(imageStringImage), (c) =>
              c.charCodeAt(0)
            ),
            transformation: {
              width: 80,
              height: 80,
            },
            floating: {
              horizontalPosition: {
                offset: 1010000,
              },
              verticalPosition: {
                offset: 450000,
              },
            },
          }),
        ],
      }),
      new Table({
        alignment: AlignmentType.RIGHT,
        // columnWidths: [3505, 5505],
        borders: {
          left: {
            style: BorderStyle.SINGLE,
            size: 1,
            color: "000000",
          },
        },
        layout: TableLayoutType.AUTOFIT,
        rows: [
          new TableRow({
            children: [
              new TableCell({
                borders: {
                  top: {
                    style: BorderStyle.NONE,
                    size: 0,
                    color: "FFFFFF",
                  },
                  bottom: {
                    style: BorderStyle.NONE,
                    size: 0,
                    color: "FFFFFF",
                  },
                  right: {
                    style: BorderStyle.NONE,
                    size: 0,
                    color: "FFFFFF",
                  },
                },
                width: {
                  size: 3505,
                  type: WidthType.DXA,
                },
                children: [
                  new Paragraph({
                    indent: {
                      left: 100,
                    },
                    children: [
                      new TextRun({
                        text: "Kodiva s.r.o.",
                        font: "Arial",
                        size: 24,
                      }),
                    ],
                  }),
                ],
              }),
              new TableCell({
                borders: {
                  top: {
                    style: BorderStyle.NONE,
                    size: 0,
                    color: "FFFFFF",
                  },
                  bottom: {
                    style: BorderStyle.NONE,
                    size: 0,
                    color: "FFFFFF",
                  },
                  left: {
                    style: BorderStyle.NONE,
                    size: 0,
                    color: "FFFFFF",
                  },
                  right: {
                    style: BorderStyle.NONE,
                    size: 0,
                    color: "FFFFFF",
                  },
                },
                width: {
                  size: 3505,
                  type: WidthType.DXA,
                },
                children: [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: "Phone: +421 905 631 868",
                        font: "Arial",
                        size: 24,
                      }),
                    ],
                  }),
                ],
              }),
            ],
          }),
          new TableRow({
            children: [
              new TableCell({
                borders: {
                  top: {
                    style: BorderStyle.NONE,
                    size: 0,
                    color: "FFFFFF",
                  },
                  bottom: {
                    style: BorderStyle.NONE,
                    size: 0,
                    color: "FFFFFF",
                  },
                  right: {
                    style: BorderStyle.NONE,
                    size: 0,
                    color: "FFFFFF",
                  },
                },
                width: {
                  size: 3505,
                  type: WidthType.DXA,
                },
                children: [
                  new Paragraph({
                    indent: {
                      left: 100,
                    },
                    children: [
                      new TextRun({
                        text: "Karpatské námestie 7770/10A",
                        font: "Arial",
                        size: 24,
                      }),
                    ],
                  }),
                ],
              }),
              new TableCell({
                borders: {
                  top: {
                    style: BorderStyle.NONE,
                    size: 0,
                    color: "FFFFFF",
                  },
                  bottom: {
                    style: BorderStyle.NONE,
                    size: 0,
                    color: "FFFFFF",
                  },
                  left: {
                    style: BorderStyle.NONE,
                    size: 0,
                    color: "FFFFFF",
                  },
                  right: {
                    style: BorderStyle.NONE,
                    size: 0,
                    color: "FFFFFF",
                  },
                },
                width: {
                  size: 3505,
                  type: WidthType.DXA,
                },
                children: [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: "E-mail: sales@kodiva.sk",
                        font: "Arial",
                        size: 24,
                      }),
                    ],
                  }),
                ],
              }),
            ],
          }),
          new TableRow({
            children: [
              new TableCell({
                borders: {
                  top: {
                    style: BorderStyle.NONE,
                    size: 0,
                    color: "FFFFFF",
                  },
                  bottom: {
                    style: BorderStyle.NONE,
                    size: 0,
                    color: "FFFFFF",
                  },
                  right: {
                    style: BorderStyle.NONE,
                    size: 0,
                    color: "FFFFFF",
                  },
                },
                width: {
                  size: 3505,
                  type: WidthType.DXA,
                },
                children: [
                  new Paragraph({
                    indent: {
                      left: 100,
                    },
                    children: [
                      new TextRun({
                        text: "831 06 Bratislava ",
                        font: "Arial",
                        size: 24,
                      }),
                    ],
                  }),
                ],
              }),
              new TableCell({
                borders: {
                  top: {
                    style: BorderStyle.NONE,
                    size: 0,
                    color: "FFFFFF",
                  },
                  bottom: {
                    style: BorderStyle.NONE,
                    size: 0,
                    color: "FFFFFF",
                  },
                  left: {
                    style: BorderStyle.NONE,
                    size: 0,
                    color: "FFFFFF",
                  },
                  right: {
                    style: BorderStyle.NONE,
                    size: 0,
                    color: "FFFFFF",
                  },
                },
                width: {
                  size: 3505,
                  type: WidthType.DXA,
                },
                children: [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: "Web:    https://kodiva.sk",
                        font: "Arial",
                        size: 24,
                      }),
                    ],
                  }),
                ],
              }),
            ],
          }),
        ],
      }),
    ],
  });
};
