import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import { StateProvider } from "./components/Context/StateProvider";
import { InitialState } from "./components/Context/InitialState";
import reducer from "./components/Context/Reducer";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { StyledEngineProvider } from "@mui/material";
import HttpsRedirect from "react-https-redirect";
import { Provider } from "react-redux";
import { persistedStore, store } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";

const queryClient = new QueryClient();
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <Router>
    <QueryClientProvider client={queryClient} contextSharing={true}>
      <StateProvider initialState={InitialState} reducer={reducer}>
        <StyledEngineProvider injectFirst>
          <HttpsRedirect>
            <Provider store={store}>
              <PersistGate loading={null} persistor={persistedStore}>
                <App />
              </PersistGate>
            </Provider>
          </HttpsRedirect>
        </StyledEngineProvider>
      </StateProvider>
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
    </QueryClientProvider>
  </Router>
);
