import {
  Paragraph,
  Document,
  Table,
  TextRun,
  ShadingType,
  WidthType,
  BorderStyle,
  TableCell,
  TableRow,
} from "docx";
import { detailComponent } from "./detailComponent/detailComponent";
import { educationComponent } from "./EducationComponent/educationComponent";
import { ePComponent } from "./EPComponent/ePComponent";
import { headerComponent } from "./Header/headerComponent";
import { languageComponent } from "./LanguageComponent/languageComponent";
import { nameComponent } from "./NameComponent/nameComponent";
import { skillsComponent } from "./Skills/skillsComponent";
import { titleComponent } from "./TitleComponent/titleComponents";

type AnonymizeType = {
  anonymizeExperienceName: boolean;
  anonymizeName: boolean;
  anonymizeNumber: boolean;
  anonymizeEmail: boolean;
  anonymizeRegion: boolean;
  anonymizeSummary: boolean;
};

export const ShowDocx = (
  data: any,
  anonymize: AnonymizeType,
  selectedNumber: string,
  selectedEmail: string
) => {
  const languageArray = JSON.parse(data.languages);
  const experienceTitle =
    data.experiences.length > 0 ? titleComponent("EXPERIENCE") : "";
  const projectTitle =
    data.projects.length > 0 ? titleComponent("PROJECTS") : "";
  const educationTitle =
    data.education.length > 0 ? titleComponent("EDUCATION") : "";
  const languageTitle =
    languageArray[0].language !== "" ? titleComponent("LANGUAGE SKILLS") : "";
  const displaySkills = data.skills !== "" ? skillsComponent(data) : "";
  const skillsTitle = data.skills !== "" ? titleComponent("SKILLS") : "";
  return new Document({
    sections: [
      {
        headers: {
          default: headerComponent(),
        },
        properties: {
          page: {
            margin: {
              top: 2000,
            },
          },
        },
        children: [
          // name surrname
          nameComponent(data, anonymize.anonymizeName),

          //DETAILS
          new Paragraph(""),
          detailComponent(
            data,
            anonymize.anonymizeRegion,
            anonymize.anonymizeNumber,
            anonymize.anonymizeEmail,
            anonymize.anonymizeSummary,
            selectedNumber,
            selectedEmail
          ),

          //Skills
          new Paragraph(""),
          skillsTitle,
          displaySkills,

          //EXPERIENCE
          new Paragraph(""),
          experienceTitle,
          // COMPANY
          ...data.experiences
            .map((experience: any) => {
              const arr: any[] = [];
              arr.push(
                ePComponent(
                  experience.name,
                  experience.experienceStart,
                  experience.experienceEnd,
                  experience.description,
                  experience.position,
                  anonymize.anonymizeExperienceName
                )
              );
              return arr;
            })
            .reduce((prev: any, curr: any) => prev.concat(curr), []),
          //skills
          new Paragraph(""),
          projectTitle,
          ...data.projects
            .map((project: any) => {
              const arr: any[] = [];
              arr.push(
                ePComponent(
                  project.name,
                  project.project_from,
                  project.project_end,
                  project.description,
                  "",
                  anonymize.anonymizeExperienceName
                )
              );
              return arr;
            })
            .reduce((prev: any, curr: any) => prev.concat(curr), []),
          //education
          new Paragraph(""),
          new Table({
            columnWidths: [10000],
            rows: [
              new TableRow({
                children: [
                  new TableCell({
                    borders: {
                      top: {
                        style: BorderStyle.NONE,
                        size: 0,
                        color: "FFFFFF",
                      },
                      left: {
                        style: BorderStyle.NONE,
                        size: 0,
                        color: "FFFFFF",
                      },
                      bottom: {
                        style: BorderStyle.NONE,
                        size: 0,
                        color: "FFFFFF",
                      },
                      right: {
                        style: BorderStyle.NONE,
                        size: 0,
                        color: "FFFFFF",
                      },
                    },
                    width: {
                      size: 4500,
                      type: WidthType.DXA,
                    },
                    shading: {
                      type: ShadingType.SOLID,
                      color: "c9c9c9",
                      fill: "c9c9c9",
                    },
                    children: [
                      new Paragraph({
                        spacing: {
                          before: 35,
                          after: 35,
                        },
                        indent: {
                          left: 60,
                          right: 60,
                        },
                        children: [
                          new TextRun({
                            text: "EDUCATION",
                            size: 32,
                            bold: true,
                            color: "1F3864",
                            font: "Arial",
                          }),
                        ],
                      }),
                    ],
                  }),
                  new TableCell({
                    borders: {
                      top: {
                        style: BorderStyle.NONE,
                        size: 0,
                        color: "FFFFFF",
                      },
                      left: {
                        style: BorderStyle.NONE,
                        size: 0,
                        color: "FFFFFF",
                      },
                      bottom: {
                        style: BorderStyle.NONE,
                        size: 0,
                        color: "FFFFFF",
                      },
                      right: {
                        style: BorderStyle.NONE,
                        size: 0,
                        color: "FFFFFF",
                      },
                    },
                    width: {
                      size: 5500,
                      type: WidthType.DXA,
                    },
                    shading: {
                      type: ShadingType.SOLID,
                      color: "c9c9c9",
                      fill: "c9c9c9",
                    },
                    children: [
                      new Paragraph({
                        spacing: {
                          before: 35,
                          after: 35,
                        },
                        indent: {
                          left: 60,
                          right: 60,
                        },
                        children: [
                          new TextRun({
                            text: "",
                            size: 32,
                            bold: true,
                            color: "1F3864",
                            font: "Arial",
                          }),
                        ],
                      }),
                    ],
                  }),
                ],
              }),
            ],
          }),
          ...data.education
            .map((education: any) => {
              const arr: any[] = [];
              arr.push(educationComponent(education));
              return arr;
            })
            .reduce((prev: any, curr: any) => prev.concat(curr), []),
          //languages
          new Paragraph(""),
          languageTitle,
          ...languageArray
            .map((languageElement: any) => {
              const arr: any[] = [];
              if (languageArray[0].language !== "") {
                arr.push(languageComponent(languageElement.language));
              }
              return arr;
            })
            .reduce((prev: any, curr: any) => prev.concat(curr), []),
        ],
      },
    ],
  });
};
