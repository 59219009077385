import React, {createContext, useEffect, useState} from "react";
import "./Invoices.scss";
import InvoiceHeader from "./InvoiceHeader/InvoiceHeader";
import InvoiceActions from "./InvoiceActions/InvoiceActions";
import AllInvoices from "./AllInvoices/AllInvoices";
import {animated, useTransition} from "react-spring";
import NewInvoice from "./AddInvoice/NewInvoice";
import UseAuth from "../../hooks/UseAuth";
import {useQuery} from "react-query";
import {ROLES} from "../../components/Roles/Roles";
import {getClient, getInvoices} from "../../api/clientApi";
import moment from "moment";
import dayjs, {Dayjs} from "dayjs";
import InvoiceNotification from "./InvoiceNotification/InvoiceNotification";
import useCurrentAndNextRate from "../../hooks/useCurrentAndNextRate";

export const invoiceContext = createContext<number | undefined>(undefined);

const Invoices = () => {
    const {auth} = UseAuth();
    const [openNewInvoice, setopenNewInvoice] = useState(false);
    const [form, setForm] = useState<any>();
    const [adminForm, setAdminForm] = useState<any>();
    const [filter, setFilter] = useState("");
    const [listData, setListData] = useState<any[]>([]);
    const [filteredListByMonth, setFilteredListByMonth] = useState<any[]>([]);
    const [count, setcount] = useState<number>(0);
    const [invoiceDateFilter, setinvoiceDateFilter] = useState<Dayjs | null>(
        null
    );
    const [sameInvoice, setSameInvoice] = useState(false);
    const [allInvoiceNumbers, setAllInvoiceNumbers] = useState<String[]>([]);
    const [allVariableNumbers, setAllVariableNumbers] = useState<String[]>([]);
    const [allInvoiceDateFilter, setAllInvoiceDateFilter] = useState(true);
    const transition = useTransition(openNewInvoice, {
        from: {x: -500, opacity: 0},
        enter: {x: 0, opacity: 1},
        leave: {x: -500, opacity: 0},
    });

    const {data, isFetched, refetch} = useQuery(
        ["invoices", auth.userName],
        () =>
            auth.userRoles === ROLES.ADMIN ? getInvoices() : getClient(auth.userName),
        {
            refetchOnWindowFocus: false,
            retry: 0,
            onSuccess: (data) => {
                if (auth.userRoles === ROLES.ADMIN) {
                    data.sort((a: any, b: any) => b.id - a.id);
                    setcount(data.length);
                    setAllInvoiceNumbers(
                        data.map((invoice: any) => invoice.invoiceNumber)
                    );
                    setAllVariableNumbers(
                        data.map((invoice: any) => invoice.variableSymbol)
                    );
                } else {
                    data.invoices.sort((a: any, b: any) => b.id - a.id);
                    setListData(data.invoices);
                    setcount(data.invoices.length);
                    setAllInvoiceNumbers(
                        data.invoices.map((invoice: any) => invoice.invoiceNumber)
                    );
                    setAllVariableNumbers(
                        data.invoices.map((invoice: any) => invoice.variableSymbol)
                    );
                }
            },
        }
    );
    const invoiceRates = useCurrentAndNextRate(data?.clientProjects);
    const {data: adminData, isFetched: adminDataFetched} = useQuery(
        ["adminData", auth.userName],
        () => getClient(auth.userName),
        {
            enabled: auth.userRoles === ROLES.ADMIN ? true : false,
            refetchOnWindowFocus: false,
            retry: 0,
        }
    );
    const checkSequenceDigits = (number: number) => {
        const finalNumber = number + 1;
        if (finalNumber.toString().length === 1) {
            return String("0" + finalNumber);
        } else {
            return String(finalNumber);
        }
    };

    useEffect(() => {
        if (allInvoiceDateFilter) {
            setFilteredListByMonth(listData);
        } else {
            setFilteredListByMonth(
                listData.filter(
                    (invoice) =>
                        dayjs(invoice.invoiceDate).format("MM") ===
                        invoiceDateFilter?.format("MM")
                )
            );
        }
    }, [listData, invoiceDateFilter, allInvoiceDateFilter]);

    useEffect(() => {
        if (isFetched) {
            setForm({
                id: data?.id,
                constantSymbol: "0308",
                invoiceDate: "",
                invoiceNumber: String(
                    data?.businessId?.replace(/\s+/g, "")?.substring(0, 3) +
                    moment(data?.invoiceDate).format("YY") +
                    checkSequenceDigits(data?.invoices?.length)
                ),
                invoices: [],
                specificSymbol: "",
                status: "PENDING",
                totalPrice: "",
                variableSymbol: String(
                    data?.businessId?.replace(/\s+/g, "")?.substring(0, 3) +
                    moment(data?.invoiceDate).format("YY") +
                    checkSequenceDigits(data?.invoices?.length)
                ),
                rate: data?.rate,
                name: data?.name,
                city: data?.city,
                zipCode: data?.zipCode,
                address: data?.address,
                country: data?.country,
                businessId: data?.businessId?.replace(/\s+/g, ""),
                taxId: data?.taxId,
                phoneNumber: data?.phoneNumber,
                email: data?.email,
                iban: data?.iban,
                swift: data?.swift,
                clientId: "none",
                byod: data?.byod,
                supplier: data?.name,
                taxPayer: data?.taxPayer,
                multiSportCard: data?.multiSportCard
            });
        }
        if (adminDataFetched) {
            setAdminForm({
                id: adminData?.id,
                constantSymbol: "0308",
                invoiceDate: "",
                invoiceNumber: String(
                    adminData.businessId?.replace(/\s+/g, "").substring(0, 3) +
                    moment(adminData?.invoiceDate).format("YY") +
                    checkSequenceDigits(adminData?.invoices.length)
                ),
                invoices: [],
                specificSymbol: "",
                status: "PENDING",
                totalPrice: "",
                variableSymbol: String(
                    adminData.businessId?.replace(/\s+/g, "")?.substring(0, 3) +
                    moment(adminData?.invoiceDate).format("YY") +
                    checkSequenceDigits(adminData?.invoices.length)
                ),
                rate: adminData?.rate,
                name: adminData?.name,
                city: adminData?.city,
                zipCode: adminData?.zipCode,
                address: adminData?.address,
                country: adminData?.country,
                businessId: adminData?.businessId.replace(/\s+/g, ""),
                taxId: adminData?.taxId,
                phoneNumber: adminData?.phoneNumber,
                email: adminData?.email,
                iban: adminData?.iban,
                swift: adminData?.swift,
                clientId: "none",
                byod: adminData?.byod,
                taxPayer: adminData?.taxPayer,
                multiSportCard: adminData?.multiSportCard
            });
        }

    }, [adminDataFetched, isFetched, setAdminForm, setForm]);
    useEffect(() => {
        if (isFetched) {
            if (filter === "") {
                if (auth.userRoles === ROLES.ADMIN) {
                    setListData(data);
                } else {
                    setListData(data.invoices);
                }
            } else {
                if (auth.userRoles === ROLES.ADMIN) {
                    setListData(
                        data.filter((dataElement: any) => {
                            if (dataElement.status === filter) {
                                return dataElement;
                            }
                        })
                    );
                } else {
                    setListData(
                        data.invoices.filter((dataElement: any) => {
                            if (dataElement.status === filter) {
                                return dataElement;
                            }
                        })
                    );
                }
            }
        }
    }, [auth.userRoles, data, filter, isFetched]);

    return (
        <div className="invoices">
            {sameInvoice && <InvoiceNotification/>}
            {isFetched && (
                <div className="invoices_container">
                    <div className="invoices_header">
                        <InvoiceHeader count={count}/>
                        <InvoiceActions
                            setOpenNewInvoice={setopenNewInvoice}
                            setFilter={setFilter}
                            filter={filter}
                            invoiceDateFilter={invoiceDateFilter}
                            setInvoiceDateFilter={setinvoiceDateFilter}
                            allInvoicesFilter={allInvoiceDateFilter}
                            setAllInvoicesFilter={setAllInvoiceDateFilter}
                        />
                    </div>
                    <div className="invoice_body">
                        <AllInvoices
                            invoices={
                                auth.userRoles === ROLES.USER
                                    ? filteredListByMonth
                                    : filteredListByMonth
                            }
                            name={auth.userName}
                            multiSportCard={auth.userRoles === ROLES.ADMIN ? adminForm?.multiSportCard : form?.multiSportCard}
                        />
                    </div>
                </div>
            )}
            {transition((style, item) =>
                item ? (
                    <animated.div style={style} className="invoices_mod">
                        <invoiceContext.Provider value={invoiceRates.currentRate}>
                            <NewInvoice
                                setopenNewInvoice={setopenNewInvoice}
                                invoice={auth.userRoles === ROLES.ADMIN ? adminForm : form}
                                setSameInvoice={setSameInvoice}
                                sameInvoice={sameInvoice}
                                allInvoices={allInvoiceNumbers}
                                allVariableNumbers={allVariableNumbers}
                                multiSportCard={auth.userRoles === ROLES.ADMIN ? adminForm?.multiSportCard : form?.multiSportCard}
                            />
                        </invoiceContext.Provider>
                    </animated.div>
                ) : null
            )}
        </div>
    );
};

export default Invoices;
