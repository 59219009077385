import React from "react";
import "./InvoiceHeader.scss";

interface Props {
  count: number;
}

const InvoiceHeader: React.FC<Props> = ({ count }) => {
  return (
    <div className="invoices_header_text">
      <h2>Invoices</h2>
      <p>There are {count} total invoices</p>
    </div>
  );
};

export default InvoiceHeader;
