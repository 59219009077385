import React, { useEffect, useState } from "react";

const useGreetHook = () => {
  const date = new Date();
  const [greet, setGreet] = useState("");
  useEffect(() => {
    if (date.getHours() < 12) {
      setGreet("Good Morning");
    } else if (date.getHours() >= 12 && date.getHours() <= 18) {
      setGreet("Good Afternoon");
    } else if (date.getHours() > 18) {
      setGreet("Good Evening");
    }
  }, []);
  return greet;
};

export default useGreetHook;
