import React from "react";
import "./Widget.scss";

interface Props {
  text: string;
  number: string;
  icon: JSX.Element;
  backgroundColor: string;
}

const Widget: React.FC<Props> = ({ text, number, icon, backgroundColor }) => {
  return (
    <div
      className="widget employee"
      style={{ backgroundColor: backgroundColor }}
    >
      <div className="widget_top">
        {" "}
        <div className="widget_employee_img">{icon}</div>
        <p className="number">{number}</p>
      </div>
      <div className="widget_bottom">
        <p className="text">{text}</p>
      </div>
    </div>
  );
};

export default Widget;
