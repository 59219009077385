import { View, Text, Font } from "@react-pdf/renderer";
import React, { useMemo } from "react";
import arial from "../../../../assets/fonts/Arial_medium.ttf";
import arialText from "../../../../assets/fonts/arialW.ttf";
import arialBold from "../../../../assets/fonts/Arial_bold.TTF";

Font.register({
  family: "Roboto",
  fonts: [
    {
      src: arial,
    },
    {
      src: arialText,
      fontWeight: 300,
    },
    { src: arialBold, fontWeight: 700 },
  ],
});

type Props = {
  totalPrice: string;
};

const TaxValues: React.FC<Props> = ({ totalPrice }) => {
  const total = useMemo(() => {
    return Number(Math.round(Number(totalPrice) * 100) / 100).toFixed(2);
  }, [totalPrice]);

  const tax = useMemo(() => {
    return Number(Math.round(Number(total) * 20) / 100).toFixed(2);
  }, [total]);

  return (
    <View
      style={{
        width: "100%",
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        flexDirection: "row",
        marginTop: 5,
      }}
    >
      <View
        style={{
          width: "20%",
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          borderBottom: "2px solid #e6e6e6",
          paddingBottom: 2,
        }}
      >
        <Text
          style={{
            fontSize: 8,
            fontFamily: "Roboto",
            fontWeight: 400,
            lineHeight: 1.6,
          }}
        >
          20%
        </Text>
      </View>
      <View
        style={{
          width: "20%",
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          borderBottom: "2px solid #e6e6e6",
          paddingBottom: 2,
        }}
      >
        <Text
          style={{
            fontSize: 8,
            fontFamily: "Roboto",
            fontWeight: 400,
            lineHeight: 1.6,
          }}
        >
          {total}
        </Text>
      </View>
      <View
        style={{
          width: "20%",
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          borderBottom: "2px solid #e6e6e6",
          paddingBottom: 2,
        }}
      >
        <Text
          style={{
            fontSize: 8,
            fontFamily: "Roboto",
            fontWeight: 400,
            lineHeight: 1.6,
          }}
        >
          {tax}
        </Text>
      </View>
      <View
        style={{
          width: "25%",
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "flex-start",
          borderBottom: "2px solid #e6e6e6",
          paddingBottom: 2,
        }}
      >
        <Text
          style={{
            fontSize: 8,
            fontWeight: 700,
            fontFamily: "Roboto",
            lineHeight: 1.6,
          }}
        >
          {Number((Number(tax) + Number(total)).toFixed(2))}
        </Text>
      </View>
    </View>
  );
};
export default TaxValues;
