import Box from "@mui/material/Box/Box";
import Modal from "@mui/material/Modal/Modal";
import React from "react";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { deleteInvoice } from "../../../../api/clientApi";
import CancelButton from "../../../../components/Buttons/Cancel/CancelButton";
import SubmitButton from "../../../../components/Buttons/Submit/SubmitButton";
import "./DeleteModal.scss";

interface Props {
  open: boolean;
  id: number | undefined;
  invoiceNumber: string;

  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const DeleteModal: React.FC<Props> = ({ open, id, invoiceNumber, setOpen }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const deleteInvoiceMutation = useMutation(deleteInvoice, {
    onSuccess: () => {
      // Invalidates cache and refetch
      queryClient.invalidateQueries("invoices");
    },
  });
  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteInvoice = () => {
    deleteInvoiceMutation.mutate(Number(id!));
    navigate(-1);
    setOpen(false);
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box className="deleteCandidate_modal delete_modal">
        <div className="deleteCandidate_modal_container">
          <h2>Confirm the action</h2>
          <p style={{ display: "flex", width: "100%", gap: "5px" }}>
            Do you really want to delete invoice number{" "}
            <p style={{ fontWeight: "bold", color: "#008db9" }}>
              {invoiceNumber}
            </p>{" "}
            ?
          </p>
          <div className="deleteCandidate_modal_container_buttons">
            <SubmitButton onClick={handleDeleteInvoice}>Delete</SubmitButton>
            <CancelButton onClick={handleClose}>Cancel</CancelButton>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default DeleteModal;
