import React, { useMemo, useState, useRef, useEffect } from "react";
import "./Employees.scss";
import { useQuery } from "react-query";
import { getAnonymizedCandidates } from "../../api/anonymizedApi";
import Navbar from "../../components/Navbar/Navbar";
import EmployeeCard from "./EmployeeCard/EmployeeCard";
import { DataGrid } from "@mui/x-data-grid/DataGrid/DataGrid";
import Box from "@mui/material/Box/Box";
import ViewButton from "../../components/Buttons/ViewButton/ViewButton";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import EmployeeView from "./EmployeeView/EmployeeView";
import { UserAuth } from "../../components/Context/StateProvider";
import EmployeeHeader from "./EmployeeHeader/EmployeeHeader";
import { FilterType } from "../../components/interfaces/interfaces";
import useEmployeeSearch from "../../hooks/useEmployeeSearch";
import useSortEmployee from "../../hooks/useSortEmployee";
import useFilterEmployee from "../../hooks/useFilterEmployee";
import TableButtonPrimary from "../../components/Buttons/TableButtons/PrimaryButton/TableButtonPrimary";
import { useNavigate } from "react-router-dom";

const Employees = () => {
  const navigate = useNavigate();
  const [{ employeeView }] = UserAuth();
  const [openViewSettings, setOpenViewSettings] = useState(false);
  const [sortView, setSortView] = useState(false);
  const [sort, setSort] = useState<string>("");
  const [filter, setFilter] = useState<FilterType[]>([]);
  const ref = useRef<HTMLDivElement>(null);
  const sortRef = useRef<HTMLDivElement>(null);
  const [search, setSearch] = useState<string>("");
  const { data: employees, isFetched } = useQuery(
    "anonymized",
    getAnonymizedCandidates,
    {
      refetchOnWindowFocus: false,
      retry: 0,
    }
  );
  const filteredList = useFilterEmployee(employees, filter);
  const searchedList = useEmployeeSearch(filteredList, search);
  const sortedList = useSortEmployee(searchedList, sort);
  const columns = useMemo(
    () => [
      {
        field: "name",
        headerName: "Name",
        width: 160,
        flex: 1,
        minWidth: 100,
        renderCell: (params: any) => params.row.firstName + params.row.lastName,
      },
      {
        field: "work_position",
        headerName: "Position",
        width: 200,
        flex: 1,
        minWidth: 100,
      },
      {
        field: "region",
        headerName: "Region",
        width: 160,
        flex: 1,
        minWidth: 160,
      },
      {
        field: "expectedSalary",
        headerName: "MD",
        width: 160,
        flex: 1,
        minWidth: 160,
      },
      {
        field: "availableDate",
        headerName: "Available",
        width: 160,
        flex: 1,
        minWidth: 160,
      },
      {
        field: "actions",
        headerName: "Actions",
        width: 160,
        minWidth: 160,
        sortable: false,
        filterable: false,
        renderCell: (params: any) => (
          <>
            <TableButtonPrimary
              onClick={() => navigate(`/cv/anonymized/${params.id}`)}
            >
              SHOW CV
            </TableButtonPrimary>
          </>
        ),
      },
    ],
    []
  );
  const handleClickOutside = (event: any) => {
    if (
      ref.current &&
      !ref.current.contains(event.target as Node) &&
      event.target.className !== "view_button"
    ) {
      setOpenViewSettings(false);
    }
    if (
      sortRef.current &&
      !sortRef.current.contains(event.target as Node) &&
      event.target.className !== "sort_filter-button " &&
      event.target.className !== "sort_filter-button  clicked" &&
      event.target.className.baseVal !==
        "MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
    ) {
      setSortView(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return (
    <div className="employees">
      <div className="employees_header">
        <p className="employees_header_text">Employees</p>
        <ViewButton onClick={() => setOpenViewSettings((prev) => !prev)}>
          {openViewSettings ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />} View
        </ViewButton>
        {openViewSettings && <EmployeeView ref={ref} />}
      </div>
      <div className="employees_container">
        {employeeView === "Table" ? (
          <Box className="users_box">
            {isFetched && (
              <DataGrid
                columns={columns}
                rows={employees}
                className="users_table"
                sx={{
                  backgroundColor: "white",
                  width: "100%",
                  boxShadow: 6,
                }}
                getRowClassName={() => "users_table_row"}
                getCellClassName={() => "users_table_cell"}
              ></DataGrid>
            )}
          </Box>
        ) : employeeView === "Card" ? (
          isFetched && (
            <>
              <EmployeeHeader
                setSort={setSort}
                sort={sort}
                filter={filter}
                setFilter={setFilter}
                search={search}
                setSearch={setSearch}
                innerRef={sortRef}
                sortView={sortView}
                setSortView={setSortView}
              />
              <div className="employee_card_cotainer_body">
                {sortedList &&
                  sortedList.map((employee: any) => (
                    <EmployeeCard
                      key={employee.id}
                      firstName={employee.firstName}
                      lastName={employee.lastName}
                      exp_date={employee.availableDate}
                      region={employee.region}
                      work_position={employee.work_position}
                      expectedSalary={employee.expectedSalary}
                      id={employee.id}
                    />
                  ))}
              </div>
            </>
          )
        ) : null}
      </div>
    </div>
  );
};

export default Employees;
