import axios from "axios";
import { AvailabilityType } from "../components/interfaces/interfaces";
import { refreshToken } from "./authApi";

const serverApi = axios.create({
  //localhost:8080
  baseURL: "https://kodiva-apps-be.herokuapp.com",
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});
export const getAnonymizedCandidates = async () => {
  await refreshToken();
  const bearer_token = `${localStorage.getItem("token")}`;
  const { data } = await serverApi.get("/api/candidate/anonymized", {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${bearer_token}`,
    },
  });
  return data;
};
export const getAnonymizedCandidate = async (id: number) => {
  await refreshToken();
  const bearer_token = `${localStorage.getItem("token")}`;
  const { data } = await serverApi.get(
    `api/candidate/anonymized/id/?id=${id}`,
    {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${bearer_token}`,
      },
    }
  );
  return data;
};

export const getAnonymizedPositions = async () => {
  await refreshToken();
  const bearer_token = `${localStorage.getItem("token")}`;
  const { data } = await serverApi.get(`/api/candidate/anonymized/positions/`, {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${bearer_token}`,
    },
  });
  return data;
};

export const getAvailabilityDateAndPrice = async (id: number) => {
  const bearer_token = `${localStorage.getItem("token")}`;
  console.log(id);
  const { data } = await serverApi.get(
    `/api/candidate/anonymized/hired/id/?id=${id}`,
    {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${bearer_token}`,
      },
    }
  );
  return data;
};

export const updateAvailability = async (payload: AvailabilityType) => {
  refreshToken();
  const bearer_token = `${localStorage.getItem("token")}`;
  await serverApi.put(`/api/candidate/anonymized/`, payload, {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${bearer_token}`,
    },
  });
};
