import React, { useEffect, useState } from "react";
import { BsFillPersonLinesFill } from "react-icons/bs";
import { FcConferenceCall } from "react-icons/fc";
import { MdWork, MdCall } from "react-icons/md";
import KodivaWidget from "../../components/Widgets/KodivaWidget/KodivaWidget";
import Widget from "../../components/Widgets/Widget/Widget";
import "./Dashboard.scss";
import OpenScheduler from "./Modals/OpenScheduler";
import { getInterviewCount, getApplicationCount } from "../../api/candidateApi";
import { useQuery } from "react-query";
import { gapi } from "gapi-script";
import Chart from "react-apexcharts";
import { getAnonymizedPositions } from "../../api/anonymizedApi";
import moment from "moment";
import { getSettings } from "../../api/settingsApi";
import GlobeDashboard from "./Globe/GlobeDashboard";
import { Calendar, momentLocalizer } from "react-big-calendar";
import CustomEvent from "./Modals/CalendarHeader/CustomEvent";
import AddEvent from "./AddEvent/AddEvent";
import AddEventModal from "./Modals/AddEventModal/AddEventModal";
import UpdateEventModal from "./Modals/UpdateEventModal/UpdateEventModal";
import {
  CalendarEventType,
  EventTypeSelected,
} from "../../components/interfaces/interfaces";
import MonthCalendar from "./Modals/MonthCalendar/MonthCalendar";

const Dashboard = () => {
  const localizer = momentLocalizer(moment);
  const minTime = new Date();
  minTime.setHours(8, 0, 0, 0);
  const maxTime = new Date();
  maxTime.setHours(18, 0, 0, 0);
  const [openModal, setOpenModal] = useState(false);
  const [addEventModal, setAddEventModal] = useState(false);
  const [updateEventModal, setUpdateEventModal] = useState(false);
  const [openMonthCalendar, setOpenMonthCalendar] = useState(false);

  const [positionCount, setPositionCount] = useState<number[]>([]);
  const [candidatePositions, setCandidatePositions] = useState({});
  const [events, setEvents] = useState<any[]>([]);

  const [selectedEvent, setSelectedEvent] = useState<EventTypeSelected>();

  const calendarID = "aberesik%40gmail.com";
  const apiKey = "AIzaSyAGsmn9HANE7Gf6bdJp8O5ZNkagAZyLTV8";

  const { data: count, isFetched } = useQuery(
    "interviewCount",
    getInterviewCount,
    {
      refetchOnWindowFocus: false,
      retry: 0,
    }
  );

  const { data: customers, isFetched: settingsFetched } = useQuery(
    "settings",
    getSettings,
    {
      refetchOnWindowFocus: false,
      retry: 0,
    }
  );

  // eslint-disable-next-line no-empty-pattern
  const {} = useQuery("positions", getAnonymizedPositions, {
    refetchOnWindowFocus: false,
    retry: 0,
    onSuccess: (data) => {
      const counts: any = {};
      for (const position of data) {
        counts[position] = counts[position] ? counts[position] + 1 : 1;
      }
      const values = Object.keys(counts).map((key) => counts[key]);
      setPositionCount(values);
      setCandidatePositions({
        plotOptions: {
          pie: {
            donut: {
              size: "55%",
            },
          },
        },
        dataLabels: {
          formatter: (val: number) => {
            return val.toFixed(0) + "%";
          },
        },
        series: values,
        labels: Object.keys(counts),
      });
    },
  });
  const { data: applicationCount, isFetched: applicationCountFetched } =
    useQuery("applicationCount", getApplicationCount, {
      refetchOnWindowFocus: false,
      retry: 0,
    });

  const getEvents = (calendarID: string, apiKey: string) => {
    async function initiate() {
      await gapi.client
        .init({
          apiKey: apiKey,
          clientId:
            "725912353387-8nrn8io4olll3bb8vij3prno570hn80o.apps.googleusercontent.com",
          scope:
            "https://www.googleapis.com/auth/calendar/auth/calendar.events",
        })
        .then(function () {
          return gapi.client.request({
            path: `https://www.googleapis.com/calendar/v3/calendars/${calendarID}/events`,
          });
        })
        .then(
          (response) => {
            const events = response.result.items;
            let eventsReworked: any[] = [];
            // eslint-disable-next-line array-callback-return
            events.map((event: any, index: number) => {
              let i = 0;
              eventsReworked.push({
                event_id: index,
                id: event.id,
                title: event.summary,
                start: new Date(moment(event.start.dateTime).toDate()),
                end: new Date(moment(event.end.dateTime).toDate()),
                description: event.description,
              });
            });
            setEvents(eventsReworked);
          },
          function (err) {
            return [false, err];
          }
        );
    }
    gapi.load("client:auth2", initiate);
  };
  useEffect(() => {
    getEvents(calendarID, apiKey);
  }, [updateEventModal, addEventModal]);
  const handleEventStyleGetter = (event: any) => {
    const style = {
      backgroundColor: "#e3f6ff",
      color: "#36829c",
      borderColor: "#e3f6ff",
      width: "100%",
    };
    return { style };
  };

  const handleSelectEvent = (selectedEvent: CalendarEventType) => {
    setSelectedEvent({
      id: selectedEvent.event_id,
      event_id: selectedEvent.id,
      start: selectedEvent.start,
      end: selectedEvent.end,
      title: selectedEvent.title,
    });
    setUpdateEventModal(true);
  };

  return (
    <div className="dashboard">
      <div className="dashboard_container">
        {/*<GlobeDashboard />*/}
        <div className="widget_container">
          <div className="widgets">
            <KodivaWidget />
            <Widget
              backgroundColor="white"
              text="Customers"
              number={settingsFetched ? String(customers[0].clientNumber) : "0"}
              icon={<MdWork className="employee_img" color="#008db9" />}
            />
            x<Widget
              backgroundColor="white"
              text="Employee"
              number="7"
              icon={
                <FcConferenceCall
                  color="rgba(250, 122, 2, 0.5)"
                  className="employee_img"
                />
              }
            />
            {applicationCountFetched && (
              <Widget
                backgroundColor="white"
                text="Applicants"
                number={String(applicationCount)}
                icon={
                  <BsFillPersonLinesFill
                    color="#a9ec75"
                    className="employee_img"
                  />
                }
              />
            )}
            {isFetched && (
              <Widget
                backgroundColor="white"
                text="Invited for interview"
                number={String(count)}
                icon={<MdCall color="#6f4a9e" className="employee_img" />}
              />
            )}
          </div>
          <div className={"second_row"}>
            <div className={"second_row_widgets"}>
              <div className="chart_widget">
                <p>Tech stack</p>
                {positionCount.length > 0 ? (
                  <Chart
                    options={candidatePositions}
                    series={positionCount}
                    height="250"
                    type="donut"
                    width="380"
                  />
                ) : (
                  <p
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "100%",
                    }}
                  >
                    None
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
        {events && (
          <div className="scheduler">
            <Calendar
              localizer={localizer}
              events={events}
              startAccessor="start"
              endAccessor="end"
              min={minTime}
              max={maxTime}
              defaultView={"work_week"}
              views={["day", "work_week"]}
              className="calendar"
              eventPropGetter={handleEventStyleGetter}
              messages={{
                next: ">",
                previous: "<",
              }}
              components={{
                event: CustomEvent,
              }}
              onSelectEvent={handleSelectEvent}
            />
            <div
              style={{
                position: "absolute",
                width: 300,
                height: 50,
                top: 0,
                left: 0,
                marginLeft: "5rem",
                cursor: "pointer",
              }}
              className="overlay"
              onClick={() => setOpenMonthCalendar(true)}
            />
            <AddEvent
              onClick={() => {
                setAddEventModal(true);
              }}
            >
              <span style={{ fontSize: 22 }}>+</span>
              <span>Add</span>
            </AddEvent>
          </div>
        )}
      </div>
      {openModal && <OpenScheduler open={openModal} setOpen={setOpenModal} />}
      {addEventModal && (
        <AddEventModal open={addEventModal} setOpen={setAddEventModal} />
      )}
      {updateEventModal && (
        <UpdateEventModal
          open={updateEventModal}
          setOpen={setUpdateEventModal}
          selectedEvent={selectedEvent}
        />
      )}
      {openMonthCalendar && (
        <MonthCalendar
          open={openMonthCalendar}
          setOpen={setOpenMonthCalendar}
          events={events}
        />
      )}
    </div>
  );
};

export default Dashboard;
