import React, { useState } from "react";
import { ROLES } from "../../components/Roles/Roles";
import UseAuth from "../../hooks/UseAuth";
import AddUser from "./AddUser/AddUser";
import ChangeUserSettings from "./ChangeUserSettings/ChangeUserSettings";
import General from "./General/General";
import "./Settings.scss";
import SettingsNavigation from "./SettingsNavigation/SettingsNavigation";
import Users from "./Users/Users";

const Settings = () => {
  const { auth } = UseAuth();
  const [navigation, setNavigation] = useState(
    auth.userRoles === ROLES.ADMIN ? "users" : "changeUserSettings"
  );
  return (
    <div className="settings">
      <div className="settings_container">
        <div className="settings_container_body">
          <h2>Settings</h2>
          <div className="settings_container_body_elements">
            <SettingsNavigation
              navigation={navigation}
              setNavigation={setNavigation}
            />
            {navigation === "users" && auth.userRoles === ROLES.ADMIN ? (
              <Users />
            ) : navigation === "addUser" && auth.userRoles === ROLES.ADMIN ? (
              <AddUser />
            ) : navigation === "general" && auth.userRoles === ROLES.ADMIN ? (
              <General />
            ) : navigation === "changeUserSettings" &&
              auth.userRoles === ROLES.USER ? (
              <ChangeUserSettings />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
