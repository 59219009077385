import React from "react";
import { useNavigate } from "react-router-dom";
import CancelButton from "../../../components/Buttons/Cancel/CancelButton";
import SubmitButton from "../../../components/Buttons/Submit/SubmitButton";
import "./Submit.scss";

interface Props {
  setChanged: React.Dispatch<React.SetStateAction<boolean>>;
  disabled: boolean;
}

const Submit: React.FC<Props> = ({ setChanged, disabled }) => {
  const navigate = useNavigate();

  return (
    <div className="submit">
      <CancelButton
        type="button"
        onClick={() => {
          navigate(-1);
        }}
      >
        Cancel
      </CancelButton>
      {!disabled && (
        <SubmitButton type="submit" onClick={() => setChanged(false)}>
          Submit
        </SubmitButton>
      )}
    </div>
  );
};

export default Submit;
