import React from "react";
import { ROLES } from "../../../../components/Roles/Roles";
import "../NewInvoiceFrom/NewInvoiceFrom.scss";

interface Props {
  invoiceDate: string;
  paymentTerms: number;
  role: string;

  setInvoiceDate: React.Dispatch<React.SetStateAction<string>>;
  setPaymentTerms: React.Dispatch<React.SetStateAction<number>>;
}

const AddInvoiceTo: React.FC<Props> = ({
  invoiceDate,
  setInvoiceDate,
  paymentTerms,
  setPaymentTerms,
  role,
}) => {
  return (
    <div className="invoice_add_from">
      <div className="invoice_add_from_element_container">
        <div className="invoice_add_from_element">
          <label htmlFor="customer">Customer</label>
          <input
            type={"text"}
            required
            style={{ width: "100%" }}
            name="firstname"
            value={"Kodiva s.r.o."}
            disabled
          />
        </div>
      </div>
      <div className="invoice_add_from_element_container">
        <div className="invoice_add_from_element">
          <label htmlFor="city">City</label>
          <input
            type={"text"}
            required
            style={{ width: "100%" }}
            name="city"
            value={"Bratislava - mestská časť Rača"}
            disabled
          />
        </div>
        <div className="invoice_add_from_element">
          <label htmlFor="zipcode">Zipcode</label>
          <input
            type={"text"}
            required
            style={{ width: "100%" }}
            name="zipcode"
            value={"831 06"}
            disabled
          />
        </div>
      </div>
      <div className="invoice_add_from_element_container">
        <div className="invoice_add_from_element">
          <label htmlFor="address">Address</label>
          <input
            type={"text"}
            required
            style={{ width: "100%" }}
            name="address"
            value={"Karpatské námestie 7770/10A"}
            disabled
          />
        </div>
        <div className="invoice_add_from_element">
          <label htmlFor="country">Country</label>
          <input
            type={"text"}
            required
            style={{ width: "100%" }}
            name="country"
            value={"Slovensko"}
            disabled
          />
        </div>
      </div>
      <div className="invoice_add_from_element_container">
        <div className="invoice_add_from_element">
          <label htmlFor="businessID">Business ID</label>
          <input
            type={"text"}
            required
            style={{ width: "100%" }}
            name="businessID"
            value={"53985851"}
            disabled
          />
        </div>
        <div className="invoice_add_from_element">
          <label htmlFor="taxID">Tax ID</label>
          <input
            type={"text"}
            required
            style={{ width: "100%" }}
            name="taxID"
            value={"2121559902"}
            disabled
          />
        </div>
      </div>
      <div className="invoice_add_from_element_container">
        <div className="invoice_add_from_element">
          <label htmlFor="businessID">VAT</label>
          <input
            type={"text"}
            required
            style={{ width: "100%" }}
            name="businessID"
            value={"SK2121559902"}
            disabled
          />
        </div>
      </div>
      <div className="invoice_add_from_element_container">
        <div className="invoice_add_from_element">
          <label htmlFor="invoiceDate">Delivery Month</label>
          <input
            type={"month"}
            required
            style={{ width: "100%" }}
            name="invoiceDate"
            value={invoiceDate}
            onChange={(e) => setInvoiceDate(e.target.value)}
          />
        </div>
        <div className="invoice_add_from_element">
          <label htmlFor="paymentTerms">Payment Terms</label>
          <div className="paymentTerms_element">
            <p>Net</p>
            {/* <input type={"text"} name="paymentTerms" value={"30"} disabled /> */}
            <input
              type="text"
              value={paymentTerms}
              disabled={role !== ROLES.ADMIN}
              style={{
                width: "50px",
              }}
              onChange={(e) => {
                setPaymentTerms(Number(e.target.value.replace(/\D/g, "")));
              }}
              className={`${role === ROLES.ADMIN ? "active" : "disabled"}`}
            />
            <p>Days</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddInvoiceTo;
