import React from "react";
import { View, Text, Font } from "@react-pdf/renderer";
import { Invoicestyles } from "../InvoiceStyles/InvoiceStyle";
import arial from "../../../../assets/fonts/Arial_medium.ttf";
import arialText from "../../../../assets/fonts/arialW.ttf";
import arialBold from "../../../../assets/fonts/Arial_bold.TTF";

Font.register({
  family: "Roboto",
  fonts: [
    {
      src: arial,
    },
    {
      src: arialText,
      fontWeight: 300,
    },
    { src: arialBold, fontWeight: 700 },
  ],
});

interface Props {
  name: string;
  address: string;
  city: string;
  zipCode: string;
  country: string;
  businessID: string;
  taxID: string;
  email: string;
  number: string;
}

const IdentificationData: React.FC<Props> = ({
  name,
  address,
  city,
  zipCode,
  country,
  businessID,
  taxID,
  email,
  number,
}) => {
  return (
    <View style={Invoicestyles.identificationData}>
      <View style={Invoicestyles.indentificationData_suplier}>
        <Text
          style={[
            Invoicestyles.header,
            { fontFamily: "Roboto", fontWeight: 700 },
          ]}
        >
          IDENTIFIKAČNÉ ÚDAJE
        </Text>
        <View style={Invoicestyles.header_text}>
          <Text
            style={{
              fontSize: 9,
              fontFamily: "Roboto",
              fontWeight: 700,
              lineHeight: 1.6,
            }}
          >
            Dodávateľ
          </Text>
          <Text
            style={{
              fontSize: 9,
              fontWeight: 700,
              fontFamily: "Roboto",
              lineHeight: 1.6,
            }}
          >
            {name}
          </Text>
          <Text
            style={{
              fontSize: 8.5,
              fontFamily: "Roboto",
              fontWeight: 300,
              lineHeight: 1.6,
            }}
          >
            {address}
          </Text>
          <Text
            style={{
              fontSize: 8.5,
              fontFamily: "Roboto",
              fontWeight: 300,
              lineHeight: 1.6,
            }}
          >
            {zipCode + " " + city}
          </Text>
          <Text
            style={{
              fontSize: 8.5,
              fontFamily: "Roboto",
              fontWeight: 300,
              lineHeight: 1.6,
            }}
          >
            {country}
          </Text>
          <Text
            style={{
              fontSize: 8.5,
              fontFamily: "Roboto",
              fontWeight: 300,
              lineHeight: 1.6,
            }}
          >
            IČO: {businessID}
            {"    "}DIČ: {taxID}
          </Text>
        </View>
        <View style={Invoicestyles.header_text}>
          <Text
            style={{
              fontSize: 9,
              fontFamily: "Roboto",
              fontWeight: 700,
              lineHeight: 1.6,
            }}
          >
            Kontaktné údaje
          </Text>
          <Text
            style={{
              fontSize: 8.5,
              fontWeight: 300,
              fontFamily: "Roboto",
              lineHeight: 1.6,
            }}
          >
            Email:{"        "}
            {email}
          </Text>
          <Text
            style={{
              fontSize: 8.5,
              fontWeight: 300,
              fontFamily: "Roboto",
              lineHeight: 1.6,
              marginLeft: -1,
            }}
          >
            Telefón:{"     "}
            {number}
          </Text>
        </View>
      </View>
      <View style={Invoicestyles.identificatioNdata_customer}>
        <View style={Invoicestyles.customer_text}>
          <Text
            style={{
              fontSize: 9,
              fontWeight: 700,
              fontFamily: "Roboto",
              lineHeight: 1.6,
            }}
          >
            Odoberateľ
          </Text>
          <Text
            style={{
              fontSize: 9,
              fontWeight: 700,
              fontFamily: "Roboto",
              lineHeight: 1.6,
            }}
          >
            Kodiva s.r.o.
          </Text>
          <Text
            style={{
              fontSize: 8.5,
              fontFamily: "Roboto",
              fontWeight: 300,
              lineHeight: 1.6,
            }}
          >
            Karpatské námestie 7770/10A
          </Text>
          <Text
            style={{
              fontSize: 8.5,
              fontFamily: "Roboto",
              fontWeight: 300,
              lineHeight: 1.6,
            }}
          >
            831 06 Bratislava - mestská časť Rača
          </Text>
          <Text
            style={{
              fontSize: 8.5,
              fontFamily: "Roboto",
              fontWeight: 300,
              lineHeight: 1.6,
            }}
          >
            Slovensko
          </Text>
          <Text
            style={{
              fontSize: 8.5,
              fontFamily: "Roboto",
              fontWeight: 300,
              lineHeight: 1.6,
            }}
          >
            IČO: 53985851{"  "} IČ DPH: SK2121559902{"  "} DIČ: 2121559902
          </Text>
        </View>
      </View>
    </View>
  );
};

export default IdentificationData;
