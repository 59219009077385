import dayjs from "dayjs";
import React, { useEffect } from "react";
import useGetEvents from "../../../hooks/useGetEvents";
import "./UserDashboardCalendar.scss";
import Tooltip from "@mui/material/Tooltip";
import Fade from "@mui/material/Fade/Fade";
import { EventTypeSelected } from "../../../components/interfaces/interfaces";

type DayOfWeek = "Mon" | "Tue" | "Wed" | "Thu" | "Fri";
interface Event {
  event_id: number;
  id: string;
  title: string;
  start: string;
  end: string;
  description?: string;
}

interface Props {
  setEventsFromCalendar: React.Dispatch<
    React.SetStateAction<EventTypeSelected[]>
  >;
  setOpenMonthCalendar: React.Dispatch<React.SetStateAction<boolean>>;
}

const getCurrentWeekDates = () => {
  const weekStart = dayjs().startOf("week");
  const dates: { [key in DayOfWeek]: string } = {
    Mon: weekStart.add(1, "day").format("dddd D MMM"),
    Tue: weekStart.add(2, "day").format("dddd D MMM"),
    Wed: weekStart.add(3, "day").format("dddd D MMM"),
    Thu: weekStart.add(4, "day").format("dddd D MMM"),
    Fri: weekStart.add(5, "day").format("dddd D MMM"),
  };

  return dates;
};

const addEventsToDates = (
  dates: { [key in DayOfWeek]: string },
  events: Event[]
) => {
  const result: { [key in string]: Event[] } = {};
  for (const key in dates) {
    result[dates[key as DayOfWeek]] = [];
  }
  events.forEach((event) => {
    const eventDay = dayjs(event.start).format("dddd D MMM");
    if (result[eventDay]) {
      result[eventDay as DayOfWeek].push(event);
    }
  });
  return result;
};

const UserDashboardCalendar: React.FC<Props> = ({
  setEventsFromCalendar,
  setOpenMonthCalendar,
}) => {
  const events = useGetEvents();
  const dates = getCurrentWeekDates();
  const eventsByDay = addEventsToDates(dates, events);

  useEffect(() => {
    setEventsFromCalendar(events);
  }, [events]);

  return (
    <Tooltip
      title="Open month calendar"
      TransitionComponent={Fade}
      placement="right-start"
      PopperProps={{
        sx: {
          "& .MuiTooltip-tooltip": {
            border: "transparent",
            color: "#003d50",
            backgroundColor: "white",
            padding: "10px",
            borderRadius: "10px",
            fontSize: "12px",
            boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px;",
          },
        },
      }}
    >
      <div
        className="user_dashboard_calendar"
        onClick={() => setOpenMonthCalendar(true)}
      >
        <h5>This week events</h5>
        {eventsByDay &&
          Object.entries(eventsByDay).map(([date, events]) => (
            <div key={date} className="event_date">
              {date}
              {events.length === 0 ? (
                <p>No events scheduled for this day</p>
              ) : (
                <div className="events">
                  {events.map((event) => (
                    <div className="event_element" key={event.id}>
                      <h4>{event.title}</h4>
                      <span>{dayjs(event.start).format("dddd HH:mm")}</span>
                      <span>
                        {event.description !== undefined
                          ? event.description
                          : "No description"}
                      </span>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
      </div>
    </Tooltip>
  );
};

export default UserDashboardCalendar;
