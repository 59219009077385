import React from "react";
import "./UserDashboardScrollableWidget.scss";

interface Props {
  children: React.ReactNode;
}

const UserDashboardScrollableWidget: React.FC<Props> = ({ children }) => {
  return <div className="userDashboardScrollableWidget">{children}</div>;
};

export default UserDashboardScrollableWidget;
