import React, { useState } from "react";
import { useQuery } from "react-query";
import { getCandidates } from "../../api/candidateApi";
import DeleteCandidate from "../Home/Modals/DeleteCandidate/DeleteCandidate";
import GDPRTable from "./GDPRTable/GDPRTable";
import "./GDPRValidation.scss";

const GDPRValidation = () => {
  const [TableData, setTableData] = useState<any[]>([]);
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
  const [deleteID, setDeleteID] = useState<number>();
  const heading = ["Name", "GDPR", "Time until expiration", "Actions"];
  // eslint-disable-next-line no-empty-pattern
  const {} = useQuery("candidates", getCandidates, {
    refetchOnWindowFocus: false,
    retry: 0,
    onSuccess: (data) => {
      setTableData(data);
    },
  });
  return (
    <div className="gdpr_container">
      <div className="gdpr_container_body">
        <GDPRTable
          headers={heading}
          tableData={TableData}
          setConfirmDelete={setConfirmDelete}
          setDeleteID={setDeleteID}
        />
      </div>
      {confirmDelete && (
        <DeleteCandidate
          open={confirmDelete}
          setConfirmDelete={setConfirmDelete}
          id={deleteID}
          setOpen={setConfirmDelete}
          name={TableData.find((candidate) => {
            return candidate.id === Number(deleteID);
          })}
        />
      )}
    </div>
  );
};

export default GDPRValidation;
