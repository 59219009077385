import React from "react";
import "./Loading.scss";
import KodivaLoadingIcon from "./KodivaLoadingIcon";

const Loading = () => {
  return (
    <div className="loading">
      <KodivaLoadingIcon />
    </div>
  );
};

export default Loading;
