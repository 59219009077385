import React from "react";
import { View, Image, Text } from "@react-pdf/renderer";
import { styles } from "./InvoiceHeaderStyle";
import kodiva from "../../../../assets/images/KODIVA_pdf.png";
import gradient from "../../../../assets/images/gradient.png";

interface Props {
  invoiceNumber: string;
}

const InvoicePDFHeader: React.FC<Props> = ({ invoiceNumber }) => {
  return (
    <View fixed style={styles.header}>
      <Image
        style={{
          position: "absolute",
          top: -5,
          left: 0,
          width: 600,
          height: 75,
        }}
        src={gradient}
      />
      <View style={{ width: "100%" }}>
        <Image src={kodiva} style={styles.image} />
      </View>
      <View
        style={{
          width: "43%",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          flexDirection: "row",
          textAlign: "center",
        }}
      >
        {/* <Text
          style={{
            fontSize: 9,
            fontFamily: "Roboto",
            fontWeight: 700,
            lineHeight: 1.6,
          }}
        >
          Faktúra
        </Text> */}
        <Text
          style={{
            fontSize: 9,
            fontWeight: 700,
            fontFamily: "Roboto",
            color: "white",
            lineHeight: 1.3,
            padding: "7px 30px 3px 30px",
            border: "1px solid #e6e6e6",
            marginTop: "-5px",
          }}
        >
          {invoiceNumber}
        </Text>
      </View>
    </View>
  );
};

export default InvoicePDFHeader;
