import axios from "axios";
import { SettingsType } from "../components/interfaces/interfaces";
import { refreshToken } from "./authApi";
import { baseURL } from './apiConfig';

const serverApi = axios.create({
  //localhost:8080
  baseURL: baseURL,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});

export const getSettings = async () => {
  await refreshToken();
  const bearer_token = `${localStorage.getItem("token")}`;
  const { data } = await serverApi.get("/settings", {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${bearer_token}`,
    },
  });
  return data;
};
export const updateSettings = async (payload: SettingsType) => {
  await refreshToken();
  const bearer_token = `${localStorage.getItem("token")}`;
  await serverApi.put(`/settings`, payload, {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${bearer_token}`,
    },
  });
};
