import { View, Text, Font } from "@react-pdf/renderer";
import React from "react";
import { Invoicestyles } from "../InvoiceStyles/InvoiceStyle";
import InvoiceItemsHeader from "./InvoiceItemsHeader";
import arial from "../../../../assets/fonts/Arial_medium.ttf";
import arialText from "../../../../assets/fonts/arialW.ttf";
import arialBold from "../../../../assets/fonts/Arial_bold.TTF";
import MultiSportCard from "./MultiSportCard";

Font.register({
  family: "Roboto",
  fonts: [
    {
      src: arial,
    },
    {
      src: arialText,
      fontWeight: 300,
    },
    { src: arialBold, fontWeight: 700 },
  ],
});

interface Props {
  list: {
    id: number;
    itemName: string;
    Qty: string;
    price: number;
    total: string;
  }[];
    multiSportCard:boolean;
}

const InvoiceItems: React.FC<Props> = ({ list, multiSportCard }) => {
  return (
    <View style={Invoicestyles.invoiceItems}>
      <Text
        style={[
          Invoicestyles.invoiceHeader,
          { fontFamily: "Roboto", fontWeight: 700 },
        ]}
      >
        FAKTURUJEME VÁM
      </Text>
      <InvoiceItemsHeader />
      {list &&
        list.map((listItem) => (
          <View
            style={{
              width: "100%",
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              flexDirection: "row",
            }}
            key={listItem.id}
          >
            <View
              style={{
                width: 400,
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                borderBottom: "2px solid #e6e6e6",
                paddingTop: 2,
              }}
            >
              <Text
                style={{
                  fontSize: 8.5,
                  fontFamily: "Roboto",
                  fontWeight: 300,
                  lineHeight: 1.6,
                }}
              >
                {listItem.itemName}
              </Text>
            </View>
            <View
              style={{
                width: 40,
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                borderBottom: "2px solid #e6e6e6",
                paddingTop: 2,
              }}
            >
              <Text
                style={{
                  fontSize: 8.5,
                  fontFamily: "Roboto",
                  fontWeight: 300,
                  lineHeight: 1.6,
                }}
              >
                {listItem.Qty}
              </Text>
            </View>
            <View
              style={{
                width: 40,
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                borderBottom: "2px solid #e6e6e6",
                paddingTop: 2,
              }}
            >
              <Text
                style={{
                  fontSize: 8.5,
                  fontFamily: "Roboto",
                  fontWeight: 300,
                  lineHeight: 1.6,
                }}
              >
                mh
              </Text>
            </View>
            <View
              style={{
                width: 100,
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "flex-start",
                borderBottom: "2px solid #e6e6e6",
                paddingTop: 2,
              }}
            >
              <Text
                style={{
                  fontSize: 8.5,
                  fontWeight: 300,
                  fontFamily: "Roboto",
                  lineHeight: 1.6,
                }}
              >
                {String(Math.round((listItem.price / 8) * 100) / 100)}
              </Text>
            </View>
            <View
              style={{
                width: 100,
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "flex-start",
                borderBottom: "2px solid #e6e6e6",
                backgroundColor: "#e6e6e6",
                marginRight: 20,
                paddingTop: 2,
              }}
            >
              <Text
                style={{
                  fontSize: 8.5,
                  fontWeight: 700,
                  fontFamily: "Roboto",
                  lineHeight: 1.6,
                  paddingRight: 5,
                }}
              >
                {Number(Math.round(Number(listItem.total) * 100) / 100).toFixed(
                  2
                )}
              </Text>
            </View>
          </View>
        ))}
        <MultiSportCard multiSportCard={multiSportCard}/>
    </View>
  );
};

export default InvoiceItems;
