import React, { useState, useEffect } from "react";

const useEmployeeSearch = (array: any, search: string) => {
  const [result, setResult] = useState<any>([]);

  useEffect(() => {
    if (search === "") {
      setResult(array);
    } else {
      setResult([
        ...array?.filter(
          (employee: any) =>
            employee.region.toLowerCase().includes(search.toLowerCase()) ||
            employee.work_position
              .toLowerCase()
              .includes(search.toLowerCase()) ||
              (employee.expectedSalary !== null && employee.expectedSalary
                  .toLowerCase()
                  .includes(search.toLowerCase())) ||
              (employee.availableDate !==null &&
                  employee.availableDate.toLowerCase().includes(search.toLowerCase()))
        ),
      ]);
    }
  }, [array, search]);
  return result;
};

export default useEmployeeSearch;
