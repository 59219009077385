import React, { useEffect, useMemo, useState } from "react";
import "./Home.scss";

import Navbar from "../../components/Navbar/Navbar";
import CVTable from "./CVTable/CVTable";
import Searchbar from "../../components/Searchbar/Searchbar";
import { useQuery } from "react-query";
import { getCandidates } from "../../api/candidateApi";
import DeleteCandidate from "./Modals/DeleteCandidate/DeleteCandidate";
import {
  CircularProgress,
  FormControl,
  MenuItem,
  Pagination,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { UserAuth } from "../../components/Context/StateProvider";
import { actTypes } from "../../components/Context/Reducer";
import dayjs from "dayjs";
import Settings from "../../components/Settings/Settings";
import { useTransition, animated } from "react-spring";
import SettingsModal from "../Dashboard/Modals/SettingsModal";
import Loading from "../../components/Loading/Loading";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux/es/exports";
import { RootState } from "../../redux/store";
import { setCandidatesStore } from "../../redux/candidateReducer";
import { setAllCandidates } from "../../redux/allCandidatesReducer";
import UpdateTableNotification from "./Notification/UpdateTableNotification";

const Home = () => {
  const candidateDispatch = useDispatch();
  const candidateStore = useSelector((state: any) => state);
  const [candidateRedux, setCandidateRedux] = useState<any[]>(
    Object.values(candidateStore.persistedReducer.candidates)
  );

  const [tableData, setTableData] = useState<any[]>(
    candidateStore.persistedAllCandidates.allCandidates.length > 0
      ? candidateStore.persistedAllCandidates.allCandidates
          .slice()
          .sort((a: any, b: any) => b.id - a.id)
      : []
  );
  const [allCandidatesRedux, setAllCandidatesRedux] = useState<any[]>(
    candidateStore.persistedAllCandidates.allCandidates.length > 0
      ? candidateStore.persistedAllCandidates.allCandidates
          .slice()
          .sort((a: any, b: any) => b.id - a.id)
      : []
  );
  const [currentData, setCurrentData] = useState<any[]>([]);

  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
  const [deleteID, setDeleteID] = useState<number>();

  const [pageNumbers, setPageNumbers] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(
    Number(localStorage.getItem("page")) || 1
  );
  const [selectPageNumber, setSelectPageNumber] = useState(
    localStorage.getItem("pageLength") || "10"
  );

  const [render, setRender] = useState(false);

  const [{ page, statusFilter, search }, dispatch] = UserAuth();
  const [searchQuery, setSearchQuery] = useState(search);

  const [emptySearch, setEmptySearch] = useState(true);
  const [candidateTable, setCandidateTable] = useState<any[]>([]);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [filter, setFilter] = useState(false);

  const [openSettings, setOpenSettings] = useState(false);
  const transition = useTransition(openSettings, {
    from: { x: -500, opacity: 0 },
    enter: { x: 0, opacity: 1 },
    leave: { x: -500, opacity: 0 },
  });

  const heading = useMemo(
    () => [
      "Name",
      "Email",
      "Phone",
      "Place",
      "Position",
      "Expected Salary",
      "Start Date",
      "Status",
      "GDPR",
      "Actions",
    ],
    []
  );
  const { data: candidates, isFetched } = useQuery(
    "candidates",
    getCandidates,
    {
      refetchOnWindowFocus: false,
      retry: 0,
      onSuccess: (data) => {
        data.sort((a: any, b: any) => b.id - a.id);
        setPageNumbers(Math.ceil(data.length / Number(selectPageNumber)));
        candidateDispatch(
          setAllCandidates(data.sort((a: any, b: any) => b.id - a.id))
        );

        //expiration count
        const now = dayjs(new Date()).format("YYYY-MM-DD");
        if (data.length > 0) {
          let count = 0;
          dispatch({
            type: actTypes.EXPIRATION_COUNT,
            expirationCount: count,
          });
          // eslint-disable-next-line array-callback-return
          data.map((dataElement: any) => {
            if (
              dataElement.gdpr === 1 &&
              (dayjs(dataElement.gdpr_valid_date).diff(now, "month") <= 2 ||
                isNaN(dayjs(dataElement.gdpr_valid_date).diff(now, "month")))
            ) {
              dataElement.expiration = dayjs(dataElement.gdpr_valid_date).diff(
                now,
                "month"
              );
              dispatch({
                type: actTypes.EXPIRATION_COUNT,
                expirationCount: ++count,
              });
            }
          });
        }

        setRender(true);
      },
    }
  );
  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    event.preventDefault();
    if (value !== pageNumber) {
      dispatch({
        type: actTypes.PAGE_NUMBER,
        page: value,
      });
      localStorage.setItem("page", String(value));
      setPageNumber(value);
    }
  };
  const changeDisplayPageNumber = (event: SelectChangeEvent) => {
    setSelectPageNumber(event.target.value);
    localStorage.setItem("pageLength", event.target.value);
  };
  useEffect(() => {
    setPageNumbers(Math.ceil(tableData.length / Number(selectPageNumber)));
  }, []);

  useEffect(() => {
    if (
      searchQuery === "" &&
      allCandidatesRedux.length > 0 &&
      ((statusFilter[0] === "" && statusFilter.length === 1) ||
        statusFilter.length === 0)
    ) {
      const firstPageIndex = (pageNumber - 1) * Number(selectPageNumber);
      const lastPageIndex = firstPageIndex + Number(selectPageNumber);
      setPageNumbers(
        Math.ceil(allCandidatesRedux?.length / Number(selectPageNumber))
      );
      setCandidateTable(
        allCandidatesRedux?.slice(firstPageIndex, lastPageIndex)
      );
      setCurrentData(allCandidatesRedux?.slice(firstPageIndex, lastPageIndex));
    }
    if (
      searchQuery !== "" &&
      tableData.length > 0 &&
      ((statusFilter[0] === "" && statusFilter.length === 1) ||
        statusFilter.length === 0)
    ) {
      if (tableData.length !== candidateTable.length) {
        const firstPageIndex = (pageNumber - 1) * Number(selectPageNumber);
        const lastPageIndex = firstPageIndex + Number(selectPageNumber);
        setCandidateTable(tableData?.slice(firstPageIndex, lastPageIndex));
        setCurrentData(tableData?.slice(firstPageIndex, lastPageIndex));
      }
      setPageNumbers(Math.ceil(tableData?.length / Number(selectPageNumber)));
      if (
        pageNumber > Math.ceil(tableData?.length / Number(selectPageNumber)) &&
        Math.ceil(tableData?.length / Number(selectPageNumber)) !== 0
      ) {
        setPageNumber(Math.ceil(tableData?.length / Number(selectPageNumber)));
      }
    }
    if (statusFilter.length > 0 && searchQuery !== "") {
      const firstPageIndex = (pageNumber - 1) * Number(selectPageNumber);
      const lastPageIndex = firstPageIndex + Number(selectPageNumber);
      setPageNumbers(Math.ceil(tableData?.length / Number(selectPageNumber)));
      if (
        pageNumber > Math.ceil(tableData?.length / Number(selectPageNumber)) &&
        Math.ceil(tableData?.length / Number(selectPageNumber)) !== 0
      ) {
        setPageNumber(Math.ceil(tableData?.length / Number(selectPageNumber)));
      }
      setCandidateTable(tableData?.slice(firstPageIndex, lastPageIndex));
      setCurrentData(tableData?.slice(firstPageIndex, lastPageIndex));
    }
    if (statusFilter.length > 0 && searchQuery === "") {
      const firstPageIndex = (pageNumber - 1) * Number(selectPageNumber);
      const lastPageIndex = firstPageIndex + Number(selectPageNumber);
      setPageNumbers(
        Math.ceil(filteredData?.length / Number(selectPageNumber))
      );
      if (
        pageNumber >
          Math.ceil(filteredData?.length / Number(selectPageNumber)) &&
        Math.ceil(filteredData?.length / Number(selectPageNumber)) !== 0
      ) {
        setPageNumber(
          Math.ceil(filteredData?.length / Number(selectPageNumber))
        );
      }
      setCandidateTable(filteredData?.slice(firstPageIndex, lastPageIndex));
      setCurrentData(filteredData?.slice(firstPageIndex, lastPageIndex));
    }
  }, [
    candidates,
    pageNumber,
    searchQuery,
    isFetched,
    selectPageNumber,
    statusFilter,
    filteredData,
    emptySearch,
    tableData,
    candidateTable.length,
    tableData.length,
    candidateStore.persistedAllCandidates.allCandidates,
    allCandidatesRedux,
  ]);
  useEffect(() => {
    if (allCandidatesRedux) {
      const firstPageIndex = (pageNumber - 1) * Number(selectPageNumber);
      const lastPageIndex = firstPageIndex + Number(selectPageNumber);
      let filteredData = allCandidatesRedux
        ?.sort((a: any, b: any) => b.id - a.id)
        .filter((candidate: any) => {
          if (statusFilter.length > 0) {
            setFilter(true);
            // eslint-disable-next-line array-callback-return
            return statusFilter.some((status: string) => {
              if (status === "" && statusFilter.length === 1) {
                return candidate;
              }
              if (candidate.status === status) {
                return candidate;
              }
            });
          } else {
            setFilter(false);
            return candidate;
          }
        });
      if (searchQuery !== "") {
        filteredData = filteredData.filter((item: any) => {
          return (
            item.email
              .toLowerCase()
              .includes(searchQuery.toLowerCase().toString()) ||
            item.firstName
              .toLowerCase()
              .includes(searchQuery.toLowerCase().toString()) ||
            (item.firstName + " " + item.lastName)
              .toLowerCase()
              .includes(searchQuery.toLowerCase().toString()) ||
            (item.lastName || "")
              .toLowerCase()
              .includes(searchQuery.toLowerCase().toString()) ||
            (item.phone || "")
              .toLowerCase()
              .includes(searchQuery.toLowerCase().toString()) ||
            (item.place || "")
              .toLowerCase()
              .includes(searchQuery.toLowerCase().toString()) ||
            (item.work_position || "")
              .toLowerCase()
              .includes(searchQuery.toLowerCase().toString()) ||
            (item.status || "")
              .toLowerCase()
              .includes(searchQuery.toLowerCase().toString()) ||
            (item.exp_salary || "")
              .toLowerCase()
              .includes(searchQuery.toLowerCase().toString()) ||
            (item.exp_date || "")
              .toLowerCase()
              .includes(searchQuery.toLowerCase().toString())
          );
        });
      }
      setFilteredData(filteredData);
      setTableData(filteredData);
      setCurrentData(filteredData?.slice(firstPageIndex, lastPageIndex));
    }
  }, [
    statusFilter,
    searchQuery,
    candidates,
    pageNumber,
    selectPageNumber,
    allCandidatesRedux,
  ]);

  useEffect(() => {
    if (candidateTable.length > 0) {
      candidateDispatch(setCandidatesStore(candidateTable));
    }
  }, [candidateDispatch, candidateTable]);

  useEffect(() => {
    if (
      Array.isArray(
        candidateStore.persistedReducer.candidates &&
          typeof candidateStore.persistedReducer.candidates[0] === "object"
      )
    ) {
      setCandidateRedux(candidateStore.persistedReducer.candidates);
      setPageNumbers(
        Math.ceil(
          candidateStore.persistedReducer.candidates?.length /
            Number(selectPageNumber)
        )
      );
    } else {
      setCandidateRedux(
        tableData.length === 0
          ? []
          : Object.values(candidateStore.persistedReducer.candidates)
      );
      setPageNumbers(Math.ceil(tableData?.length / Number(selectPageNumber)));
    }
  }, [
    candidateStore.persistedReducer.candidates,
    selectPageNumber,
    tableData?.length,
  ]);
  useEffect(() => {
    if (candidates) {
      candidateDispatch(
        setAllCandidates(
          candidates.slice().sort((a: any, b: any) => b.id - a.id)
        )
      );
      setAllCandidatesRedux(
        candidates.slice().sort((a: any, b: any) => b.id - a.id)
      );
    }
  }, [candidateDispatch, candidates]);

  if (allCandidatesRedux.length === 0) {
    return <Loading />;
  }
  return (
    <div className="home">
      {(!render || confirmDelete) && (
        <UpdateTableNotification result="UPDATE" />
      )}
      <div className="home_header">
        <Searchbar
          setTableData={setTableData}
          currentData={currentData}
          allData={candidates}
          filteredData={filteredData}
          filter={filter}
          setSearchQuery={setSearchQuery}
          setEmpty={setEmptySearch}
        />
        <Settings setOpenSet={setOpenSettings} openSet={openSettings} />
      </div>
      {allCandidatesRedux.length > 0 ? (
        <>
          <div className="home_candidates">
            <p>
              {allCandidatesRedux?.length + " "}
              {allCandidatesRedux?.length !== 1
                ? "candidates"
                : "candidate"}{" "}
            </p>
          </div>
          <div className="home_table">
            {candidateRedux && (
              <CVTable
                tableData={candidateRedux}
                setTableData={setTableData}
                heading={heading}
                setConfirmDelete={setConfirmDelete}
                setDeleteID={setDeleteID}
              />
            )}
            <div className="home_pagination">
              <Pagination
                count={pageNumbers}
                color="primary"
                onChange={handlePageChange}
                page={pageNumber}
                className="pagination"
              />
              <div className="home_pagination_select">
                <FormControl size="small" sx={{ m: 1, width: "80px" }}>
                  <Select
                    value={selectPageNumber}
                    onChange={changeDisplayPageNumber}
                    style={{
                      height: "50px",
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={25}>25</MenuItem>
                    <MenuItem value={allCandidatesRedux?.length}>All</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="home_candidate_info">
                {allCandidatesRedux?.length > 0 ? (
                  <p>
                    {(pageNumber - 1) * Number(selectPageNumber) <= 0
                      ? 1
                      : (pageNumber - 1) * Number(selectPageNumber)}
                    {"-"}
                    {pageNumber * Number(selectPageNumber) > tableData?.length
                      ? pageNumber - 1 <= 0
                        ? candidateRedux?.length
                        : String(pageNumber - 1) +
                          String(candidateRedux?.length)
                      : pageNumber * Number(selectPageNumber)}
                    {` / ${tableData?.length}`}
                  </p>
                ) : null}
              </div>
            </div>
          </div>
        </>
      ) : (
        <Loading />
      )}
      {confirmDelete && (
        <DeleteCandidate
          open={confirmDelete}
          setConfirmDelete={setConfirmDelete}
          id={deleteID}
          setOpen={setConfirmDelete}
          name={tableData.find((candidate) => {
            return candidate.id === Number(deleteID);
          })}
        />
      )}
      {transition((style, item) =>
        item ? (
          <animated.div style={style} className="settings_mod">
            <SettingsModal setOpenSettings={setOpenSettings} />
          </animated.div>
        ) : null
      )}
    </div>
  );
};

export default Home;
