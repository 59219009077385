import React, { useState } from "react";
import "./Navbar.scss";
import kodiva from "../../assets/images/kodiva_logo_white.png";
import { HiOutlineLogout } from "react-icons/hi";
import { GiHamburgerMenu } from "react-icons/gi";
import { AiOutlineClose } from "react-icons/ai";

import { UserAuth } from "../Context/StateProvider";
import { actTypes } from "../Context/Reducer";
import { useNavigate } from "react-router-dom";
import UseAuth from "../../hooks/UseAuth";
import { ROLES } from "../Roles/Roles";
import ActiveLayer from "./AdminActiveLayer/ActiveLayer";
import UserActiveLayer from "./UserActiveLayer/UserActiveLayer";
import CustomerActiveLayer from "./CustomerActiveLayer/CustomerActiveLayer";

const Navbar = () => {
  const navigate = useNavigate();
  const { auth } = UseAuth();
  const [{ expirationCount, menu }, dispatch] = UserAuth();
  const [toggleMenu, setToggleMenu] = useState(false);
  const handleLogout = () => {
    dispatch({
      type: actTypes.SET_USER,
      user: false,
    });
    localStorage.setItem("token", "");
    localStorage.setItem("user", "false");
    dispatch({
      type: actTypes.SET_MENU,
      menu: "Dashboard",
    });
    navigate("/");
  };

  return (
    <nav className="navbar">
      <div className="navbar_info">
        <img src={kodiva} alt="kodiva" />
        <h4>Kodiva Apps</h4>
      </div>
      <ul className="navbar_Links">
        {/*Overlay before navbar menu option */}
        {auth.userRoles === ROLES.ADMIN ? (
          <ActiveLayer menu={menu} />
        ) : auth.userRoles === ROLES.USER ? (
          <UserActiveLayer menu={menu} />
        ) : auth.userRoles === ROLES.CUSTOMER ? (
          <CustomerActiveLayer menu={menu} />
        ) : null}
        {/* ADMIN, CUSTOMER routes */}
        {auth.userRoles === ROLES.CUSTOMER && (
          <>
            <li
              className={`"navbar_link_text " ${
                menu === "Employees" ? "active" : ""
              }`}
            >
              <div
                className="navbar_link"
                onClick={() => {
                  navigate("/employees");
                  dispatch({
                    type: actTypes.SET_MENU,
                    menu: "Employees",
                  });
                  localStorage.setItem("navbar", "Employees");
                }}
              >
                Employees
              </div>
            </li>
          </>
        )}

        {/* ADMIN ROUTES */}
        {auth.userRoles === ROLES.ADMIN && (
          <>
            <li
              className={`"navbar_link_text " ${
                menu === "Dashboard" ? "active" : ""
              }`}
            >
              <div
                className="navbar_link"
                onClick={() => {
                  navigate("/dashboard");
                  dispatch({
                    type: actTypes.SET_MENU,
                    menu: "Dashboard",
                  });
                  localStorage.setItem("navbar", "Dashboard");
                }}
              >
                Dashboard
              </div>
            </li>
            <li
              className={`"navbar_link_text " ${
                menu === "Employees" ? "active" : ""
              }`}
            >
              <div
                className="navbar_link"
                onClick={() => {
                  navigate("/employees");
                  dispatch({
                    type: actTypes.SET_MENU,
                    menu: "Employees",
                  });
                  localStorage.setItem("navbar", "Employees");
                }}
              >
                Employees
              </div>
            </li>
            <li
              className={`"navbar_link_text " ${
                menu === "Candidates" ? "active" : ""
              }`}
            >
              <div
                className="navbar_link"
                onClick={() => {
                  navigate("/cv");
                  dispatch({
                    type: actTypes.SET_MENU,
                    menu: "Candidates",
                  });
                  localStorage.setItem("navbar", "Candidates");
                }}
              >
                Candidates
              </div>
            </li>

            <li
              className={`"navbar_link_text" ${
                menu === "GDPR" ? "active" : ""
              }`}
              style={{ width: 90 }}
            >
              <div
                className="navbar_link"
                onClick={() => {
                  navigate("/gdpr");
                  dispatch({
                    type: actTypes.SET_MENU,
                    menu: "GDPR",
                  });
                  localStorage.setItem("navbar", "GDPR");
                }}
              >
                <div className="navbar_link_gdpr">
                  GDPR
                  {expirationCount > 0 ? <p>{expirationCount}</p> : null}
                </div>
              </div>
            </li>

            <li
              className={`"navbar_link_text " ${
                menu === "Invoices" ? "active" : ""
              }`}
            >
              <div
                className="navbar_link"
                onClick={() => {
                  navigate("/invoices");
                  dispatch({
                    type: actTypes.SET_MENU,
                    menu: "Invoices",
                  });
                  localStorage.setItem("navbar", "Invoices");
                }}
              >
                Invoices
              </div>
            </li>
            <li
              className={`"navbar_link_text " ${
                menu === "Settings" ? "active" : ""
              }`}
            >
              <div
                className="navbar_link"
                onClick={() => {
                  navigate("/settings");
                  dispatch({
                    type: actTypes.SET_MENU,
                    menu: "Settings",
                  });
                  localStorage.setItem("navbar", "Settings");
                }}
              >
                Settings
              </div>
            </li>
          </>
        )}
        {/* USER ROUTES */}
        {auth.userRoles === ROLES.USER && (
          <>
            <li
              className={`"navbar_link_text " ${
                menu === "Dashboard" ? "active" : ""
              }`}
            >
              <div
                className="navbar_link"
                onClick={() => {
                  dispatch({
                    type: actTypes.SET_MENU,
                    menu: "Dashboard",
                  });
                  navigate("/dashboard");
                  localStorage.setItem("navbar", "Dashboard");
                }}
              >
                Dashboard
              </div>
            </li>
            <li
              className={`"navbar_link_text " ${
                menu === "Invoices" ? "active" : ""
              }`}
            >
              <div
                className="navbar_link"
                onClick={() => {
                  dispatch({
                    type: actTypes.SET_MENU,
                    menu: "Invoices",
                  });
                  navigate("/invoices");
                  localStorage.setItem("navbar", "Invoices");
                }}
              >
                Invoices
              </div>
            </li>
            <li
              className={`"navbar_link_text " ${
                menu === "Settings" ? "active" : ""
              }`}
            >
              <div
                className="navbar_link"
                onClick={() => {
                  navigate("/settings");
                  dispatch({
                    type: actTypes.SET_MENU,
                    menu: "Settings",
                  });
                  localStorage.setItem("navbar", "Settings");
                }}
              >
                Settings
              </div>
            </li>
          </>
        )}

        <li className="navbar_link_text">
          <HiOutlineLogout />
          <div className="navbar_link" onClick={handleLogout}>
            Logout
          </div>
        </li>
      </ul>
      {/* mobile menu */}
      <div className="navbar_small-screen ">
        <GiHamburgerMenu
          className="navbar_small-screen_menu_icon"
          onClick={() => setToggleMenu(true)}
        />
        {toggleMenu && (
          <div
            className={`navbar_small-screen_overlay ${
              toggleMenu ? "open_modal" : "close_modal"
            }`}
          >
            <AiOutlineClose
              className="navbar_small-screen_menu_icon_close"
              onClick={() => setToggleMenu(false)}
            />
            <ul className="navbar_small-screen_overlay_links">
              {(auth.userRoles === ROLES.ADMIN ||
                auth.userRoles === ROLES.CUSTOMER) && (
                <li className="navbar_small-screen_overlay_text">
                  <div
                    className="navbar_link"
                    onClick={() => {
                      navigate("/dashboard");
                      setToggleMenu(false);
                    }}
                  >
                    Dashboard
                  </div>
                </li>
              )}
              {(auth.userRoles === ROLES.ADMIN ||
                auth.userRoles === ROLES.USER) && (
                <li className="navbar_small-screen_overlay_text">
                  <div
                    className="navbar_link"
                    onClick={() => {
                      navigate("/invoices");
                      setToggleMenu(false);
                    }}
                  >
                    Invoices
                  </div>
                </li>
              )}
              {(auth.userRoles === ROLES.ADMIN ||
                auth.userRoles === ROLES.CUSTOMER) && (
                <li className="navbar_small-screen_overlay_text">
                  <div
                    className="navbar_link"
                    onClick={() => {
                      navigate("/employees");
                      setToggleMenu(false);
                    }}
                  >
                    Employees
                  </div>
                </li>
              )}
              <li className="navbar_small-screen_overlay_text">
                <div
                  className="navbar_link"
                  onClick={() => {
                    navigate("/settings");
                    setToggleMenu(false);
                  }}
                >
                  Settings
                </div>
              </li>
              {auth.userRoles === ROLES.ADMIN && (
                <>
                  <li className="navbar_small-screen_overlay_text">
                    <div
                      className="navbar_link"
                      onClick={() => {
                        navigate("/cv");
                        setToggleMenu(false);
                      }}
                    >
                      Candidates
                    </div>
                  </li>
                  <li className="navbar_small-screen_overlay_text">
                    <div
                      className="navbar_link"
                      onClick={() => {
                        navigate("/gdpr");
                        setToggleMenu(false);
                      }}
                    >
                      <div className="navbar_link_gdpr">
                        GDPR
                        {expirationCount > 0 ? <p>{expirationCount}</p> : null}
                      </div>
                    </div>
                  </li>
                  <li className="navbar_link_text">
                    <div
                      className="navbar_link"
                      onClick={() => {
                        navigate("/settings");
                        setToggleMenu(false);
                      }}
                    >
                      Settings
                    </div>
                  </li>
                </>
              )}
              <li className="navbar_small-screen_overlay_text">
                <HiOutlineLogout />
                <div onClick={handleLogout} className="navbar_link">
                  Logout
                </div>
              </li>
            </ul>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
