import React from "react";
import FormControl from "@mui/material/FormControl/FormControl";
import "./FilterModal.scss";
import RadioGroup from "@mui/material/RadioGroup/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel/FormControlLabel";
import Radio from "@mui/material/Radio/Radio";
import FormLabel from "@mui/material/FormLabel/FormLabel";

interface props {
  filter: string;
  setFilter: React.Dispatch<React.SetStateAction<string>>;
}

const FilterModal: React.FC<props> = ({ setFilter, filter }) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter((event.target as HTMLInputElement).value);
    //   localStorage.setItem("statusFilter", String(value));
    //   dispatch({
    //     type: actTypes.STATUS_FILTER,
    //     statusFilter: value,
    //   });
  };

  return (
    <div className="filter_modal">
      <FormControl sx={{ m: 1, width: 300 }}>
        <FormLabel id="radio-buttons-group">Select Status</FormLabel>
        <RadioGroup
          aria-labelledby="radio-buttons-group"
          name="radio-buttons-group"
          value={filter}
          onChange={handleChange}
        >
          <FormControlLabel value="" control={<Radio />} label="None" />
          <FormControlLabel value="PAID" control={<Radio />} label="Paid" />
          <FormControlLabel
            value="PENDING"
            control={<Radio />}
            label="Unpaid"
          />
          <FormControlLabel
            value="REJECTED"
            control={<Radio />}
            label="Declined"
          />
        </RadioGroup>
      </FormControl>
    </div>
  );
};

export default FilterModal;
