import React from "react";
import "./EmployeeSortFilter.scss";

export interface ButtonProps
  extends React.DetailedHTMLProps<
      React.ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    >,
    React.AriaAttributes {}

export const EmployeeSortFilter: React.FC<ButtonProps> = (props) => {
  const { children, className, ...rest } = props;

  return (
    <button {...rest} className={`sort_filter-button ${className}`}>
      {children}
    </button>
  );
};

export default EmployeeSortFilter;
