import {
  Paragraph,
  Table,
  TableRow,
  TableCell,
  WidthType,
  TextRun,
  BorderStyle,
  ShadingType,
} from "docx";
export const titleComponent = (title: string) => {
  return new Table({
    columnWidths: [10000],
    rows: [
      new TableRow({
        children: [
          new TableCell({
            borders: {
              top: {
                style: BorderStyle.NONE,
                size: 0,
                color: "FFFFFF",
              },
              left: {
                style: BorderStyle.NONE,
                size: 0,
                color: "FFFFFF",
              },
              bottom: {
                style: BorderStyle.NONE,
                size: 0,
                color: "FFFFFF",
              },
              right: {
                style: BorderStyle.NONE,
                size: 0,
                color: "FFFFFF",
              },
            },
            shading: {
              type: ShadingType.SOLID,
              color: "c9c9c9",
              fill: "c9c9c9",
            },
            width: { size: 100 / 5, type: WidthType.PERCENTAGE },
            children: [
              new Paragraph({
                indent: {
                  left: 60,
                  right: 60,
                },
                spacing: {
                  before: 35,
                  after: 35,
                },
                children: [
                  new TextRun({
                    text: title,
                    size: 32,
                    bold: true,
                    color: "1F3864",
                    font: "Arial",
                  }),
                ],
              }),
            ],
          }),
        ],
      }),
    ],
  });
};
