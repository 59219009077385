import React from "react";

interface Props {
  email: string;
  number: string;

  setEmail: React.Dispatch<React.SetStateAction<string>>;
  setNumber: React.Dispatch<React.SetStateAction<string>>;
}

const ContactInforInvoice: React.FC<Props> = ({
  email,
  number,
  setEmail,
  setNumber,
}) => {
  return (
    <div className="invoice_add_from">
      <div className="invoice_add_from_element_container">
        <div className="invoice_add_from_element">
          <label htmlFor="email">Email</label>
          <input
            type={"text"}
            required
            disabled
            style={{ width: "100%" }}
            name="email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
        </div>
        <div className="invoice_add_from_element">
          <label htmlFor="Number">Number</label>
          <input
            type={"text"}
            required
            disabled
            style={{ width: "100%" }}
            name="number"
            value={number}
            onChange={(e) => {
              setNumber(e.target.value);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ContactInforInvoice;
