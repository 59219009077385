import React from "react";
import GDPRBody from "../GDPRBody/GDPRBody";
import GDPRHead from "../GDPRHead/GDPRHead";
import "./GDPRTable.scss";

interface Props {
  headers: string[];
  tableData: {
    id: number;
    firstName: string;
    lastName: string;
    gdpr: number;
    gdpr_valid_date: string | null;
    expiration: number | null;
  }[];
  setConfirmDelete: React.Dispatch<React.SetStateAction<boolean>>;
  setDeleteID: React.Dispatch<React.SetStateAction<any>>;
}

const GDPRTable: React.FC<Props> = ({
  headers,
  tableData,
  setConfirmDelete,
  setDeleteID,
}) => {
  return (
    <>
      <table className="gdpr_table">
        <GDPRHead GDPRHeaders={headers} />
        <GDPRBody
          data={tableData}
          setConfirmDelete={setConfirmDelete}
          setDeleteID={setDeleteID}
        />
      </table>
    </>
  );
};

export default GDPRTable;
