import {
  Paragraph,
  Table,
  TableRow,
  TableCell,
  TextRun,
  WidthType,
  ShadingType,
  BorderStyle,
  AlignmentType,
  ImageRun,
  VerticalAlign,
} from "docx";
import { emailImage } from "../emailImage";
import { phoneImage } from "../phoneImage";
import { placeImage } from "../placeImage";

const returnPlace = (
  anonymizeRegion: boolean,
  place: string,
  region: string
) => {
  return anonymizeRegion ? region : place;
};

export const detailComponent = (
  data: any,
  anonymizeRegion: boolean,
  anonymizeNumber: boolean,
  anonymizeEmail: boolean,
  anonymizeSummary: boolean,
  selectedNumber: string,
  selectedEmail: string
) => {
  const dataPhone = JSON.parse(data.phone);
  const dataEmail = JSON.parse(data.email);

  let summary = anonymizeSummary || data.summary === "" ? "" : "SUMMARY";
  let summaryColor =
    anonymizeSummary || data.summary === "" ? "e6e6e6" : "FFFFFF";
  let summaryText = anonymizeSummary ? "" : data.summary;

  let number: any;
  let numberPicture: any;

  let email: any;
  let emailPicture: any;

  //anonymize region
  let place = returnPlace(anonymizeRegion, data.place, data.region);
  console.log(place);
  //anonymize number
  if (anonymizeNumber) {
    if (selectedNumber === "") {
      number = [];
      numberPicture = [];
    } else {
      number = new TextRun({
        text: `\t${selectedNumber}`,
        size: 24,
        font: "Arial",
      });
      numberPicture = new ImageRun({
        data: Uint8Array.from(atob(phoneImage), (c) => c.charCodeAt(0)),
        transformation: {
          width: 17,
          height: 17,
        },
      });
    }
  }
  if (!anonymizeNumber) {
    number = new TextRun({
      text: `\t${dataPhone[0].phone}`,
      size: 24,
      font: "Arial",
    });
    numberPicture = new ImageRun({
      data: Uint8Array.from(atob(phoneImage), (c) => c.charCodeAt(0)),
      transformation: {
        width: 17,
        height: 17,
      },
    });
  }
  //anonymize email
  if (anonymizeEmail) {
    if (selectedEmail === "") {
      email = [];
      emailPicture = [];
    } else {
      email = new TextRun({
        text: `\t${selectedEmail}`,
        size: 24,
        font: "Arial",
      });
      emailPicture = new ImageRun({
        data: Uint8Array.from(atob(emailImage), (c) => c.charCodeAt(0)),
        transformation: {
          width: 17,
          height: 17,
        },
      });
    }
  }
  if (!anonymizeEmail) {
    email = new TextRun({
      text: `\t${dataEmail[0].email}`,
      size: 24,
      font: "Arial",
    });
    emailPicture = new ImageRun({
      data: Uint8Array.from(atob(emailImage), (c) => c.charCodeAt(0)),
      transformation: {
        width: 17,
        height: 17,
      },
    });
  }
  return new Table({
    columnWidths: [10000],
    borders: {
      top: {
        style: BorderStyle.SINGLE,
        size: 1,
        color: "e6e6e6",
      },
      left: {
        style: BorderStyle.SINGLE,
        size: 1,
        color: "e6e6e6",
      },
      bottom: {
        style: BorderStyle.SINGLE,
        size: 1,
        color: "e6e6e6",
      },
      right: {
        style: BorderStyle.SINGLE,
        size: 1,
        color: "e6e6e6",
      },
    },
    rows: [
      new TableRow({
        children: [
          new TableCell({
            borders: {
              top: {
                style: BorderStyle.NONE,
                size: 0,
                color: "FFFFFF",
              },
              left: {
                style: BorderStyle.NONE,
                size: 0,
                color: "FFFFFF",
              },
              bottom: {
                style: BorderStyle.NONE,
                size: 0,
                color: "FFFFFF",
              },
              right: {
                style: BorderStyle.NONE,
                size: 0,
                color: "FFFFFF",
              },
            },
            width: {
              size: 4500,
              type: WidthType.DXA,
            },
            shading: {
              type: ShadingType.SOLID,
              color: "c9c9c9",
              fill: "c9c9c9",
            },
            children: [
              new Paragraph({
                spacing: {
                  before: 35,
                  after: 35,
                },
                indent: {
                  left: 60,
                  right: 60,
                },
                children: [
                  new TextRun({
                    text: "DETAILS",
                    size: 32,
                    bold: true,
                    color: "1F3864",
                    font: "Arial",
                  }),
                ],
              }),
            ],
          }),
          new TableCell({
            borders: {
              top: {
                style: BorderStyle.NONE,
                size: 0,
                color: "FFFFFF",
              },
              left: {
                style: BorderStyle.NONE,
                size: 0,
                color: "FFFFFF",
              },
              bottom: {
                style: BorderStyle.NONE,
                size: 0,
                color: "FFFFFF",
              },
              right: {
                style: BorderStyle.NONE,
                size: 0,
                color: "FFFFFF",
              },
            },
            width: {
              size: 5500,
              type: WidthType.DXA,
            },
            shading: {
              type: ShadingType.SOLID,
              color: "c9c9c9",
              fill: "c9c9c9",
            },
            children: [
              new Paragraph({
                spacing: {
                  before: 35,
                  after: 35,
                },
                indent: {
                  left: 60,
                  right: 60,
                },
                children: [
                  new TextRun({
                    text: summary,
                    size: 32,
                    bold: true,
                    color: "1F3864",
                    font: "Arial",
                  }),
                ],
              }),
            ],
          }),
        ],
      }),
      new TableRow({
        children: [
          new TableCell({
            borders: {
              top: {
                style: BorderStyle.NONE,
                size: 0,
                color: "FFFFFF",
              },
              bottom: {
                style: BorderStyle.NONE,
                size: 0,
                color: "FFFFFF",
              },
              left: {
                style: BorderStyle.NONE,
                size: 0,
                color: "FFFFFF",
              },
              right: {
                style: BorderStyle.NONE,
                size: 0,
                color: "FFFFFF",
              },
            },
            width: {
              size: 3000,
              type: WidthType.DXA,
            },
            shading: {
              type: ShadingType.SOLID,
              color: "e6e6e6",
              fill: "e6e6e6",
            },
            children: [
              new Paragraph({
                spacing: {
                  before: 80,
                  after: 70,
                },
                indent: {
                  left: 200,
                  right: 60,
                },
                children: [
                  new ImageRun({
                    data: Uint8Array.from(atob(placeImage), (c) =>
                      c.charCodeAt(0)
                    ),
                    floating: {
                      horizontalPosition: {
                        offset: 134400,
                      },
                      verticalPosition: {
                        offset: 50000,
                      },
                    },
                    transformation: {
                      width: 15,
                      height: 20,
                    },
                  }),
                  new TextRun({
                    text: `\t${place}`,
                    font: "Arial",
                    size: 24,
                  }),
                ],
              }),
              new Paragraph({
                spacing: {
                  before: 80,
                  after: 70,
                },
                indent: {
                  left: 200,
                  right: 60,
                },
                children: [numberPicture, number],
              }),
              ...dataPhone
                .slice(1)
                .map((phone: any) => {
                  const arr: any[] = [];
                  if (!anonymizeNumber) {
                    arr.push(
                      new Paragraph({
                        indent: {
                          left: 200,
                          right: 60,
                        },
                        spacing: {
                          before: 80,
                          after: 70,
                        },
                        children: [
                          new ImageRun({
                            data: Uint8Array.from(atob(phoneImage), (c) =>
                              c.charCodeAt(0)
                            ),
                            // floating: {
                            //   horizontalPosition: {
                            //     offset: 114400,
                            //   },
                            //   verticalPosition: {
                            //     offset: 300000,
                            //   },
                            // },
                            transformation: {
                              width: 17,
                              height: 17,
                            },
                          }),
                          new TextRun({
                            text: `\t${phone.phone}`,
                            size: 24,
                            font: "Arial",
                          }),
                        ],
                      })
                    );
                  }
                  return arr;
                })
                .reduce((prev: any, curr: any) => prev.concat(curr), []),
              new Paragraph({
                spacing: {
                  before: 80,
                  after: 70,
                },
                indent: {
                  left: 200,
                  right: 60,
                },
                children: [emailPicture, email],
              }),
              ...dataEmail
                .slice(1)
                .map((email: any) => {
                  const arr: any[] = [];
                  if (!anonymizeEmail) {
                    arr.push(
                      new Paragraph({
                        indent: {
                          left: 200,
                          right: 60,
                        },
                        spacing: {
                          before: 80,
                          after: 70,
                        },
                        children: [
                          new ImageRun({
                            data: Uint8Array.from(atob(emailImage), (c) =>
                              c.charCodeAt(0)
                            ),
                            // floating: {
                            //   horizontalPosition: {
                            //     offset: 114400,
                            //   },
                            //   verticalPosition: {
                            //     offset: 300000,
                            //   },
                            // },
                            transformation: {
                              width: 17,
                              height: 17,
                            },
                          }),
                          new TextRun({
                            text: `\t${email.email}`,
                            size: 24,
                            font: "Arial",
                          }),
                        ],
                      })
                    );
                  }
                  return arr;
                })
                .reduce((prev: any, curr: any) => prev.concat(curr), []),
            ],
            verticalAlign: VerticalAlign.CENTER,
          }),
          new TableCell({
            borders: {
              top: {
                style: BorderStyle.SINGLE,
                size: 1,
                color: "e6e6e6",
              },
              left: {
                style: BorderStyle.SINGLE,
                size: 1,
                color: "e6e6e6",
              },
              bottom: {
                style: BorderStyle.SINGLE,
                size: 1,
                color: "e6e6e6",
              },
              right: {
                style: BorderStyle.SINGLE,
                size: 1,
                color: "e6e6e6",
              },
            },
            width: {
              size: 7000,
              type: WidthType.DXA,
            },
            shading: {
              type: ShadingType.SOLID,
              color: summaryColor,
              fill: summaryColor,
            },
            children: [
              new Paragraph({
                spacing: {
                  before: 70,
                  after: 70,
                },
                indent: {
                  left: 60,
                  right: 60,
                },
                alignment: AlignmentType.JUSTIFIED,
                children: [
                  new TextRun({
                    text: summaryText,
                    font: "Arial",
                    size: 24,
                  }),
                ],
              }),
            ],
          }),
        ],
      }),
    ],
  });
};
