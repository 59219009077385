import { View, Text, Font } from "@react-pdf/renderer";
import React from "react";
import { Invoicestyles } from "../InvoiceStyles/InvoiceStyle";
import arial from "../../../../assets/fonts/Arial_medium.ttf";
import arialText from "../../../../assets/fonts/arialW.ttf";
import arialBold from "../../../../assets/fonts/Arial_bold.TTF";

Font.register({
  family: "Roboto",
  fonts: [
    {
      src: arial,
    },
    {
      src: arialText,
      fontWeight: 300,
    },
    { src: arialBold, fontWeight: 700 },
  ],
});

interface Props {
  totalPrice: string;
  taxPayer: boolean;
}

const InvoiceSummary: React.FC<Props> = ({ totalPrice, taxPayer }) => {
  return (
    <View
      style={{
        marginTop: taxPayer ? 40 : 100,
        width: "100%",
        display: "flex",
        alignItems: "flex-end",
        justifyContent: "center",
        position: "relative",
      }}
    >
      <Text
        style={[
          Invoicestyles.summaryHeader,
          { fontFamily: "Roboto", fontWeight: 700 },
        ]}
      >
        ZHRNUTIE
      </Text>
      <View
        style={{
          width: "55%",
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "space-between",
          position: "relative",
          flexDirection: "row",
          backgroundColor: "#008db9",
          marginRight: 20,
          paddingLeft: 100,
          paddingTop: 10,
          paddingBottom: 10,
        }}
      >
        <Text
          style={{
            fontSize: 12,
            fontFamily: "Roboto",
            fontWeight: 700,
            lineHeight: 1.6,
            color: "white",
          }}
        >
          Spolu na úhradu:
        </Text>
        <Text
          style={{
            fontSize: 12,
            fontFamily: "Roboto",
            fontWeight: 700,
            lineHeight: 1.6,
            color: "white",
            paddingRight: 10,
          }}
        >
          {Number(Math.round(Number(totalPrice) * 100) / 100).toFixed(2) +
            " EUR"}
        </Text>
      </View>
    </View>
  );
};

export default InvoiceSummary;
