import { View, Text, Font } from "@react-pdf/renderer";
import React from "react";
import arial from "../../../../../assets/fonts/Arial_medium.ttf";
import arialText from "../../../../../assets/fonts/arialW.ttf";
import arialBold from "../../../../../assets/fonts/Arial_bold.TTF";
import TaxInvoiceItemsHeader from "./TaxInvoiceItemsHeader";
import { Invoicestyles } from "../../InvoiceStyles/InvoiceStyle";
import MultiSportCard from "../MultiSportCard";

Font.register({
  family: "Roboto",
  fonts: [
    {
      src: arial,
    },
    {
      src: arialText,
      fontWeight: 300,
    },
    { src: arialBold, fontWeight: 300 },
  ],
});

interface Props {
  list: {
    id: number;
    itemName: string;
    Qty: string;
    price: number;
    total: string;
  }[];
  multiSportCard: boolean;
}

const TaxInvoiceItems: React.FC<Props> = ({ list, multiSportCard }) => {
  return (
    <View style={Invoicestyles.invoiceItems}>
      <Text
        style={[
          Invoicestyles.invoiceHeader,
          { fontFamily: "Roboto", fontWeight: 300 },
        ]}
      >
        FAKTURUJEME VÁM
      </Text>
      <TaxInvoiceItemsHeader />
      {list &&
        list.map((listItem) => (
          <View
            style={{
              width: "100%",
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              flexDirection: "row",
            }}
            key={listItem.id}
          >
            <View
              style={{
                width: "36%",
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                borderBottom: "2px solid #e6e6e6",
                paddingBottom: 2,
                paddingTop: 2,
              }}
            >
              <Text
                style={{
                  fontSize: 8,
                  fontFamily: "Roboto",
                  fontWeight: 300,
                  lineHeight: 1.6,
                }}
              >
                {listItem.itemName}
              </Text>
            </View>
            <View
              style={{
                width: "7%",
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                borderBottom: "2px solid #e6e6e6",
                paddingBottom: 2,
                paddingTop: 2,
              }}
            >
              <Text
                style={{
                  fontSize: 8,
                  fontFamily: "Roboto",
                  fontWeight: 300,
                  lineHeight: 1.6,
                }}
              >
                {listItem.Qty}
              </Text>
            </View>
            <View
              style={{
                width: "5%",
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                borderBottom: "2px solid #e6e6e6",
                paddingBottom: 2,
                paddingTop: 2,
              }}
            >
              <Text
                style={{
                  fontSize: 8,
                  fontFamily: "Roboto",
                  fontWeight: 300,
                  lineHeight: 1.6,
                }}
              >
                mh
              </Text>
            </View>
            <View
              style={{
                width: "11%",
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "flex-start",
                borderBottom: "2px solid #e6e6e6",
                paddingBottom: 2,
                paddingTop: 2,
              }}
            >
              <Text
                style={{
                  fontSize: 8,
                  fontWeight: 300,
                  fontFamily: "Roboto",
                  lineHeight: 1.6,
                }}
              >
                {String(Math.round((listItem.price / 8) * 100) / 100)}
              </Text>
            </View>
            <View
              style={{
                width: "7%",
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "flex-start",
                borderBottom: "2px solid #e6e6e6",
                // marginRight: 20,
                paddingBottom: 2,
                paddingTop: 2,
              }}
            >
              <Text
                style={{
                  fontSize: 8,
                  fontWeight: 300,
                  fontFamily: "Roboto",
                  lineHeight: 1.6,
                }}
              >
                20
              </Text>
            </View>
            <View
              style={{
                width: "10%",
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "flex-start",
                borderBottom: "2px solid #e6e6e6",
                // marginRight: 20,
                paddingBottom: 2,
                paddingTop: 2,
              }}
            >
              <Text
                style={{
                  fontSize: 8,
                  fontWeight: 300,
                  fontFamily: "Roboto",
                  lineHeight: 1.6,
                }}
              >
                {Number(Math.round(Number(listItem.total) * 100) / 100).toFixed(
                  2
                )}
              </Text>
            </View>
            <View
              style={{
                width: "10%",
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "flex-start",
                borderBottom: "2px solid #e6e6e6",
                // marginRight: 20,
                paddingBottom: 2,
                paddingTop: 2,
              }}
            >
              <Text
                style={{
                  fontSize: 8,
                  fontWeight: 300,
                  fontFamily: "Roboto",
                  lineHeight: 1.6,
                }}
              >
                {Number((Number(listItem.total) / 100) * 20).toFixed(2)}
              </Text>
            </View>
            <View
              style={{
                width: "14%",
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "flex-start",
                borderBottom: "2px solid #e6e6e6",
                backgroundColor: "#e6e6e6",
                marginRight: 20,
                paddingTop: 2,
              }}
            >
              <Text
                style={{
                  fontSize: 8.5,
                  fontWeight: 700,
                  fontFamily: "Roboto",
                  lineHeight: 1.6,
                  paddingRight: 5,
                }}
              >
                {getTotalNumber(
                  Number(
                    Number((Number(listItem.total) / 100) * 20).toFixed(2)
                  ),
                  Number(
                    Number(
                      Math.round(Number(listItem.total) * 100) / 100
                    ).toFixed(2)
                  )
                )}
              </Text>
            </View>
          </View>
        ))}
        <MultiSportCard multiSportCard={multiSportCard}/>
    </View>
  );
};

const getTotalNumber = (tax: number, price: number) => {
  return Number(Number(tax) + Number(price)).toFixed(2);
};

export default TaxInvoiceItems;
