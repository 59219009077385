import React, { forwardRef } from "react";
import CheckIcon from "@mui/icons-material/Check";
import "./SortView.scss";

interface Props {
  sort: string;
  setSort: React.Dispatch<React.SetStateAction<string>>;
}

const SortView = forwardRef<HTMLDivElement, Props>(({ sort, setSort }, ref) => {
  return (
    <div className="sort_view" ref={ref}>
      <p>Select sort</p>
      <div className="sort_view_container">
        <button
          className={`option_button ${sort === "rate" ? "active_button" : ""}`}
          onClick={() => setSort((prev) => (prev === "rate" ? "" : "rate"))}
        >
          <p className="button_text">Sort by rate</p>
          <div
            className={`option_button_circle ${
              sort === "rate" ? "active_circle" : ""
            }`}
          >
            <CheckIcon className="check_icon" />
          </div>
        </button>
        <button
          className={`option_button ${
            sort === "availability" ? "active_button" : ""
          }`}
          onClick={() =>
            setSort((prev) => (prev === "availability" ? "" : "availability"))
          }
        >
          <p className="button_text">Sort by availability</p>
          <div
            className={`option_button_circle ${
              sort === "availability" ? "active_circle" : ""
            }`}
          >
            <CheckIcon className="check_icon" />
          </div>
        </button>
      </div>
    </div>
  );
});

export default SortView;
