import React, { useEffect, useState } from "react";

const useIsVisible = (ref: React.RefObject<HTMLDivElement>) => {
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) =>
      setIntersecting(entry.isIntersecting)
    );

    if (ref.current && ref.current instanceof Element) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current && ref.current instanceof Element) {
        observer.unobserve(ref.current);
      }
    };
  }, [ref]);

  return isIntersecting;
};

export default useIsVisible;
