import React, { useState, useEffect } from "react";
import { FilterType } from "../components/interfaces/interfaces";

const useFilterEmployee = (array: any[], filter: FilterType[]) => {
  const [result, setResult] = useState<any>([]);

  useEffect(() => {
    let filteredArray: any[] = [];
    if (filter.length > 0) {
      array.filter((item: any) => {
        filter.every((filter) => {
          //check if filter contains option CONTAINS and filter by value
          if (filter.option === "contains") {
            if (
              item.work_position
                .toLowerCase()
                .includes(filter.value.toLowerCase())
            ) {
              if (
                !filteredArray.find(
                  (filteredArrayElement) => filteredArrayElement.id === item.id
                )
              ) {
                filteredArray.push(item);
              }
            }
          }
          //check if filter contains option IS and filter by value
          if (filter.option === "is") {
            if (item.work_position === filter.value) {
              if (
                !filteredArray.find(
                  (filteredArrayElement) => filteredArrayElement.id === item.id
                )
              ) {
                filteredArray.push(item);
              }
            }
          }
          //check if filter contains option IS NOT and filter by value
          if (filter.option === "is not") {
            if (item.work_position !== filter.value) {
              if (
                !filteredArray.find(
                  (filteredArrayElement) => filteredArrayElement.id === item.id
                )
              ) {
                filteredArray.push(item);
              }
            }
          }
          return item;
        });
      });
      setResult(filteredArray);
    } else {
      setResult(array);
    }
  }, [array, filter]);
  return result;
};

export default useFilterEmployee;
