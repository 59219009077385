import { View, Text, Font } from "@react-pdf/renderer";
import React from "react";
import { styles } from "./PDFStylings";
import arial from "../../../../assets/fonts/ArialMdm.ttf";
import arialText from "../../../../assets/fonts/arialW.ttf";

Font.register({
  family: "Roboto",
  fonts: [
    {
      src: arial,
    },
    {
      src: arialText,
      fontWeight: 300,
    },
  ],
});

export interface Props {
  education: {
    id: number;
    school_name: string;
    field_of_study: string;
    faculty: string;
    study_from: string;
    study_end: string;
    sequence: number;
    description: string;
  }[];
}

const ShowPDFEducation: React.FC<Props> = ({ education }) => {
  return (
    <View style={styles.experiences} wrap={false}>
      <View style={styles.experiences_header}>
        <Text
          style={{
            color: "#1F3864",
            fontSize: "14px",
            backgroundColor: "#c9c9c9",
            textTransform: "uppercase",
            padding: "4px",
            fontFamily: "Roboto",
            fontWeight: "bold",
          }}
        >
          EDUCATION
        </Text>
      </View>
      {education
        .sort((a, b) => (a.sequence > b.sequence ? -1 : 1))
        .map((exp) => (
          <View
            key={exp.id}
            style={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              flexDirection: "row",
              width: "100%",
              // padding: "4px",
              padding: "4px 4px 0 4px",
              borderRight: "1px solid #c9c9c9",
              borderLeft: "1px solid #c9c9c9",
              borderBottom: "1px solid #c9c9c9",
            }}
            wrap={false}
          >
            <View
              style={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                width: "30%",
              }}
            >
              <Text
                style={{
                  fontSize: "12px",
                  fontFamily: "Roboto",
                  fontWeight: 300,
                }}
              >
                {/* {exp.study_from === null && exp.study_end === null
                  ? ""
                  : exp.study_end === null
                  ? exp.study_from + " - "
                  : exp.study_from === null
                  ? " - " + exp.study_end
                  : exp.study_from + " - " + exp.study_end} */}
                {exp.study_from === "" && exp.study_end === ""
                  ? ""
                  : exp.study_from + " - " + exp.study_end}
              </Text>
            </View>
            <View
              style={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                width: "70%",
                flexDirection: "column",
                paddingLeft: "4px",
              }}
            >
              <Text
                style={{
                  fontSize: "12px",
                  padding: "0 2px 2px 0",
                  lineHeight: "1.3px",
                  fontFamily: "Roboto",
                  fontWeight: 300,
                }}
              >
                {exp.school_name}
              </Text>
              <Text
                style={{
                  fontSize: "12px",
                  padding: "0 2px 2px 0",
                  lineHeight: "1.3px",
                  fontFamily: "Roboto",
                  fontWeight: 300,
                }}
              >
                {exp.faculty}
              </Text>
              <Text
                style={{
                  fontSize: "12px",
                  padding: "0 2px 2px 0",
                  lineHeight: "1.3px",
                }}
              >
                {exp.field_of_study}
              </Text>
              {exp.description !== "" && (
                <Text
                  style={{
                    fontSize: "12px",
                    padding: "0 2px 2px 0",
                    lineHeight: "1.3px",
                  }}
                >
                  {exp.description}
                </Text>
              )}
            </View>
          </View>
        ))}
    </View>
  );
};

export default ShowPDFEducation;
