import {
  Paragraph,
  Table,
  TableRow,
  TableCell,
  WidthType,
  AlignmentType,
  TextRun,
  BorderStyle,
  ShadingType,
  TabStopType,
  TabStopPosition,
  SpaceType,
} from "docx";
export const ePComponent = (
  name: string,
  from: string,
  to: string,
  description: string,
  position: string,
  anonymizeExperienceName: boolean
) => {
  const descriptionText = description
    .split("\n")
    .map(
      (line) => new TextRun({ break: 1, text: line, font: "Arial", size: 24 })
    );
  let experienceName = "";
  if (anonymizeExperienceName) {
    experienceName = position;
  } else {
    experienceName = name;
  }

  return new Table({
    columnWidths: [10000],
    rows: [
      new TableRow({
        children: [
          new TableCell({
            borders: {
              top: {
                style: BorderStyle.SINGLE,
                size: 0,
                color: "e6e6e6",
              },
              left: {
                style: BorderStyle.SINGLE,
                size: 0,
                color: "e6e6e6",
              },
              bottom: {
                style: BorderStyle.SINGLE,
                size: 0,
                color: "e6e6e6",
              },
              right: {
                style: BorderStyle.SINGLE,
                size: 0,
                color: "e6e6e6",
              },
            },
            width: {
              size: 10000,
              type: WidthType.DXA,
            },
            shading: {
              type: ShadingType.SOLID,
              color: "e6e6e6",
              fill: "e6e6e6",
            },
            children: [
              new Paragraph({
                spacing: {
                  before: 35,
                  after: 35,
                },
                indent: {
                  left: 60,
                  right: 60,
                },
                tabStops: [
                  {
                    type: TabStopType.RIGHT,
                    position: TabStopPosition.MAX,
                  },
                ],
                children: [
                  new TextRun({
                    text: experienceName,
                    bold: true,
                    size: 28,
                    color: "2f5496",
                  }),
                  new TextRun({
                    space: SpaceType.PRESERVE,

                    text: `\t${from + " - " + to}`,
                    bold: true,
                    size: 28,
                    color: "2f5496",
                  }),
                ],
              }),
            ],
          }),
        ],
      }),
      new TableRow({
        children: [
          new TableCell({
            borders: {
              top: {
                style: BorderStyle.SINGLE,
                size: 0,
                color: "e6e6e6",
              },
              left: {
                style: BorderStyle.SINGLE,
                size: 0,
                color: "e6e6e6",
              },
              bottom: {
                style: BorderStyle.SINGLE,
                size: 0,
                color: "e6e6e6",
              },
              right: {
                style: BorderStyle.SINGLE,
                size: 0,
                color: "e6e6e6",
              },
            },
            width: {
              size: 10000,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                spacing: {
                  before: 35,
                  after: 35,
                },
                indent: {
                  left: 60,
                  right: 60,
                },
                children: [
                  new TextRun({
                    text: position,
                    bold: true,
                    font: "Arial",
                    size: 24,
                  }),
                ],
              }),
              new Paragraph({
                spacing: {
                  before: 35,
                  after: 35,
                },
                indent: {
                  left: 60,
                  right: 60,
                },
                alignment: AlignmentType.LEFT,

                children: descriptionText,
              }),
            ],
          }),
        ],
      }),
    ],
  });
};
