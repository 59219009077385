import React, { useState } from "react";
import { ROLES } from "../../../../../components/Roles/Roles";
import UseAuth from "../../../../../hooks/UseAuth";
import "./CustomToggle.scss";

interface Props {
  checked: boolean;
  setChecked?: React.Dispatch<React.SetStateAction<boolean>>;
  setRecalculateRate?: React.Dispatch<React.SetStateAction<boolean>>;
}

const CustomToggle: React.FC<Props> = ({
  checked,
  setChecked,
  setRecalculateRate,
}) => {
  const { auth } = UseAuth();

  const handleChange = () => {
    if (auth.userRoles === ROLES.ADMIN) {
      if (setChecked) {
        setChecked((prev) => !prev);
      }
      if (setRecalculateRate) {
        setRecalculateRate(false);
      }
    }
  };
  return (
    <div className="toggle-button-cover">
      <div className="button-cover">
        <div className="button r" id="button-3">
          <input
            type="checkbox"
            className="checkbox"
            checked={checked === undefined ? false : checked}
            onChange={handleChange}
          />
          <div className="knobs"></div>
          <div className="layer"></div>
        </div>
      </div>
    </div>
  );
};

export default CustomToggle;
