import { View, Text, Font } from "@react-pdf/renderer";
import React from "react";
import arial from "../../../../../assets/fonts/Arial_medium.ttf";
import arialText from "../../../../../assets/fonts/arialW.ttf";
import arialBold from "../../../../../assets/fonts/Arial_bold.TTF";

Font.register({
  family: "Roboto",
  fonts: [
    {
      src: arial,
    },
    {
      src: arialText,
      fontWeight: 300,
    },
    { src: arialBold, fontWeight: 700 },
  ],
});

const TaxInvoiceItemsHeader = () => {
  return (
    <View
      style={{
        width: "100%",
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        flexDirection: "row",
      }}
    >
      <View
        style={{
          width: "36%",
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          borderBottom: "2px solid #e6e6e6",
          paddingBottom: 2,
        }}
      >
        <Text
          style={{
            fontSize: 8,
            fontFamily: "Roboto",
            fontWeight: 700,
            lineHeight: 1.6,
          }}
        >
          Označenie dodávky
        </Text>
      </View>
      <View
        style={{
          width: "7%",
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          borderBottom: "2px solid #e6e6e6",
          paddingBottom: 2,
        }}
      >
        <Text
          style={{
            fontSize: 8,
            fontFamily: "Roboto",
            fontWeight: 700,
            lineHeight: 1.6,
          }}
        >
          Počet
        </Text>
      </View>
      <View
        style={{
          width: "5%",
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          borderBottom: "2px solid #e6e6e6",
          paddingBottom: 2,
        }}
      >
        <Text
          style={{
            fontSize: 8,
            fontFamily: "Roboto",
            fontWeight: 700,
            lineHeight: 1.6,
          }}
        >
          m. j .
        </Text>
      </View>
      <View
        style={{
          width: "11%",
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "flex-start",
          borderBottom: "2px solid #e6e6e6",
          paddingBottom: 2,
        }}
      >
        <Text
          style={{
            fontSize: 8,
            fontWeight: 700,
            fontFamily: "Roboto",
            lineHeight: 1.6,
          }}
        >
          Cena za m.j .
        </Text>
      </View>
      <View
        style={{
          width: "7%",
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "flex-start",
          borderBottom: "2px solid #e6e6e6",
          // marginRight: 20,
          paddingBottom: 2,
        }}
      >
        <Text
          style={{
            fontSize: 8,
            fontWeight: 700,
            fontFamily: "Roboto",
            lineHeight: 1.6,
          }}
        >
          DPH %
        </Text>
      </View>
      <View
        style={{
          width: "10%",
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "flex-start",
          borderBottom: "2px solid #e6e6e6",
          // marginRight: 20,
          paddingBottom: 2,
        }}
      >
        <Text
          style={{
            fontSize: 8,
            fontWeight: 700,
            fontFamily: "Roboto",
            lineHeight: 1.6,
          }}
        >
          Bez DPH
        </Text>
      </View>
      <View
        style={{
          width: "10%",
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "flex-start",
          borderBottom: "2px solid #e6e6e6",
          // marginRight: 20,
          paddingBottom: 2,
        }}
      >
        <Text
          style={{
            fontSize: 8,
            fontWeight: 700,
            fontFamily: "Roboto",
            lineHeight: 1.6,
          }}
        >
          DPH
        </Text>
      </View>
      <View
        style={{
          width: "14%",
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "flex-start",
          borderBottom: "2px solid #e6e6e6",
          marginRight: 20,
          paddingBottom: 2,
        }}
      >
        <Text
          style={{
            fontSize: 8,
            fontWeight: 700,
            fontFamily: "Roboto",
            lineHeight: 1.6,
          }}
        >
          Spolu
        </Text>
      </View>
    </View>
  );
};

export default TaxInvoiceItemsHeader;
