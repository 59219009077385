import React from "react";
import "./UserDashboardSmallWidget.scss";

interface Props {
  children: React.ReactNode;
}

const UserDashboardSmallWidget: React.FC<Props> = ({ children }) => {
  return <div className="user_dashboard_small_widget">{children}</div>;
};

export default UserDashboardSmallWidget;
