import React, { useEffect, useState } from "react";
import { ClientProjects } from "../components/interfaces/interfaces";

const useFindNextRateDate = (
  clientProjects: ClientProjects[],
  nextRate: number | undefined
) => {
  const [data, setData] = useState<string | undefined>(undefined);

  useEffect(() => {
    const nextRateObject = clientProjects?.find(
      (project) => Number(project.rate) === nextRate
    );
    setData(nextRateObject?.startingFrom);
  }, [clientProjects, nextRate]);
  return data;
};

export default useFindNextRateDate;
