import { PDFSettings } from "../interfaces/interfaces";

export enum actTypes {
  SET_USER = "SET_USER",
  PAGE_NUMBER = "SET_PAGE_NUMBER",
  EXPIRATION_COUNT = "SET_EXPIRATION_COUNT",
  STATUS_FILTER = "SET_STATUS_FILTER",
  SET_PDF_SETTINGS = "SET_PDF_SETTINGS",
  SET_SELECTED_MAIL = "SET_SELECTED_MAIL",
  SET_SELECTED_NUMBER = "SET_SELECTED_NUMBER",
  SET_INVOICE_DATA = "SET_INVOICE_DATA",
  SET_MENU = "SET_MENU",
  SET_EMPLOYEE_VIEW = "SET_EMPLOYEE_VIEW",
  SET_SEARCH_BAR = "SET_SEARCH_BAR",
}
export interface IuserAuth {
  user: boolean;
  page: number;
  expirationCount: number;
  statusFilter: string[];
  PDFSettings: PDFSettings;
  selectedNumber: string;
  selectedEmail: string;
  invoice: InvoicePDFData;
}
interface IActionType {
  type: actTypes;
  user: boolean;
  page: number;
  expirationCount: number;
  statusFilter: string[];
  PDFSettings: PDFSettings;
  selectedNumber: string;
  selectedEmail: string;
  invoice: InvoicePDFData;
  menu: MenuType;
  employeeView: string;
  search: string;
}

type InvoicePDFData = {
  name: string;
  city: string;
  zipCode: string;
  address: string;
  country: string;
  businessID: string;
  taxID: string;
  email: string;
  number: string;
  invoiceDate: string;
  iban: string;
  swift: string;
  constantSymbol: string;
  variableSymbol: string;
  specificSymbol: string;
  list: ListType[];
  invoiceNumber: string;
  taxPayer: boolean;
};

type ListType = {
  id: number;
  itemName: string;
  Qty: string;
  price: number;
  total: string;
};

type MenuType = {
  menu: string;
};

const reducer = (state: IuserAuth, action: IActionType) => {
  switch (action.type) {
    case actTypes.SET_USER:
      return { ...state, user: action.user };
    case actTypes.PAGE_NUMBER:
      return { ...state, page: action.page };
    case actTypes.EXPIRATION_COUNT:
      return { ...state, expirationCount: action.expirationCount };
    case actTypes.STATUS_FILTER:
      return { ...state, statusFilter: action.statusFilter };
    case actTypes.SET_PDF_SETTINGS:
      return { ...state, PDFSettings: action.PDFSettings };
    case actTypes.SET_SELECTED_MAIL:
      return { ...state, selectedEmail: action.selectedEmail };
    case actTypes.SET_SELECTED_NUMBER:
      return { ...state, selectedNumber: action.selectedNumber };
    case actTypes.SET_INVOICE_DATA:
      return { ...state, invoice: action.invoice };
    case actTypes.SET_MENU:
      return { ...state, menu: action.menu };
    case actTypes.SET_EMPLOYEE_VIEW:
      return { ...state, employeeView: action.employeeView };
    case actTypes.SET_SEARCH_BAR:
      return { ...state, search: action.search };
    default:
      return state;
  }
};

export default reducer;
