import { View, Text, Font, Image } from "@react-pdf/renderer";
import React from "react";
import { Invoicestyles } from "../InvoiceStyles/InvoiceStyle";
import arial from "../../../../assets/fonts/Arial_medium.ttf";
import arialText from "../../../../assets/fonts/arialW.ttf";
import arialBold from "../../../../assets/fonts/Arial_bold.TTF";

Font.register({
  family: "Roboto",
  fonts: [
    {
      src: arial,
    },
    {
      src: arialText,
      fontWeight: 300,
    },
    { src: arialBold, fontWeight: 700 },
  ],
});

interface Props {
  totalPrice: string;
  iban: string;
  swift: string;
  variableSymbol: string;
  specificSymbol: string;
  constantSymbol: string;
  qrCode: string;
}

const BillingInfo: React.FC<Props> = ({
  totalPrice,
  iban,
  constantSymbol,
  specificSymbol,
  swift,
  variableSymbol,
  qrCode,
}) => {
  return (
    <View
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        flexDirection: "row",
      }}
    >
      <View style={Invoicestyles.invoiceBilling}>
        <Text
          style={[
            Invoicestyles.billing_header,
            { fontFamily: "Roboto", fontWeight: 700 },
          ]}
        >
          PLATOBNÉ ÚDAJE
        </Text>
        <View style={Invoicestyles.invoiceBilling_column}>
          <Text
            style={{
              fontSize: 9,
              fontWeight: 300,
              fontFamily: "Roboto",
              color: "#88eaf0",
              lineHeight: 1.6,
              paddingTop: 11,
              paddingBottom: 5,
            }}
          >
            Bankový účet
          </Text>
          <Text
            style={{
              fontSize: 8.5,
              fontWeight: 300,
              fontFamily: "Roboto",
              color: "white",
              lineHeight: 1.4,
            }}
          >
            IBAN: {iban}
          </Text>
          <Text
            style={{
              fontSize: 8.5,
              fontWeight: 300,
              fontFamily: "Roboto",
              color: "white",
              lineHeight: 1.4,
            }}
          >
            SWIFT: {swift}
          </Text>
        </View>
        <View style={Invoicestyles.invoiceBilling_column}>
          <Text
            style={{
              fontSize: 9,
              fontWeight: 300,
              fontFamily: "Roboto",
              color: "#88eaf0",
              lineHeight: 1.6,
              paddingTop: 11,
              paddingBottom: 5,
            }}
          >
            Symbol
          </Text>
          <Text
            style={{
              fontSize: 8.5,
              fontWeight: 300,
              fontFamily: "Roboto",
              color: "white",
              lineHeight: 1.4,
            }}
          >
            variabilný:{"       "}
            {variableSymbol}
          </Text>
          <Text
            style={{
              fontSize: 8.5,
              fontWeight: 300,
              fontFamily: "Roboto",
              color: "white",
              lineHeight: 1.4,
            }}
          >
            konštantný:{"     "}
            {constantSymbol}
          </Text>
        </View>
        <View style={Invoicestyles.invoiceBilling_lastColumn}>
          <Text
            style={{
              fontSize: 9,
              fontWeight: 300,
              fontFamily: "Roboto",
              color: "white",
              lineHeight: 1.6,
              paddingTop: 11,
              paddingBottom: 5,
            }}
          >
            Spôsob platby: Prevodom
          </Text>
          <View
            style={{
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "flex-end",
            }}
          >
            <Text
              style={{
                fontSize: 9,
                fontWeight: 300,
                fontFamily: "Roboto",
                color: "#88eaf0",
                paddingTop: 11,
                lineHeight: 1.6,
                textAlign: "right",
              }}
            >
              Na úhradu
            </Text>
            <Text
              style={{
                fontSize: 12,
                fontWeight: 700,
                fontFamily: "Roboto",
                lineHeight: 2,
                color: "white",
                marginTop: 2,
                paddingBottom: 5,
                textAlign: "right",
              }}
            >
              {Number(Math.round(Number(totalPrice) * 100) / 100).toFixed(2)}{" "}
              EUR
            </Text>
          </View>
        </View>
      </View>
      <View
        style={{
          width: 100,
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          paddingTop: 10,
          paddingLeft: 5,
        }}
      >
        <Image
          src={`data:image/png;base64,${qrCode}`}
          style={{ width: 93, height: 93, objectFit: "contain" }}
        />
      </View>
    </View>
  );
};

export default BillingInfo;
