import dayjs from "dayjs";
import React, { useState, useEffect } from "react";

const useSortEmployee = (array: any, sort: string) => {
  const [result, setResult] = useState<any>([]);

  useEffect(() => {
    if (sort === "rate") {
      const sortedRate = array.slice().sort((a: any, b: any) => {
        if (a.expectedSalary === "") return 1;
        if (b.expectedSalary === "") return -1;
        return a.expectedSalary - b.expectedSalary;
      });
      setResult(sortedRate);
    }
    if (sort === "availability") {
      setResult(
        array.slice().sort((a: any, b: any) => {
          if (a.availableDate === "") return 1;
          if (b.availableDate === "") return -1;
          return (
            +dayjs(a.availableDate).valueOf() -
            +dayjs(b.availableDate).valueOf()
          );
        })
      );
    }
    if (sort === "") setResult(array);
  }, [array, sort]);
  return result;
};

export default useSortEmployee;
