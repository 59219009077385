import React, {useEffect, useState} from "react";
import {actTypes} from "../../../components/Context/Reducer";
import {UserAuth} from "../../../components/Context/StateProvider";
import UseAuth from "../../../hooks/UseAuth";
import AddInvoiceButtons from "./AddInvoiceButtons/AddInvoiceButtons";
import AddInvoiceTo from "./AddInvoiceTo/AddInvoiceTo";
import ContactInforInvoice from "./ContactInforInvoice/ContactInforInvoice";
import InvoiceList from "./InvoiceList/InvoiceList";
import "./NewInvoice.scss";
import NewInvoiceFrom from "./NewInvoiceFrom/NewInvoiceFrom";
import NewInvoiceHeader from "./NewInvoiceHeader";
import PaymentInfo from "./PaymentInfo/PaymentInfo";
import {ROLES} from "../../../components/Roles/Roles";
import moment from "moment";
import {useParams} from "react-router-dom";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {createInvoice, updateInvoice} from "../../../api/clientApi";
import {getSettings} from "../../../api/settingsApi";

interface Props {
    setopenNewInvoice: React.Dispatch<React.SetStateAction<boolean>>;
    setSameInvoice: React.Dispatch<React.SetStateAction<boolean>>;
    sameInvoice: boolean;
    allInvoices?: String[];
    allVariableNumbers?: String[];
    multiSportCard: boolean;
    invoice: {
        address: string;
        businessId: string;
        city: string;
        constantSymbol: string;
        country: string;
        iban: string;
        id: number;
        invoiceDate: string;
        email: string;
        invoiceNumber: string;
        invoices: ListType[];
        name: string;
        phoneNumber: string;
        rate: string;
        specificSymbol: string;
        status: string;
        swift: string;
        taxId: string;
        totalPrice: string;
        variableSymbol: string;
        zipCode: string;
        clientId: string;
        byod: boolean;
        supplier: string;
        paymentTerms: number;
        taxPayer: boolean;
        multiSportCard: boolean;
    };
}

type ListType = {
    id: number;
    itemName: string;
    Qty: string;
    price: number;
    total: string;
};

const NewInvoice: React.FC<Props> = ({
                                         setopenNewInvoice,
                                         invoice,
                                         setSameInvoice,
                                         sameInvoice,
                                         allInvoices,
                                         allVariableNumbers,
                                         multiSportCard
                                     }) => {
    const {id} = useParams();
    const [{}, dispatch] = UserAuth();
    const queryClient = useQueryClient();
    console.log(multiSportCard);
    const {auth} = UseAuth();
    const role = auth.userRoles;

    const [name, setName] = useState(invoice.name);
    const [city, setCity] = useState(invoice.city);
    const [zipCode, setZipCode] = useState(invoice.zipCode);
    const [address, setAddress] = useState(invoice.address);
    const [country, setCountry] = useState(invoice.country);
    const [businessID, setBusinessID] = useState(invoice.businessId);
    const [taxID, setTaxID] = useState(invoice.taxId);
    const [invoiceNumber, setInvoiceNumber] = useState(invoice.invoiceNumber);
    const [status, setStatus] = useState(invoice.status);
    const [supplier, setSupplier] = useState(invoice.name);
    const [taxPayer, setTaxPayer] = useState(invoice.taxPayer);
    const [paymentTerms, setPaymentTerms] = useState<number>(
        invoice.paymentTerms || 30
    );
    const generalSettings = useQuery(["byod"], getSettings, {
        refetchOnWindowFocus: false,
        retry: 0,
    });
    const [rate, setRate] = useState("0");

    useEffect(() => {
        if (generalSettings.isSuccess) {
            setRate(
                invoice?.byod
                    ? String(
                        Number(invoice?.rate) + Number(generalSettings?.data[0]?.byod)
                    )
                    : invoice?.rate
            );
        }
    }, [
        generalSettings?.data,
        generalSettings.isSuccess,
        invoice?.byod,
        invoice?.rate,
    ]);

    const [email, setEmail] = useState(invoice.email);
    const [number, setNumber] = useState(invoice.phoneNumber);

    const [invoiceDate, setInvoiceDate] = useState(
        moment(invoice.invoiceDate).format("YYYY-MM")
    );

    const [iban, setIban] = useState(invoice.iban);
    const [swift, setSwift] = useState(invoice.swift);
    const [constantSymbol, setConstantSymbol] = useState(invoice.constantSymbol);
    const [variableSymbol, setVariableSymbol] = useState(invoice.variableSymbol);
    const [specificSymbol, setSpecificSymbol] = useState(invoice.specificSymbol);
    const [list, setList] = useState<ListType[]>(invoice.invoices);

    //Updating invoice
    const updateInvoiceMutation = useMutation(updateInvoice, {
        onSuccess: () => {
            // Invalidates cache and refetch
            queryClient.invalidateQueries("invoices");
        },
        onError: () => {
        },
    });

    //Create invoice
    const createInvoiceMutation = useMutation(createInvoice, {
        onSuccess: () => {
            // Invalidates cache and refetch
            queryClient.invalidateQueries("invoices");
        },
        onError: () => {
        },
    });

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!sameInvoice) {
            const totalPrice: number = list
                .map((item) => Number(item.total))
                .reduce(function (a, b) {
                    return Number(a) + Number(b);
                });
            const data = {
                id: Number(id),
                name,
                city,
                zipCode,
                address,
                country,
                businessId: businessID,
                taxId: taxID,
                email,
                phoneNumber: number,
                invoiceDate,
                iban,
                swift,
                constantSymbol,
                variableSymbol,
                specificSymbol,
                list: JSON.stringify(list),
                invoiceNumber,
                status,
                totalPrice: String(totalPrice),
                userId: Number(invoice.id),
                paymentTerms,
                taxPayer: taxPayer,
                multiSportCard: multiSportCard
            };
            dispatch({
                type: actTypes.SET_INVOICE_DATA,
                invoice: data,
            });

            localStorage.setItem("InvoicePDF", JSON.stringify(data));
            if (id === undefined) {
                const preparedData = {
                    invoiceNumber,
                    invoiceDate,
                    status,
                    variableSymbol,
                    constantSymbol,
                    specificSymbol,
                    totalPrice: String(totalPrice),
                    userId: Number(invoice.id),
                    list: JSON.stringify(list),
                    supplier,
                    paymentTerms,
                };
                createInvoiceMutation.mutate(preparedData);
            } else {
                const preparedData = {
                    id: Number(id),
                    invoiceNumber,
                    invoiceDate,
                    status,
                    variableSymbol,
                    constantSymbol,
                    specificSymbol,
                    totalPrice: String(totalPrice),
                    userId: Number(invoice.clientId),
                    list: JSON.stringify(list),
                    supplier,
                    paymentTerms,
                };
                updateInvoiceMutation.mutate(preparedData);
            }
            window.open(`/invoice/pdf`, "_blank", "noopener,noreferrer");
            setopenNewInvoice(false);
        }
    };

    useEffect(() => {
        if (allInvoices && allVariableNumbers) {
            // if (
            //   (allInvoices.includes(invoiceNumber) ||
            //     allVariableNumbers.includes(variableSymbol)) &&
            //   (invoice.invoiceNumber > invoiceNumber ||
            //     invoice.variableSymbol > variableSymbol)
            // ) {
            //   setSameInvoice(true);
            // } else {
            //   setSameInvoice(false);
            // }
            if (id) {
                if (
                    (invoice.invoiceNumber !== invoiceNumber &&
                        allInvoices.includes(invoiceNumber)) ||
                    (invoice.variableSymbol !== variableSymbol &&
                        allVariableNumbers.includes(variableSymbol))
                ) {
                    setSameInvoice(true);
                } else {
                    setSameInvoice(false);
                }
            } else {
                if (
                    allInvoices.includes(invoiceNumber) ||
                    allVariableNumbers.includes(variableSymbol)
                ) {
                    setSameInvoice(true);
                } else {
                    setSameInvoice(false);
                }
            }
        }
    }, [
        allInvoices,
        allVariableNumbers,
        id,
        invoice.invoiceNumber,
        invoice.variableSymbol,
        invoiceNumber,
        setSameInvoice,
        variableSymbol,
    ]);

    return (
        <div className="invoice_add">
            <div className="invoice_add_header">
                <h2>Create Invoice</h2>
                {role === ROLES.ADMIN && (
                    <div className="basic_information_input">
                        <select
                            name="contractType"
                            value={status}
                            placeholder="Status"
                            onChange={(e) => {
                                setStatus(e.target.value);
                            }}
                        >
                            <option value="PENDING" defaultValue={"PAID"}>
                                Pending
                            </option>
                            <option value="PAID">Paid</option>
                            <option value="REJECTED">Rejected</option>
                        </select>
                    </div>
                )}
            </div>
            <form className="invoice_add_form" onSubmit={handleSubmit}>
                <NewInvoiceHeader text="Bill From"/>
                <NewInvoiceFrom
                    address={address}
                    businessID={businessID}
                    city={city}
                    country={country}
                    name={name}
                    setAddress={setAddress}
                    setBusinessID={setBusinessID}
                    setCity={setCity}
                    setCountry={setCountry}
                    setName={setName}
                    setTaxID={setTaxID}
                    setZipCode={setZipCode}
                    taxID={taxID}
                    zipCode={zipCode}
                    invoiceNumber={invoiceNumber}
                    setInvoiceNumber={setInvoiceNumber}
                />
                <NewInvoiceHeader text="Contact Info"/>
                <ContactInforInvoice
                    email={email}
                    number={number}
                    setEmail={setEmail}
                    setNumber={setNumber}
                />
                <NewInvoiceHeader text="Bill To"/>
                <AddInvoiceTo
                    invoiceDate={invoiceDate}
                    setInvoiceDate={setInvoiceDate}
                    paymentTerms={paymentTerms}
                    setPaymentTerms={setPaymentTerms}
                    role={role}
                />
                <NewInvoiceHeader text="Payment Info"/>
                <PaymentInfo
                    iban={iban}
                    swift={swift}
                    constantSymbol={constantSymbol}
                    variableSymbol={variableSymbol}
                    specificSymbol={specificSymbol}
                    setIban={setIban}
                    setSwift={setSwift}
                    setConstantSymbol={setConstantSymbol}
                    setVariableSymbol={setVariableSymbol}
                    setSpecificSymbol={setSpecificSymbol}
                />
                <NewInvoiceHeader text="Item List"/>
                <InvoiceList
                    list={list}
                    setList={setList}
                    rate={rate}
                    byod={invoice?.byod}
                />
                <AddInvoiceButtons
                    setopenNewInvoice={setopenNewInvoice}
                    setInvoiceError={setSameInvoice}
                />
            </form>
        </div>
    );
};

export default NewInvoice;
