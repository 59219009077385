import React, { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  getClient,
  getClientSettings,
  updateClient,
} from "../../../api/clientApi";
import CancelButton from "../../../components/Buttons/Cancel/CancelButton";
import SubmitButton from "../../../components/Buttons/Submit/SubmitButton";
import PasswordIcon from "@mui/icons-material/Password";
import UseAuth from "../../../hooks/UseAuth";
import "./ChangeUserSettings.scss";
import { Tooltip } from "@mui/material";
import ChangePasswordModal from "../Users/ChangePasswordModal/ChangePasswordModal";
import Notification from "../../../components/Notification/Notification";
import CustomToggle from "../Users/UserInfoModal/CustomToggle/CustomToggle";
import {
  ClientProjects,
  ISection,
} from "../../../components/interfaces/interfaces";
import UserInfoModalHeader from "../Users/UserInfoModal/UserInfoModalHeader/UserInfoModalHeader";
import Collapse from "@mui/material/Collapse";
import useCurrentAndNextRate from "../../../hooks/useCurrentAndNextRate";
import useFindNextRateDate from "../../../hooks/useFindNextRateDate";
import dayjs from "dayjs";

const ChangeUserSettings = () => {
  const queryClient = useQueryClient();
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [street, setStreet] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [iban, setIban] = useState("");
  const [swift, setSwift] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [businessId, setBusinessId] = useState("");
  const [taxId, setTaxId] = useState("");
  const [rate, setRate] = useState("");
  const [byod, setByod] = useState<boolean>(false);
  const [contractExpirationDate, setContractExpirationDate] = useState("");
  const [multiSportCard, setmultiSportCard] = useState<boolean>(false);
  const [accompanyingMultiSportCard, setAccompanyingMultiSportCard] =
    useState<boolean>(false);
  const [clientProjects, setClientProjects] = useState<ClientProjects[]>([]);
  const [taxPayer, setTaxPayer] = useState<boolean>(false);

  const { auth } = UseAuth();
  const [openChangePassword, setOpenChangePassword] = useState(false);

  //custom hooks
  const rates = useCurrentAndNextRate(clientProjects);
  const nextRateDate = useFindNextRateDate(clientProjects, rates.nextRate);

  //Notification
  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");
  const [displayNotification, setDisplayNotification] = useState(false);

  //Sections
  const [displayUserCredentials, setdisplayUserCredentials] =
    useState<ISection>({ angle: 180, display: true });
  const [displayBusinessInformation, setDisplayBusinessInformation] =
    useState<ISection>({ angle: 180, display: true });
  const [displayPersonalInformation, setDisplayPersonalInformation] =
    useState<ISection>({ angle: 180, display: true });
  const [displayPaymentInformation, setDisplayPaymentInformation] =
    useState<ISection>({ angle: 180, display: true });
  const [displayRateInformation, setDisplayRateInformation] =
    useState<ISection>({ angle: 180, display: true });
  const [
    displayContractExpirationInformation,
    setDisplayContractExpirationInformation,
  ] = useState<ISection>({ angle: 180, display: true });
  const [displayContractInformation, setDisplayContractInformation] =
    useState<ISection>({ angle: 180, display: true });
  const [multisportCardSection, setmultisportCardSection] = useState<ISection>({
    angle: 180,
    display: true,
  });

  const renderNotification = (Message: string, Error: boolean) => {
    setError(Error);
    setMessage(Message);
    setDisplayNotification(true);
    const timeId = setTimeout(() => {
      // After 3 seconds set the show value to false
      setDisplayNotification(false);
    }, 4000);

    return () => {
      clearTimeout(timeId);
    };
  };

  const { data, isFetched } = useQuery(
    ["user", auth.userName],
    () => getClient(auth.userName),
    {
      refetchOnWindowFocus: false,
      retry: 0,
      onSuccess(data) {
        setEmail(data.email);
        setPhoneNumber(data.phoneNumber);
        setCountry(data.country);
        setCity(data.city);
        setStreet(data.address);
        setZipCode(data.zipCode);
        setBusinessName(data.name);
        setBusinessId(data.businessId);
        setTaxId(data.taxId);
        setIban(data.iban);
        setSwift(data.swift);
        setRate(data.rate);
        setByod(data.byod);
        setTaxPayer(data.taxPayer);
        setContractExpirationDate(data.contractExpirationDate);
        setmultiSportCard(data.multiSportCard);
        setAccompanyingMultiSportCard(data.accompanyingMultiSportCard);
        setClientProjects(data.clientProjects);
      },
    }
  );

  const { data: user } = useQuery(
    ["user_settings"],
    () => getClientSettings(Number(data.id)),
    {
      enabled: isFetched,
      refetchOnWindowFocus: false,
      retry: 0,
    }
  );

  //Updating user
  const updateUserMutation = useMutation(updateClient, {
    onSuccess: () => {
      // Invalidates cache and refetch
      queryClient.invalidateQueries("user");
      renderNotification("User updated", true);
    },
    onError: () => {
      renderNotification("Something went wrong", false);
    },
  });

  const handleUpdateUser = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const payload = {
      address: street,
      businessId,
      city,
      country,
      email,
      iban,
      id: data.id,
      invoices: data.invoices,
      name: businessName,
      phoneNumber,
      rate,
      swift,
      taxId,
      userName: data.userName,
      zipCode,
      password: user.password,
      role: user.role,
      byod,
      contractExpirationDate,
      multiSportCard,
      accompanyingMultiSportCard,
      clientProjects,
      taxPayer,
    };
    updateUserMutation.mutate(payload);
  };
  const handleClose = () => {
    console.log("cancel");
  };
  return (
    <div className="changeUserSettings">
      {displayNotification ? (
        <Notification message={message} error={error} />
      ) : null}
      <UserInfoModalHeader
        headerText="Account Settings"
        displayUserCredentials={displayUserCredentials}
        setdisplayUserCredentials={setdisplayUserCredentials}
      />
      {isFetched && (
        <form onSubmit={(e) => handleUpdateUser(e)}>
          <div className="changeUserSettings_container">
            <Collapse
              in={displayUserCredentials.display}
              style={{ width: "100%" }}
            >
              <div className="changeUserSettings_container_row">
                <div className="changeUserSettings_container_row_column">
                  <label>Username</label>
                  <input
                    style={{ width: "60%" }}
                    defaultValue={data.userName}
                    type="text"
                    disabled
                  />
                </div>
                <div className="changeUserSettings_container_row_column">
                  <label>Password</label>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      gap: "1rem",
                    }}
                  >
                    <input
                      style={{ width: "60%" }}
                      type="password"
                      defaultValue={"password"}
                      disabled
                    />
                    <Tooltip title="Change Password">
                      <PasswordIcon
                        onClick={() => setOpenChangePassword(true)}
                      />
                    </Tooltip>
                  </div>
                </div>
              </div>
            </Collapse>
            <UserInfoModalHeader
              headerText="Contact information"
              displayUserCredentials={displayContractInformation}
              setdisplayUserCredentials={setDisplayContractInformation}
            />
            <Collapse
              in={displayContractInformation.display}
              style={{ width: "100%" }}
            >
              <div className="changeUserSettings_container_row">
                <div className="changeUserSettings_container_row_column">
                  <label>Email</label>
                  <input
                    style={{ width: "60%" }}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    type="text"
                  />
                </div>
                <div className="changeUserSettings_container_row_column">
                  <label>Phone Number</label>
                  <input
                    style={{ width: "60%" }}
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    type="text"
                  />
                </div>
              </div>
            </Collapse>
            <UserInfoModalHeader
              headerText="Address information"
              displayUserCredentials={displayPersonalInformation}
              setdisplayUserCredentials={setDisplayPersonalInformation}
            />
            <Collapse
              in={displayPersonalInformation.display}
              style={{ width: "100%" }}
            >
              <div className="changeUserSettings_container_row">
                <div className="changeUserSettings_container_row_column">
                  <label>Country</label>
                  <input
                    style={{ width: "60%" }}
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                    type="text"
                  />
                </div>
                <div className="changeUserSettings_container_row_column">
                  <label>City</label>
                  <input
                    style={{ width: "60%" }}
                    type="text"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                  />
                </div>
              </div>
              <div className="changeUserSettings_container_row">
                <div className="changeUserSettings_container_row_column">
                  <label>Street</label>
                  <input
                    style={{ width: "60%" }}
                    value={street}
                    onChange={(e) => setStreet(e.target.value)}
                    type="text"
                  />
                </div>
                <div className="changeUserSettings_container_row_column">
                  <label>Zip Code</label>
                  <input
                    style={{ width: "60%" }}
                    type="text"
                    value={zipCode}
                    onChange={(e) => setZipCode(e.target.value)}
                  />
                </div>
              </div>
            </Collapse>
            <UserInfoModalHeader
              headerText="Business information"
              displayUserCredentials={displayBusinessInformation}
              setdisplayUserCredentials={setDisplayBusinessInformation}
            />
            <Collapse
              in={displayBusinessInformation.display}
              style={{ width: "100%" }}
            >
              <div className="changeUserSettings_container_row">
                <div className="changeUserSettings_container_row_column">
                  <label>Business Name</label>
                  <input
                    style={{ width: "60%" }}
                    value={businessName}
                    onChange={(e) => setBusinessName(e.target.value)}
                    type="text"
                  />
                </div>
                <div className="changeUserSettings_container_row_column">
                  <label>Business ID</label>
                  <input
                    style={{ width: "60%" }}
                    type="text"
                    value={businessId}
                    onChange={(e) => setBusinessId(e.target.value)}
                  />
                </div>
              </div>
              <div className="changeUserSettings_container_row">
                <div className="changeUserSettings_container_row_column">
                  <label>Tax ID</label>
                  <input
                    style={{ width: "30%" }}
                    value={taxId}
                    onChange={(e) => setTaxId(e.target.value)}
                    type="text"
                  />
                </div>
                <div className="changeUserSettings_container_row_column">
                  <label>Tax Payer</label>
                  <CustomToggle checked={taxPayer} />
                </div>
              </div>
            </Collapse>
            <UserInfoModalHeader
              headerText="Payment information"
              displayUserCredentials={displayPaymentInformation}
              setdisplayUserCredentials={setDisplayPaymentInformation}
            />
            <Collapse
              in={displayPaymentInformation.display}
              style={{ width: "100%" }}
            >
              <div className="changeUserSettings_container_row">
                <div className="changeUserSettings_container_row_column">
                  <label>Iban</label>
                  <input
                    style={{ width: "60%" }}
                    value={iban}
                    onChange={(e) => setIban(e.target.value)}
                    type="text"
                  />
                </div>
                <div className="changeUserSettings_container_row_column">
                  <label>Swift</label>
                  <input
                    style={{ width: "60%" }}
                    type="text"
                    value={swift}
                    onChange={(e) => setSwift(e.target.value)}
                  />
                </div>
              </div>
            </Collapse>
            <UserInfoModalHeader
              headerText="Multisport card"
              displayUserCredentials={multisportCardSection}
              setdisplayUserCredentials={setmultisportCardSection}
            />
            <Collapse
              in={multisportCardSection.display}
              style={{ width: "100%" }}
            >
              <div className="changeUserSettings_container_row">
                <div className="changeUserSettings_container_row_column">
                  <label>Multisport card</label>
                  <CustomToggle checked={multiSportCard} />
                </div>
                <div className="changeUserSettings_container_row_column">
                  <label>Accompanying card</label>
                  <CustomToggle checked={accompanyingMultiSportCard} />
                </div>
              </div>
            </Collapse>
            <UserInfoModalHeader
              headerText="Rate information"
              displayUserCredentials={displayRateInformation}
              setdisplayUserCredentials={setDisplayRateInformation}
            />
            <Collapse
              in={displayRateInformation.display}
              style={{ width: "100%" }}
            >
              <div className="changeUserSettings_container_row">
                <div className="changeUserSettings_container_row_column">
                  <label>Current Rate</label>
                  <input
                    style={{ width: "80%" }}
                    defaultValue={rates.currentRate}
                    type="text"
                    disabled
                    className="disabled_rate"
                  />
                </div>
                {(rates.nextRate || !isNaN(rates.nextRate!)) &&
                  nextRateDate && (
                    <div className="changeUserSettings_container_row_column">
                      <label>
                        Next Rate from{" "}
                        <label style={{ fontWeight: "bold", color: "#008db9" }}>
                          {dayjs(nextRateDate).format("DD.MM.YYYY")}
                        </label>
                      </label>
                      <input
                        style={{ width: "80%" }}
                        defaultValue={rates.nextRate}
                        type="text"
                        disabled
                        className="disabled_next_rate"
                      />
                    </div>
                  )}
                <div className="changeUserSettings_container_row_column">
                  <label>Bring Your Own Device</label>
                  <CustomToggle checked={byod} />
                </div>
              </div>
            </Collapse>
            <UserInfoModalHeader
              headerText="Contract information"
              displayUserCredentials={displayContractExpirationInformation}
              setdisplayUserCredentials={
                setDisplayContractExpirationInformation
              }
            />
            <Collapse
              in={displayContractExpirationInformation.display}
              style={{ width: "100%" }}
            >
              <div className="changeUserSettings_container_row">
                <div className="changeUserSettings_container_row_column">
                  <label>Contract Expiration Date</label>
                  <input
                    style={{ width: "30%" }}
                    defaultValue={contractExpirationDate}
                    type="text"
                    disabled
                  />
                </div>
              </div>
            </Collapse>
          </div>
          <div className="update_account_settings">
            <SubmitButton type="submit">Update</SubmitButton>
            <CancelButton onClick={handleClose} type={"button"}>
              Cancel
            </CancelButton>
          </div>
        </form>
      )}

      {openChangePassword && (
        <ChangePasswordModal
          id={data.id}
          name={data.userName}
          open={openChangePassword}
          setOpen={setOpenChangePassword}
        />
      )}
    </div>
  );
};

export default ChangeUserSettings;
