import React from "react";
import "./CVTable.scss";
import CVTableBody from "./CVTableBody";
import CVTableHead from "./CVTableHead";

export interface Props {
  tableData: {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    place: string;
    status: string;
    work_position: string;
    exp_salary: string;
    exp_date: string;
    gdpr: number;
  }[];
  heading: string[];
  setTableData: React.Dispatch<React.SetStateAction<any>>;
  setConfirmDelete: React.Dispatch<React.SetStateAction<boolean>>;
  setDeleteID: React.Dispatch<React.SetStateAction<any>>;
}

const CVTable: React.FC<Props> = ({
  tableData,
  setTableData,
  heading,
  setConfirmDelete,
  setDeleteID,
}) => {
  return (
    <>
      <table className="cv_table">
        <CVTableHead CVHeading={heading} />
        <CVTableBody
          CVData={tableData}
          setCVData={setTableData}
          setConfirmDelete={setConfirmDelete}
          setDeleteID={setDeleteID}
        />
      </table>
    </>
  );
};

export default CVTable;
