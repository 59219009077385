import Box from "@mui/material/Box/Box";
import { DataGrid } from "@mui/x-data-grid/DataGrid/DataGrid";
import Tooltip from "@mui/material/Tooltip";
import React, { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { getClientsSettings } from "../../../api/clientApi";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import "./Users.scss";
import UserInfoModal from "./UserInfoModal/UserInfoModal";
import DeleteUserModal from "./DeleteUserModal/DeleteUserModal";
import ChangePasswordModal from "./ChangePasswordModal/ChangePasswordModal";
import EditIcon from "@mui/icons-material/Edit";
import PasswordIcon from "@mui/icons-material/Password";

const Users = () => {
  const [editUser, setEditUser] = useState(false);
  const [deleteUser, setDeleteUser] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  const [userName, setUserName] = useState("");
  const [id, setId] = useState<number | undefined>();

  //fetch users
  const {
    data: users,
    isFetched: usersFetched,
    refetch: userRefetch,
  } = useQuery(["users"], getClientsSettings, {
    refetchOnWindowFocus: true,
    retry: 0,
  });

  //get username from ID
  useEffect(() => {
    if (usersFetched) {
      setUserName(
        users.map((user: any) => {
          if (Number(user.id) === Number(id)) {
            return user.userName;
          }
        })
      );
    }
  }, [id]);

  //refetch after edit,delete, password change
  useEffect(() => {
    userRefetch();
  }, [editUser, deleteUser, changePassword]);

  const columns = useMemo(
    () => [
      { field: "name", headerName: "Name", width: 160, flex: 1, minWidth: 100 },
      {
        field: "userName",
        headerName: "User Name",
        width: 160,
        flex: 1,
        minWidth: 160,
      },
      { field: "rate", headerName: "Rate", width: 160, flex: 1, minWidth: 160 },
      { field: "role", headerName: "ROLE", width: 160, flex: 1, minWidth: 160 },
      {
        field: "contractExpirationDate",
        headerName: "Contract Expiration",
        width: 160,
        flex: 1,
        minWidth: 160,
      },
      {
        field: "actions",
        headerName: "Actions",
        width: 160,
        minWidth: 160,
        sortable: false,
        filterable: false,
        renderCell: (params: any) => (
          <>
            <Tooltip title="Update User">
              <EditIcon
                onClick={() => {
                  setId(params.id);
                  setEditUser(true);
                }}
                className="edit_icon"
              />
            </Tooltip>
            {params.row.userName !== "kodiva" && (
              <Tooltip title="Remove User">
                <PersonRemoveIcon
                  onClick={() => {
                    setId(params.id);
                    setDeleteUser(true);
                  }}
                  className="delete_icon"
                  data-tip
                  data-for={"deleteIcon"}
                />
              </Tooltip>
            )}
            <Tooltip title="Change Password">
              <PasswordIcon
                onClick={() => {
                  setId(params.id);
                  setChangePassword(true);
                }}
                className="password_icon"
              />
            </Tooltip>
          </>
        ),
      },
    ],
    []
  );

  return (
    <div className="users">
      <Box className="users_box">
        {usersFetched && (
          <DataGrid
            columns={columns}
            rows={users}
            className="users_table"
            sx={{
              backgroundColor: "white",
              width: "100%",
              boxShadow: 6,
            }}
            getRowClassName={() => "users_table_row"}
            getCellClassName={() => "users_table_cell"}
          ></DataGrid>
        )}
      </Box>
      {editUser && (
        <UserInfoModal open={editUser} setOpen={setEditUser} id={id} />
      )}
      {deleteUser && (
        <DeleteUserModal
          open={deleteUser}
          setOpen={setDeleteUser}
          id={id}
          name={userName}
        />
      )}
      {changePassword && (
        <ChangePasswordModal
          open={changePassword}
          setOpen={setChangePassword}
          id={id}
          name={userName}
        />
      )}
    </div>
  );
};

export default Users;
