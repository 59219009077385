import React, { useEffect, useState } from "react";
import { ClientProjects } from "../components/interfaces/interfaces";

interface UseRatesReturnType {
  currentRate: number;
  nextRate: number;
}
const useCurrentAndNextRate = (clientProjects: ClientProjects[]) => {
  const [currentRate, setCurrentRate] = useState<number | undefined>(undefined);
  const [nextRate, setNextRate] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (clientProjects?.length === 0) {
      return;
    }

    // sort the rates array by startingFrom date in ascending order
    if (clientProjects) {
      const sortedRates = clientProjects.slice().sort((a, b) => {
        return (
          new Date(a.startingFrom).getTime() -
          new Date(b.startingFrom).getTime()
        );
      });

      // loop through the rates to find the current rate
      let currentRate: number | undefined = undefined;
      let currentIndex = -1;
      for (let i = 0; i < sortedRates.length; i++) {
        const currentStartingFrom = new Date(sortedRates[i].startingFrom);
        const currentDate = new Date();

        if (currentDate >= currentStartingFrom) {
          // current date is after or equal to startingFrom date of this rate, set current rate
          setCurrentRate(parseFloat(sortedRates[i].rate));
          currentRate = parseFloat(sortedRates[i].rate);
          currentIndex = i;
        } else {
          // current date is before startingFrom date of this rate, set next rate
          setNextRate(parseFloat(sortedRates[i].rate));
          break;
        }
      }

      // if we haven't found the current rate yet, set it to the undefined
      if (currentIndex === -1 && sortedRates.length > 0) {
        setCurrentRate(undefined);
        currentRate = undefined;
        currentIndex = sortedRates.length - 1;
      }

      // find the closest next rate
      let nextIndex = currentIndex + 1;
      while (nextIndex < sortedRates.length) {
        if (sortedRates[nextIndex].rate !== undefined) {
          setNextRate(parseFloat(sortedRates[nextIndex].rate));
          break;
        }
        nextIndex++;
      }
      if (currentRate === undefined) {
        setNextRate(parseFloat(sortedRates[0].rate));
        nextIndex = -1;
      }

      // if we have reached the end of the array and haven't found the next rate, set it to undefined
      if (nextIndex === sortedRates.length) {
        setNextRate(undefined);
      }
    }
  }, [clientProjects]);

  return { currentRate, nextRate };
};

export default useCurrentAndNextRate;
