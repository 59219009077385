import TextField from "@mui/material/TextField/TextField";
import {
  CalendarPickerView,
  StaticDatePicker,
} from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import React, { useState } from "react";
import "./CustomDatePicker.scss";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider/LocalizationProvider";

interface Props {
  value: Dayjs | null;
  setValue: React.Dispatch<React.SetStateAction<dayjs.Dayjs | null>>;
  allInvoices: boolean;
  setAllInvoices: React.Dispatch<React.SetStateAction<boolean>>;
  divRef: React.RefObject<HTMLDivElement>;
}

const CustomDatePicker: React.FC<Props> = ({
  value,
  setValue,
  allInvoices,
  setAllInvoices,
  divRef,
}) => {
  const [view, setView] = useState<CalendarPickerView[]>(["month", "year"]);

  return (
    <div className="customDatePicker" ref={divRef}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <StaticDatePicker
          className={`${allInvoices && "date_picker"}`}
          label="Date of invoices"
          views={view}
          value={value}
          onYearChange={() => setView(["month", "year"])}
          onChange={(newValue) => {
            setValue(newValue);
            setAllInvoices(false);
          }}
          renderInput={(props) => (
            <TextField {...props} style={{ backgroundColor: "red" }} />
          )}
          showToolbar={false}
          OpenPickerButtonProps={{ disabled: true }}
        />
      </LocalizationProvider>
      <div
        className="customDatePicker_all"
        onClick={() => {
          setValue(null);
          setAllInvoices(true);
        }}
      >
        All
      </div>
    </div>
  );
};

export default CustomDatePicker;
