import React from "react";
import "./CustomEvent.scss";
import moment from "moment";

interface Props {
  event: {
    end: Date;
    id: number;
    title: string;
    start: Date;
  };
}

const CustomEvent: React.FC<Props> = (props) => {
  const start = moment(props.event.start).format("HH:mm");
  const end = moment(props.event.end).format("HH:mm");
  return (
    <div className="customEvent">
      <span className="customEvent_text">{props.event.title}</span>
      <span className="customEvent_time">
        {start} - {end}
      </span>
    </div>
  );
};

export default CustomEvent;
