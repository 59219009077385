import moment from "moment";
import React from "react";
import "./InvoiceDetailsBodyHeader.scss";

interface Props {
  invoice: {
    address: string;
    businessId: string;
    city: string;
    constantSymbol: string;
    country: string;
    iban: string;
    id: number;
    invoiceDate: string;
    email: string;
    invoiceNumber: string;
    list: string;
    name: string;
    phoneNumber: string;
    rate: string;
    specificSymbol: string;
    status: string;
    swift: string;
    taxId: string;
    totalPrice: string;
    variableSymbol: string;
    zipCode: string;
  };
}

const InvoiceDetailsBodyHeader: React.FC<Props> = ({ invoice }) => {
  const nextMonth = moment(invoice.invoiceDate).add(1, "M");
  return (
    <>
      <div className="invoiceDetails_body_header">
        <div className="invoiceDetails_body_header_id">
          <h3>
            <span style={{ color: "#008db9" }}>#</span>
            {invoice.invoiceNumber}
          </h3>
          <p className="text">{invoice.name}</p>
        </div>
        <div className="invoiceDetails_body_header_address">
          <p className="text">{invoice.address}</p>
          <p className="text">{invoice.city}</p>
          <p className="text">{invoice.zipCode}</p>
          <p className="text">{invoice.country}</p>
        </div>
      </div>
      <div className="invoiceDetails_body_info">
        <div className="invoiceDetails_body_info_dates">
          <div className="invoiceDteails_body_info_dates_date">
            <p className="text">
              Delivery Date
            </p>
            <h4>{moment(invoice.invoiceDate).endOf('month').format("DD.MM.YYYY")}</h4>
          </div>
          <div className="invoiceDteails_body_info_dates_date">
            <p className="text">Invoice Date</p>
            <h4>{moment(nextMonth).startOf('month').format("DD.MM.YYYY")}</h4>
          </div>
          <div className="invoiceDteails_body_info_dates_date">
            <p className="text">
              Due Date
            </p>
            <h4>{moment(invoice.invoiceDate).add(1,'M').add(30,'day').format("DD.MM.YYYY")}</h4>
          </div>
        </div>
        <div className="invoiceDetails_body_info_billTo">
          <p className="text">
            Bill To
          </p>
          <h4>Kodiva s.r.o.</h4>
          <p className="text">Karpatské námestie 7770/10A</p>
          <p className="text">Bratislava - mestská časť Rača</p>
          <p className="text">831 06</p>
          <p className="text">Slovensko</p>
        </div>
        <div className="invoiceDetails_body_info_contact">
          <p className="text" style={{ paddingBottom: "7px" }}>
            Contact Information
          </p>
          <h4>{invoice.email}</h4>
          <h4>{invoice.phoneNumber}</h4>
        </div>
      </div>
    </>
  );
};

export default InvoiceDetailsBodyHeader;
