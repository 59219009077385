import { View, Text, Image, Font } from "@react-pdf/renderer";
import React from "react";
import { styles } from "./PDFStylings";

import placeImage from "../../../../assets/images/place.png";
import phone from "../../../../assets/images/phone.png";
import emailImage from "../../../../assets/images/mail.png";

import arial from "../../../../assets/fonts/Arial_medium.ttf";
import arial_italic from "../../../../assets/fonts/arial_italic.TTF";
import arialText from "../../../../assets/fonts/arialW.ttf";
import { CandidateNumbers } from "../../../../components/interfaces/interfaces";
import {
  PDF_type,
  CandidateEmails,
} from "../../../../components/interfaces/interfaces";

Font.register({
  family: "Roboto",
  fonts: [
    {
      src: arial,
    },
    {
      src: arial_italic,
      fontStyle: "italic",
    },
    {
      src: arialText,
      fontWeight: 300,
    },
  ],
});

interface Props {
  email: string;
  place: string;
  number: string;
  summary: string;
  PDFType: string;
  region: string;
  anonymizeNumber: boolean;
  anonymizeRegion: boolean;
  anonymizeEmail: boolean;
  anonymizeSummary: boolean;
  selectedEmail: string;
  selectedNumber: string;
}

const ShowPDFSummary: React.FC<Props> = ({
  email,
  place,
  number,
  region,
  summary,
  PDFType,
  anonymizeNumber,
  anonymizeRegion,
  anonymizeEmail,
  anonymizeSummary,
  selectedEmail,
  selectedNumber,
}) => {
  return (
    <View
      style={[
        styles.details_summary,
        { backgroundColor: "rgba(128, 128, 128, 0.2)" },
      ]}
    >
      <View
        style={[
          styles.details,
          {
            width: `${summary === "" || anonymizeSummary ? "100%" : "40%"}
            `,
            height: "100%",
            position: "relative",
          },
        ]}
      >
        <Text
          style={{
            color: "#1F3864",
            fontWeight: "bold",
            fontSize: "14px",
            width: "100%",
            backgroundColor: "#c9c9c9",
            textTransform: "uppercase",
            padding: "4px",
            fontFamily: "Roboto",
          }}
        >
          DETAILS
        </Text>
        <View
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
            {anonymizeRegion && region === "" ? (null):(
                <View
                    style={{
                        width: "100%",
                        height: `${PDFType !== PDF_type.normal ? "25px" : "25px"}`,
                        paddingLeft: "7px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        flexDirection: "row",
                    }}
                >
                    <Image
                        src={placeImage}
                        style={{
                            width: "12px",
                            height: "12px",
                            objectFit: "contain",
                        }}
                    />
                    <Text
                        style={{
                            marginLeft: "10px",
                            fontSize: "12px",
                            color: "black",
                            fontFamily: "Roboto",
                            fontWeight: 300,
                        }}
                    >
                        {anonymizeRegion
                            ? region
                            : PDFType !== PDF_type.normal
                                ? region
                                : place}
                    </Text>
                </View>
            )}
          {anonymizeNumber &&
          selectedNumber === "" ? null : !anonymizeNumber ? (
            JSON.parse(number).map(
              (numberElement: CandidateNumbers, index: number) => (
                <View
                  style={{
                    width: "100%",
                    height: "25px",
                    paddingLeft: "7px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    flexDirection: "row",
                  }}
                  key={index}
                >
                  <Image
                    src={phone}
                    style={{
                      width: "12px",
                      height: "12px",
                      objectFit: "contain",
                    }}
                  />
                  <Text
                    style={{
                      marginLeft: "10px",
                      fontSize: "12px",
                      color: "black",
                      fontFamily: "Roboto",
                      fontWeight: 300,
                    }}
                  >
                    {numberElement.phone.charCodeAt(4) !== 32
                      ? numberElement.phone.replace(
                          /^(.{4})(.{3})(.{3})(.*)$/,
                          "$1 $2 $3 $4"
                        )
                      : numberElement.phone}
                  </Text>
                </View>
              )
            )
          ) : (
            <View
              style={{
                width: "100%",
                height: "25px",
                paddingLeft: "7px",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                flexDirection: "row",
              }}
            >
              <Image
                src={phone}
                style={{
                  width: "12px",
                  height: "12px",
                  objectFit: "contain",
                }}
              />
              <Text
                style={{
                  marginLeft: "10px",
                  fontSize: "12px",
                  color: "black",
                  fontFamily: "Roboto",
                  fontWeight: 300,
                }}
              >
                {selectedNumber.charCodeAt(4) !== 32
                  ? selectedNumber.replace(
                      /^(.{4})(.{3})(.{3})(.*)$/,
                      "$1 $2 $3 $4"
                    )
                  : selectedNumber}
              </Text>
            </View>
          )}
          {anonymizeEmail && selectedEmail === "" ? null : !anonymizeEmail ? (
            JSON.parse(email).map(
              (emailElement: CandidateEmails, index: number) => (
                <View
                  style={{
                    width: "100%",
                    height: "25px",
                    paddingLeft: "7px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    flexDirection: "row",
                  }}
                  key={index}
                >
                  <Image
                    src={emailImage}
                    style={{
                      width: "12px",
                      height: "12px",
                      objectFit: "contain",
                    }}
                  />
                  <Text
                    style={{
                      marginLeft: "10px",
                      fontSize: "12px",
                      color: "black",
                      fontFamily: "Roboto",
                      fontWeight: 300,
                    }}
                  >
                    {emailElement.email}
                  </Text>
                </View>
              )
            )
          ) : (
            <View
              style={{
                width: "100%",
                height: "25px",
                paddingLeft: "7px",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                flexDirection: "row",
              }}
            >
              <Image
                src={emailImage}
                style={{
                  width: "12px",
                  height: "12px",
                  objectFit: "contain",
                }}
              />
              <Text
                style={{
                  marginLeft: "10px",
                  fontSize: "12px",
                  color: "black",
                  fontFamily: "Roboto",
                  fontWeight: 300,
                }}
              >
                {selectedEmail}
              </Text>
            </View>
          )}
        </View>
      </View>
      {summary !== "" && anonymizeSummary === false ? (
        <View
          style={[
            styles.detailsSummary,
            { width: "60%", backgroundColor: "white", height: "100%" },
          ]}
        >
          <Text
            style={{
              color: "#1F3864",
              fontWeight: "bold",
              fontSize: "14px",
              width: "100%",
              backgroundColor: "#c9c9c9",
              textTransform: "uppercase",
              padding: "4px",
              fontFamily: "Roboto",
            }}
          >
            SUMMARY
          </Text>
          <View
            style={{
              width: "100%",
              backgroundColor: "transparent",
              paddingLeft: "2px",
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              flexDirection: "row",
              paddingTop: "2px",
            }}
          >
            <Text
              style={{
                fontSize: "12px",
                color: "black",
                lineHeight: "1.3px",
                fontFamily: "Roboto",
                fontStyle: "italic",
                textAlign: "justify",
                paddingRight: "3px",
              }}
            >
              {summary}
            </Text>
          </View>
        </View>
      ) : null}
    </View>
  );
};

export default ShowPDFSummary;
