import moment from "moment";
import React, { useEffect, useState } from "react";

interface Event {
  event_id: number;
  id: string;
  title: string;
  start: string;
  end: string;
  description?: string;
}

const useGetEvents = () => {
  const [result, setResult] = useState<Event[]>([]);
  const calendarID = "aberesik%40gmail.com";
  const apiKey = "AIzaSyAGsmn9HANE7Gf6bdJp8O5ZNkagAZyLTV8";

  useEffect(() => {
    const getEvents = () => {
      async function initiate() {
        await gapi.client
          .init({
            apiKey: apiKey,
            clientId:
              "725912353387-8nrn8io4olll3bb8vij3prno570hn80o.apps.googleusercontent.com",
            scope:
              "https://www.googleapis.com/auth/calendar/auth/calendar.events",
          })
          .then(function () {
            return gapi.client.request({
              path: `https://www.googleapis.com/calendar/v3/calendars/${calendarID}/events`,
            });
          })
          .then(
            (response) => {
              const events = response.result.items;
              let eventsReworked: any[] = [];
              // eslint-disable-next-line array-callback-return
              events.map((event: any, index: number) => {
                let i = 0;
                eventsReworked.push({
                  event_id: index,
                  id: event.id,
                  title: event.summary,
                  start: new Date(moment(event.start.dateTime).toDate()),
                  end: new Date(moment(event.end.dateTime).toDate()),
                  description: event.description,
                });
              });
              setResult(eventsReworked);
            },
            function (err) {
              return [false, err];
            }
          );
      }
      gapi.load("client:auth2", initiate);
    };
    getEvents();
  }, []);
  return result;
};

export default useGetEvents;
