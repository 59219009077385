import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { authUser } from "../../api/authApi";
import "./Login.scss";

import logo from "../../assets/images/kodiva_logo_simple.png";
import { UserAuth } from "../../components/Context/StateProvider";
import { actTypes } from "../../components/Context/Reducer";
import { useNavigate } from "react-router-dom";
import Notification from "../../components/Notification/Notification";
import UseAuth from "../../hooks/UseAuth";
import { ROLES } from "../../components/Roles/Roles";
import { UserRoles } from "../../components/interfaces/interfaces";

const Login = () => {
  const { auth } = UseAuth();
  const navigate = useNavigate();
  const [{ user }, dispatch] = UserAuth();

  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [enabled, setEnabled] = useState(false);
  const { refetch } = useQuery(
    ["authToken", name, password],
    () => authUser({ name, password }),
    {
      enabled: enabled,
      retry: 0,
      onSuccess: (payload) => {
        localStorage.setItem("token", payload);
        localStorage.setItem("user", "true");
        dispatch({
          type: actTypes.SET_USER,
          user: true,
        });
        clearForm();
      },
      onError: () => {
        renderNotification("Wrong credentials", false);
      },
    }
  );

  const [message, setMessage] = useState("");
  const [error, setError] = useState(false);
  const [displayNotification, setDisplayNotification] = useState(false);

  const credentials = { username: "kodiva", userPassword: "password" };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    checkLogin();
    setEnabled(false);
  };

  const renderNotification = (Message: string, Error: boolean) => {
    setError(Error);
    setMessage(Message);
    setDisplayNotification(true);
    const timeId = setTimeout(() => {
      // After 3 seconds set the show value to false
      setDisplayNotification(false);
    }, 4000);

    return () => {
      clearTimeout(timeId);
    };
  };

  const clearForm = () => {
    setName("");
    setPassword("");
  };
  const checkLogin = async () => {
    if (credentials.username !== "" && credentials.userPassword !== "") {
      setEnabled(true);
      await refetch();
      return true;
    }
    return false;
  };
  useEffect(() => {
    if (user === true) {
      if (auth.userRoles === ROLES.ADMIN || auth.userRoles === ROLES.USER) {
        localStorage.setItem("navbar", "Dashboard");
        navigate("/dashboard");
        dispatch({
          type: actTypes.SET_MENU,
          menu: "Dashboard",
        });
      } else {
        localStorage.setItem("navbar", "Employees");
        navigate("/employees");
        dispatch({
          type: actTypes.SET_MENU,
          menu: "Employees",
        });
      }
    }
  }, [navigate, user]);
  return (
    <section className="login">
      {displayNotification ? (
        <Notification message={message} error={error} />
      ) : null}
      <div className="login_container">
        <div className="login_container_header">
          <img src={logo} alt="Kodiva logo" />
          <h1>Kodiva</h1>
        </div>
        <form className="login_container_auth-form" onSubmit={handleSubmit}>
          <h3>Sign into your account</h3>
          <div className="login_container_auth-form_username">
            <input
              type={"text"}
              required
              name="username"
              value={name}
              onChange={(event) => setName(event.target.value)}
            />
            <label htmlFor="username" className="text">
              Username
            </label>
          </div>
          <div className="login_container_auth-form_password">
            <input
              type={"password"}
              required
              name="password"
              value={password}
              onChange={(event) => setPassword(event.target.value)}
            />
            <label htmlFor="password" className="text">
              Password
            </label>
          </div>
          <button type={"submit"}>LOGIN</button>
        </form>
      </div>
    </section>
  );
};

export default Login;
