import React from "react";
import "./GDPRHead.scss";

export interface Props {
  GDPRHeaders: string[];
}

const GDPRHead: React.FC<Props> = ({ GDPRHeaders }) => {
  return (
    <thead>
      <tr>
        {GDPRHeaders.map((head, index) => (
          <th key={index}>{head}</th>
        ))}
      </tr>
    </thead>
  );
};

export default GDPRHead;
