import Box from "@mui/material/Box/Box";
import Modal from "@mui/material/Modal/Modal";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { CalendarPicker } from "@mui/x-date-pickers/CalendarPicker/CalendarPicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider/LocalizationProvider";
import {
  PickersDay,
  PickersDayProps,
} from "@mui/x-date-pickers/PickersDay/PickersDay";
import dayjs, { Dayjs } from "dayjs";
import React, { useState } from "react";
import { EventTypeSelected } from "../../../../components/interfaces/interfaces";
import "./MonthCalendar.scss";
import { useEffect } from "react";

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  events: EventTypeSelected[];
}

const MonthCalendar: React.FC<Props> = (props) => {
  const [date, setDate] = useState<Dayjs | null>(dayjs());
  const [dateSelectedEvents, setDateSelectedEvents] = useState<
    EventTypeSelected[]
  >([]);

  const handleClose = () => {
    props.setOpen(false);
  };

  const renderEvent = (day: Dayjs) => {
    const events = props.events.filter((event) => {
      if (
        dayjs(event.start).format("DD/MM/YYYY") === day.format("DD/MM/YYYY")
      ) {
        return event;
      }
    });
    return events;
  };

  const CustomDayCellRenderer = (
    day: Dayjs,
    selectedDates: Dayjs[],
    pickersDayProps: PickersDayProps<Dayjs>
  ) => {
    const dayOfMonth = dayjs(day).format("D");
    const events = renderEvent(day);
    return (
      <PickersDay {...pickersDayProps}>
        <div className="dayOfMonth">
          <span>{dayOfMonth}</span>
          <div style={{ marginRight: 5 }} className="dot_container">
            {events.map((event) => (
              <div className="dot" key={event.id} />
            ))}
          </div>
        </div>
      </PickersDay>
    );
  };

  useEffect(() => {
    const getEventsForDay = () => {
      const events = props.events.filter((event) => {
        if (
          dayjs(event.start).format("DD/MM/YYYY") === date?.format("DD/MM/YYYY")
        ) {
          return event;
        }
      });
      return events;
    };
    setDateSelectedEvents(getEventsForDay());
  }, [date, props.events]);

  const randomColor = () => {
    return `rgba(${Math.floor(Math.random() * 256)}, ${Math.floor(
      Math.random() * 256
    )}, ${Math.floor(Math.random() * 256)}, 0.5)`;
  };

  return (
    <Modal open={props.open} onClose={handleClose}>
      <Box className="displayMonth_modal">
        <div className="displayMonth_modal_container">
          <p className="header">Events</p>
          <div className="displayMonth_modal_container_header">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <CalendarPicker
                date={date}
                onChange={(newDate) => {
                  setDate(newDate);
                }}
                renderDay={CustomDayCellRenderer}
              />
            </LocalizationProvider>
          </div>
          {dateSelectedEvents && dateSelectedEvents.length > 0 && (
            <div className="displayMonth_modal_container_events">
              {dateSelectedEvents.map((event) => (
                <div
                  key={event.event_id}
                  className="displayMonth_modal_container_event"
                  style={{ background: randomColor() }}
                >
                  <div className="displayMonth_modal_container_event-title-description">
                    <p>{event.title}</p>
                    <p>
                      {event.description !== undefined
                        ? event.description
                        : "No description"}
                    </p>
                  </div>
                  <p>
                    {dayjs(event.start).format("HH:mm")} -{" "}
                    {dayjs(event.end).format("HH:mm")}
                  </p>
                </div>
              ))}
            </div>
          )}
        </div>
      </Box>
    </Modal>
  );
};

export default MonthCalendar;
