import React, {useCallback} from "react";
import "./InvoiceDetailsBody.scss";
import InvoiceDetailsBodyHeader from "./InvoiceDetailsBodyHeader/InvoiceDetailsBodyHeader";

interface Props {
    invoice: {
        address: string;
        businessId: string;
        city: string;
        constantSymbol: string;
        country: string;
        iban: string;
        id: number;
        invoiceDate: string;
        invoiceNumber: string;
        list: string;
        name: string;
        email: string;
        phoneNumber: string;
        rate: string;
        specificSymbol: string;
        status: string;
        swift: string;
        taxId: string;
        totalPrice: string;
        variableSymbol: string;
        zipCode: string;
    }
    multiSportCard?:boolean
}

const InvoiceDetailsBody: React.FC<Props> = ({invoice, multiSportCard}) => {
    const list = JSON.parse(invoice.list);

    const totalWithMultiSportCard = (price:number)=>{
        return Number(Number(price) - (multiSportCard ? 20 : 0)).toFixed(2);
    };

    return (
        <div className="invoiceDetails_body">
            <InvoiceDetailsBodyHeader invoice={invoice}/>
            <div className="invoiceDetails_body_items">
                <table>
                    <thead>
                    <tr>
                        <th>Item Name</th>
                        <th>Hours</th>
                        <th>Price</th>
                        <th>Total</th>
                    </tr>
                    </thead>
                    <tbody>
                    {list &&
                        list.map((listElement: any) => (
                            <tr key={listElement.id}>
                                <td>{listElement.itemName}</td>
                                <td>{listElement.Qty}</td>
                                <td>{(Number(listElement.price) / 8).toFixed(2)}</td>
                                <td>{Number(listElement.total).toFixed(2)}</td>
                            </tr>
                        ))}
                    {multiSportCard && (
                        <tr>
                            <td>MultiSport Card</td>
                            <td>1</td>
                            <td>20</td>
                            <td>-20</td>
                        </tr>
                    )}
                    </tbody>
                    <tfoot>
                    <tr>
                        <td>Amount Due</td>
                        <td></td>
                        <td></td>
                        <td>€{totalWithMultiSportCard(Number(invoice.totalPrice))}</td>
                    </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    );
};

export default InvoiceDetailsBody;
