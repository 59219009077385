import React from "react";
import "./NewInvoiceFrom.scss";

interface Props {
  name: string;
  city: string;
  zipCode: string;
  address: string;
  country: string;
  businessID: string;
  taxID: string;
  invoiceNumber: string;

  setName: React.Dispatch<React.SetStateAction<string>>;
  setCity: React.Dispatch<React.SetStateAction<string>>;
  setZipCode: React.Dispatch<React.SetStateAction<string>>;
  setAddress: React.Dispatch<React.SetStateAction<string>>;
  setCountry: React.Dispatch<React.SetStateAction<string>>;
  setBusinessID: React.Dispatch<React.SetStateAction<string>>;
  setTaxID: React.Dispatch<React.SetStateAction<string>>;
  setInvoiceNumber: React.Dispatch<React.SetStateAction<string>>;
}

const NewInvoiceFrom: React.FC<Props> = ({
  name,
  city,
  zipCode,
  address,
  country,
  businessID,
  taxID,
  invoiceNumber,
  setName,
  setCity,
  setZipCode,
  setAddress,
  setCountry,
  setBusinessID,
  setTaxID,
  setInvoiceNumber,
}) => {
  return (
    <div className="invoice_add_from">
      <div className="invoice_add_from_element_container">
        <div className="invoice_add_from_element">
          <label htmlFor="firstname">Name</label>
          <input
            type={"text"}
            required
            disabled
            style={{ width: "100%" }}
            name="firstname"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
        </div>
      </div>
      <div className="invoice_add_from_element_container">
        <div className="invoice_add_from_element">
          <label htmlFor="city">City</label>
          <input
            type={"text"}
            required
            style={{ width: "100%" }}
            name="city"
            value={city}
            onChange={(e) => {
              setCity(e.target.value);
            }}
            disabled
          />
        </div>
        <div className="invoice_add_from_element">
          <label htmlFor="zipcode">Zipcode</label>
          <input
            type={"text"}
            required
            disabled
            style={{ width: "100%" }}
            name="zipcode"
            value={zipCode}
            onChange={(e) => {
              setZipCode(e.target.value);
            }}
          />
        </div>
      </div>
      <div className="invoice_add_from_element_container">
        <div className="invoice_add_from_element">
          <label htmlFor="address">Address</label>
          <input
            type={"text"}
            required
            style={{ width: "100%" }}
            name="address"
            disabled
            value={address}
            onChange={(e) => {
              setAddress(e.target.value);
            }}
          />
        </div>
        <div className="invoice_add_from_element">
          <label htmlFor="country">Country</label>
          <input
            type={"text"}
            required
            disabled
            style={{ width: "100%" }}
            name="country"
            value={country}
            onChange={(e) => {
              setCountry(e.target.value);
            }}
          />
        </div>
      </div>
      <div className="invoice_add_from_element_container">
        <div className="invoice_add_from_element">
          <label htmlFor="businessID">Business ID</label>
          <input
            type={"text"}
            required
            disabled
            style={{ width: "100%" }}
            name="businessID"
            value={businessID}
            onChange={(e) => {
              setBusinessID(e.target.value);
            }}
          />
        </div>
        <div className="invoice_add_from_element">
          <label htmlFor="taxID">Tax ID</label>
          <input
            type={"text"}
            required
            disabled
            style={{ width: "100%" }}
            name="taxID"
            value={taxID}
            onChange={(e) => {
              setTaxID(e.target.value);
            }}
          />
        </div>
      </div>
      <div className="invoice_add_from_element_container">
        <div className="invoice_add_from_element">
          <label htmlFor="invoiceNumber">Invoice Number</label>
          <input
            type={"text"}
            required
            style={{ width: "100%" }}
            name="invoiceNumber"
            value={invoiceNumber}
            onChange={(e) => {
              setInvoiceNumber(e.target.value);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default NewInvoiceFrom;
