import React from "react";
import Description from "../Description/Description";
import Title from "../Title/Title";
import "./AdditionalInformation.scss";

export interface Props {
  expectedSalary: string;
  startDate: string;
  disabled: boolean;

  setexpectedSalary: React.Dispatch<React.SetStateAction<any>>;
  setstartDate: React.Dispatch<React.SetStateAction<any>>;
  setChanged: React.Dispatch<React.SetStateAction<boolean>>;
}

const AdditionalInformation: React.FC<Props> = ({
  expectedSalary,
  startDate,
  disabled,
  setexpectedSalary,
  setstartDate,
  setChanged,
}) => {
  return (
    <div className="additional_information">
      <div className="additional_information_body">
        <div className="additional_information_body_description">
          <Title title="Additional information" />
          <Description description="Additional information about salary and possible start date expectations." />
        </div>
        <div className="additional_information_body_input_container">
          <div className="additional_information_input">
            <label htmlFor="expectedSalary">Expected Salary</label>
            <input
              type={"text"}
              style={{ width: "33%" }}
              name="expectedSalary"
              value={expectedSalary}
              onChange={(e) => {
                setexpectedSalary(e.currentTarget.value);
                setChanged(true);
              }}
              disabled={disabled}
            />
          </div>
          <div className="additional_information_input">
            <label htmlFor="startDate">Start date</label>
            <input
              type={"text"}
              style={{ width: "33%" }}
              name="startDate"
              value={startDate !== null ? startDate : ""}
              onChange={(e) => {
                setstartDate(e.currentTarget.value);
                setChanged(true);
              }}
              disabled={disabled}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdditionalInformation;
