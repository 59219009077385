import React, { useEffect, useRef, useState } from "react";
import "./InvoiceActions.scss";
import AddIcon from "@mui/icons-material/Add";
import AddInvoice from "../../../components/Buttons/AddInvoice/AddInvoice";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import FilterModal from "../FilterModal/FilterModal";
import dayjs, { Dayjs } from "dayjs";
import CustomDatePicker from "./CustomDatePicker/CustomDatePicker";

interface props {
  filter: string;
  setOpenNewInvoice: React.Dispatch<React.SetStateAction<boolean>>;
  setFilter: React.Dispatch<React.SetStateAction<string>>;
  allInvoicesFilter: boolean;
  setAllInvoicesFilter: React.Dispatch<React.SetStateAction<boolean>>;
  invoiceDateFilter: Dayjs | null;
  setInvoiceDateFilter: React.Dispatch<React.SetStateAction<Dayjs | null>>;
}

const InvoiceActions: React.FC<props> = ({
  setOpenNewInvoice,
  setFilter,
  filter,
  allInvoicesFilter,
  setAllInvoicesFilter,
  invoiceDateFilter,
  setInvoiceDateFilter,
}) => {
  const invoiceDateRef = useRef<HTMLDivElement>(null);
  const invoiceLabelRef = useRef<HTMLDivElement>(null);
  const [showFilter, setShowFilter] = useState(false);
  const [openSelectInvoice, setOpenSelectInvoice] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        invoiceDateRef.current &&
        (event.target as Node) !== invoiceLabelRef.current &&
        !invoiceDateRef.current.contains(event.target as Node)
      ) {
        setOpenSelectInvoice(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [invoiceDateRef]);

  return (
    <div className="invoices_header_filter">
      <div className="invoice_header_filter_date">
        <div
          className="invoice_header_filter_date_text"
          onClick={() => setOpenSelectInvoice((prev) => !prev)}
          ref={invoiceLabelRef}
        >
          {allInvoicesFilter
            ? "All"
            : invoiceDateFilter?.format("MMMM YYYY").toString()}
          <label>Invoice month</label>
        </div>
        {openSelectInvoice && (
          <CustomDatePicker
            value={invoiceDateFilter}
            setValue={setInvoiceDateFilter}
            allInvoices={allInvoicesFilter}
            setAllInvoices={setAllInvoicesFilter}
            divRef={invoiceDateRef}
          />
        )}
      </div>

      <div
        className="invoices_header_filter_choose"
        onClick={() => setShowFilter((prev) => !prev)}
      >
        Filter by status
        <KeyboardArrowDownIcon sx={{ color: "#008db9" }} />
      </div>
      <AddInvoice onClick={() => setOpenNewInvoice((prev) => !prev)}>
        <div className="invoice_button_icon">
          <AddIcon
            sx={{
              borderRadius: "50%",
              color: "#008db9",
              fontSize: 20,
            }}
          />
        </div>
        Add Invoice
      </AddInvoice>
      {showFilter && <FilterModal setFilter={setFilter} filter={filter} />}
    </div>
  );
};

export default InvoiceActions;
