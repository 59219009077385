import React from "react";
import Description from "../Description/Description";
import Title from "../Title/Title";
import "./BasicInformation.scss";
import {
  CandidateEmails,
  CandidateNumbers,
} from "../../../components/interfaces/interfaces";
import RemoveExperience from "../../../components/Buttons/RemoveExperience/RemoveExperience";
import AddExperience from "../../../components/Buttons/AddExperience/AddExperience";
import { CandidateLanguages } from "../../../components/interfaces/interfaces";
import HandlePaste from "../../../hooks/HandlePaste";

type SettingsType = {
  id: number;
  whitespaceRemove: boolean;
  clientNumber: number;
};

export interface Props {
  firstname: string;
  lastName: string;
  email: CandidateEmails[];
  number: CandidateNumbers[];
  place: string;
  position: string;
  summary: string;
  skills: string;
  status: string;
  certification: string;
  links: string;
  fullTemplate: boolean;
  reason: string;
  languages: CandidateLanguages[];
  region: string;
  disabled: boolean;
  settings: SettingsType | undefined;
  contractType: string;

  setFirstname: React.Dispatch<React.SetStateAction<any>>;
  setLastName: React.Dispatch<React.SetStateAction<any>>;
  setEmail: React.Dispatch<React.SetStateAction<any>>;
  setNumber: React.Dispatch<React.SetStateAction<any>>;
  setPlace: React.Dispatch<React.SetStateAction<any>>;
  setPosition: React.Dispatch<React.SetStateAction<any>>;
  setSummary: React.Dispatch<React.SetStateAction<any>>;
  setSkills: React.Dispatch<React.SetStateAction<any>>;
  setStatus: React.Dispatch<React.SetStateAction<any>>;
  setCertification: React.Dispatch<React.SetStateAction<any>>;
  setLinks: React.Dispatch<React.SetStateAction<any>>;
  setChanged: React.Dispatch<React.SetStateAction<boolean>>;
  setReason: React.Dispatch<React.SetStateAction<any>>;
  setLanguages: React.Dispatch<React.SetStateAction<CandidateLanguages[]>>;
  setRegion: React.Dispatch<React.SetStateAction<string>>;
  setContractType: React.Dispatch<React.SetStateAction<string>>;
}

const BasicInformation: React.FC<Props> = ({
  fullTemplate,
  firstname,
  lastName,
  email,
  number,
  place,
  position,
  summary,
  skills,
  status,
  certification,
  links,
  reason,
  languages,
  region,
  disabled,
  settings,
  contractType,
  setFirstname,
  setLastName,
  setEmail,
  setNumber,
  setPlace,
  setPosition,
  setSummary,
  setSkills,
  setStatus,
  setCertification,
  setLinks,
  setChanged,
  setReason,
  setLanguages,
  setRegion,
  setContractType,
}) => {
  const add = (
    setState: React.Dispatch<React.SetStateAction<any>>,
    array: any[],
    field: {}
  ) => {
    setState([...array, field]);
  };

  const remove = (
    index: number,
    setState: React.Dispatch<React.SetStateAction<any>>,
    array: any[]
  ) => {
    setState([...array.slice(0, index), ...array.slice(index + 1)]);
  };

  const update = (
    text: string,
    index: number,
    setState: React.Dispatch<React.SetStateAction<any>>,
    array: any[],
    type: string
  ) => {
    const newArray = [...array];
    newArray[index][type] = text;
    setState(newArray);
  };
  return (
    <div className="basic_information">
      <div className="basic_information_description">
        <Title title="Basic information" />
        <Description
          description="Basic personal information with a brief and general description of
          work experiences, technological focus and interests."
        />
      </div>
      <div className="basic_information_inputs_container">
        <div className="basic_information_input">
          <label htmlFor="firstname">First name</label>
          <input
            type={"text"}
            required
            style={{ width: "50%" }}
            name="firstname"
            value={firstname}
            onChange={(e) => {
              setFirstname(e.target.value);
              setChanged(true);
            }}
            disabled={disabled}
          />
        </div>
        <div className="basic_information_input">
          <label htmlFor="lastname">Last name</label>
          <input
            type={"text"}
            required
            style={{ width: "50%" }}
            name="lastname"
            value={lastName}
            onChange={(e) => {
              setLastName(e.target.value);
              setChanged(true);
            }}
            disabled={disabled}
          />
        </div>
        {email.map((emailElement: CandidateEmails, index: number) => (
          <div className="basic_information_email_input" key={index}>
            <div className="basic_information_input">
              <label htmlFor="email">Email address</label>
              <input
                type={"email"}
                required
                style={{ width: "50%" }}
                name="email"
                value={emailElement.email}
                onChange={(e) => {
                  // updateValue(e.target.value, index);
                  update(e.target.value, index, setEmail, email, "email");
                  setChanged(true);
                }}
                disabled={disabled}
              />
            </div>
            <div className="email_buttons">
              {index === 0
                ? !disabled && (
                    <AddExperience
                      type="button"
                      onClick={() => add(setEmail, email, { email: "" })}
                    >
                      +
                    </AddExperience>
                  )
                : !disabled && (
                    <RemoveExperience
                      type="button"
                      onClick={() => {
                        remove(index, setEmail, email);
                      }}
                    >
                      -
                    </RemoveExperience>
                  )}
            </div>
          </div>
        ))}
        {number.map((numberElement: CandidateNumbers, index: number) => (
          <div className="basic_information_email_input" key={index}>
            <div className="basic_information_input">
              <label htmlFor="number">Phone Number</label>
              <input
                type={"text"}
                required
                style={{ width: "33%" }}
                name="number"
                value={numberElement.phone}
                onChange={(e) => {
                  // updateNumberValue(e.target.value, index);
                  update(e.target.value, index, setNumber, number, "phone");
                  setChanged(true);
                }}
                disabled={disabled}
              />
            </div>
            <div className="email_buttons">
              {index === 0
                ? !disabled && (
                    <AddExperience
                      type="button"
                      onClick={() => add(setNumber, number, { phone: "" })}
                    >
                      +
                    </AddExperience>
                  )
                : !disabled && (
                    <RemoveExperience
                      type="button"
                      onClick={() => remove(index, setNumber, number)}
                    >
                      -
                    </RemoveExperience>
                  )}
            </div>
          </div>
        ))}
        <div className="basic_information_input">
          <label htmlFor="place">Place</label>
          <input
            type={"text"}
            required
            style={{ width: "50%" }}
            name="place"
            value={place}
            onChange={(e) => {
              setPlace(e.target.value);
              setChanged(true);
            }}
            disabled={disabled}
          />
        </div>
        <div className="basic_information_input">
          <label htmlFor="region">Region</label>
          <input
            type={"text"}
            style={{ width: "50%" }}
            name="region"
            value={region !== null ? region : ""}
            onChange={(e) => {
              setRegion(e.target.value);
              setChanged(true);
            }}
            disabled={disabled}
          />
        </div>
        <div className="basic_information_input">
          <label htmlFor="position">Position</label>
          <input
            type={"text"}
            required
            style={{ width: "33%" }}
            name="position"
            value={position}
            onChange={(e) => {
              setPosition(e.target.value);
              setChanged(true);
            }}
            disabled={disabled}
          />
        </div>
        <div className="basic_information_input">
          <label htmlFor="skills">Skills</label>
          <textarea
            name="skills"
            rows={5}
            value={skills}
            onChange={(e) => {
              setSkills(
                settings?.whitespaceRemove
                  ? HandlePaste(e.target.value)
                  : e.target.value
              );
              setChanged(true);
            }}
            disabled={disabled}
          />
        </div>
        <div className="basic_information_input">
          <label htmlFor="certifications">Certifications</label>
          <textarea
            name="certifications"
            rows={5}
            value={certification}
            onChange={(e) => {
              setCertification(
                settings?.whitespaceRemove
                  ? HandlePaste(e.target.value)
                  : e.target.value
              );
              setChanged(true);
            }}
            disabled={disabled}
          />
        </div>
        <div className="basic_information_input">
          <label htmlFor="links">Links</label>
          <textarea
            name="links"
            rows={5}
            value={links}
            onChange={(e) => {
              setLinks(
                settings?.whitespaceRemove
                  ? HandlePaste(e.target.value)
                  : e.target.value
              );
              setChanged(true);
            }}
            disabled={disabled}
          />
        </div>
        <div className="basic_information_input">
          <label htmlFor="summary">Summary</label>
          <textarea
            name="summary"
            rows={5}
            value={summary}
            onChange={(e) => {
              setSummary(
                settings?.whitespaceRemove
                  ? HandlePaste(e.target.value)
                  : e.target.value
              );
              setChanged(true);
            }}
            disabled={disabled}
          />
        </div>
        {languages.map((language, index) => (
          <div className="basic_information_email_input" key={index}>
            <div className="basic_information_input">
              <label htmlFor="languages">Languages</label>
              <input
                type={"text"}
                name="languages"
                style={{ width: "50%" }}
                value={language.language}
                onChange={(e) => {
                  update(
                    e.target.value,
                    index,
                    setLanguages,
                    languages,
                    "language"
                  );
                  setChanged(true);
                }}
                disabled={disabled}
              />
            </div>
            <div className="email_buttons">
              {index === 0
                ? !disabled && (
                    <AddExperience
                      type="button"
                      onClick={() =>
                        add(setLanguages, languages, { language: "" })
                      }
                    >
                      +
                    </AddExperience>
                  )
                : !disabled && (
                    <RemoveExperience
                      type="button"
                      onClick={() => remove(index, setLanguages, languages)}
                    >
                      -
                    </RemoveExperience>
                  )}
            </div>
          </div>
        ))}
        {fullTemplate && (
          <>
            <div className="basic_information_input">
              <label htmlFor="status">Status</label>
              <select
                required
                name="status"
                value={status}
                onChange={(e) => {
                  setStatus(e.target.value);
                  setChanged(true);
                }}
              >
                <option value={""}>{""}</option>
                <option value="Hired">Hired</option>
                <option value="Interview">Interview</option>
                <option value="Rejected">Rejected</option>
                <option value="User Rejected">User Rejected</option>
                <option value="Accepted">Accepted</option>
              </select>
            </div>
            <div className="basic_information_input">
              <label htmlFor="reason">Status reason</label>
              <textarea
                name="reason"
                rows={5}
                value={reason === null ? "" : reason}
                onChange={(e) => {
                  setReason(
                    settings?.whitespaceRemove
                      ? HandlePaste(e.target.value)
                      : e.target.value
                  );
                  setChanged(true);
                }}
              />
            </div>
            <div className="basic_information_input">
              <label htmlFor="contractType">Contract type</label>
              <select
                required
                name="contractType"
                value={contractType}
                onChange={(e) => {
                  setContractType(e.target.value);
                  setChanged(true);
                }}
              >
                <option value={""}>{""}</option>
                <option value="Contract">Contract</option>
                <option value="Full Time">Full time</option>
                <option value="Part Time">Part Time</option>
                <option value="Internship">Internship</option>
              </select>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default BasicInformation;
