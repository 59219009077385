import { View, Text, Font } from "@react-pdf/renderer";
import React from "react";
import { Invoicestyles } from "../InvoiceStyles/InvoiceStyle";

import arial from "../../../../assets/fonts/Arial_medium.ttf";
import arialText from "../../../../assets/fonts/arialW.ttf";
import arialBold from "../../../../assets/fonts/Arial_bold.TTF";

Font.register({
  family: "Roboto",
  fonts: [
    {
      src: arial,
    },
    {
      src: arialText,
      fontWeight: 300,
    },
    { src: arialBold, fontWeight: 700 },
  ],
});

const InvoiceText = () => {
  return (
    <View style={Invoicestyles.invoiceText}>
      <Text
        style={{
          fontSize: 8.5,
          fontFamily: "Roboto",
          fontWeight: 300,
          lineHeight: 1.6,
        }}
      >
        Fakturujeme Vám za dodaný tovar či služby:
      </Text>
    </View>
  );
};

export default InvoiceText;
