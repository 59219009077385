import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import UseAuth from "../../hooks/UseAuth";

interface Props {
  allowedRoles: string[];
}

const RouteAuth: React.FC<Props> = ({ allowedRoles }) => {
  const { auth } = UseAuth();
  const location = useLocation();
  const content = allowedRoles.some((roleElement) =>
    auth.userRoles.includes(roleElement)
  ) ? (
    <Outlet />
  ) : (
    <Navigate to="/" state={{ from: location }} replace />
  );

  return content;
};
export default RouteAuth;
