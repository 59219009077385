import React from "react";
import "./GDPR.scss";

import Description from "../Description/Description";
import Title from "../Title/Title";

interface Props {
  fullTemplate: boolean;
  gdpr: boolean;
  documentLocation: string;
  gdprValidation: string | null;
  disabled: boolean;

  setGdpr: React.Dispatch<React.SetStateAction<any>>;
  setDocumentLocation: React.Dispatch<React.SetStateAction<any>>;
  setGdprValidation: React.Dispatch<React.SetStateAction<any>>;
  setChanged: React.Dispatch<React.SetStateAction<boolean>>;
}

const GDPR: React.FC<Props> = ({
  fullTemplate,
  gdpr,
  documentLocation,
  gdprValidation,
  disabled,
  setGdpr,
  setDocumentLocation,
  setGdprValidation,
  setChanged,
}) => {
  return (
    <div className="gdpr">
      <div className="gdpr_description">
        <Title title="GDPR information" />
        <Description description="Information about approved GDPR and place where documents are stored." />
      </div>
      <div className="gdpr_inputs_container">
        <div className="gdpr_input">
          <label htmlFor="firstname">GDPR approval</label>
          <input
            type={"checkbox"}
            name="firstname"
            checked={gdpr}
            onChange={(e) => {
              setGdpr((prev: boolean) => !prev);
              setChanged(true);
            }}
            disabled={disabled}
          />
        </div>
        {fullTemplate ? (
          <>
            <div className="gdpr_input">
              <label htmlFor="lastname">GDPR Expiration Date</label>
              <input
                type={"date"}
                style={{ width: "20%" }}
                name="lastname"
                value={gdprValidation || ""}
                onChange={(e) => {
                  if (e.target.value === null) {
                    setGdprValidation("");
                  } else {
                    setGdprValidation(e.target.value);
                  }
                }}
                disabled={disabled}
              />
            </div>
            <div className="gdpr_input">
              <label htmlFor="lastname">Personal folder location</label>
              <input
                type={"text"}
                style={{ width: "50%" }}
                name="lastname"
                value={documentLocation}
                onChange={(e) => {
                  setDocumentLocation(e.target.value);
                  setChanged(true);
                }}
                disabled={disabled}
              />
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default GDPR;
