import React from "react";
import "./UserDashboardWidget.scss";

interface Props {
  children: React.ReactNode;
}

const UserDashboardWidget: React.FC<Props> = ({ children }) => {
  return <div className="user_dashboard_widget">{children}</div>;
};

export default UserDashboardWidget;
