import Box from "@mui/material/Box/Box";
import Modal from "@mui/material/Modal/Modal";
import TextField from "@mui/material/TextField/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider/LocalizationProvider";
import dayjs, { Dayjs } from "dayjs";
import React, { useState } from "react";
import CancelButton from "../../../../components/Buttons/Cancel/CancelButton";
import SubmitButton from "../../../../components/Buttons/Submit/SubmitButton";
import Notification from "../../../../components/Notification/Notification";
import "./UpdateEventModal.scss";
import { useEffect } from "react";
import { EventTypeSelected } from "../../../../components/interfaces/interfaces";
import RemoveExperience from "../../../../components/Buttons/RemoveExperience/RemoveExperience";
import UseAuth from "../../../../hooks/UseAuth";
import { ROLES } from "../../../../components/Roles/Roles";

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedEvent: EventTypeSelected | undefined;
}

const UpdateEventModal: React.FC<Props> = ({
  open,
  setOpen,
  selectedEvent,
}) => {
  const { auth } = UseAuth();
  const [title, setTitle] = useState<string | undefined>(selectedEvent?.title);
  const [firstDate, setFirstDate] = useState<Dayjs | null>(
    dayjs(selectedEvent?.start)
  );
  const [secondDate, setSecondDate] = useState<Dayjs | null>(
    dayjs(selectedEvent?.end)
  );

  //Notification
  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");
  const [displayNotification, setDisplayNotification] = useState(true);

  const [accessToken, setAccessToken] = useState("");
  const timeZone = "Europe/Prague";
  const calendarID = "aberesik%40gmail.com";

  const renderNotification = (Message: string, Error: boolean) => {
    setError(Error);
    setMessage(Message);
    setDisplayNotification(true);
    const timeId = setTimeout(() => {
      // After 3 seconds set the show value to false
      setDisplayNotification(false);
    }, 4000);

    return () => {
      clearTimeout(timeId);
    };
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleUpdateEvent = async () => {
    let token = accessToken;
    const payload = {
      summary: title,
      location: "",
      start: {
        dateTime: dayjs(firstDate).format("YYYY-MM-DDTHH:mm:00"),
        timeZone: timeZone,
      },
      end: {
        dateTime: dayjs(secondDate).format("YYYY-MM-DDTHH:mm:00"),
        timeZone: timeZone,
      },
      attendees: [],
      reminders: {
        useDefault: false,
        overrides: [{ method: "email", minutes: 24 * 60 }],
      },
    };
    if (token === "") {
      await gapi.auth.authorize(
        {
          client_id:
            "725912353387-d73mbc9levd7tcftnnfe9cehkilpftgn.apps.googleusercontent.com",
          scope:
            "https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.events",
          response_type: " id_token permission",
        },
        function (response: any) {
          token = response.access_token;
          setAccessToken(response.access_token);
        }
      );
    }
    function initiate() {
      gapi.client
        .request({
          path: `https://www.googleapis.com/calendar/v3/calendars/${calendarID}/events/${selectedEvent?.event_id}`,
          method: "PUT",
          body: payload,
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then(
          (response) => {
            renderNotification("Event updated", true);
            return [true, response];
          },
          function (err) {
            resendCalendarAction(payload, "PUT");
            return [false, err];
          }
        );
    }
    gapi.load("client:auth2", initiate);
    setOpen(false);
  };
  const resendCalendarAction = async (payload: any, action: string) => {
    let token = "";
    await gapi.auth.authorize(
      {
        client_id:
          "725912353387-d73mbc9levd7tcftnnfe9cehkilpftgn.apps.googleusercontent.com",
        scope:
          "https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.events",
        response_type: " id_token permission",
      },
      function (response: any) {
        token = response.access_token;
        setAccessToken(response.access_token);
      }
    );
    if (action === "PUT") {
      gapi.client
        .request({
          path: `https://www.googleapis.com/calendar/v3/calendars/${calendarID}/events/${selectedEvent?.event_id}`,
          method: "PUT",
          body: payload,
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then(
          (response) => {
            return [true, response];
          },
          function (err) {
            return [false, err];
          }
        );
    }
    if (action === "DELETE") {
      await gapi.auth.authorize(
        {
          client_id:
            "725912353387-d73mbc9levd7tcftnnfe9cehkilpftgn.apps.googleusercontent.com",
          scope:
            "https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.events",
          response_type: " id_token permission",
        },
        function (response: any) {
          token = response.access_token;
          setAccessToken(response.access_token);
        }
      );
    }
  };

  const deleteEvent = async () => {
    let token = accessToken;
    if (token === "") {
      await gapi.auth.authorize(
        {
          client_id:
            "725912353387-d73mbc9levd7tcftnnfe9cehkilpftgn.apps.googleusercontent.com",
          scope:
            "https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.events",
          response_type: " id_token permission",
        },
        function (response: any) {
          token = response.access_token;
          setAccessToken(response.access_token);
        }
      );
    }
    function initiate() {
      gapi.client
        .request({
          path: `https://www.googleapis.com/calendar/v3/calendars/${calendarID}/events/${selectedEvent?.event_id}`,
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(
          (response) => {
            renderNotification("Event deleted", true);
            return [true, response];
          },
          function (err) {
            return [false, err];
          }
        );
    }
    gapi.load("client:auth2", initiate);
    setOpen(false);
  };

  useEffect(() => {
    if (firstDate === null || secondDate === null || title === "") {
      setMessage("Please fill out all fields");
      setDisplayNotification(true);
    } else {
      setMessage("");
      setDisplayNotification(false);
    }
  }, [firstDate, secondDate, title]);
  return (
    <Modal open={open} onClose={handleClose}>
      <Box className="updateEvent_modal">
        {displayNotification ? (
          <Notification message={message} error={error} />
        ) : null}
        <div className="updateEvent_modal_container">
          <div className="updateEvent_modal_container_header">
            <p style={{ fontSize: 20 }}>Update event</p>
            {auth.userRoles === ROLES.ADMIN && (
              <RemoveExperience onClick={deleteEvent}>
                Delete Event
              </RemoveExperience>
            )}
          </div>
          <div className="updateEvent_modal_container_fields">
            <div className="user_input">
              <label htmlFor="title">Title</label>
              <input
                type={"text"}
                style={{ width: "50%" }}
                name="userName"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                required
              />
            </div>
            <div className="user_input_time">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  label="Start"
                  renderInput={(params) => <TextField {...params} />}
                  value={firstDate}
                  onChange={(newValue) => {
                    setFirstDate(newValue);
                  }}
                />
                <DateTimePicker
                  label="End"
                  renderInput={(params) => <TextField {...params} />}
                  value={secondDate}
                  onChange={(newValue) => {
                    setSecondDate(newValue);
                  }}
                />
              </LocalizationProvider>
            </div>
          </div>
          <div className="addEvent_modal_container_buttons">
            <SubmitButton
              onClick={handleUpdateEvent}
              disabled={displayNotification}
            >
              Update
            </SubmitButton>
            <CancelButton onClick={handleClose}>Cancel</CancelButton>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default UpdateEventModal;
