import {
  Paragraph,
  Table,
  TableRow,
  TableCell,
  TextRun,
  BorderStyle,
  WidthType,
} from "docx";
export const educationComponent = (arr: any) => {
  return new Table({
    columnWidths: [10000],
    rows: [
      new TableRow({
        children: [
          new TableCell({
            width: {
              size: 4500,
              type: WidthType.DXA,
            },
            borders: {
              top: {
                style: BorderStyle.SINGLE,
                size: 0,
                color: "e6e6e6",
              },
              left: {
                style: BorderStyle.SINGLE,
                size: 0,
                color: "e6e6e6",
              },
              bottom: {
                style: BorderStyle.SINGLE,
                size: 0,
                color: "e6e6e6",
              },
              right: {
                style: BorderStyle.NONE,
                size: 0,
                color: "FFFFFF",
              },
            },
            children: [
              new Paragraph({
                spacing: {
                  before: 35,
                  after: 35,
                },
                indent: {
                  left: 60,
                  right: 60,
                },
                children: [
                  new TextRun({
                    text: arr.study_from + " - " + arr.study_end,
                    font: "Arial",
                    size: 24,
                  }),
                ],
              }),
            ],
          }),
          new TableCell({
            width: {
              size: 5500,
              type: WidthType.DXA,
            },
            borders: {
              top: {
                style: BorderStyle.SINGLE,
                size: 0,
                color: "e6e6e6",
              },
              left: {
                style: BorderStyle.NONE,
                size: 0,
                color: "e6e6e6",
              },
              bottom: {
                style: BorderStyle.SINGLE,
                size: 0,
                color: "e6e6e6",
              },
              right: {
                style: BorderStyle.SINGLE,
                size: 0,
                color: "e6e6e6",
              },
            },
            children: [
              new Paragraph({
                spacing: {
                  before: 35,
                  after: 35,
                },
                indent: {
                  left: 60,
                  right: 60,
                },
                children: [
                  new TextRun({
                    text: arr.school_name,
                    font: "Arial",
                    size: 24,
                  }),
                ],
              }),
              new Paragraph({
                spacing: {
                  before: 35,
                  after: 35,
                },
                indent: {
                  left: 60,
                  right: 60,
                },
                children: [
                  new TextRun({
                    text: arr.faculty,
                    font: "Arial",
                    size: 24,
                  }),
                ],
              }),
              new Paragraph({
                spacing: {
                  before: 35,
                  after: 35,
                },
                indent: {
                  left: 60,
                  right: 60,
                },
                children: [
                  new TextRun({
                    text: arr.field_of_study,
                    font: "Arial",
                    size: 24,
                  }),
                ],
              }),
              new Paragraph({
                spacing: {
                  before: 35,
                  after: 35,
                },
                indent: {
                  left: 60,
                  right: 60,
                },
                children: [
                  new TextRun({
                    text: arr.description,
                    font: "Arial",
                    size: 24,
                  }),
                ],
              }),
            ],
          }),
        ],
      }),
    ],
  });
};
