import React from "react";
import "./DeleteCandidate.scss";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { useMutation, useQueryClient } from "react-query";
import { deleteCandidate } from "../../../../api/candidateApi";
import SubmitButton from "../../../../components/Buttons/Submit/SubmitButton";
import CancelButton from "../../../../components/Buttons/Cancel/CancelButton";

interface Props {
  open: boolean;
  id: number | undefined;
  name: any;

  setConfirmDelete: React.Dispatch<React.SetStateAction<any>>;
  setOpen: React.Dispatch<React.SetStateAction<any>>;
}
const DeleteCandidate: React.FC<Props> = ({
  open,
  id,
  name,
  setConfirmDelete,
  setOpen,
}) => {
  const queryClient = useQueryClient();
  const handleClose = () => {
    setOpen(false);
    setConfirmDelete(false);
  };
  const CVDeleteMutation = useMutation(deleteCandidate, {
    onSuccess: () => {
      // Invalidates cache and refetch
      queryClient.invalidateQueries("candidates");
    },
  });

  const handledeleteCandidate = () => {
    CVDeleteMutation.mutate(Number(id));
    setOpen(false);
    setConfirmDelete(false);
  };
  return (
    <div>
      <Modal open={open} onClose={handleClose}>
        <Box className="deleteCandidate_modal">
          <div className="deleteCandidate_modal_container">
            <h2>Confirm the action</h2>
            <p>
              Do you really want to delete candidate{" "}
              {name?.firstName + " " + name?.lastName} ?
            </p>
            <div className="deleteCandidate_modal_container_buttons">
              <SubmitButton onClick={handledeleteCandidate}>
                Delete
              </SubmitButton>
              <CancelButton onClick={handleClose}>Cancel</CancelButton>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default DeleteCandidate;
