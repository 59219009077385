import React, { useEffect, useState } from "react";
import { FilterType } from "../../../../../components/interfaces/interfaces";
import Filter from "./Filter/Filter";
import FilterOption from "./FilterOption/FilterOption";
import "./FilterView.scss";

interface Props {
  filter: FilterType[];
  setFilter: React.Dispatch<React.SetStateAction<FilterType[]>>;
}

const FilterView: React.FC<Props> = ({ filter, setFilter }) => {
  const [isInterSecting, setIsInterSecting] = useState(true);
  const [openFilter, setOpenFilter] = useState(false);
  const [payload, setPayload] = useState<FilterType>({
    name: "",
    option: "",
    value: "",
  });

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setFilter([...filter, payload]);
    setPayload({ name: "", option: "", value: "" });
    setOpenFilter(false);
  };
  const handleDelete = (id: number) => {
    setFilter([...filter.slice(0, id), ...filter.slice(id + 1)]);
  };
  useEffect(() => {
    if (!openFilter) {
      setPayload({
        name: "",
        option: "",
        value: "",
      });
    }
  }, [openFilter]);

  return (
    <div className="filter_view">
      {filter &&
        filter.map((filterElement, index) => (
          <div className="filter_option" key={index}>
            <p className="filter_option_name">{filterElement.name}</p>
            <p className="filter_option_option">{filterElement.option}</p>
            <p className="filter_option_name">{filterElement.value}</p>
            <p className="cross" onClick={() => handleDelete(index)}>
              x
            </p>
          </div>
        ))}
      <form className="add_filter" onSubmit={(event) => handleSubmit(event)}>
        <p className="add" onClick={() => setOpenFilter((prev) => !prev)}>
          +
        </p>
        {openFilter && (
          <Filter
            setPayload={setPayload}
            setIsInterSecting={setIsInterSecting}
          />
        )}
        {payload.name !== "" && (
          <FilterOption
            payload={payload}
            setPayload={setPayload}
            isInterSecting={isInterSecting}
          />
        )}
      </form>
    </div>
  );
};

export default FilterView;
