import React, { useRef } from "react";
import AddExperience from "../../../components/Buttons/AddExperience/AddExperience";
import RemoveExperience from "../../../components/Buttons/RemoveExperience/RemoveExperience";
import {
  ExperienceData,
  SettingsType,
} from "../../../components/interfaces/interfaces";
import HandlePaste from "../../../hooks/HandlePaste";
import Description from "../Description/Description";
import Title from "../Title/Title";
import { MdDragHandle } from "react-icons/md";
import "./Experience.scss";
import ReactTooltip from "react-tooltip";

export interface Props {
  experienceData: {
    id: number;
    name: string;
    description: string;
    experienceEnd: string;
    experienceStart: string;
    position: string;
    sequence: number;
  }[];
  disabled: boolean;
  settings: SettingsType | undefined;
  setExperiencesData: React.Dispatch<React.SetStateAction<any>>;
  setChanged: React.Dispatch<React.SetStateAction<boolean>>;
}
const Experience: React.FC<Props> = ({
  experienceData,
  disabled,
  settings,
  setExperiencesData,
  setChanged,
}) => {
  const dragItem = useRef(null);
  const dragOverItem = useRef(null);
  const dragStart = (position: any) => {
    dragItem.current = position;
  };
  const dragEnter = (position: any) => {
    dragOverItem.current = position;
  };

  const drop = () => {
    var copyListItems = [...experienceData];
    let firstIndex = 0;
    let lastIndex = 0;
    copyListItems = copyListItems.map((obj, index) => {
      if (obj.sequence === dragItem.current) {
        firstIndex = index;
        return { ...obj, sequence: dragOverItem.current! };
      } else if (obj.sequence === dragOverItem.current!) {
        lastIndex = index;
        return { ...obj, sequence: dragItem.current! };
      }
      return obj;
    });
    const dragItemContent = copyListItems[firstIndex];
    copyListItems.splice(firstIndex, 1);
    copyListItems.splice(lastIndex, 0, dragItemContent!);
    dragItem.current = null;
    dragOverItem.current = null;
    setExperiencesData(copyListItems);
    setChanged(true);
  };

  const addExperience = () => {
    const maxId = experienceData.length
      ? Math.max(...experienceData.map((experience) => experience.id))
      : 0;
    const maxPosition = experienceData.length
      ? Math.max(...experienceData.map((experience) => experience.sequence)) + 1
      : 0;
    const experienceFields = {
      id: maxId + 1,
      name: "",
      description: "",
      experienceStart: "",
      experienceEnd: "",
      position: "",
      sequence: maxPosition,
    };
    setExperiencesData([experienceFields, ...experienceData]);
    setChanged(true);
  };

  const updateValue = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>,
    id: number
  ) => {
    const name = e.currentTarget.name;
    const value = settings?.whitespaceRemove
      ? HandlePaste(e.currentTarget.value)
      : e.currentTarget.value;
    setExperiencesData((current: []) =>
      current.map((experience: ExperienceData) => {
        if (Number(experience.id) === id) {
          return { ...experience, [name]: value.toString() };
        }
        return experience;
      })
    );
    setChanged(true);
  };

  const removeExperience = (id: number) => {
    setExperiencesData((current: []) =>
      current.filter((experience: ExperienceData) => {
        return Number(experience.id) !== id;
      })
    );
    setChanged(true);
  };
  return (
    <div className="experience">
      <div className="experience_body">
        <div className="experience_description">
          <Title title="Experience" />
          <Description description="Achieved work experiences with detailed description about interesting tasks performed, technologies used and processes." />
          {!disabled && (
            <div className="experience_addButton">
              <AddExperience type="button" onClick={addExperience}>
                + Experience
              </AddExperience>
            </div>
          )}
        </div>
        <div className="experience_input">
          {experienceData &&
            experienceData
              .sort((a, b) => (a.sequence > b.sequence ? -1 : 1))
              .map((experience: ExperienceData, index: number) => (
                <div
                  className="experience_input_container"
                  key={experience.id}
                  onDragStart={() => dragStart(experience.sequence)}
                  onDragEnter={() => dragEnter(experience.sequence)}
                  onDragEnd={drop}
                  draggable={!disabled}
                >
                  <div className="experience_input_row">
                    <div className="experience_input">
                      <label htmlFor="name">Experience name</label>
                      <input
                        type={"text"}
                        style={{ width: "80%" }}
                        name="name"
                        value={experience.name}
                        onChange={(e) => updateValue(e, experience.id)}
                        disabled={disabled}
                      />
                    </div>
                    <div className="experience_input">
                      <label htmlFor="position">Experience position</label>
                      <input
                        type={"text"}
                        style={{ width: "80%" }}
                        name="position"
                        value={experience.position}
                        onChange={(e) => updateValue(e, experience.id)}
                        disabled={disabled}
                      />
                    </div>
                  </div>
                  <div className="experience_input">
                    <label htmlFor="description">Description</label>
                    <textarea
                      name="description"
                      rows={5}
                      value={experience.description}
                      onChange={(e) => updateValue(e, experience.id)}
                      disabled={disabled}
                    />
                  </div>
                  <div className="experience_input_row">
                    <div className="experience_input">
                      <label htmlFor="experienceStart">From</label>
                      <input
                        type={"text"}
                        style={{ width: "80%" }}
                        name="experienceStart"
                        value={
                          experience.experienceStart !== null
                            ? experience.experienceStart
                            : ""
                        }
                        onChange={(e) => updateValue(e, experience.id)}
                        disabled={disabled}
                      />
                    </div>
                    <div className="experience_input">
                      <label htmlFor="experienceEnd">To</label>
                      <input
                        type={"text"}
                        style={{ width: "80%" }}
                        name="experienceEnd"
                        value={
                          experience.experienceEnd !== null
                            ? experience.experienceEnd
                            : ""
                        }
                        onChange={(e) => updateValue(e, experience.id)}
                        disabled={disabled}
                      />
                    </div>
                  </div>
                  {!disabled && (
                    <div className="experience_input">
                      <RemoveExperience
                        type="button"
                        onClick={() => removeExperience(experience.id)}
                      >
                        - Experience
                      </RemoveExperience>
                    </div>
                  )}
                  <p className="sequence_number">{index + 1}</p>
                  {!disabled && (
                    <>
                      <div
                        id="drag_icon"
                        className="drag_icon"
                        data-tip
                        data-for={"tooltip"}
                      >
                        <MdDragHandle className="drag_handle" />
                      </div>
                      <ReactTooltip
                        id="tooltip"
                        effect="solid"
                        className="exp_tooltip"
                      >
                        Drag to another position
                      </ReactTooltip>
                    </>
                  )}
                </div>
              ))}
        </div>
      </div>
    </div>
  );
};

export default Experience;
