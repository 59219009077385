import React from "react";
import "./NewCVHeader.scss";

import kodivaLogo from "../../../assets/images/kodiva_logo_simple.png";
import PDF from "../../../assets/images/PDF.svg";
import { FcDocument } from "react-icons/fc";

interface Props {
  showPdf: boolean;
  disabled: boolean;

  setEditPDF: React.Dispatch<React.SetStateAction<boolean>>;
  setCVType: React.Dispatch<React.SetStateAction<string>>;
}

const NewCVHeader: React.FC<Props> = ({
  showPdf,
  setEditPDF,
  disabled,
  setCVType,
}) => {
  return (
    <div className="newCV_header">
      <div className="newCV_header_info">
        <img src={kodivaLogo} alt="kodiva" />
        <h4>Kodiva CV</h4>
      </div>
      {(showPdf || !disabled) && (
        <div className="new_CV_header_buttons">
          <FcDocument
            className="pdf"
            style={{ height: 36, width: 36 }}
            onClick={() => {
              setCVType("WORD");
              setEditPDF((prev) => !prev);
            }}
          />
          <div>
            <img
              src={PDF}
              alt="semi_pdf"
              className="pdf_img_full"
              onClick={() => {
                setCVType("PDF");
                setEditPDF((prev) => !prev);
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default NewCVHeader;
